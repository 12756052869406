import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { WarningModalComponent } from './warning-modal/warning-modal.component';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard  {

    constructor(private modalService: BsModalService) {}

    public canDeactivate(
        component: CanComponentDeactivate,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        if (component && component.canDeactivate && !component.canDeactivate()) {
            // console.log(component.canDeactivate());
            const bsModalRef = this.modalService.show(WarningModalComponent, { backdrop: 'static' });
            return bsModalRef.content.state;
        }

        return true;
    }
}
