import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {IFrachtOrt} from '../api-dtos/fracht-ort';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class FrachtOrtService {
  baseUrl = `${environment.restApiUrl}/frachtort`;

  constructor(
    private http: HttpClient) { }

  public getAll(): Observable<IFrachtOrt[]> {
    return this.http.get<IFrachtOrt[]>(
      this.baseUrl,
      FrachtOrtService.getHttpHeader()
    ).pipe(
      map(data => {
        return data;
      })
    );
  }

  public getAllFaehre(): Observable<IFrachtOrt[]> {
    return this.http.get<IFrachtOrt[]>(
      `${this.baseUrl}/faehre`,
      FrachtOrtService.getHttpHeader()
    ).pipe(
      map(data => {
        return data;
      })
    );
  }
  public getAllFlug(): Observable<IFrachtOrt[]> {
    return this.http.get<IFrachtOrt[]>(
      `${this.baseUrl}/flug`,
      FrachtOrtService.getHttpHeader()
    ).pipe(
      map(data => {
        return data;
      })
    );
  }

  private static getHttpHeader() {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return httpOptions;
  }

}
