import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ITransportStatus} from '../api-dtos/api-dtos.module';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class TransportStatusService {
  baseUrl = `${environment.restApiUrl}/transportstatus`;
  transportStatusCache: Observable<ITransportStatus[]>;

  constructor(
    private http: HttpClient) { }

  public getAll(): Observable<ITransportStatus[]> {
    if (!this.transportStatusCache) {
      this.transportStatusCache = this.http
        .get<ITransportStatus[]>(this.baseUrl, this.getHttpHeader());
    }
    return this.transportStatusCache;
  }

  public getTransportStatus(id: number): Observable<ITransportStatus> {
    return this.http.get<ITransportStatus>(
      `${this.baseUrl}/${id}`,
      this.getHttpHeader()
    );
  }

  private getHttpHeader() {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }
}


