import {DataElement} from '../api-basics/data-element';
import {IFahrplan} from './fahrplan';

export class GepaeckTransaction implements DataElement {
  id?: number;
  mand_id?: number;
  gptra_id?: number;
  gptra_datzeit?: Date;
  gptra_ziel_ort?: string;
  fp_id?: number;
  gptra_fahrplan_plan?: IFahrplan;
  gptra_bemerk_text?: string;
}
