import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthenticationService} from './shared-services/authentication.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NavigationModule} from './navigation/navigation.module';
import {ConfigurationModule} from './configuration/configuration.module';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {WarningModalComponent} from './guards/warning-modal/warning-modal.component';
import {AuthorizationGuard} from './guards/authorization.guard';
import {UserService} from './shared-services/user.service';
import {AlertService} from './shared-services/alert.service';
import {MaterialModule} from './material/material.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomeComponent} from './home/home.component';
import {JwtInterceptor} from './helpers/jwt.interceptor';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BackButtonDirective} from './directives';
import {DxButtonModule, DxDataGridModule, DxTooltipModule} from 'devextreme-angular';
import {HttpErrorInterceptor} from './api/http-interceptors/error-interceptor';
import {PagesModule} from './pages/pages.module';
import {LoginModule} from './login/login.module';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {
    ClarityModule,
    ClrAccordionModule,
    ClrAlertModule,
    ClrMainContainerModule,
    ClrNavigationModule,
    ClrSpinnerModule
} from '@clr/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {QRCodeModule} from 'angularx-qrcode';
import { CspNonceDirective } from './directives/csp-nonce.directive';

export function init_auth(authenticationService: AuthenticationService) {
  return () => authenticationService.initializeAuth();
}

registerLocaleData(localeDe, 'de');

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ButtonsModule.forRoot(),
    ClarityModule,
    ClrAlertModule,
    ClrMainContainerModule,
    ClrNavigationModule,
    ClrSpinnerModule,
    ClrAccordionModule,
    DxButtonModule,
    DxDataGridModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    PopoverModule.forRoot(),
    NavigationModule,
    ConfigurationModule,
    ReactiveFormsModule,
    AppRoutingModule,
    PagesModule,
    QRCodeModule,
    LoginModule,
    DxTooltipModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    WarningModalComponent,
    BackButtonDirective,
    CspNonceDirective,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de-DE'},
    AlertService,
    AuthenticationService,
    /*{ provide: APP_INITIALIZER, useFactory: init_auth, deps: [AuthenticationService], multi: true },*/
    AuthorizationGuard,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 20000}},
    UserService
  ],
  exports: [
    CspNonceDirective
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

