export class ShopAddress {
  id?: number;
  firstName?: string;
  lastName?: string;
  company?: string;
  street?: string;
  addressAddition?: string;
  zip?: string;
  city?: string;
  state?: string;
  country?: string;
}
