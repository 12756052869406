import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Lock} from '../api-dtos/lock';
import {AuthenticationService} from '../../shared-services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LockService {
  baseUrl = `${environment.restApiUrl}`;

  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient) {
  }

  private async getHttpHeaderAsync() {
    const accessToken: string = await localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  private static getHttpHeader() {
    const accessToken: string = localStorage.getItem('sessionKey');
    let bodyVar: any;
    let httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      body: bodyVar
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  static handleError(error: any) {
    let errorMessage: string;

    if (error.error && error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      if (error.message && error.message !== '') {
        errorMessage = `${error.message}`;
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
    }
    window.alert(errorMessage);
    return;
  }

  unlock(lockCategory: LockCategory, internalTag: string, tableShortcut: string) {
    return;
  }

  unlockReservation(internalTag: string, tableShortcut: string){
    return this.unlock(LockCategory.Reservation, internalTag, tableShortcut);
  }

  public async createReservation(lockData: Lock): Promise<Lock> {
    if (lockData === undefined ) {
      return;
    }

    lockData.lock_create_user = this.authenticationService.isAuthenticated && !!this.authenticationService.currentUser?.userName
      ? this.authenticationService.currentUser.userName
      : 'Gast';

    return await this.http.post<Lock>(
      `${this.baseUrl}/reservation`,
      lockData,
      LockService.getHttpHeader()
    ).toPromise().then((res: Lock) => {
      if (res !== undefined && res.lock_id && res.lock_id !== 0 && res.status === 'success') {
        return res;
      } else {
        return;
      }
    });
  }

  public async deleteReservation(lockData: Lock) {
    if (lockData === undefined ) {
      return;
    }
      try {
      let httpOptions = LockService.getHttpHeader();
      httpOptions.body = lockData;
      return await this.http.delete(
        `${this.baseUrl}/reservation`,
        httpOptions
      ).toPromise();
    } catch (error) {
      await LockService.handleError;
    }
  }


}

export enum LockCategory {
  Default = 1,
  Lock = 1,
  Reservation = 100
}
