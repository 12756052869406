<div class="dx-card sits-card">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header">
      <dx-toolbar>
        <dxi-item widget="dxButton" location="before">
          <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
        </dxi-item>
        <dxi-item location="left" widget="dxButton" locateInMenu="never">
          <div *dxTemplate>
            <div class="toolbar-label">{{[this.componentTitle]}}</div>
          </div>
        </dxi-item>
      </dx-toolbar>
    </div>
    <dx-toolbar class="py-2 px-1">
      <dxi-item id="btnminus1" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button (onClick)="toolbarAction('minus01')" hint="Datum um einen Tag reduzieren"
                   icon="chevronleft"></dx-button>
      </dxi-item>
      <dxi-item id="dateselection" locateInMenu="auto" location="before" widget="dxDateBox">
        <dx-date-box #dateSelector
                     (onValueChanged)="dateChanged($event)"
                     [(value)]="schedulerDate"
                     [max]="lastDayOfNextYear"
                     [min]="firstDayOfLastYear"
                     [width]="125"
                     pickerType="calendar"
                     displayFormat="E dd.MM.yy"
                     [showClearButton]="false"
                     type="date">
        </dx-date-box>
      </dxi-item>
      <dxi-item id="btnplus1" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button (onClick)="toolbarAction('plus01')" hint="Datum um einen Tag erhöhen"
                   icon="chevronright"></dx-button>
      </dxi-item>
      <dxi-item id="mitarbeiterselection" locateInMenu="auto" location="before" widget="dxTagBox">
        <dx-lookup
          [dataSource]="mitarbeiters"
          displayExpr="mit_such_name"
          valueExpr="mit_id"
          [value]="employeeId"
          [disabled]="false"
          [searchEnabled]="false"
          [showClearButton]="true"
          [showCancelButton]="false"
          placeholder="(Mitarbeitende ...)"
          hint="Person wählen, deren Aufgaben gelistet werden sollen. Löschen-Button in der Auswahl verwenden, um alle zu sehen. Eine Mehrfachauswahl gibt es bisher nicht. Keiner ausgewählt = ALLE"
          noDataText="keiner = Alle"
          (onValueChanged)="onValueChangedMitarbeiter($event)">
        </dx-lookup>
      </dxi-item>
      <dxi-item widget="dxButton" location="before" locateInMenu="auto">
        <dx-button icon="refresh" (click)="refreshList()"></dx-button>
      </dxi-item>

      <!--<dxi-item id="export" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button (onClick)="toolbarAction('exportxlsx')"
                   icon="exportxlsx"></dx-button>
      </dxi-item>-->
    </dx-toolbar>

    <!--keyExpr="scd_id"-->
    <dx-data-grid #grid class="schedulertask-selection-list"
                  id="grid-container"
                  [dataSource]="schedulers"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  columnResizingMode="widget"
                  [showBorders]="true"
                  [showColumnLines]="false"
                  [showRowLines]="true"
                  [hoverStateEnabled]="true"
                  [rowAlternationEnabled]="true"
                  (onExporting)="onExporting($event)">
      <dxo-paging [enabled]="true" [pageSize]="gridPageSize"></dxo-paging>
      <dxo-pager
        [visible]="true"
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[7,8,9,10,12,15,20,100]"></dxo-pager>
      <dxo-remote-operations
        [filtering]="true"
        [paging]="true"
        [sorting]="true"
        [summary]="false"
        [grouping]="true"
        [groupPaging]="true">
      </dxo-remote-operations>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-selection selectAllMode="allPages" mode="multiple" showCheckBoxesMode="none"></dxo-selection>
      <dxo-scrolling mode="standard" [useNative]="true"></dxo-scrolling>
      <dxo-load-panel
        [enabled]="true">
      </dxo-load-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-column-fixing [enabled]="false"></dxo-column-fixing>
      <dxi-column dataField="scd_start_stamp"
                  caption="Beginn"
                  width="auto"
                  dataType="date"
                  format=" HH:mm "
                  [sortIndex]="2"
                  sortOrder="asc"
                  [fixed]="false" fixedPosition="left"
      ></dxi-column>
      <dxi-column dataField="tsk_id"
                  [visible]="true"
                  caption="Aufgabe"
                  width="auto">
        <dxo-lookup [dataSource]="getTasks"
                    valueExpr="tsk_id"
                    displayExpr="tsk_name"
        ></dxo-lookup>

      </dxi-column>

      <dxi-column dataField="adr_id"
                  [visible]="true"
                  caption="Adresse"
                  width="auto">
        <dxo-lookup [dataSource]="getAdresses"
                    valueExpr="adr_id"
                    displayExpr="adr_gepaeck_name"
        ></dxo-lookup>
      </dxi-column>

      <dxi-column dataField="scd_assist0_mit_id"
                  [visible]="true"
                  caption="Mitarbeit."
                  width="auto">
        <dxo-lookup [dataSource]="getMitarbeiters"
                    valueExpr="mit_id"
                    displayExpr="mit_such_name">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="scd_assist1_mit_id"
                  [visible]="true"
                  caption="unterstützend"
                  width="auto">
        <dxo-lookup [dataSource]="getMitarbeiters"
                    valueExpr="mit_id"
                    displayExpr="mit_such_name">

        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="ger_nr"
                  [visible]="true"
                  caption="Gerät/Fahrzeug"
                  width="auto">
        <dxo-lookup [dataSource]="getGeraets"
                    valueExpr="ger_nr"
                    displayExpr="ger_name"
        ></dxo-lookup>
      </dxi-column>

      <dxi-column dataField="scd_info_code"
                  [visible]="true"
                  caption="Hinweis"
                  width="auto"
      ></dxi-column>

      <dxi-column dataField="scd_status_code"
                  [visible]="true"
                  caption="Status"
                  width="auto"
      ></dxi-column>

      <dxi-column dataField="scd_bemerk_text" caption="Bemerkung" width="auto">
      </dxi-column>
      <dxi-column dataField="scd_kasse_amount"
                  caption="Betrag €"
                  dataType="number"
                  width="auto">
        <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
      </dxi-column>
      <dxi-column dataField="scd_id"
                  [visible]="false"
                  caption="Nr."
                  width="auto"
      ></dxi-column>

    </dx-data-grid>
  </div>
</div>
