<p>_______________________________________<br>
  Lüders Logistik e.K.<br>
  Inhaber Frederik Lüders<br>
  D-26474 Spiekeroog<br>
  Tel: +49 176 8563 7971<br>
  Internet: <a href="https://www.inselspedition.de" target="_blank">www.inselspedition.de</a><br>
  E-Mail: <a href="mailto:info@inselspedition.de">info&#64;inselspedition.de</a><br>
  Sitz des Unternehmens: Spiekeroog | Amtsgericht Aurich<br>
  USt. Ident-Nr. DE 70/027/31174<br>
  Steuer-Nr. 2371/127/00885
</p>
