<mat-card>
  <mat-card-title [hidden]="true">{{title}}</mat-card-title>
  <dx-map #map
          class="sits-map-container"
          [center]="getInitialLocation()"
          [zoom]="initialMapZoom"
          width="100%"
          provider="bing"
          (onClick)="mapClicked($event)"
          (onOptionChanged)="mapOptionChanged($event)"
          [type]="initialMapType">
  </dx-map>
  <div class="option">
    <span>Ansicht</span>
    <dx-select-box
      [dataSource]="mapTypes"
      displayExpr="name"
      valueExpr="key"
      (onSelectionChanged)="mapTypeChanged($event)"
      [(value)]="map.type">
    </dx-select-box>
  </div>
</mat-card>
