<div class="sits-container">
  <div class="sits-card-center dx-card sits-card-small">
    <form (submit)="onFormSubmit($event)" action="your-action" autocomplete="on" method="get">
      <div class="dx-fieldset">

        <div class="dx-fieldset-header">
          <dx-toolbar class="sits-print-hide">
            <dxi-item widget="dxButton" location="before">
              <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
            </dxi-item>
            <dxi-item location="left" widget="dxButton" locateInMenu="never">
              <div *dxTemplate>
                <div class="toolbar-label">{{[componentTitle]}}</div>
              </div>
            </dxi-item>
          </dx-toolbar>
        </div><br>

        <div class="dx-field">
          <div class="dx-field-label">Benutzername</div>
          <div class="dx-field-value">
            <dx-text-box
              [(value)]="userName">
              <dx-validator>
                <dxi-validation-rule type="required" message="Benutzer ist erforderlich"></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [max]="64"
                                     message="Benutzer: maximal 64 Zeichen"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">Passwort</div>
          <div class="dx-field-value">
            <dx-text-box
              mode="password"
              [(value)]="password">
              <dx-validator>
                <dxi-validation-rule type="required" message="Passwort ist erforderlich"></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [max]="64"
                                     message="Passwort: maximal 64 Zeichen"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </div>
        <div class="dx-field" [hidden]="!messageText || messageText.length === 0">
          {{messageText}}
        </div>
      </div>
      <div class="dx-fieldset">
        <dx-validation-summary id="summary"></dx-validation-summary>
        <div class="dx-empty"></div>
        <dx-button [disabled]="loading || processed"
                   id="btn_login"
                   text="Login"
                   stylingMode="contained"
                   type="default"
                   [useSubmitBehavior]="true">
        </dx-button>&nbsp;
        <dx-button [disabled]="loading || processed"
                   id="btn_passwordforgot"
                   text="Passwort vergessen"
                   stylingMode="contained"
                   (click)="gotoPasswordForgot()">
        </dx-button>
        <div class="dx-empty"></div><br>
        <dx-button [disabled]="loading || processed"
                   [visible] = "isDevelopment"
                   id="btn_registrationform"
                   text="Registrieren"
                   stylingMode="contained"
                   hint="Für gelegentliche Gepäckaufträge ist KEINE Registrierung erforderlich.
Wenn Sie Frachtaufträge buchen, Lagerboxen mieten möchten
oder bereits Kunde sind,können Sie sich registrieren
und weitere Angebote nutzen."
                   (click)="gotoRegistrationForm()">
        </dx-button>
      </div>
    </form>
  </div>
</div>
