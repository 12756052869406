import {DataElement} from '../api-basics/data-element';

export class Lock implements DataElement{
  id?: number;
  lock_id?: number;
  lock_lockcat_id?: number;
  lock_tbl1_krz?: string;
  lock_tbl1_id1?: number;
  lock_tbl1_id2?: number;
  lock_tbl1_id3?: number;
  lock_tbl1_id4?: number;
  lock_comment_text?: string;
  lock_locked_yn?: number;
  lock_internal_tag?: string;
  lock_tag?: string;
  lock_for_seconds?: number;
  lock_until_stamp?: Date;
  lock_unlock_stamp?: Date;
  lock_create_stamp?: Date;
  lock_create_user?: string;
  message?: string;
  status?: string;
}
