import {DataElement} from '../api-basics/data-element';

export class GeraeteVermietungStorageBox implements DataElement {
  id?: number;
  gervm_id?: number;
  gervm_mand_id?: number;
  gervm_ger_nr?: number | null;
  gervm_ger_name?: string | null;
  gervm_beginn_datzeit?: Date | null;
  gervm_ende_datzeit?: Date | null;
  gervm_anz?: number = 0;
  gervm_menge?: number = 0;
  gervm_me_code?: string | null;
  gervm_brutto_amount?: number | null;
  gervm_bemerk_text?: string | null;
  gervm_bezahlt_kz?: string | null;
  gervm_create_stamp?: Date | null;
  gervm_create_user?: string | null;
  gervm_change_stamp?: Date | null;
  gervm_change_user?: string | null;
  gervm_extern_kz?: string | null;
  gervm_extern_name?: string | null;
  gervm_extern_mobil_tel?: string | null;
  gervm_extern_email?: string | null;
  gervm_extern_mobiletan?: string | null;
  gervm_extern_personalKey?: string | null;  // todo: relikt!!!
  gervm_extern_personalkey?: string | null;
  gervm_rgempf_adr_id?: number | null;
  gervm_tpol_id?: number | null;
  gervm_ladestelle_adr_id?: number | null;
  gervm_ladestelle_adr_gepaeck_name?: string | null;
  gervm_art_nr?: number | null;
  gervm_ustk_nr?: number | null;
  gervm_zktoart_id?: number | null;
  gervm_extern_transpbed_bestaetigt_yn?: number | null;
  gervm_bezahlt_provider_name?: string | null;
  gervm_bezahlt_transaction_id_text?: string | null;
  gervm_bezahlt_transaction_data?: string | null;
  gervm_bezahlt_stamp?: Date | null;
  message?: string | null;
  status?: string | null;
}
