import {Injectable} from '@angular/core';
import {ApiService} from '../api-basics/api.service';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {Tarif} from '../api-dtos/tarif';
import {Observable} from 'rxjs/internal/Observable';
import {TarifPosition} from '../api-dtos/tarif-position';
import {formatDate} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {makeBindingParser} from '@angular/compiler';
import {map, tap} from 'rxjs/operators';
import {lastValueFrom} from 'rxjs/internal/lastValueFrom';

@Injectable({
  providedIn: 'root'
})
export class TarifService {
  tarifPositions: TarifPosition[] = [];
  baseUrl = `${environment.restApiUrl}`;
  private myResult: any;

  constructor(private apiService: ApiService,
              private http: HttpClient,
              private authenticationService: AuthenticationService) {
  }

  private _timeScopeCurrent: string = 'CURRENT';

  public get timeScopeCurrent(): string {
    return this._timeScopeCurrent;
  }

  private _timeScopeNext: string = 'NEXT';

  public get timeScopeNext(): string {
    return this._timeScopeNext;
  }

  public existsTimeScopeData(timeScope: string): boolean {
    return !!timeScope && timeScope === this.timeScopeCurrent ? true : false;
  }

  getAllByTimeScope(timeScope: string): Observable<Tarif[]> {
    if (!timeScope || !(!!timeScope)) {
      timeScope = this.timeScopeCurrent;
    }
    timeScope = timeScope.toLowerCase();

    return this.http.get<Tarif[]>(
      `${this.baseUrl}/tarif/list/${timeScope}`,
      this.getHttpHeader()
    );
  }

  getCurrentAll(): Observable<Tarif[]> {
    return this.http.get<Tarif[]>(
      `${this.baseUrl}/tarif/list/current`,
      this.getHttpHeader()
    );
  }

  public async getTarif(frachtDatum: Date, artNr: number, menge: number): Promise<Observable<TarifPosition>> {
    this.myResult = await this.apiService.get('/tarif', null, '&frakodatum=' + frachtDatum.toDateString());
    return this.myResult;
  }

  /**
   * Frachttarif für eine Route, ein Datum, (einen Artikel), eine Menge ermitteln
   * Review 20210512
   * @param fromLocationId
   * @param toLocationId
   * @param frachtDatum
   * @param artNr
   * @param frachtMenge
   */
  public async getFrachtTarif(fromLocationId: number, toLocationId: number,
                              frachtDatum: Date, artNr: number, frachtMenge: number): Promise<TarifPosition[]> {
    if (!artNr || artNr < 0) {
      artNr = 0;
    }
    if (!frachtDatum) {
      return;
    }
    if (!frachtMenge) {
      return;
    }
    const filterDate: string = formatDate(frachtDatum, 'yyyy-MM-dd', 'de-DE');

    let tarifPos: TarifPosition[];
    tarifPos = await lastValueFrom(this.http.get<TarifPosition[]>(
      `${this.baseUrl}/frachttarif/${fromLocationId}/${toLocationId}/${filterDate}/${artNr}/${frachtMenge}`,
      this.getHttpHeader()));
    if (tarifPos === undefined || tarifPos === null || tarifPos.length < 1) {
      return tarifPos;
    }
    tarifPos[0].tarpo_fracht1_preis = tarifPos[0].tarpo_fracht1_preis === undefined ? 0 : Number(tarifPos[0].tarpo_fracht1_preis);
    tarifPos[0].tarpo_fracht2_preis = tarifPos[0].tarpo_fracht2_preis === undefined ? 0 : Number(tarifPos[0].tarpo_fracht2_preis);
    tarifPos[0].tarpo_fracht3_preis = tarifPos[0].tarpo_fracht3_preis === undefined ? 0 : Number(tarifPos[0].tarpo_fracht3_preis);
    return tarifPos;
  };

  private getHttpHeader() {
    const accessToken: string = localStorage.getItem('sessionKey');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return {headers: headers};
  }
}
