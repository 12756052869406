import {Component, OnInit, ViewChild} from '@angular/core';
import {Adresse} from '../../api/api-dtos/adresse-dto';
import {AdresseFilter, AdresseService} from '../../api/api-services/adresse.service';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {DxDataGridComponent} from 'devextreme-angular';
import {Location} from '@angular/common';

@Component({
  selector: 'app-adresse-list',
  templateUrl: './adresse-list.component.html',
  styleUrls: ['./adresse-list.component.scss']
})
export class AdresseListComponent implements OnInit {
  public isLoadingResults: boolean;
  componentTitle: string;
  gridPageSize = 15;
  rowEditAllowed = false;
  rowAddAllowed = true;
  rowDeleteAllowed = false;
  adrNrEditAllowed = false;
  adrNrDisplayVisible: false;
  currentPositionRowIndex: number;
  adresses: Adresse[];
  adresseFilter: AdresseFilter = new AdresseFilter();
  private subscribeAuth: Subscription;

  filterSuchNameOptions = {
    maxlength: 64,
    placeholder: 'Suche nach Suchname',
    onValueChanged: (args: any) => {
      this.onValueFilterValueChangeSuchName(args);
    },
  };

  filterPlzOptions = {
    maxlength: 10,
    placeholder: 'PLZ-Suche',
    onValueChanged: (args: any) => {
      this.onValueFilterValueChangePlz(args);
    },
  };

  filterOrtOptions = {
    maxlength: 64,
    placeholder: 'Ort-Suche',
    onValueChanged: (args: any) => {
      this.onValueFilterValueChangeOrt(args);
    },
  };

  @ViewChild('grid', {static: false}) grid: DxDataGridComponent;

  constructor(private authenticationService: AuthenticationService,
              private adresseService: AdresseService,
              private readonly location: Location,
  ) {
    if (!!localStorage.getItem('adresse-list.grid.paging.pageSize')) {
      this.gridPageSize = +localStorage.getItem('adresse-list.grid.paging.pageSize');
      if (this.gridPageSize < 5) {
        this.gridPageSize = 5;
      } else if (this.gridPageSize > 300) {
        this.gridPageSize = 300;
      }
    }
    this.componentTitle = 'Adressen';
  }

  async ngOnInit() {
    this.subscribeAuth = this.authenticationService.authenticated.subscribe(item => {
      this.refreshList(this.adresseFilter, true);
    });
  }

  setTitle() {
    if (this.adresses && this.adresses.length > 0) {
      this.componentTitle = 'Adressen (' + this.adresses.length.toString() + ')';
    } else {
      this.componentTitle = 'Adressen';
    }
  }

  refreshList(adresseFilter: AdresseFilter, forceReload: boolean = false) {
    this.rowAddAllowed = !!this.authenticationService
      && (this.authenticationService.isCustomer || this.authenticationService.isEmployee);
    this.rowEditAllowed = !!this.authenticationService && this.authenticationService.isEmployee;
    this.rowDeleteAllowed = !!this.authenticationService && this.authenticationService.isEmployee;
    this.adresseService.getAll(adresseFilter, forceReload).subscribe((res: Adresse[]) => {
        this.adresses = res;
        this.setTitle();
        this.isLoadingResults = false;
      },
      // handle the error, otherwise will break the Observable
      error => console.log(error)
    );
    /*this.adresseService.getAll().then( res => {
      this.adresses = res;
      this.setTitle();
      this.isLoadingResults =  false;
    });*/
  }

  optionChangedHandler(event: any) {
    if (event.fullName === 'paging.pageSize') {
      if (event.value >= 5) {
        localStorage.setItem('adresse-list.grid.paging.pageSize', event.value);
      }
    }
  }

  isCustomer() {
    return this.authenticationService.isCustomer;
  }

  isEmployee() {
    return this.authenticationService.isEmployee;
  }

  rowInserted($event: any) {
    $event.data.adr_id = null;
    // this.adresseService.
    if (this.rowAddAllowed) {
      this.adresseService.create($event.data).then(res => {
        this.setTitle();
        this.refreshList(this.adresseFilter, true);
        // this.grid.instance.repaint();
      });
    }
  }

  rowUpdated($event: any) {
    // this.adresseService.
    if (this.rowAddAllowed) {
      this.adresseService.update($event.data.adr_id, $event.data);
    }
  }

  rowRemoved($event: any) {
    // this.adresseService.
    if (this.rowDeleteAllowed) {
      this.adresseService.delete($event.data.adr_id).then(res => {
        this.setTitle();
        this.grid.instance.repaint();
      });
    }
  }

  addRow() {
    if (this.rowAddAllowed) {
      this.grid.instance.addRow();
    }
  }

  onValueFilterValueChangeSuchName(args: any) {
    if (args.value.length > 0) {
      this.adresseFilter.suchName = args.value;
      this.refreshList(this.adresseFilter, true);
    } else {
      this.adresseFilter.suchName = null;
    }
  }

  onValueFilterValueChangePlz(args: any) {
    this.adresseFilter.plz = args.value;
    this.refreshList(this.adresseFilter, true);
  }

  onValueFilterValueChangeOrt(args: any) {
    this.adresseFilter.ort = args.value;
    this.refreshList(this.adresseFilter, true);
  }

  public goBack(): void {
    this.location.back();
  }

}
