export class PaymentLog {
  id?: number;
  pylg_id?: number;
  pylg_mand_id?: number;
  pylg_shoporder_guid?: string;
  pylg_init_processkey?: string;
  pylg_init_id?: number;
  pylg_provider_code?: string;
  pylg_payment_interface_code?: string;
  pylg_direction_code?: string;
  pylg_process_name?: string;
  pylg_message_type?: string;
  pylg_tag01?: string;
  pylg_tag02?: string;
  pylg_tag03?: string;
  pylg_tag04?: string;
  pylg_tag05?: string;
  pylg_tag06?: string;
  pylg_link_id?: string;
  pylg_url?: string;
  pylg_body_data?: string;
  pylg_body_format?: string;
  pylg_create_stamp?: Date;
  pylg_change_stamp?: Date;
  pylg_creator?: string;
  pylg_lock_tag?: string;
  pylg_aktiv_yn?: number;

}
