import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {FrachtPosition} from '../api-dtos/api-dtos.module';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FrachtPositionService {
  baseUrl: string = `${environment.restApiUrl}/orderposition`;
  frachtPositionEmpty: FrachtPosition[];

  constructor(
    private http: HttpClient) {
  }

  public async getAll(): Promise<FrachtPosition[]> {
    return await this.http.get<FrachtPosition[]>(
      this.baseUrl,
      await this.getHttpHeader()
    ).toPromise();
  }

  public async getFrachtbriefPositions(frako_id: number): Promise<FrachtPosition[]> {
    /*if (undefined(frako_id) || frako_id < 1 ) {
      return await this.frachtPositionEmpty;
    }*/
    return await this.http.get<FrachtPosition[]>(
      `${this.baseUrl}/order/${frako_id}`,
      await this.getHttpHeader()
    ).toPromise();
  }

  public async getFrachtPosition(frapo_id: number, frako_id: number): Promise<FrachtPosition> {
    return await this.http.get<FrachtPosition>(
      `${this.baseUrl}/${frapo_id}/${frako_id}`,
      await this.getHttpHeader()
    ).toPromise();
  }

  private async getHttpHeader() {
    const accessToken: string = await localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return httpOptions;
  }


}
