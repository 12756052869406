import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {formatDate} from '@angular/common';
import {Scheduler} from '../api-dtos/scheduler-dto';
import {lastValueFrom} from 'rxjs/internal/lastValueFrom';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {
  baseUrl = `${environment.restApiUrl}/schedulertask`;

  constructor(
    private http: HttpClient) { }

  private async getHttpHeader() {
    const accessToken: string = await localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  public async getAll(): Promise<Scheduler[]> {
    let result;
    result = await lastValueFrom(this.http.get<Scheduler[]>(
      `${this.baseUrl}s`,
      await this.getHttpHeader()
    ));
    return result;
  }

  public async getFiltered(schedulerDate: Date, employeeId: number): Promise<Scheduler[]> {
    if (!schedulerDate) {
      return null;
    }
    let filterDate, url: string;
    filterDate = formatDate(schedulerDate, 'yyyy-MM-dd', 'de-DE');
    url = `${this.baseUrl}s/${filterDate}`;
    if (!!employeeId) {
      url += `/${employeeId}`;
    }
    return await lastValueFrom(this.http.get<Scheduler[]>(
      url,
      await this.getHttpHeader()
    ));
  }
}
