import { DataElement } from '../api-basics/data-element';

export class Patch<T extends DataElement> {
    constructor(
        public create: boolean = true,
        public modify: boolean = true,
        public remove: boolean = true,
        public values: Array<T> = []
    ) {
    }
}
