import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {delay, map, takeLast, takeUntil, tap} from 'rxjs/operators';
import {FrachtBrief} from '../api-dtos/fracht-brief';
import {FrachtPosition} from '../api-dtos/fracht-position';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {TarifService} from './tarif.service';
import {TarifPosition} from '../api-dtos/tarif-position';
import {ArtikelService} from './artikel.service';
import {IArtikel} from '../api-dtos/artikel';
import {Observable} from 'rxjs';
import {TextblockService} from './textblock.service';

@Injectable({
  providedIn: 'root'
})
export class FrachtBriefService {
  baseUrl: string = `${environment.restApiUrl}/order`;
  frontendTransportBedingungenUrl: string;
  private today: Date = new Date(new Date().setHours(0, 0, 0, 0));
  private tomorrow: Date = new Date(this.today.setDate(this.today.getDate() + 1));

  constructor(
    private authenticationService: AuthenticationService,
    private tarifService: TarifService,
    private artikelService: ArtikelService,
    private textblockService: TextblockService,
    private http: HttpClient) {
  }

  static handleError(error: any) {
    let errorMessage: string;

    if (error?.error && error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      if (error?.message && !!error.message) {
        errorMessage = `${error.message}`;
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
    }
    window.alert(errorMessage);
    // return _throw(errorMessage);
    return;
  }

  private static async getHttpHeaderAsync() {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions: { headers: HttpHeaders } = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  private static getHttpHeader() {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions: { headers: HttpHeaders } = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  public getNewFrachtbrief(): FrachtBrief {
    let frachtbrief;
    frachtbrief = new FrachtBrief();
    frachtbrief.frako_id = null;
    frachtbrief.frako_datum = null;
    frachtbrief.frako_belegart_code = 'RG';
    frachtbrief.frako_belegtyp_code = 'FBRG';
    frachtbrief.frako_stop_kz = false;
    frachtbrief.frako_absender_adr_id = null;
    frachtbrief.frako_empf_adr_id = null;
    frachtbrief.frako_rgempf_adr_id = null;
    frachtbrief.frako_paritaet_code = 'freihs';
    frachtbrief.frako_fest_status_nr = 0;
    frachtbrief.frako_trst_status_nr = 0;
    frachtbrief.frako_fakstat_status_nr = 0;
    frachtbrief.frako_plan_fp_id = null;
    frachtbrief.frako_rg_nr = null;
    frachtbrief.frako_datum = new Date();
    if (this.authenticationService.authenticated) {
      if (!!this.authenticationService.currentUser && this.authenticationService.currentUser.isCustomer) {
        // bei Kunden: alle Adressen mit der eigenen KundenNr vorbelegen ...
        frachtbrief.frako_absender_adr_id = this.authenticationService.customerId;
        frachtbrief.frako_empf_adr_id = this.authenticationService.customerId;
        frachtbrief.frako_rgempf_adr_id = this.authenticationService.customerId;
      }
    }
    // todo: Standard-Route bzw. Standard-Orte anders vorbelegen, avoid magic numbers!
    frachtbrief.frako_abgang_fort_id = 1;
    frachtbrief.frako_ziel_fort_id = 2;
    frachtbrief.frachtPositions = [new FrachtPosition()];
    frachtbrief.frachtPositions[0].frapo_sort_nr = 10;
    return frachtbrief;
  }

  public async getAll(): Promise<FrachtBrief[]> {
    return await this.http.get<FrachtBrief[]>(
      this.baseUrl,
      await FrachtBriefService.getHttpHeaderAsync()
    ).toPromise();
  }

  public async getFrachtbrief(id: number): Promise<FrachtBrief> {
    return await this.http.get<FrachtBrief>(
      `${this.baseUrl}/${id}`,
      await FrachtBriefService.getHttpHeaderAsync()
    ).toPromise();
  }

  // Frachtbrief mit Positionen
  public getFrachtbriefPositions(id: number): Observable<FrachtBrief> {
    return this.http.get<FrachtBrief>(
      `${this.baseUrl}/${id}/positions`,
      FrachtBriefService.getHttpHeader()
    );
  }

  // public async addOrderType(orderType: OrderType) {
  //   const url = `${environment.api_url}/ordertypes/`;
  //
  //   return await this._http.post<OrderType>(url, orderType, await this.getHttpHeader()).toPromise();
  // }


  public async create(frachtbrief: FrachtBrief): Promise<FrachtBrief> {
    return await this.http.post<FrachtBrief>(
      this.baseUrl,
      frachtbrief,
      await FrachtBriefService.getHttpHeaderAsync()
    ).toPromise().then(res => {
      if (res.frako_id && res.frako_id !== 0) {
        return res;
      }
      return frachtbrief;
    });
  }

  public async duplicate(id: number) {
    return await this.http.post<FrachtBrief>(
      `${this.baseUrl}/duplicate`,
      {frako_id: id},
      await FrachtBriefService.getHttpHeaderAsync()
    ).toPromise();
  }

  public async update(id: number, frachtbrief: FrachtBrief): Promise<FrachtBrief> {
    delay(400);
    //try {
    const url = `${this.baseUrl}/${id}`;
    return await this.http.put<FrachtBrief>(
      url, frachtbrief, await FrachtBriefService.getHttpHeaderAsync()).toPromise();
    /*} catch (error) {
      await FrachtBriefService.handleError;
      return frachtbrief;
    }*/
  }

  public async delete(id: number) {
    try {
      return await this.http.delete(
        `${this.baseUrl}/${id}`,
        await FrachtBriefService.getHttpHeaderAsync()
      ).toPromise();
    } catch (error) {
      await FrachtBriefService.handleError;
    }
  }

  public async deletePosition(id: number) {
    try {
      return await this.http.delete(
        `${this.baseUrl}/position/${id}`,
        await FrachtBriefService.getHttpHeaderAsync()
      ).toPromise();
    } catch (error) {
      await FrachtBriefService.handleError;
    }
  }

  public async approveForTransportation(id: number) {
    return await this.http.put(
      `${this.baseUrl}/approvetransport/${id}`,
      id,
      await FrachtBriefService.getHttpHeaderAsync()
    ).toPromise();
  }

  /**
   * Frachtbrief bearbeiten erlaubt?
   * Kunden: Frachtdatum muss mindestens morgen sein. Bearbeitung alter FB nicht erlaubt
   * @param frachtbrief
   */
  public isFrachtbriefEditAllowed(frachtbrief: FrachtBrief): boolean {
    if (!frachtbrief || !this.authenticationService.currentUser) {
      return false;
    }
    if (!!frachtbrief.frako_rg_nr) {
      return false;
    }

    if (this.authenticationService.currentUser.isCustomer) {
      // noch nicht für den Transport freigegeben
      // Transport/Annahme hat noch nicht begonnen
      return (frachtbrief.frako_fakstat_status_nr?.valueOf() >= 0
          && frachtbrief.frako_fakstat_status_nr?.valueOf() <= 90)
        && (frachtbrief.frako_fest_status_nr?.valueOf() >= 0
          && (frachtbrief.frako_fest_status_nr?.valueOf() < 50 || frachtbrief.frako_stop_kz?.valueOf()))
        && (frachtbrief.frako_trst_status_nr?.valueOf() >= 0
          && frachtbrief.frako_trst_status_nr?.valueOf() < 10)
        && ((!frachtbrief.frako_datum) || new Date(new Date(frachtbrief.frako_datum).setHours(0, 0, 0, 0)) >= this.tomorrow);
    } else if (this.authenticationService.currentUser.isEmployee) {
      return ((!frachtbrief.frako_fakstat_status_nr)
          || (frachtbrief.frako_fakstat_status_nr?.valueOf() >= 0 && frachtbrief.frako_fakstat_status_nr?.valueOf() <= 90))
        && ((!frachtbrief.frako_fest_status_nr)
          || (frachtbrief.frako_fest_status_nr?.valueOf() >= 0 && frachtbrief.frako_trst_status_nr?.valueOf() < 90));
    } else {
      return false;
    }
  }

  public isFrako_trst_status_nrEditAllowed(frachtbrief: FrachtBrief): boolean {
    // bearbeitbar nur durch Interne
    if (!this.isFrachtbriefEditAllowed(frachtbrief)) {
      return false;
    }
    if (this.authenticationService.currentUser.isEmployee) {
      return frachtbrief.frako_trst_status_nr?.valueOf() < 90;
    } else {
      return false;
    }
  }

  public isFrako_ist_fp_idDisplayVisible(frachtbrief: FrachtBrief): boolean {
    return !!(this.authenticationService.currentUser.isEmployee
      || this.authenticationService.currentUser.isCustomer && !!frachtbrief.frako_ist_fp_id);
  }

  public isFrapo_tarifDataEditAllowed(frachtbrief: FrachtBrief) {
    // bearbeitbar nur durch Interne
    if (!this.isFrachtbriefEditAllowed(frachtbrief)) {
      return false;
    }
    if (this.authenticationService.currentUser.isEmployee) {
      return (frachtbrief.frako_fakstat_status_nr !== undefined && frachtbrief.frako_fakstat_status_nr <= 90);
    } else {
      return false;
    }
  }

  public async stopProcessing(id: number) {
    return await this.http.put(
      `${this.baseUrl}/stopprocessing/${id}`,
      id,
      await FrachtBriefService.getHttpHeaderAsync()
    ).toPromise();
  }

  public async unStopProcessing(id: number) {
    return await this.http.put(
      `${this.baseUrl}/unstopprocessing/${id}`,
      id,
      await FrachtBriefService.getHttpHeaderAsync()
    ).toPromise();
  }

  /**
   * Frachttarifermittlung und Frachttarifberechnung für eine Frachtbrief-Position
   * @param frachtbrief
   * @param frachtposition
   */
  public async getCalcedPosition(frachtbrief: FrachtBrief, frachtposition: FrachtPosition): Promise<FrachtPosition> {
    // Umschlag: frapo_fracht1_amount: keine Rabatte und Aufschläge
    // Rollgeld: frapo_fracht2_amount: Aufschlag und Tonnagerabatt anwenden.
    // Seefracht: frapo_fracht3_amount: Aufschlag und Tonnagerabatt anwenden.
    // bin ich ein Artkel mit Frachtberechnung / Tarifermittlung?

    if (frachtposition === undefined) {
      return new FrachtPosition();
    }
    if (frachtposition?.art_nr === undefined || frachtposition.art_nr < 1) {
      return frachtposition;
    }
    if (frachtposition?.frapo_fracht1_amount === undefined) {
      frachtposition.frapo_fracht1_amount = 0;
    }
    if (frachtposition.frapo_fracht2_amount === undefined) {
      frachtposition.frapo_fracht2_amount = 0;
    }
    if (frachtposition.frapo_fracht3_amount === undefined) {
      frachtposition.frapo_fracht3_amount = 0;
    }
    if (frachtposition.frapo_tarif_amount === undefined) {
      frachtposition.frapo_tarif_amount = 0;
    }
    if (frachtposition.frapo_frachtberechnung_manuell_yn === undefined || frachtposition.frapo_frachtberechnung_manuell_yn === null) {
      frachtposition.frapo_frachtberechnung_manuell_yn = 0;
    }

    let artikel: IArtikel;
    await this.artikelService.getArtikel(frachtposition.art_nr).then(async artikelData => {
        artikel = artikelData;
        frachtposition.art_frachtberechnung_yn = artikel.art_frachtberechnung_yn;

        if (frachtposition.frapo_frachtberechnung_manuell_yn === 1) {
          frachtposition.frapo_tarif_amount = 0;
          frachtposition.frapo_fracht1_amount.toFixed(2);
          frachtposition.frapo_fracht2_amount.toFixed(2);
          frachtposition.frapo_fracht3_amount.toFixed(2);
          frachtposition.frapo_artikel_amount = (frachtposition.frapo_fracht1_amount
            + frachtposition.frapo_fracht2_amount
            + frachtposition.frapo_fracht3_amount);
          return frachtposition;
        }

        if (artikel.art_frachtberechnung_yn === 1) {
          frachtposition.frapo_artikel_amount = 0;
        } else {
          // nur Frachtartikel: Aufschlag und Tonnagerabatt!
          frachtposition.frapo_aufschlag_proz = 0;
          frachtposition.frapo_trab_proz = 0;
        }

        if (!frachtposition.frapo_aufschlag_proz) {
          frachtposition.frapo_aufschlag_proz = 0;
        }
        if (!frachtposition.frapo_trab_proz) {
          frachtposition.frapo_trab_proz = 0;
        }

        if (artikel.art_frachtberechnung_yn !== 1 || (!frachtposition.frapo_menge)) {
          frachtposition.frapo_fracht1_amount = 0;
          frachtposition.frapo_fracht2_amount = 0;
          frachtposition.frapo_fracht3_amount = 0;
          frachtposition.frapo_tarif_amount = 0;
          return frachtposition;
        }

        frachtposition.frapo_menge = frachtposition.frapo_menge == undefined || frachtposition.frapo_menge == null
          ? 0
          : frachtposition.frapo_menge;

        frachtposition.frapo_aufschlag_proz = frachtposition.frapo_aufschlag_proz == undefined || frachtposition.frapo_aufschlag_proz == null
          ? 0
          : Number(frachtposition.frapo_aufschlag_proz);

        frachtposition.frapo_trab_proz = frachtposition.frapo_trab_proz == undefined || frachtposition.frapo_trab_proz == null
          ? 0
          : Number(frachtposition.frapo_trab_proz);

        await this.tarifService.getFrachtTarif(frachtbrief.frako_abgang_fort_id,
          frachtbrief.frako_ziel_fort_id,
          frachtbrief.frako_datum,
          frachtposition.art_nr,
          frachtposition.frapo_menge)
          .then((tarifPos: TarifPosition[]) => {
              if (tarifPos && tarifPos.length === 1) {
                // frapo_tarif_amount: nicht aufsummieren aus den Anteilen!
                // -> ist die Summe der Anteile OHNE Zuschlag und Tonnagerabatt!
                frachtposition.frapo_tarif_amount =
                  tarifPos[0].tarpo_fracht1_preis
                  + tarifPos[0].tarpo_fracht2_preis
                  + tarifPos[0].tarpo_fracht3_preis;

                //  Umschlag...
                frachtposition.frapo_fracht1_amount = tarifPos[0].tarpo_fracht1_preis;
                // Rollgeld + Aufschlag ...
                frachtposition.frapo_fracht2_amount = tarifPos[0].tarpo_fracht2_preis
                  * (100 + frachtposition.frapo_aufschlag_proz) * 0.01;
                // Rollgeld - TonnageRabatt ...
                if (frachtposition.frapo_fracht2_amount !== 0 && frachtposition.frapo_trab_proz !== 0) {
                  frachtposition.frapo_fracht2_amount = frachtposition.frapo_fracht2_amount
                    - (frachtposition.frapo_fracht2_amount * frachtposition.frapo_trab_proz * 0.01);
                }
                // Seefracht + Aufschlag ...
                frachtposition.frapo_fracht3_amount = tarifPos[0].tarpo_fracht3_preis
                  * (100 + frachtposition.frapo_aufschlag_proz) * 0.01;
                // Umschlag - TonnageRabatt ...
                if (frachtposition.frapo_fracht3_amount !== 0 && frachtposition.frapo_trab_proz !== 0) {
                  frachtposition.frapo_fracht3_amount = frachtposition.frapo_fracht3_amount
                    - (frachtposition.frapo_fracht3_amount * frachtposition.frapo_trab_proz * 0.01);
                }
              } else {
                frachtposition.frapo_fracht1_amount = 0;
                frachtposition.frapo_fracht2_amount = 0;
                frachtposition.frapo_fracht3_amount = 0;
                frachtposition.frapo_tarif_amount = 0;
              }
              frachtposition.frapo_fracht1_amount = frachtposition.frapo_fracht1_amount === undefined || frachtposition.frapo_fracht1_amount === null
                ? 0
                : Number(Number(frachtposition.frapo_fracht1_amount).toFixed(2));
              frachtposition.frapo_fracht2_amount = frachtposition.frapo_fracht2_amount === undefined || frachtposition.frapo_fracht2_amount === null
                ? 0
                : Number(Number(frachtposition.frapo_fracht2_amount).toFixed(2));
              frachtposition.frapo_fracht3_amount = frachtposition.frapo_fracht3_amount === undefined || frachtposition.frapo_fracht3_amount === null
                ? 0
                : Number(Number(frachtposition.frapo_fracht3_amount).toFixed(2));
              frachtposition.frapo_artikel_amount = frachtposition.frapo_tarif_amount;
              return frachtposition;
            }
          ); /*###*/
        ;
      }
    );
  }

  public loadFrontendTransportBedingungenUrl(): string {
    this.textblockService.getTextBlock('FRBLG-TRSPBED-URL').toPromise().then(res => {
      this.frontendTransportBedingungenUrl = res.txblk_plaintext;
      return !!this.frontendTransportBedingungenUrl ? this.frontendTransportBedingungenUrl : '';
    }, () => {
      if (!environment.production) {
        alert('Missing Textblock for loadFrontendTransportBedingungenUrl');
      }
    });
    return !!this.frontendTransportBedingungenUrl ? this.frontendTransportBedingungenUrl : '';
  }

  public getFrontendTransportBedingungenUrl(): string {
    return !!this.frontendTransportBedingungenUrl ? this.frontendTransportBedingungenUrl : '';
  }

}
