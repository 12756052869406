<div class="card-text sits-print-only">
  Stellen Sie Ihr Gepäck bitte <b>1 1/2 Std.</b>, <b>außerorts</b> (Haus Klasing, Jugendhof, Haus Wolfgang
  , Haus am Meer, Walter-Requardt-Heim, Haus Sturmeck, Huus Jan Westend
  , CVJM Haus Quellerdünen) bitte <b>2 Std.</b> vor Schiffsabfahrt deutlich sichtbar zur Abholung bereit.<br>
  Vielen Dank für Ihre Unterstützung.<br>
  Um alles weitere kümmern wir uns.<br>
  Den Gepäckservice gibt es für Abfahrten ab Spiekeroog <b>bis 19:00 Uhr</b> <br>(bisher nicht für Anreisen).
  Siehe auch... <a class="dx-icon-link" href="https://www.inselspedition.de/gepaeck" target="_blank">www.inselspedition.de/gepaeck</a><br>
</div>
<dx-popover
  target="#link1"
  position="bottom"
  cdkScrollable
  [width]="300"
  [showTitle]="true"
  [title]="titleText"
  [(visible)]="inputPopoverVisible">
  <div *dxTemplate="let data = model of 'content'">
    <b>Abreise:</b>&nbsp;<small><i><span class="sits-color-serious">(Kofferabholung von Inseladresse -> {{frachtOrtName1}})</span></i></small>
    Stellen Sie Ihr Gepäck bitte <b>1 1/2 Std.</b>, und <b>außerorts</b> (Haus Klasing, Jugendhof, Haus Wolfgang, Haus am
    Meer
    , Walter-Requardt-Heim, Haus Sturmeck, Huus Jan Westend, CVJM Haus Quellerdünen) bitte <b>2 Std.</b> vor
    Schiffsabfahrt deutlich sichtbar zur Abholung bereit.<br>
    <b>Mindesttarif außerorts:</b> 24 EUR pro Tour<br>
    <b>Zeltplatz:</b> Bitte direkt am Zeltplatz buchen<br>
    Vielen Dank für Ihre Unterstützung.<br>
    Um alles weitere kümmern wir uns.<br>
    Den Gepäckservice gibt es für Abfahrten <b>bis 19:00 Uhr</b>.<br>
    Ach ja: bei der Online-Buchung benötigen Sie derzeit <b>KEINE Banderole</b>.<br>
    Siehe auch... <a class="dx-icon-link" href="https://www.inselspedition.de/gepaeck" target="_blank">www.inselspedition.de/gepaeck</a>
  </div>
</dx-popover>
