import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {MatCardModule} from '@angular/material/card';
import {InvoiceListComponent} from './invoice-list/invoice-list.component';
import {DevExpressModule} from '../../shared/devexpress/dev-express.module';

@NgModule({
  declarations: [
    InvoiceListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    TooltipModule,
    DevExpressModule
  ],
  exports: [
    InvoiceListComponent
  ]
})
export class AccountingModule { }
