<div class="dx-card sits-card-large">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header">
      <dx-toolbar>
        <dxi-item widget="dxButton" location="before">
          <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
        </dxi-item>
        <dxi-item location="left" widget="dxButton" locateInMenu="never">
          <div *dxTemplate>
            <div class="toolbar-label">{{[this.componentTitle]}}</div>
          </div>
        </dxi-item>
      </dx-toolbar>
    </div>
    <div class="dx-field" fxShow="true" fxHide.lt-md="true" [hidden]="!isCustomer()">
      <div class="dx-field-item-content">
        Liste der mit Ihrem Konto bisher verknüpften Adressen (Absender, Empfänger).
      </div>
    </div>
    <div>
    </div>

    <dx-toolbar class="py-2 px-1">
      <dxi-item widget="dxButton" location="before" locateInMenu="auto">
        <dx-button icon="refresh" (click)="refreshList(adresseFilter,true)"></dx-button>
      </dxi-item>

      <dxi-item id="btnplus1" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button (onClick)="addRow()" hint="Neue Adresse anlegen"
                   icon="plus"></dx-button>
      </dxi-item>
      <dxi-item id="suchName" locateInMenu="auto" location="before"
                widget="dxTextBox"
                [options]="filterSuchNameOptions"
      ></dxi-item>
      <dxi-item id="plz" locateInMenu="auto" location="before"
                widget="dxTextBox"
                [options]="filterPlzOptions"
      ></dxi-item>
      <dxi-item id="ort" locateInMenu="auto" location="before"
                widget="dxTextBox"
                [options]="filterOrtOptions"
      ></dxi-item>

    </dx-toolbar>

    <dx-data-grid #grid class="adresse-selection-list"
                  id="grid-container"
                  [dataSource]="adresses"
                  [repaintChangesOnly]="true"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  keyExpr="adr_id"
                  (onOptionChanged)="optionChangedHandler($event)"
                  (onRowInserted)="rowInserted($event)"
                  (onRowUpdated)="rowUpdated($event)"
                  (onRowRemoved)="rowRemoved($event)"
                  [showBorders]="true"
                  [hoverStateEnabled]="true"
                  [showColumnLines]="false"
                  [showRowLines]="true"
                  height="auto"
                  [cacheEnabled]="false"
                  [rowAlternationEnabled]="true">
      <dxo-paging [enabled]="true" [pageSize]="gridPageSize"></dxo-paging>
      <dxo-pager
        [visible]="true"
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[7,8,9,10,12,15,20,100]"></dxo-pager>
      <dxo-remote-operations
        [filtering]="true"
        [paging]="true"
        [sorting]="true"
        [summary]="false"
        [grouping]="true"
        [groupPaging]="true">
      </dxo-remote-operations>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-selection selectAllMode="allPages" mode="multiple" showCheckBoxesMode="none"></dxo-selection>
      <dxo-scrolling mode="standard" [useNative]="true"></dxo-scrolling>
      <dxo-editing
        mode="row"
        refreshMode="full"
        useIcons="true"
        startEditAction="dblClick"
        [allowAdding]="false"
        [allowUpdating]="rowEditAllowed"
        [allowDeleting]="rowDeleteAllowed">
        <dxo-texts [confirmDeleteMessage]></dxo-texts>
      </dxo-editing>
      <dxo-load-panel
        [enabled]="isLoadingResults">
      </dxo-load-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-column-fixing [enabled]="false"></dxo-column-fixing>
      <dxi-column dataField="adr_id"
                  [visible]="true"
                  [allowEditing]="false"
                  caption="Nr."
                  width="auto"
                  [fixed]="false" fixedPosition="left"
      ></dxi-column>

      <dxi-column type="buttons"
                  [fixed]="false" fixedPosition="left">
        <dxi-button
          name="save"
          cssClass="my-class">
        </dxi-button>
        <dxi-button name="edit"></dxi-button>
        <dxi-button name="delete"></dxi-button>
      </dxi-column>

      <dxi-column dataField="adr_name" caption="Firma/Name" width="auto" [fixed]="false" fixedPosition="left">
        <dxi-validation-rule
          type="required"
          message="Erforderliches Pflichtfeld.">
        </dxi-validation-rule>
      </dxi-column>
      <dxi-column dataField="adr_surname" caption="Vorname" width="auto"></dxi-column>
      <dxi-column dataField="adr_such_name" caption="Suchname" width="auto"
                  [sortIndex]="1"
                  sortOrder="asc">
        <dxi-validation-rule
          type="required"
          message="Erforderliches Pflichtfeld, muss eindeutig sein. Wird nur intern verwendet. Ggf. zweckmäßige Abkürzungen verwenden.">
        </dxi-validation-rule>
      </dxi-column>
      <dxi-column dataField="adr_strasse_plz" caption="PLZ" dataType="string" width="auto">
        <dxi-validation-rule
          type="required"
          message="Erforderliches Pflichtfeld.">
        </dxi-validation-rule>
      </dxi-column>
      <dxi-column dataField="adr_ort" caption="Ort" width="auto">
        <dxi-validation-rule
          type="required"
          message="Erforderliches Pflichtfeld.">
        </dxi-validation-rule>
      </dxi-column>
      <dxi-column dataField="adr_strasse_hausnr" caption="Straße" width="auto">
        <dxi-validation-rule
          type="required"
          message="Erforderliches Pflichtfeld.">
        </dxi-validation-rule>
      </dxi-column>
      <dxi-column dataField="adr_ansprechpartner_name" caption="Ansprechpartner"
                  [visible]="isEmployee()"
                  width="auto">
      </dxi-column>
      <dxi-column dataField="adr_gepaeck_name" caption="Gepäckname"
                  [visible]="isEmployee()"
                  width="auto">
      </dxi-column>
      <dxi-column dataField="adr_email" caption="E-Mail"
                  [visible]="isEmployee()"
                  width="auto">
        <dxi-validation-rule
          message="Ungültige E-Mail-Adresse."
          type="email">
        </dxi-validation-rule>
      </dxi-column>
    </dx-data-grid>
  </div>
</div>
