import {DataElement} from '../api-basics/data-element';

// export interface AdresseDddw extends DataElement {
export class AdresseDddw implements DataElement {
  id?: number;
  mand_id?: number;
  adr_id?: number;
  adr_such_name?: string;
  adr_name?: string;
  adr_surname?: string;
  adr_strasse?: string;
  adr_strasse_plz?: string;
  adr_strasse_hausnr?: string;
  adr_gepaeck_name?: string;
  adr_ort?: string;
  adr_gepaeck_minimum_anz?: number;
}



