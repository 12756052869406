import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulerTaskListComponent } from './scheduler-task-list/scheduler-task-list.component';
import {
  DxAccordionModule,
  DxButtonModule,
  DxCalendarModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxFormModule,
  DxLookupModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxSelectBoxModule, DxSwitchModule,
  DxTagBoxModule,
  DxToolbarModule,
  DxTooltipModule,
  DxValidationSummaryModule,
  DxValidatorModule
} from 'devextreme-angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../material/material.module';

@NgModule({
  declarations: [SchedulerTaskListComponent],
  imports: [
    CommonModule,
    FormsModule,
    DxAccordionModule,
    DxButtonModule,
    DxCalendarModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxFormModule,
    DxLookupModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxTagBoxModule,
    DxToolbarModule,
    DxTooltipModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
  exports: [
    SchedulerTaskListComponent,
  ]

})
export class SchedulerModule { }
