<div class="sits-container">
  <div class="sits-card-center dx-card sits-card-small">
    <form action="your-action" (submit)="onFormSubmit($event)" autocomplete="on">

      <div class="dx-fieldset">
        <div class="dx-fieldset-header">Passwort vergessen?</div>
        <div class="dx-field-item-content">Im Anschluss an die Übermittlung Ihres gültigen Benutzernamens
          erhalten Sie umgehend eine E-Mail, die Sie zu einem Dialog weiterleitet, in dem Sie ein neues Passwort
          vergeben
          können.<br>
          So lange das Passwort nicht geändert wurde, behält das bisherige seine Gültigkeit.
        </div>
        <div class="dx-field-empty-item"></div>
        <div class="dx-field">
          <div class="dx-field-label">Benutzername</div>
          <div class="dx-field-value">
            <dx-text-box
              [(value)]="userName">
              <dx-validator>
                <dxi-validation-rule type="required" message="Benutzer ist erforderlich"></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [max]="64"
                                     message="Benutzer: maximal 64 Zeichen"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
          <div class="dx-field-empty-item"><br></div>
        </div>
        <div class="dx-fieldset">
          <dx-validation-summary id="summary"></dx-validation-summary>
          <dx-button [disabled]="loading || processed"
                     id="btn_login"
                     text="Neues Passwort anfordern"
                     stylingMode="contained"
                     type="default"
                     [useSubmitBehavior]="true">
          </dx-button>&nbsp;
        </div>
        <div class="dx-fieldset" [hidden]="!messageText || messageText.toString().trim() === '' ">
          <div class="dx-field-empty-item"><br></div>
          <div class="dx-field-item-content">{{messageText}}</div>
        </div>
      </div>
    </form>
  </div>
</div>

