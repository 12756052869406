import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {ArtikelService} from './artikel.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GepaeckBeleg} from '../api-dtos/gepaeck-beleg';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs';
import {ITan} from '../api-dtos/itan';
import {TextblockService} from './textblock.service';
import {ShopOrder} from '../api-dtos/shop-order';
import {ShopOrderItem} from '../api-dtos/shop-order-item';
import {IArtikel} from '../api-dtos/artikel';
import {ShopAddress} from '../api-dtos/shop-address';
import {AdresseService} from './adresse.service';
import {AdresseDddw} from '../api-dtos/adresse-dddw';
import {formatDate} from '@angular/common';
import {GepaeckProcessStatus} from './gepaeck-process-status';
import {map} from 'rxjs/operators';
import {lastValueFrom} from 'rxjs/internal/lastValueFrom';

@Injectable({
  providedIn: 'root'
})
export class GepaeckBelegService {
  baseUrl: string = `${environment.restApiUrl}/gepaeckbeleg`;
  frontendTransportBedingungenUrl: string;
  gepaeckProcessStatus: GepaeckProcessStatus;
  private moveGepaeckOrdersToInvoicesCalled: boolean = false;
  private bruttoPreisProGepaeckStueck: number = 0;
  private gepaeckTransport01Artikel: IArtikel;

  constructor(
    private authenticationService: AuthenticationService,
    private artikelService: ArtikelService,
    private adresseService: AdresseService,
    private textblockService: TextblockService,
    private http: HttpClient) {
    this.loadFrontendTransportBedingungenUrl();
    this.getGepaeckTransport01Artikel().then(resArt => {
      this.gepaeckTransport01Artikel = resArt;
    });
  }

  static handleError(error: any): void {
    let errorMessage: string;

    if (error?.error && error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      if (error?.message && error.message !== '') {
        errorMessage = `${error.message}`;
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
    }
    window.alert(errorMessage);
    /* const errMsg = error.message
       ? error.message
       : error.status
         ? `${error.status} - ${error.statusText} - ${error._body}`
         : 'Server error';*/
    return;
  }

  private static async getHttpHeaderAsync(): Promise<{ headers: HttpHeaders }> {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions: { headers: HttpHeaders } = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  /** GEP persistieren
   * API erkennt, ob es sich um ein INSERT oder UPDATE handelt.
   * @param gepaeckBeleg
   */
  public async create(gepaeckBeleg: GepaeckBeleg): Promise<GepaeckBeleg> {
    // API erkennt, ob es sich um ein INSERT oder UPDATE handelt.
    return await lastValueFrom(this.http.post<GepaeckBeleg>(
      `${this.baseUrl}/public`,
      gepaeckBeleg,
      this.getHttpHeader()
    )).then(res => {
      if (res.gpblg_id && res.gpblg_id !== 0 && (!(!!res.status) || res.status === 'success')) {
        return res;
      } else {
        gepaeckBeleg.message = !!res.message ? res.message : 'Ggf. falsche TAN oder anderes Problem.';
        gepaeckBeleg.status = res.status;
        return gepaeckBeleg;
      }
    }).catch(() => {
      gepaeckBeleg.message = 'Ggf. falsche TAN oder anderes Problem.';
      gepaeckBeleg.status = 'error';
      return gepaeckBeleg;
    });
  }

  // Update erstellt die Banderolen neu, aber benachrichtigt nicht
  // refactored 2024
  public async update(gepaeckBeleg: GepaeckBeleg): Promise<GepaeckBeleg> {
    try {
      const res: GepaeckBeleg = await lastValueFrom(
        this.http.put<GepaeckBeleg>(`${this.baseUrl}/public`, gepaeckBeleg, this.getHttpHeader())
      );

      if (res !== undefined && res.gpblg_id && res.gpblg_id !== 0 && res.status === 'success') {
        return res;
      } else {
        return gepaeckBeleg;
      }
    } catch (error) {
      // Optional: error handling, logging, etc.
      console.error('Error updating GepaeckBeleg', error);
      throw error;
    }
  }

  public async moveGepaeckOrdersToInvoices(): Promise<any> {
    if (this.moveGepaeckOrdersToInvoicesCalled) {
      return;
    }
    this.moveGepaeckOrdersToInvoicesCalled = true;
    return await this.http.get<any>(
      `${this.baseUrl}/movetofrako`,
      await GepaeckBelegService.getHttpHeaderAsync()).toPromise();
  }

  public getAll(): Observable<GepaeckBeleg[]> {
    // refactored: 24.07.2023 (vorher Promise)
    return this.http.get<GepaeckBeleg[]>(`${this.baseUrl}/list`, this.getHttpHeader());
  }

  public getFiltered(gepaeckbeleg: GepaeckBeleg): Observable<GepaeckBeleg[]> {
    // refactored: 24.07.2023 (vorher Promise)
    let url: string = `${this.baseUrl}/list`;
    let queryParams: string = '';
    if (gepaeckbeleg !== undefined && gepaeckbeleg !== null) {
      const filterDate = gepaeckbeleg.gpblg_dummy_datum !== undefined ? formatDate(gepaeckbeleg.gpblg_dummy_datum, 'yyyy-MM-dd', 'de-DE') : '2099-01-01';
      gepaeckbeleg.gpblg_dummy_abgang_fort_id = (gepaeckbeleg.gpblg_dummy_abgang_fort_id ?? 0) < 1 ? null : gepaeckbeleg.gpblg_dummy_abgang_fort_id;
      gepaeckbeleg.gpblg_dummy_ziel_fort_id = (gepaeckbeleg.gpblg_dummy_ziel_fort_id ?? 0) < 1 ? null : gepaeckbeleg.gpblg_dummy_ziel_fort_id;
      gepaeckbeleg.fp_id = (gepaeckbeleg.fp_id ?? 0) < 1 ? 0 : gepaeckbeleg.fp_id;

      if ((gepaeckbeleg.fp_id ?? 0) >= 0) {
        queryParams += `?fahrplanId=${gepaeckbeleg.fp_id}`;
      }
      if ((gepaeckbeleg.gpblg_dummy_abgang_fort_id ?? 0) > 0) {
        queryParams += `&abfahrtOrtId=${gepaeckbeleg.gpblg_dummy_abgang_fort_id}`;
      }
      if ((gepaeckbeleg.gpblg_dummy_ziel_fort_id ?? 0) > 0) {
        queryParams += `&zielOrtId=${gepaeckbeleg.gpblg_dummy_ziel_fort_id}`;
      }
      if (!!filterDate) {
        queryParams += `&filterDate=${filterDate}`;
      }
      if (gepaeckbeleg.gpblg_external_order_id !== undefined && !!gepaeckbeleg.gpblg_external_order_id) {
        queryParams += `&externalId=${gepaeckbeleg.gpblg_external_order_id}`;
      }
      url += queryParams;
    }

    let headers: { headers: HttpHeaders } = this.getHttpHeader();
    return this.http.get<GepaeckBeleg[]>(url, headers);
  }

  public getFilteredOld(abfahrtDatum: Date, abfahrtOrtId: number, fahrplanId: number, filterByExternalOrderId: string): Observable<GepaeckBeleg[]> {
    // refactored: 24.07.2023 (vorher Promise)
    let url: string = `${this.baseUrl}/list`;
    let queryParams: string = '';
    const filterDate = abfahrtDatum ? formatDate(abfahrtDatum, 'yyyy-MM-dd', 'de-DE') : '2099-01-01';
    abfahrtOrtId = (abfahrtOrtId ?? 0) < 1 ? null : abfahrtOrtId;
    fahrplanId = (fahrplanId ?? 0) < 1 ? 0 : fahrplanId;

    if ((fahrplanId ?? 0) >= 0) {
      queryParams += `?fahrplanId=${fahrplanId}`;
    }
    if ((abfahrtOrtId ?? 0) > 0) {
      queryParams += `&abfahrtOrtId=${abfahrtOrtId}`;
    }
    if (!!filterDate) {
      queryParams += `&filterDate=${filterDate}`;
    }
    if (!!filterByExternalOrderId) {
      queryParams += `&externalId=${filterByExternalOrderId}`;
    }
    url += queryParams;
    let headers: { headers: HttpHeaders } = this.getHttpHeader();
    return this.http.get<GepaeckBeleg[]>(url, headers);
  }

  public getGepaeckBeleg(gepaeckId: number, tan: number | undefined): Observable<GepaeckBeleg> {
    let gepQuery: string = `?id=${gepaeckId?.toString() ?? -1}`;
    if (tan !== undefined) {
      gepQuery = `${gepQuery}&tan=${tan}`;
    }

    // 20240403_ liefert nur noch einen Auftrag, bisher war es ein Array
    return this.http.get<GepaeckBeleg>(
      `${this.baseUrl}${gepQuery}`,
      this.getHttpHeader()
    ).pipe(
      map(data => {
        return data;
      })
    );
  }

  public getNewGepaeckBeleg(): GepaeckBeleg {
    let gepaeckBeleg = new GepaeckBeleg;
    gepaeckBeleg = {
      mand_id: 1,
      adr_id: null,
      gpblg_anz: 0,
      gpblg_bezahlt_kz: '0',
      gpblg_zktoart_id: null,
      gpblg_brutto_amount: null,
      gpblg_gepaeckstuecke_json: null,
      gpblg_extern_name: null,
      gpblg_extern_mobil_tel: null,
      gpblg_extern_email: null,
      gpblg_rgempf_adr_id: null,
      gpblg_bemerk_text: null,
      gpblg_extern_transpbed_bestaetigt_yn: false
    };
    (async () => {
      await this.authenticationService.getGuid().then(res => {
        gepaeckBeleg.gpblg_shoporder_guid = res;
      });
    });
    return gepaeckBeleg;
  }

  public getGepaeckDescriptions(): string[] {
    return ['Hartschale schwarz', 'Hartschale rot', 'Hartschale grün', 'Hartschale blau',
      'Lederkoffer', 'Ledertasche', 'Sporttasche', 'Reisetasche', 'Trolley', 'Zelttasche bis 20kg', 'Karton', 'Kiste',
      'schwarz', 'rot', 'blau', 'silber', 'bronze', 'gelb', 'grau', 'grün', 'pink',
      'gestreift', 'mit Aufklebern'];
  }

  public async getGepaeckTransport01Artikel(): Promise<IArtikel> {
    if (this.gepaeckTransport01Artikel !== undefined && !!this.gepaeckTransport01Artikel) {
      return this.gepaeckTransport01Artikel;
    }
    return this.artikelService.getGepaeckTransport01Artikel().then(resArt => {
      this.bruttoPreisProGepaeckStueck = resArt.art_brutto_preis1;
      return resArt;
    });
  }

  public async getBruttoPreisProGepaeckStueck(): Promise<any> {
    if (this.bruttoPreisProGepaeckStueck !== undefined && this.bruttoPreisProGepaeckStueck > 0) {
      return this.bruttoPreisProGepaeckStueck;
    }
    return this.artikelService.getGepaeckTransport01Artikel().then(resArt => {
      if (resArt === undefined || resArt.art_nr === undefined || resArt.art_brutto_preis1 === undefined || resArt.art_brutto_preis1 <= 0) {
        this.bruttoPreisProGepaeckStueck = 0;
        return this.bruttoPreisProGepaeckStueck;
      }
      this.bruttoPreisProGepaeckStueck = resArt.art_brutto_preis1;
      return resArt.art_brutto_preis1;
    });
  }

  public async getNewTan(personalKey: string): Promise<ITan> {
    return await lastValueFrom(this.http.get<ITan>(
      `${environment.restApiUrl}/newTan/gepaeckbeleg?personalKey=${personalKey}`,
      this.getHttpHeader()
    ));
  }

  public getMinimalStartDate(): Date {
    let startDate: Date;

    if (this.authenticationService.isEmployee) {
      // heute - 62 Tage
      // Mitarbeiter koennen GEP auch nachtraeglich erfassen, bis 31 Tage rueckwirkend
      startDate = new Date(new Date().setHours(0, 0, 0, 0) - (62 * 24 * 60 * 60 * 1000));
    } else {
      // Regel für Kunden ...
      if (new Date().getHours() < 16) {
        // heute + 1 Tag
        startDate = new Date(new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000));
        /*console.log('Regel fuer Gepaeckkunden vor 16', this.gepaeckBeleg.gpblg_dummy_datum);*/
      } else {
        // heute + 2 Tage
        startDate = new Date(new Date().setHours(0, 0, 0, 0) + (48 * 60 * 60 * 1000));
        /*console.log('Regel fuer Gepaeckkunden nach 16', this.gepaeckBeleg.gpblg_dummy_datum);*/
      }
    }
    return startDate;
  }

  public getMinimalPreallocateDate(): Date {
    let preallocateDate: Date;

    if (this.authenticationService.isEmployee) {
      // heute, ab Mittag: morgen
      preallocateDate = new Date().getHours() <= 12
        ? new Date(new Date().setHours(0, 0, 0, 0))
        : new Date(new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000));
    } else {
      // Regel für Kunden ...
      if (new Date().getHours() < 16) {
        // heute + 1 Tag
        preallocateDate = new Date(new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000));
        /*console.log('Regel fuer Gepaeckkunden vor 16', this.gepaeckBeleg.gpblg_dummy_datum);*/
      } else {
        // heute + 2 Tage
        preallocateDate = new Date(new Date().setHours(0, 0, 0, 0) + (48 * 60 * 60 * 1000));
        /*console.log('Regel fuer Gepaeckkunden nach 16', this.gepaeckBeleg.gpblg_dummy_datum);*/
      }
    }
    preallocateDate.setHours(0, 0, 0, 0);
    return preallocateDate;
  }

  public isGepackBelegEditAllowed(gepackBeleg: GepaeckBeleg) {
    return this.authenticationService.isEmployee;
  }

  public async signAsPayed(gpblg_id: number) {
    return await lastValueFrom(this.http.put(
      `${this.baseUrl}/signaspayed/${gpblg_id}`,
      gpblg_id,
      await GepaeckBelegService.getHttpHeaderAsync()
    ));
  }

  public async signAsPayedByPayPal(gpblg_id: number,
                                   mobileTan: string,
                                   transactionId: string,
                                   transactionDate: Date | any| undefined,
                                   transactionData: object | any| undefined) {
    if (!gpblg_id || gpblg_id < 1
      || !mobileTan || !transactionId) {
      return of();
    }
    if (transactionDate === undefined) {
      transactionDate = new Date();
    }

    const url = `${this.baseUrl}/signaspayed`;
    const body = {
      id: gpblg_id,
      tan: mobileTan,
      paymentProvider: 'PayPal',
      paymentTransactionId: transactionId.toString() ?? '',
      paymentTransactionTimestamp: transactionDate?.toString() ?? '',
      transactionData: transactionData?.toString() ?? ''
    };

    return await lastValueFrom(this.http.put(
      url, body,
      await GepaeckBelegService.getHttpHeaderAsync()
    ));
  }

  public async signAsUnPayed(gpblg_id: number) {
    return await lastValueFrom(this.http.put(
      `${this.baseUrl}/signasunpayed/${gpblg_id}`,
      gpblg_id,
      await GepaeckBelegService.getHttpHeaderAsync()
    ));
  }

  public signAsServicedByKeys(gpblg_id: number[], serviceProcessStatus: GepaeckProcessStatus, containerNo: string, revokeSigning: boolean = false): Observable<any> {
    let bodyData: any;
    bodyData = {processStatus: serviceProcessStatus};
    bodyData.rowKeys = gpblg_id;
    bodyData.containerNo = containerNo;
    bodyData.revokeSigning = revokeSigning;

    return this.http.put(
      `${this.baseUrl}/signasserviced`,
      bodyData,
      this.getHttpHeader()
    );
  }

  public signAsServiced(gpblg_id: number, serviceProcessStatus: GepaeckProcessStatus): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/signasserviced`,
      {id: gpblg_id, processStatus: serviceProcessStatus, revokeSigning: false},
      this.getHttpHeader()
    );
  }

  public async notifyOrderToCustomer(gpblg_id: number, gpblg_extern_mobiletan: string): Promise<void> {
    return await lastValueFrom(this.http.put<GepaeckBeleg>(
      `${this.baseUrl}/notifyordertocustomer`,
      {gpblg_id: gpblg_id, gpblg_extern_mobiletan: gpblg_extern_mobiletan},
      await GepaeckBelegService.getHttpHeaderAsync()
    )).then(() => {
      return;
    });
  }

  public async delete(gpblg_id: number) {
    try {
      return await lastValueFrom(this.http.delete(
        `${this.baseUrl}/${gpblg_id}`,
        await GepaeckBelegService.getHttpHeaderAsync()
      )).then();
    } catch (error) {
      return GepaeckBelegService.handleError;
    }
  }

  public loadFrontendTransportBedingungenUrl(): string {
    this.textblockService.getTextBlock('GPBLG-TRSPBED-URL').toPromise().then(res => {
      this.frontendTransportBedingungenUrl = res.txblk_plaintext;
      return !!this.frontendTransportBedingungenUrl ? this.frontendTransportBedingungenUrl : '';
    });
    return !!this.frontendTransportBedingungenUrl ? this.frontendTransportBedingungenUrl : '';
  }

  public getFrontendTransportBedingungenUrl(): string {
    return !!this.frontendTransportBedingungenUrl ? this.frontendTransportBedingungenUrl : '';
  }

  public getShopOrder(gepaeckBeleg: GepaeckBeleg): ShopOrder {
    // map GepaeckBeleg to ShopOrder
    if (!environment.production) console.log('getShopOrder start');

    let shopOrder: ShopOrder = new ShopOrder();
    if (gepaeckBeleg === undefined || !gepaeckBeleg) {
      return shopOrder;
      /*return new Promise(() => { return shopOrder;
      });*/
    }
    let id: number = (gepaeckBeleg.gpblg_id === undefined || gepaeckBeleg.gpblg_id < 1) ? null : gepaeckBeleg.gpblg_id;

    shopOrder.id = id;
    shopOrder.orderId = id;
    shopOrder.guid = gepaeckBeleg.gpblg_shoporder_guid;
    shopOrder.customerTan = gepaeckBeleg.gpblg_extern_mobiletan;
    shopOrder.typeCode = 'GEP';
    shopOrder.orderTypeText = 'Gepäckauftrag';
    shopOrder.orderNoFull = 'GEP-' + id.toString();
    shopOrder.deliveryDate = gepaeckBeleg.gpblg_dummy_datum;
    shopOrder.invoiceDate = gepaeckBeleg.gpblg_dummy_datum;
    shopOrder.currency = 'EUR';

    let shopOrderItems: ShopOrderItem[] = [];
    shopOrderItems[0] = new ShopOrderItem();
    shopOrderItems[0].id = this.gepaeckTransport01Artikel.art_nr;
    shopOrderItems[0].quantity = gepaeckBeleg.gpblg_anz;
    shopOrderItems[0].itemNo = this.gepaeckTransport01Artikel.art_nr.toString();
    shopOrderItems[0].itemName1 = this.gepaeckTransport01Artikel.art_name1;
    shopOrderItems[0].itemName2 = this.gepaeckTransport01Artikel.art_name2;
    shopOrderItems[0].priceNet = this.gepaeckTransport01Artikel.art_preis1;
    shopOrderItems[0].amountGross = gepaeckBeleg.gpblg_brutto_amount;
    // shopOrderItem[0].priceGross = this.gepaeckTransport01Artikel.art_brutto_preis1;
    shopOrderItems[0].priceGross = gepaeckBeleg.gpblg_anz !== undefined && gepaeckBeleg.gpblg_anz > 0
    && gepaeckBeleg.gpblg_brutto_amount !== undefined && gepaeckBeleg.gpblg_brutto_amount > 0
      ? Number((gepaeckBeleg.gpblg_brutto_amount / gepaeckBeleg.gpblg_anz).toFixed(2))
      : 0;
    shopOrderItems[0].taxCode = this.gepaeckTransport01Artikel.ustk_nr.toString();
    shopOrderItems[0].quantityUnitCode = this.gepaeckTransport01Artikel.art_me_code;
    shopOrderItems[0].itemText = this.gepaeckTransport01Artikel.art_name2 ?? this.gepaeckTransport01Artikel.art_name1 ?? gepaeckBeleg.gpblg_gepaeckstuecke_json.toString() ?? gepaeckBeleg.gpblg_bemerk_text;
    shopOrder.items = shopOrderItems;
    if (!environment.production) console.log('getShopOrder', shopOrder);

    if (gepaeckBeleg.adr_id ?? 0 > 0) {
      this.adresseService.getGepaeckAdrDddw().then((resAdr: AdresseDddw[]) => {
        if (resAdr !== undefined && resAdr.length > 0) {
          let index = resAdr.findIndex(rec => rec.adr_id === gepaeckBeleg.adr_id);
          if (index >= 0) {
            shopOrder.shippingAddress = new ShopAddress();
            shopOrder.shippingAddress.lastName = resAdr[index].adr_gepaeck_name;
            shopOrder.shippingAddress.zip = resAdr[index].adr_strasse_plz;
            shopOrder.shippingAddress.street = resAdr[index].adr_strasse;
            shopOrder.shippingAddress.city = resAdr[index].adr_ort;
            shopOrder.shippingAddress.country = 'DE';
          }
        }
      });
    }

    shopOrder.billingAddress = new ShopAddress();
    shopOrder.billingAddress.lastName = gepaeckBeleg.gpblg_extern_name ?? 'unbekannt';
    shopOrder.billingAddress.country = 'DE';
    if (!environment.production) console.log('getShopOrder', shopOrder);
    return shopOrder;
  }

  public checkMindestDatum = function (value: any, requiredStartDate: Date) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (!requiredStartDate) {
          resolve(true);
        }
        resolve(new Date(value) >= requiredStartDate);
      }, 500);
    });
  };

  public checkMindestGepaeckAnzahl = function (value: any, requiredMindestanzahl: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (!requiredMindestanzahl) {
          resolve(true);
        }
        resolve(value >= requiredMindestanzahl);
      }, 500);
    });
  };

  private getHttpHeader(): { headers: HttpHeaders } {
    const accessToken: string = localStorage.getItem('sessionKey');
    let httpOptions: { headers: HttpHeaders } = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
    };
    if (accessToken) {
      httpOptions.headers.append('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

}
