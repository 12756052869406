import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Op} from '../api-dtos/op';
import {Docstore} from '../api-dtos/docstore';
import {ZahlKontoArt} from '../api-dtos/zahl-konto-art-dto';

export enum ZahlungStatus {
  Offen = '0',
  Bezahlt = '1',
  Rechnung = '2'
}

@Injectable({
  providedIn: 'root'
})
export class AccountingService {
  baseUrl = `${environment.restApiUrl}/accounting`;

  constructor(
    private http: HttpClient) {
  }

  private async getHttpHeader() {
    const accessToken: string = localStorage.getItem('sessionKey') ?? '';
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (!!accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  private async getHttpHeaderBinary(doc_mimetype: string | null) {
    if (!!doc_mimetype) {
      doc_mimetype.toLowerCase();
    } else {
      doc_mimetype = 'json';
    }
    const accessToken: string = localStorage.getItem('sessionKey') ?? '';
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/' + doc_mimetype}),
      responseType: 'blob'
    };

    if (!!accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  public async getAll(): Promise<Op[]> {
    return await this.http.get<Op[]>(
      `${this.baseUrl}/invoice/list`,
      await this.getHttpHeader()
    ).toPromise();
  }

  public async getArchivedDocument(belegArtCode: string, belegNr: number): Promise<Op[]> {
    if (belegArtCode.trim().length === 0 || belegNr.valueOf() < 1) {
      return;
    }
    belegArtCode = belegArtCode.trim();
    return await this.getArchivedDocumentInfo(belegArtCode, belegNr);
  }

  public async getArchivedDocumentInfo(belegArtCode: string, belegNr: number): Promise<Docstore[]> {
    if (belegArtCode.trim().length === 0 || belegNr < 1) {
      return;
    }
    belegArtCode = belegArtCode.trim();
    return this.http.get<Docstore[]>(
      `${this.baseUrl}/invoice/download/info?belegArtCode=${belegArtCode.trim() ?? '---'}&belegNr=${belegNr.toString() ?? '---'}`,
      await this.getHttpHeader())
      .toPromise();
  }

  public async getArchivedDocumentBinary(op: Op, docInfo: Docstore): Promise<Blob> {
    if (!op || op.op_belegart_code.trim().length === 0 || op.op_beleg_nr < 1
      || !docInfo || !docInfo.doc_id || docInfo.doc_id < 0) {
      return;
    }
    op.op_belegart_code = op.op_belegart_code.trim();
    const belegArtCode: string = op.op_belegart_code;
    const belegNr: number = op.op_beleg_nr;
    if (!docInfo.doc_mimetype) {
      docInfo.doc_mimetype = 'txt';
    }

    return await this.http.get(
      `${this.baseUrl}/invoice/download/binary?belegArtCode=${belegArtCode}&belegNr=${belegNr.toString()}`,
      {responseType: 'blob'}
    ).toPromise();
  }

  public async getZahlArtDddw(activeOnly: boolean = false): Promise<ZahlKontoArt[]> {
    let useUrl;
    useUrl = this.baseUrl + `/zahlkontoart/dropdownlist`;
    if (activeOnly) {
      useUrl += `/aktiv`;
    }
    return await this.http.get<ZahlKontoArt[]>(
      useUrl,
      await this.getHttpHeader()
    ).toPromise();
  }

  public removeZahlArtFromDddw(zahlKontoArts: ZahlKontoArt[], zahlKontoArtNameKz: string): ZahlKontoArt[] {
    if (!(!!zahlKontoArtNameKz) || zahlKontoArts === undefined || zahlKontoArts.length === 0) {
      return zahlKontoArts
    }
    let index = zahlKontoArts.findIndex(rec => rec.zktoart_name_kz === zahlKontoArtNameKz);
    if (index >= 0) {
      zahlKontoArts.splice(index, 1);
    }
    return zahlKontoArts;
  }


  public async gettyAll(): Promise<Op[]> {
    return await this.http.get<Op[]>(
      `${this.baseUrl}/invoice/list`,
      await this.getHttpHeader()
    ).toPromise();
  }


}
