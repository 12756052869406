import {Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {FahrplanService} from '../../../api/api-services/fahrplan.service';
import {AdresseDddw} from '../../../api/api-dtos/adresse-dddw';
import {IFahrplan} from '../../../api/api-dtos/fahrplan';
import {GepaeckBeleg} from '../../../api/api-dtos/gepaeck-beleg';
import {formatDate, Location} from '@angular/common';
import {DxFormComponent} from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import {GepaeckBelegService, SmsService, StreckeSettingService} from '../../../api/api-services/api-services.module';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {ActivatedRoute, Router} from '@angular/router';
import {ITan} from '../../../api/api-dtos/itan';
import {ITransportArt} from '../../../api/api-dtos/transport-art';
import {IStreckeSetting} from '../../../api/api-dtos/strecke-setting';
import {environment} from '../../../../environments/environment';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'app-gepaeck-auftrag-cust',
  templateUrl: './gepaeck-auftrag-cust-edit.component.html',
  styleUrls: ['./gepaeck-auftrag-cust-edit.component.scss']
})

export class GepaeckAuftragCustEditComponent implements OnInit, OnChanges, OnDestroy {
  public frontendGepaeckTransportBedingungenUrl: string;
  public maxCardWidth = 'auto';
  public submitButtonText: BehaviorSubject<string> = new BehaviorSubject<string>('Gepäckauftrag buchen');
  public bruttoPreisProGepaeckStueck: number = 0;
  initialLoading: boolean = true;
  popoverVisible: boolean = false;
  submitOrderDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  validatePhoneNumber: BehaviorSubject<boolean | undefined> = new BehaviorSubject<boolean | undefined>(false);
  isValidOrderData = false;
  isValidPhoneNumber = false;
  startDate: Date = new Date();
  adresseDddw: AdresseDddw[] = [{}];
  gepaeckAdresseDddw: AdresseDddw[] = [{}];
  gepaeckTransportArts: ITransportArt[];
  streckeSettings: IStreckeSetting[];
  currentStreckeSetting: IStreckeSetting;
  private streckeSettingIndex: number;
  fahrplans: IFahrplan[];
  colCountByScreen: Object;
  gepaeckBeleg: GepaeckBeleg;
  isNewOrder: boolean | void = null;

  frachtbriefEditAllowed = false;
  radioGroupItemsTypeOfTransport = [
    {text: 'Schiff', fp_art_kz: 'FAEHRE'},
    {text: 'Flug', fp_art_kz: 'FLUG'},
  ];
  radioGroupItemsFrachtOrte = [
    {text: 'zur Insel', frachtortId: 2},
    {text: 'zum Festland', frachtortId: 1}
  ];
  public isProcessing = false;
  public isLoading = true;
  public isDisableEditing = false;
  public isDisableEditingFaehre = true;
  public isDisableEditingBelegEmpfaenger = false;
  public isGetApprovalCodeDisabled = true;
  public isMobileTanDisabled = true;
  public gepaeckDescriptions: string[] = [];
  private _gepaeckStueckValue: any;

  buttonBack = {
    text: 'zurück',
    icon: 'back',
    type: 'normal',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    onClick: () => {
      this.goBack();
      return;
    },
  };
  buttonPrint = {
    text: 'Druck-Ansicht',
    icon: 'print',
    type: 'normal',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    onClick: () => {
      this.printPreview();
      return;
    },
  };

  buttonSubmitOrderData = {
    text: this.getSaveButtonLabelText(),
    name: 'saveButton',
    type: 'default',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    hint: 'Prüft den Gepäckauftrag und sendet ihn an das Buchungssystem.',
    onClick: (e: any) => {
      this.onSubmitOrderData().then();
    },
  };
  componentTitle = 'Gepäckauftrag';
  private unsubscribe: Subject<void> = new Subject<void>();

  @ViewChild(DxFormComponent, {static: false}) gepaeckForm: DxFormComponent;
  @Input() inputGepaeckAdresseDddw: AdresseDddw[];
  @Input() inputAdresseDddw: AdresseDddw[];
  inputGepaeckID: number | undefined;
  inputGepaeckTAN: number | undefined;
  @Input() inputFahrplanID: number | undefined;
  @Input() inputTypeOfTransport: string | undefined;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private adresseService: AdresseService,
    private fahrplanService: FahrplanService,
    private streckeSettingService: StreckeSettingService,
    private smsService: SmsService,
    private gepaeckBelegService: GepaeckBelegService,
    private readonly location: Location
  ) {
    // todo: Regel fuer registrierte Kunden anders als für anonyme Kunden?
    /* Regel fuer fuer registrierte Kunden:
    * bis 16:00 eines Tages fuer den Folgetag, nach 16.00 fuer den uebernaechsten Tag buchen
    * */

    this.gepaeckBeleg = this.gepaeckBelegService.getNewGepaeckBeleg();

    this.getSaveButtonLabelText = this.getSaveButtonLabelText.bind(this);
    this.getIsValidOrderData = this.getIsValidOrderData.bind(this);
    this.getIsValidPhoneNumber = this.getIsValidPhoneNumber.bind(this);
    this.getTan = this.getTan.bind(this);
    this.getMaxCardWidth = this.getMaxCardWidth.bind(this);
    this.onGepaeckStueckTagCreatingDescriptions = this.onGepaeckStueckTagCreatingDescriptions.bind(this);
    this.onGepaeckStueckTagInput = this.onGepaeckStueckTagInput.bind(this);
    this.onGepaeckStueckTagKeyDown = this.onGepaeckStueckTagKeyDown.bind(this);
    this.onGepaeckStueckTagFocusOut = this.onGepaeckStueckTagFocusOut.bind(this);
    this.printPreview = this.printPreview.bind(this);
    this.asyncValidationGpBlgAnz = this.asyncValidationGpBlgAnz.bind(this);
    this.getDatumLabelText = this.getDatumLabelText.bind(this);
    this.isLoading = true;

    this.authenticationService.checkRefreshLogin().then();
    if (!this.authenticationService.isAuthenticated) {
      notify({
        message: 'Ihre Anmeldung fehlt oder ist abgelaufen. Bitte erst einloggen.',
        position: {
          my: 'center',
          at: 'center'
        }
      }, 'error', 3000);
      this.router.navigate(['/home']);
    }

    this.gepaeckBelegService.getBruttoPreisProGepaeckStueck().then(resPreis => {
      this.bruttoPreisProGepaeckStueck = resPreis;
      if (!environment.production) console.log('constructor getBruttoPreisProGepaeckStueck: resPreis ', resPreis, this.bruttoPreisProGepaeckStueck);
    });

    this.inputGepaeckID = 0;
    this.inputFahrplanID = null;
    this.inputTypeOfTransport = null;
    this.streckeSettingService.getTransportArtListByService('gepaeck')
      .subscribe(resTransport => {
        // FLUG, FAEHRE, ...
        this.gepaeckTransportArts = resTransport;
        this.radioGroupItemsTypeOfTransport = this.streckeSettingService.getGroupItemsTypeOfTransport(resTransport);
      });

    route.params.subscribe(params => {
      if (!environment.production) console.log('params', params);
      this.inputGepaeckID = !!params['id'] && Number(params['id']) > 0 ? Number(params['id']) : 0;
      if (this.inputGepaeckID === 0) {
        this.inputFahrplanID = !!params['fp_id'] && Number(params['fp_id']) > 0 ? Number(params['fp_id']) : null;
        this.inputTypeOfTransport = !!params['fp_typeOfTransport'] ? params['fp_typeOfTransport'].toString().toUpperCase() : 'FAEHRE';
      }
    });
    if (!environment.production) console.log('con after route.params.subscribe', this.inputGepaeckID, this.inputTypeOfTransport, this.inputFahrplanID);

    // 2023-05-??
    if (!!this.inputGepaeckID && this.inputGepaeckID > 0) {
      if (!environment.production) console.log('GepId', this.inputGepaeckID);
      this.isNewOrder = false;
      this.doInit();
      return;
    } else {
      this.isNewOrder = true;
      this.gepaeckBeleg = this.gepaeckBelegService.getNewGepaeckBeleg();
      this.gepaeckBeleg.fp_id = 0;
    }

    if (!environment.production) console.log('constructor this.inputTypeOfTransport ', this.inputTypeOfTransport);
    if (!!this.inputTypeOfTransport) {
      this.gepaeckBeleg.gpblg_dummy_fp_art_kz = this.inputTypeOfTransport.toUpperCase();
      this.switchTransportArtCode(this.inputTypeOfTransport).then(() => {
        this.doInit();
      });
    } else if (this.gepaeckTransportArts?.length > 0 && this.isNewOrder) {
      this.gepaeckBeleg.gpblg_dummy_fp_art_kz = this.gepaeckTransportArts[0].transportArtCode;
      this.switchTransportArtCode(this.gepaeckBeleg.gpblg_dummy_fp_art_kz).then(() => {
        this.doInit();
      });
    } else {
      this.initialLoading = false;
    }
    if (!environment.production) console.log('con end route.params.subscribe', this.inputGepaeckID, this.inputTypeOfTransport, this.inputFahrplanID);
  }

  private doInit() {
    this.initialLoading = true;
    if (this.inputGepaeckID === undefined) {
      this.inputGepaeckID = 0;
    }
    if (this.inputFahrplanID > 0 && this.gepaeckBeleg.fp_id && this.gepaeckBeleg.fp_id !== this.inputFahrplanID) {
      this.gepaeckBeleg.fp_id = this.inputFahrplanID;
    }

    if (!environment.production) console.log('vor getGepaeckBeleg', this.inputGepaeckID);
    if (this.inputGepaeckID !== undefined && this.inputGepaeckID > 0) {
      if (!environment.production) console.log('vor getGepaeckBeleg', this.inputGepaeckID);
      this.gepaeckBelegService.getGepaeckBeleg(this.inputGepaeckID, null).subscribe( (resGepBeleg: GepaeckBeleg) => {
        if (resGepBeleg === undefined) {
          return;
        }
        this.isNewOrder = false;
        this.submitButtonText.next('Gepäckauftrag ändern');
        // this.buttonSubmitOrderData.text = 'Gepäckauftrag speichern';
        // console.log('ngOnInit SET buttonSubmitOrderData.text', this.buttonSubmitOrderData.text);
        // damit aus dem String wieder ein Array wird ...
        // resGepBeleg[0].gpblg_gepaeckstuecke_json = JSON.parse(resGepBeleg[0].gpblg_gepaeckstuecke_json);
        this.gepaeckBeleg = resGepBeleg;

        //  Frachtorte Radiobuttons laden aus Settings ...
        // this.gepaeckBeleg.gpblg_dummy_fp_art_kz = this.gepaeckTransportArts[0].transportArtCode;
        this.switchTransportArtCode(this.gepaeckBeleg.gpblg_dummy_fp_art_kz).then(() => {
          this.reselectStreckeSettingAfterRichtungChange(this.gepaeckBeleg.gpblg_dummy_fp_art_kz, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
          this.radioGroupItemsFrachtOrte = this.streckeSettingService.getStreckeRadioGroupItems(this.streckeSettings);
          this.isDisableEditing = (new Date(this.gepaeckBeleg.gpblg_dummy_datum) < this.gepaeckBelegService.getMinimalStartDate()) || (this.gepaeckBeleg.gpblg_storniert_yn ?? 0) == 1;
          this.isDisableEditingBelegEmpfaenger = (!this.authenticationService.isEmployee || this.isDisableEditing);
          this.isDisableEditingFaehre = this.isDisableEditing;
          this.startDate = this.isDisableEditing ? null : this.streckeSettingService.getMinimalStartDateByPattern(this.currentStreckeSetting.svstrs_gepaeckbuchungsfristregel_code);
        });
      });
    } else {
      // Neuer Auftrag
      this.isNewOrder = true;
      this.gepaeckBeleg.gpblg_extern_transpbed_bestaetigt_yn = this.authenticationService.isAuthenticated && this.authenticationService.isEmployee ? 1 : 0;
      if (!!this.inputFahrplanID) {
        this.gepaeckBeleg.fp_id = this.inputFahrplanID;
      } else {
        // erste grobe Vorbelegung ...
        if (this.gepaeckBeleg.gpblg_dummy_fp_art_kz === undefined || !this.gepaeckBeleg.gpblg_dummy_fp_art_kz) {
          this.gepaeckBeleg.gpblg_dummy_fp_art_kz = !!this.inputTypeOfTransport ? this.inputTypeOfTransport : 'FAEHRE';
        }
        this.gepaeckBeleg.gpblg_dummy_datum = new Date(this.gepaeckBelegService.getMinimalStartDate().setHours(0, 0, 0, 0));
        this.startDate = new Date(this.gepaeckBelegService.getMinimalStartDate().setHours(0, 0, 0, 0));
      }
      this.reselectStreckeSettingAfterRichtungChange(this.gepaeckBeleg.gpblg_dummy_fp_art_kz, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
      this.radioGroupItemsFrachtOrte = this.streckeSettingService.getStreckeRadioGroupItems(this.streckeSettings);
      this.isDisableEditing = new Date(this.gepaeckBeleg.gpblg_dummy_datum) < this.gepaeckBelegService.getMinimalStartDate();
      this.isDisableEditingBelegEmpfaenger = (!this.authenticationService.isEmployee || this.isDisableEditing);
      this.isDisableEditingFaehre = this.isDisableEditing;
      this.startDate = this.isDisableEditing ? null : this.streckeSettingService.getMinimalStartDateByPattern(this.currentStreckeSetting.svstrs_gepaeckbuchungsfristregel_code);

      if (!this.gepaeckBeleg.fp_id) {
        this.lookupFahrplans().then(() => {
          return;
        });
      }
    }

    this.adresseDddw = [];
    this.adresseService.getAllDddw().then(resAdr => {
      this.adresseDddw = resAdr;

      this.fahrplanService.getById(this.gepaeckBeleg.fp_id ?? 0)
        .then(resFahrplan => {
          if (!environment.production) console.log('fahrplanService.getById after read FP ', this.gepaeckBeleg.fp_id);
          if (!!resFahrplan && resFahrplan.length > 0 && this.isNewOrder) {
            if (!environment.production) console.log('fahrplanService.getById ', this.gepaeckBeleg.fp_id, resFahrplan, resFahrplan.length, this.gepaeckBeleg.gpblg_dummy_fp_art_kz);

            // jetzt die Settings aus dem Fahrplan aktualisieren
            // this.gepaeckBeleg.gpblg_dummy_fp_art_kz = resFahrplan[0].fp_art_kz;
            this.reselectStreckeSettingAfterRichtungChange(this.gepaeckBeleg.gpblg_dummy_fp_art_kz, resFahrplan[0].fp_abfahrt_fort_id, resFahrplan[0].fp_ankunft_fort_id);
            this.gepaeckBeleg.fp_id = this.inputFahrplanID;
            this.gepaeckBeleg.fp_abfahrt_fort_id = resFahrplan[0].fp_abfahrt_fort_id;
            this.gepaeckBeleg.gpblg_dummy_abgang_fort_id = resFahrplan[0].fp_abfahrt_fort_id;
            this.gepaeckBeleg.gpblg_dummy_ziel_fort_id = resFahrplan[0].fp_ankunft_fort_id;
            this.gepaeckBeleg.gpblg_dummy_datum = new Date(new Date(resFahrplan[0].fp_abfahrt_datzeit).setHours(0, 0, 0, 0));
            this.reselectStreckeSettingAfterRichtungChange(this.gepaeckBeleg.gpblg_dummy_fp_art_kz, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
            // this.startDate = this.gepaeckBeleg.gpblg_dummy_datum;
            if (new Date(resFahrplan[0].fp_abfahrt_datzeit) < this.startDate) {
            }

            if (new Date(resFahrplan[0].fp_abfahrt_datzeit)
              >= this.streckeSettingService.getMinimalStartDateByPattern(resFahrplan[0].svstrs_gepaeckbuchungsfristregel_code)) {
            }
          } else {
            if ((this.gepaeckBeleg.fp_id ?? 0) === 0) {
              // ohne Fahrplan ...
              this.gepaeckBeleg.gpblg_dummy_datum = this.streckeSettingService.getMinimalStartDateByPattern(this.currentStreckeSetting?.svstrs_gepaeckbuchungsfristregel_code);
              this.gepaeckBeleg.gpblg_dummy_datum = new Date(this.gepaeckBeleg.gpblg_dummy_datum.setHours(0, 0, 0, 0));
              this.gepaeckBeleg.gpblg_dummy_abgang_fort_id = this.currentStreckeSetting?.svstrs_ort1_fort_id;
              this.startDate = this.gepaeckBeleg.gpblg_dummy_datum;
              // bis 01.01.23
              // this.startDate = this.gepaeckBelegService.getMinimalStartDate();
              // this.gepaeckBeleg.gpblg_dummy_datum = this.gepaeckBelegService.getMinimalStartDate();
              if (this.authenticationService.isAuthenticated && this.authenticationService.isEmployee) {
                // morgen
                this.gepaeckBeleg.gpblg_dummy_datum = new Date(new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000));
              }
              this.gepaeckBeleg.gpblg_dummy_datum = new Date(this.gepaeckBeleg.gpblg_dummy_datum.setHours(0, 0, 0, 0));
            }
            //  Frachtorte Radiobuttons laden aus Settings ...
            this.reselectStreckeSettingAfterRichtungChange(this.gepaeckBeleg.gpblg_dummy_fp_art_kz, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
            this.radioGroupItemsFrachtOrte = this.streckeSettingService.getStreckeRadioGroupItems(this.streckeSettings);

            if (this.authenticationService.isAuthenticated && this.authenticationService.isCustomer) {
              this.gepaeckBeleg.adr_id = this.authenticationService.customerId;
              this.gepaeckBeleg.gpblg_rgempf_adr_id = this.authenticationService.customerId;
            }
          }

          if (this.authenticationService.isAuthenticated && this.authenticationService.isEmployee) {
            // this.startDate = null;
          }

        });
      this.adresseService.getGepaeckAdrDddw().then(resGepaeckAdr => {
        this.gepaeckAdresseDddw = resGepaeckAdr;
        /*if (!(this.gepaeckBeleg.gpblg_dummy_abgang_fort_id && this.gepaeckBeleg.gpblg_dummy_abgang_fort_id > 0)) {
          return;
        }*/
        if (this.authenticationService.isAuthenticated && this.authenticationService.isCustomer) {
          if (!environment.production) console.log('299 User isCustomer');
          if (!this.gepaeckBeleg.adr_id || this.gepaeckBeleg.adr_id <= 0 || this.isNewOrder) {
            this.gepaeckBeleg.adr_id = this.authenticationService.customerId;
          }
          this.gepaeckBeleg.gpblg_rgempf_adr_id = this.authenticationService.customerId;
        } else if (this.authenticationService.isAuthenticated && this.authenticationService.isEmployee) {
          if (!environment.production) console.log('303 User isEmployee');
          this.adresseService.getDiversePortalKundenAdrId().then(resAdrId => {
            if (!this.gepaeckBeleg.gpblg_rgempf_adr_id || this.gepaeckBeleg.gpblg_rgempf_adr_id <= 0 || this.isNewOrder) {
              this.gepaeckBeleg.gpblg_rgempf_adr_id = resAdrId;
            }
          });
        }
      });
    });
  }

  async ngOnInit() {
    if (!environment.production) console.log('ngOnInit START inputGepaeckID', this.inputGepaeckID);
    this.gepaeckDescriptions = this.gepaeckBelegService.getGepaeckDescriptions();
    this.frontendGepaeckTransportBedingungenUrl = this.gepaeckBelegService.loadFrontendTransportBedingungenUrl();
    this.isDisableEditingBelegEmpfaenger = (!this.authenticationService.isEmployee || this.isDisableEditing);

    window.onresize = () => {
      // this.isMobileResolution = window.innerWidth <= 768;
      if (window.innerWidth < 300) {
        this.maxCardWidth = '300px';
      } else if (window.innerWidth <= 640) {
        this.maxCardWidth = (window.innerWidth - 20).toString() + 'px';
      } else {
        this.maxCardWidth = '620px';
      }
    };
  }

  ngOnChanges(changes: any): void {
    if (!environment.production) console.log('ngOnChanges START inputGepaeckID', changes !== undefined ? changes : 'changes undef');
    this.isLoading = true;

    if (this.bruttoPreisProGepaeckStueck === undefined || this.bruttoPreisProGepaeckStueck <= 0) {
      this.gepaeckBelegService.getBruttoPreisProGepaeckStueck().then(resPreis => {
        this.bruttoPreisProGepaeckStueck = resPreis;
        if (!environment.production) console.log('ngOnChanges START this.bruttoPreisProGepaeckStueck ', this.bruttoPreisProGepaeckStueck);
      });
    }

    this.gepaeckBelegService.getGepaeckBeleg(this.inputGepaeckID, null).subscribe((resGepBeleg: GepaeckBeleg) => {
      if (resGepBeleg === undefined) {
        this.isLoading = false;
        return;
      }
      this.gepaeckBeleg = resGepBeleg;
      this.isLoading = false;
      return;
    });
  }

  ngOnDestroy(): void {
    // Emit something to stop all Observables
    this.unsubscribe.next();
    // Complete the notifying Observable to remove it
    this.unsubscribe.complete();
  }

  public async lookupFahrplans(): Promise<void> {
    this.fahrplans = [{} as IFahrplan];
    if (!this.gepaeckBeleg) {
      return;
    }
    let searchDate: Date = this.gepaeckBeleg.gpblg_dummy_datum;
    if (this.startDate !== undefined && this.startDate !== null) {
      if (new Date(searchDate).setHours(0, 0, 0, 0) === new Date(this.startDate).setHours(0, 0, 0, 0) && this.startDate > searchDate) {
        searchDate = this.startDate;
      }
    }

    if (!environment.production) console.log('lookupFahrplans ', this.gepaeckBeleg.gpblg_dummy_datum, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id);
    await this.fahrplanService.getGepaeckServiceFahrtenByAbfahrtOrt(
      searchDate,
      this.gepaeckBeleg.gpblg_dummy_abgang_fort_id)
      .then((resFahrplans) => {
        this.fahrplans = resFahrplans;
        if (!this.fahrplans || this.fahrplans.length == 0) {
          if (!environment.production) console.log('lookupFahrplans NO DATA found');
          // this.gepaeckBeleg.fp_id = null;
          return;
        }
        if (!!this.fahrplans && this.fahrplans.length === 1 && !!this.fahrplans[0].fp_id) {
          if (!environment.production) console.log('lookupFahrplans ONE found', this.gepaeckBeleg.fp_id, this.fahrplans[0]);
          if (!this.gepaeckBeleg.fp_id) {
            this.gepaeckBeleg.fp_id = this.fahrplans[0].fp_id;
          }
        } else {
          if (!environment.production) console.log('lookupFahrplans SOME found', this.gepaeckBeleg.fp_id, this.fahrplans);

          if (this.gepaeckBeleg.fp_id && this.gepaeckBeleg.fp_id !== 0 && this.fahrplans.findIndex(e => e.fp_id == this.gepaeckBeleg.fp_id) >= 0) {
            // selektiere aus der Liste der Fahrten die bereits gewählte Fahrt!
            if (!environment.production) console.log('lookupFahrplans SOME found, taking index', this.fahrplans.findIndex(e => e.fp_id == this.gepaeckBeleg.fp_id));
            this.gepaeckBeleg.fp_id = this.fahrplans[(this.fahrplans.findIndex(e => e.fp_id == this.gepaeckBeleg.fp_id))].fp_id;
          } else {
            if (!environment.production) console.log('lookupFahrplans SOME found, NO MATCHING INDEX!!!', this.gepaeckBeleg.fp_id);
            // this.gepaeckBeleg.fp_id = this.fahrplans[0].fp_id;
          }
        }
        if (!environment.production) console.log('lookupFahrplans GEP.fp_id is now ', this.gepaeckBeleg.fp_id);
      });
  }

  // Versorge Dropdown mit Daten ...
  getAdresses(options: any) {
    return {
      store: this.adresseDddw,
      filter: options.data ? ['adr_id', '=', options.data.adr_id] : null
    };
  }

  form_fieldDataChanged(e: any) {
    if (this.isLoading || this.isDisableEditing) {
      this.isLoading = false;
      return;
    }
    if (e.component.option('formData') === undefined || e.component.option('formData') === null) {
      return;
    }
    this.gepaeckBeleg = e.component.option('formData');
    if (this.gepaeckBeleg === undefined) {
      this.isDisableEditingFaehre = true;
      return;
    }
    switch (e.dataField) {
      case 'gpblg_dummy_fp_art_kz':
        if (!environment.production) console.log('form_fieldDataChanged do lookup ', e.dataField, e.value);
        this.gepaeckBeleg.gpblg_dummy_abgang_fort_id = null;
        this.gepaeckBeleg.gpblg_dummy_ziel_fort_id = null;

        this.switchTransportArtCode(e.value).then(() => {
          // this.reselectStreckeSettingAfterRichtungChange(e.value, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
          this.lookupFahrplans().then(() => {
            return;
          });
        });
        break;

      case 'gpblg_dummy_abgang_fort_id':
        if (e.value == null || this.currentStreckeSetting == undefined || e.value == this.currentStreckeSetting.svstrs_ort1_fort_id) {
          return;
        }
        if (!environment.production) console.log('form_fieldDataChanged', e.dataField, e.value, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
        if (this.gepaeckBeleg.gpblg_dummy_ziel_fort_id === undefined || this.gepaeckBeleg.gpblg_dummy_ziel_fort_id != this.currentStreckeSetting.svstrs_ort1_fort_id) {
          this.gepaeckBeleg.gpblg_dummy_ziel_fort_id = this.currentStreckeSetting.svstrs_ort1_fort_id;
        }
        if (!environment.production) console.log('form_fieldDataChanged', e.dataField, e.value, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
        this.reselectStreckeSettingAfterRichtungChange(this.gepaeckBeleg.gpblg_dummy_fp_art_kz, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
        if (!environment.production) console.log('form_fieldDataChanged', e.dataField, e.value, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
        this.gepaeckBeleg.gpblg_dummy_abgang_fort_id = e.value;

        if (new Date(this.startDate).setHours(0, 0, 0, 0) > new Date(this.gepaeckBeleg.gpblg_dummy_datum).setHours(0, 0, 0, 0)) {
          this.gepaeckBeleg.gpblg_dummy_datum = new Date(new Date(this.startDate).setHours(0, 0, 0, 0));
        }

        if (!environment.production) console.log('form_fieldDataChanged do lookup', e.dataField, e.value, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
        this.lookupFahrplans().then(() => {
          return;
        });
        break;

      case 'gpblg_dummy_datum':
        this.gepaeckBeleg.gpblg_dummy_datum = e.value;
        if (!environment.production) console.log('form_fieldDataChanged', e.dataField, e.value, this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
        this.lookupFahrplans().then(() => {
          return;
        });
        break;
      case 'fp_id':
        this.getDisplayAbfahrtzeitVoll(e.dataField.item);
        break;
      case 'gpblg_extern_mobil_tel':
        // TelefonNr. ist bei Kunden nicht zwingend erforderlich
        if (e.value === null || e.value.toString().trim() === '') {
          this.validatePhoneNumber.next(true);
          break;
        } else {
          if (e.value.substring(0, 4) === '0049') {
            this.gepaeckBeleg.gpblg_extern_mobil_tel = '+49' + e.value.substr(4, 99);
          }
        }
        this.smsService.callPhoneNumberValidator(e.value).then(resSmsPhoneFormat => {
          if (!!resSmsPhoneFormat && resSmsPhoneFormat?.success === true) {
            this.isValidPhoneNumber = true;
            this.gepaeckBeleg.gpblg_extern_mobil_tel = resSmsPhoneFormat.international_formatted;
            this.getIsValidOrderData();
          } else {
            this.isValidPhoneNumber = false;
            this.getIsValidOrderData();
          }
        });
        break;
      case 'gpblg_anz':
        if (e.value === null || e.value === 0) {
          this.gepaeckBeleg.gpblg_brutto_amount = 0;
          break;
        } else {
          this.gepaeckBeleg.gpblg_brutto_amount = Number((e.value * this.bruttoPreisProGepaeckStueck).toFixed(2));

        }
        break;
      case 'gpblg_rgempf_adr_id':
        if (this.authenticationService.isEmployee) {
          if (this.gepaeckBeleg.adr_id === undefined || this.gepaeckBeleg.adr_id === null || this.gepaeckBeleg.adr_id.valueOf() < 1) {
            this.gepaeckBeleg.adr_id = e.value;
          }
        }
        break;
    }
    this.isDisableEditingFaehre = this.isDisableEditing || this.gepaeckBeleg.gpblg_dummy_abgang_fort_id === undefined;
  }

  getDisplayAbfahrtzeit(item: any): string {
    if (!item || !item.fp_abfahrt_datzeit) {
      return '';
    }
    return formatDate(item.fp_abfahrt_datzeit, 'HH:mm', 'de-DE');
  }

  getDisplayAbfahrtzeitVoll(item: any): string {
    if (!item || !item.fp_abfahrt_datzeit) {
      return '';
    }
    if (parent.window.outerWidth < 520) {
      return formatDate(item.fp_abfahrt_datzeit, 'EE dd.MM.yy HH:mm', 'de-DE');
    } else {
      return formatDate(item.fp_abfahrt_datzeit, 'EE dd.MM.yy HH:mm', 'de-DE') + ' ' + item.ger_name;
    }
  }

  checkComparison() {
    return true;
  }

  getIsValidOrderData(): boolean {
    if (this.authenticationService.isAuthenticated && this.authenticationService.isEmployee
      && (this.gepaeckBeleg.gpblg_rgempf_adr_id == undefined || this.gepaeckBeleg.gpblg_rgempf_adr_id <= 0)) {
      this.adresseService.getDiversePortalKundenAdrId().then(res => {
        this.gepaeckBeleg.gpblg_rgempf_adr_id = res;
      });
    }
    this.isValidOrderData = this.gepaeckForm.instance.validate().isValid;
    return this.gepaeckForm.instance.validate().isValid;
  }

  async onSubmitOrderData() {
    if (this.getIsValidOrderData()) {
      // wenn ich Mitarbeiter bin, habe ich keine Kunden-Nr. Wohin damit?
      // 20.05.2021: erst einmal wie bisher in SITS ERP: keine gpblg_rgempf_adr_id zuweisen!
      // console.log('onSubmitOrderData START isValid ', this.gepaeckBeleg);
      // console.log('onSubmitOrderData ', e, this.gepaeckBeleg.gpblg_extern_mobiletan, this.gepaeckBeleg);

      this.isDisableEditing = true;
      this.isProcessing = true;

      if (this.authenticationService.isAuthenticated && this.authenticationService.isEmployee) {
        await this.adresseService.getDiversePortalKundenAdrId().then(res => {
          if (this.gepaeckBeleg.gpblg_rgempf_adr_id === res) {
            if (this.gepaeckBeleg.gpblg_bezahlt_kz === undefined || this.gepaeckBeleg.gpblg_bezahlt_kz === '' || this.gepaeckBeleg.gpblg_bezahlt_kz !== '0') {
              this.gepaeckBeleg.gpblg_bezahlt_kz = '0';
            }
          } else if (this.gepaeckBeleg.gpblg_rgempf_adr_id !== undefined && this.gepaeckBeleg.gpblg_rgempf_adr_id > 0) {
            // auf Rechnung
            this.gepaeckBeleg.gpblg_bezahlt_kz = '2';
          }
        });
      }

      if (this.gepaeckBeleg.gpblg_rgempf_adr_id && this.gepaeckBeleg.gpblg_rgempf_adr_id > 0) {
        this.gepaeckBeleg.gpblg_extern_personalkey = 'GEP-CUST-' + this.gepaeckBeleg.gpblg_rgempf_adr_id.toString();
      } else {
        this.gepaeckBeleg.gpblg_rgempf_adr_id = null;
        this.gepaeckBeleg.gpblg_extern_personalkey = 'GEP-CUST-00000';
      }

      // todo: kann demnächst weg ...
      this.gepaeckBeleg.gpblg_extern_personalKey = this.gepaeckBeleg.gpblg_extern_personalkey;

      // console.log('onSubmitOrderData START isValid (2) ', this.gepaeckBeleg);
      // auch für Kunden eine TAN generieren, damit für deren Kunden ein oeffentlicher Link zum GEP generiert werden kann.
      this.getTan(this.gepaeckBeleg.gpblg_extern_personalkey).then((resTan: ITan) => {
        this.gepaeckBeleg.gpblg_extern_mobiletan = resTan.tan;
        this.gepaeckBeleg.tpol_id = resTan.tpol_id;
        if (this.gepaeckBeleg.gpblg_extern_mobiletan && this.gepaeckBeleg.gpblg_extern_mobiletan.toString().trim().length === 6) {
          this.gepaeckBelegService.create(this.gepaeckBeleg).then(res => {
            this.gepaeckBeleg = res;
            // console.log('onSubmitOrderData gepaeckBeleg', this.gepaeckBeleg);
            if (this.gepaeckBeleg.gpblg_id && this.gepaeckBeleg.gpblg_id > 0) {
              // this.gepaeckBelegService.notifyOrderToCustomer(this.gepaeckBeleg.gpblg_id, this.gepaeckBeleg.gpblg_extern_mobiletan);
              notify({
                message: 'Ihr Gepäckauftrag wurde gebucht. Sofern Sie Gast-Daten angelegt hatten, haben wir Ihre Gäste informiert. ' +
                  'Siehe Benachrichtigung per SMS und ggf. E-Mail.',
                position: {
                  my: 'center',
                  at: 'center'
                }
              }, 'info', 2000);
              this.router.navigate(['/gepaeckauftrag/show', this.gepaeckBeleg.gpblg_id, this.gepaeckBeleg.gpblg_extern_mobiletan]);
            }
          }).catch(() => {
            notify({
              message: 'Problem: ihr Gepäckauftrag wurde NICHT erfolgreich angelegt. ' +
                'Bitte ggf. korrigieren und nochmals versuchen.',
              position: {
                my: 'center',
                at: 'center'
              }
            }, 'error', 6000);
            this.isDisableEditing = false;
            this.isProcessing = false;
          });
          return;
        } else {
          notify({
            message: 'Erforderliche TAN konnte vom System nicht erfolgreich generiert werden.',
            position: {
              my: 'center',
              at: 'center'
            }
          }, 'error', 2000);
        }
      });
      return;
    }

    notify({
      message: 'Bitte die erforderlichen Daten vervollständigen und dann nochmals buchen.',
      position: {
        my: 'center',
        at: 'center'
      }
    }, 'error', 3000);

    this.isDisableEditing = false;
    this.isProcessing = false;
    return;
  }

  public async asyncValidationDatum(params: any): Promise<unknown> {
    if (params === undefined || !this.startDate) {
      return;
    }

    params.rule.message = 'Bitte das Mindestdatum ' + this.startDate.toLocaleString() + ' nicht unterschreiten. Auf Grund der unterschiedlichen Abläufe sind die möglichen Servicezeiten zwischen An- und Abreise verschieden.';
    return this.gepaeckBelegService.checkMindestDatum(params.value, this.startDate);
  }

  public getDatumLabelText(): string {
    if (!this.startDate || !this.startDate == null) {
      return 'Datum';
    }
    if (this.startDate.getHours() + this.startDate.getMinutes() == 0) {
      return `Datum (ab ${this.startDate.toLocaleDateString()})`;
    }
    return `Datum (ab ${this.startDate.toLocaleDateString()} ${this.startDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    })})`;
  }

  /**
   * leere oder korrekte Telefonnummer
   * @param e
   */
  public getIsValidPhoneNumber(e: any): boolean {
    if (e.value === null || e.value.trim() === '') {
      this.isValidPhoneNumber = true;
      return true;
    }
    return this.isValidPhoneNumber;
  }

  public isSubmitOrderDisabled(): boolean {
    return this.submitOrderDisabled.getValue();
  }

  public onGepaeckStueckTagCreatingDescriptions(args: any) {
    const newValue = args.text;
    if (!this.gepaeckDescriptions) {
      this.gepaeckDescriptions = this.gepaeckBelegService.getGepaeckDescriptions();
    }
    this.gepaeckDescriptions.unshift(newValue);
    args.customItem = newValue;
  }

  // inspired by https://supportcenter.devexpress.com/ticket/details/t971767/tagbox-how-to-create-a-new-item-on-tabbing-and-clicking-out-in-v20-2
  public onGepaeckStueckTagKeyDown(e: any) {
    if (e && e.component) {
      if (e.event.key === 'Tab') {
        e.event.key = 'Enter';
        e.event.keyCode = 13;
        e.event.which = 13;
      }
    }
  }

  public onGepaeckStueckTagInput(e: any) {
    this._gepaeckStueckValue = e.component.field().value;
  }

  public onGepaeckStueckTagFocusOut(e: any) {
    if (e && e.component) {
      if (this._gepaeckStueckValue) {
        //this.sendToHelpText = '';
        this._gepaeckStueckValue = this._gepaeckStueckValue.toString().replace('\'', '´').trim();
        e.component.option(
          'value',
          e.component.option('value')
            ? e.component.option('value').concat(this._gepaeckStueckValue)
            : [e.component.field().value]
        );
      }
    }
  }

  public getisDisableEditingFaehre(): boolean {
    return this.isDisableEditing || this.gepaeckBeleg.gpblg_dummy_abgang_fort_id === undefined;
  }

  public goBack(): void {
    this.location.back();
  }

  public printPreview(): void {
    if (!!this.gepaeckBeleg.gpblg_id && this.gepaeckBeleg.gpblg_id > 0
      && !!this.gepaeckBeleg.gpblg_extern_mobiletan && this.gepaeckBeleg.gpblg_extern_mobiletan?.valueOf() !== null) {
      this.router.navigate(['gepaeckauftrag/show',
        this.gepaeckBeleg.gpblg_id,
        this.gepaeckBeleg.gpblg_extern_mobiletan?.valueOf()]);
    } else {
      window.print();
    }
  }

  public async asyncValidationGpBlgAnz(params: any): Promise<unknown> {
    if (params === undefined) {
      return;
    }
    let mindestanzahl = 1;
    if (!!this.gepaeckBeleg.adr_id && this.gepaeckBeleg.adr_id > 0) {
      // mindestanzahl = this.gepaeckBeleg.adr_id === 16592 ? 4: 1;
      let firstEntryMatchIndex = -1;
      if (!this.gepaeckAdresseDddw || this.gepaeckAdresseDddw.length < 1) {
        mindestanzahl = 1;
      } else {
        firstEntryMatchIndex = this.gepaeckAdresseDddw
          .findIndex((e) =>
            e.adr_id === this.gepaeckBeleg.adr_id);

        if (firstEntryMatchIndex > -1 && this.gepaeckAdresseDddw[firstEntryMatchIndex] && this.gepaeckAdresseDddw[firstEntryMatchIndex]?.adr_id && this.gepaeckAdresseDddw[firstEntryMatchIndex]?.adr_gepaeck_minimum_anz) {
          mindestanzahl = this.gepaeckAdresseDddw[firstEntryMatchIndex].adr_gepaeck_minimum_anz;
        }
      }
    }
    params.rule.message = 'Bitte die Mindestanzahl von ' + mindestanzahl.toString() + ' Gepäckstücken nicht unterschreiten.';
    return this.gepaeckBelegService.checkMindestGepaeckAnzahl(params.value, mindestanzahl);
  }

  getTan(personalKey: string): Promise<ITan> {
    /*console.log('_getTan START', this.gepaeckBeleg.gpblg_extern_mobiletan,
      (this.gepaeckBeleg.gpblg_extern_mobiletan && this.gepaeckBeleg.gpblg_extern_mobiletan?.toString().trim().length === 6));*/
    if (this.gepaeckBeleg.gpblg_extern_mobiletan && this.gepaeckBeleg.gpblg_extern_mobiletan?.toString().trim().length === 6) {
      // console.log('_getTan schon da', this.gepaeckBeleg.gpblg_extern_mobiletan);
      return new Promise<ITan>((resolve) => {
        const tan: ITan = {
          tan: this.gepaeckBeleg.gpblg_extern_mobiletan,
          tpol_id: this.gepaeckBeleg.tpol_id
        };
        resolve(tan);
      });
    }
    return this.gepaeckBelegService.getNewTan(personalKey).then((resTan: ITan) => {
      // console.log('_getTan Neue TAN', resTan);
      return resTan;
    });
  }

  popoverToggleDefault() {
    this.popoverVisible = !this.popoverVisible;
  }

  public getSaveButtonLabelText(): string {
    // console.log('getSaveButtonLabelText', this.gepaeckBeleg, this.gepaeckBeleg.gpblg_id);
    if (this.gepaeckBeleg === undefined || this.gepaeckBeleg.gpblg_id === undefined || this.gepaeckBeleg.gpblg_id <= 0) {
      return 'Gepäckauftrag buchen';
    }
    return 'Gepäckauftrag ändern & Gast benachrichtigen';
  }

  getMaxCardWidth(): string {
    return this.maxCardWidth;
  }

  private switchTransportArtCode(transportArtCode: string): Promise<IStreckeSetting[]> {
    if (!environment.production) console.log('switchTransportArtCode to ', transportArtCode);
    return this.streckeSettingService.getSettingsByTransportType(transportArtCode).then((strRes) => {
      this.streckeSettings = strRes;
      if (!environment.production) console.log('switchTransportArtCode streckeSettings', this.streckeSettings);
      if (strRes != undefined && strRes.length > 0) {
        // den Rest macht reselectStreckeSettingAfterRichtungChange!!!

        // wenn wir bereits einen Abgangsort haben und der Abgangsort in einer der Strecken ist,
        // dann waehle diese Strecke, ansonsten nimm die erste Strecke / Richtung
        // Hinweis: bei bereits vorhandenen GEP an dieser Stelle nicht versehentlich die Richtung aendern!!!
        /*if (this.gepaeckBeleg.gpblg_dummy_abgang_fort_id && this.gepaeckBeleg.gpblg_dummy_abgang_fort_id > 0 &&
          this.gepaeckBeleg.gpblg_dummy_ziel_fort_id && this.gepaeckBeleg.gpblg_dummy_ziel_fort_id > 0) {
          if (!environment.production) console.log('switchTransportArtCode beide Orte vorhanden', this.gepaeckBeleg.gpblg_dummy_abgang_fort_id, this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
          this.streckeSettingIndex = this.streckeSettings.findIndex(e =>
            e.svstrs_fp_art_kz == transportArtCode
            && e.svstrs_ort1_fort_id == this.gepaeckBeleg.gpblg_dummy_abgang_fort_id
            && e.svstrs_ort2_fort_id == this.gepaeckBeleg.gpblg_dummy_ziel_fort_id);
          this.streckeSettingIndex = (this.streckeSettingIndex == null || this.streckeSettingIndex < 0) ? 0 : this.streckeSettingIndex;
          this.currentStreckeSetting = strRes[this.streckeSettingIndex];
        } else {*/
          if (!environment.production) console.log('switchTransportArtCode ELSE ...');
          this.streckeSettingIndex = 0;
          this.currentStreckeSetting = strRes[this.streckeSettingIndex];
          this.gepaeckBeleg.gpblg_dummy_ziel_fort_id = strRes[0].svstrs_ort2_fort_id;
          this.gepaeckBeleg.gpblg_dummy_abgang_fort_id = strRes[0].svstrs_ort1_fort_id;
        /*}*/

        // 1602 ausk
        /*if (!this.gepaeckBeleg.gpblg_dummy_fp_art_kz || this.gepaeckBeleg.gpblg_dummy_fp_art_kz !== strRes[0].svstrs_fp_art_kz) {
          this.gepaeckBeleg.gpblg_dummy_fp_art_kz = strRes[0].svstrs_fp_art_kz;
        }*/
        this.radioGroupItemsFrachtOrte = this.streckeSettingService.getStreckeRadioGroupItems(strRes);
      }
      return this.streckeSettings;
    });
  }

  private reselectStreckeSettingAfterRichtungChange(typeOfTransport: string, fromOrtId: number, toOrtId: number) {
    if (this.streckeSettings == undefined) {
      return;
    }
    this.streckeSettingIndex = this.streckeSettings.findIndex((e: IStreckeSetting) =>
      e.svstrs_fp_art_kz == typeOfTransport
      && e.svstrs_ort1_fort_id == fromOrtId
      && e.svstrs_ort2_fort_id == toOrtId);
    if (this.streckeSettingIndex >= 0) {
      this.currentStreckeSetting = this.streckeSettings[this.streckeSettingIndex];
    }
    this.startDate = this.streckeSettingService.getMinimalStartDateByPattern(this.currentStreckeSetting?.svstrs_gepaeckbuchungsfristregel_code);
  }
}
