import { Component } from '@angular/core';
import {DevExpressSitsService, MapSetting} from '../../shared/devexpress/dev-express-sits.service';
import {environment} from '../../../environments/environment';
import DevExpress from 'devextreme';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  preserveWhitespaces: true
})
export class MapComponent {
  public title: string;
  mapTypes: MapSetting[];
  initialMapType: any;
  initialMapZoom: number;

  constructor(devExpressSitsService: DevExpressSitsService) {
    this.title = 'Landkarte';
    this.mapTypes = devExpressSitsService.getMapTypes();
    this.initialMapType = localStorage.getItem('map_general_last_maptype').trim();
    if ( !this.initialMapType || this.initialMapType.trim() === '' ) {
      this.initialMapType = this.mapTypes[0].key;
    }
    this.initialMapZoom = Number(localStorage.getItem('map_general_last_mapzoom'));
    if ( !this.initialMapZoom || this.initialMapZoom < 1 || this.initialMapZoom > 29 ) {
      this.initialMapZoom = 14;
    }
  }

  public getInitialLocation(): string {
  return environment.islandLocationName;
  }

  mapTypeChanged(e: any) {
    localStorage.setItem('map_general_last_maptype', e.selectedItem.key);
  }

  mapClicked(e: any) {
   // console.log('MapComponent mapClicked ', e);
  }

  mapOptionChanged(e: any) {
    // console.log('MapComponent mapOptionChanged ', e);
    if (e.name && e.name === 'zoom') {
      if (e.value && Number(e.value) && e.value > 0 && e.value < 30 && e.value !== this.initialMapZoom ) {
        localStorage.setItem('map_general_last_mapzoom', e.value);
      }
    }
  }
}
