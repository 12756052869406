import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {

    public showMapStatusCallback = new Subject<any>();
    public hideMapStatusCallback = new Subject<any>();

    constructor(public readonly snackBar: MatSnackBar) { }

    public showStatusText(statusText: string, time: number = 2000) {
        this.snackBar.open(statusText, '', {duration: time});
    }

    public hideStatusText() {
        this.snackBar.dismiss();
    }
}
