export class EventLog {
  id?: number;
  evlo_id?: number;
  mand_id?: number;
  evlo_owner_table_code?: string;
  evlo_owner_id?: number;
  evlo_status_table_code?: string;
  evlo_status_nr?: number;
  evlo_status_text?: string;
  evlo_create_stamp?: Date;
  evlo_change_stamp?: Date;
  evlo_create_user?: string;
  evlo_change_user?: string;
  evlo_comment?: string;
  evlo_latest_yn?: number;
}
