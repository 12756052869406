import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {ServerMessage} from '../../api/api-dtos/server-message';

@Component({
  selector: 'app-passwordforgot',
  templateUrl: 'passwordforgot.component.html',
  styleUrls: ['passwordforgot.component.css']
})
export class PasswordforgotComponent implements OnInit {
  userName: string;
  loading:boolean = false;
  submitted:boolean = false;
  processed:boolean = false;
  returnUrl: string;
  messageText: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) {}

  ngOnInit():void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if (this.authenticationService.authenticated && (this.authenticationService.isEmployee || this.authenticationService.isCustomer)) {
      // go home
      this.router.navigate(['/']).then();
    }
  }

  onFormSubmit(e: any):void  {
    this.submitted = true;
    this.loading = true;
    let serviceResult: ServerMessage;
    this.authenticationService.passwordForgot( this.userName)
      .then( (result: ServerMessage):void => {
        serviceResult = result;
        this.loading = false;
        if (serviceResult.status === 'success') {
          this.setMessageText(serviceResult.message);
          this.processed = true;
        } else {
          this.setMessageText(serviceResult.message);
        }
      });
  }

  setMessageText(message: string) {
    this.messageText = message;
  }
}

