import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthorizationGuard} from './guards/authorization.guard';
import {CanDeactivateGuard} from './guards/can-deactivate-guard.service';
import {LogoutComponent} from './login/logout/logout.component';
import {SettingsComponent} from './configuration/settings/settings.component';
import {AdresseListComponent} from './pages/adresse-list/adresse-list.component';
import {LoginComponent} from './login';
import {HomeComponent} from './home/home.component';
import {AboutComponent} from './configuration/about/about.component';
import {CommonModule} from '@angular/common';
import {DatenschutzComponent} from './configuration/datenschutz/datenschutz.component';
import {FrachtbriefListComponent} from './pages/frachtbrief/frachtbrief-list/frachtbrief-list.component';
import {FahrplanListComponent} from './pages/fahrplan-list/fahrplan-list.component';
import {SchedulerTaskListComponent} from './pages/scheduler/scheduler-task-list/scheduler-task-list.component';
import {TarifListComponent} from './pages/tarif-list/tarif-list.component';
import {PasswordforgotComponent} from './login/passwordforgot/passwordforgot.component';
import {RenewforgottenpasswordComponent} from './login/renewforgottenpassword/renewforgottenpassword.component';
import {ChangepasswordComponent} from './login/changepassword/changepassword.component';
import {FrachtbriefEditComponent} from './pages/frachtbrief/frachtbrief-edit/frachtbrief-edit.component';
import {GepaeckAuftragEditComponent} from './pages/gepaeck/gepaeck-auftrag-edit/gepaeck-auftrag-edit.component';
import {MapComponent} from './pages/map/map.component';
import {InvoiceListComponent} from './pages/accounting/invoice-list/invoice-list.component';
import {GepaeckAuftragShowComponent} from './pages/gepaeck/gepaeck-auftrag-show/gepaeck-auftrag-show.component';
import {GepaeckAuftragCustEditComponent} from './pages/gepaeck/gepaeck-auftrag-cust-edit/gepaeck-auftrag-cust-edit.component';
import {GepaeckAuftragCustListComponent} from './pages/gepaeck/gepaeck-auftrag-cust-list/gepaeck-auftrag-cust-list.component';
import {RegistrationformComponent} from './login/registrationform/registrationform.component';
import {UserEditComponent} from './pages/admin/user-edit/user-edit.component';
import {StorageboxOrderListComponent} from './pages/storagebox/storagebox-order-list/storagebox-order-list.component';
import {StorageboxOrderCustEditComponent} from './pages/storagebox/storagebox-order-cust-edit/storagebox-order-cust-edit.component';
import {StorageboxOrderGuestEditComponent} from './pages/storagebox/storagebox-order-guest-edit/storagebox-order-guest-edit.component';
import {StorageboxOrderShowComponent} from './pages/storagebox/storagebox-order-show/storagebox-order-show.component';
import {StorageboxAvailabilityComponent} from './pages/storagebox/storagebox-availability/storagebox-availability.component';
import {FlugplanListComponent} from './pages/flugplan-list/flugplan-list.component';
import {
  GepaeckAuftragCustListAbholenComponent
} from './pages/gepaeck/gepaeck-auftrag-cust-list-abholen/gepaeck-auftrag-cust-list-abholen.component';
import {
  GepaeckAuftragCustListZustellenComponent
} from './pages/gepaeck/gepaeck-auftrag-cust-list-zustellen/gepaeck-auftrag-cust-list-zustellen.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home', redirectTo: '', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'changepassword', component: ChangepasswordComponent},
  {path: 'passwordforgot', component: PasswordforgotComponent},
  {path: 'registrationform', component: RegistrationformComponent},
  {path: 'registration', component: RegistrationformComponent},
  {path: 'register', component: RegistrationformComponent},
  {path: 'renewforgottenpassword', component: RenewforgottenpasswordComponent},
  {path: 'loginsuccess', redirectTo: '', pathMatch: 'full'},
  {path: 'settings', component: SettingsComponent, data: {breadcrumb: 'Optionen'}, canActivate: [AuthorizationGuard]},
  {path: 'about', component: AboutComponent, data: {breadcrumb: 'Info'}},
  {path: 'datenschutz', component: DatenschutzComponent, data: {breadcrumb: 'Datenschutz'}},
  {path: 'fahrplan', component: FahrplanListComponent, data: {breadcrumb: 'Fahrplan'}},
  {path: 'flugplan', component: FlugplanListComponent, data: {breadcrumb: 'Flugplan'}},
  {path: 'map', component: MapComponent, data: {breadcrumb: 'Landkarte'}},

  {
    path: 'frachtbrief/single/:id', component: FrachtbriefEditComponent,
    data: {breadcrumb: 'Frachtbrief'}, canActivate: [AuthorizationGuard]
  },
  {
    path: 'frachtbrief/list', component: FrachtbriefListComponent,
    data: {breadcrumb: 'Frachtbriefe'}, canActivate: [AuthorizationGuard]
  },

  {
    path: 'gepaeckauftrag-cust', children: [
      {
        path: 'list', children: [
          {path: 'fp_abfahrt_tag/:fp_abfahrt_fort_id/:fp_id', component: GepaeckAuftragCustListAbholenComponent},
          {path: 'abholen', component: GepaeckAuftragCustListAbholenComponent},
          {path: 'zustellen', component: GepaeckAuftragCustListZustellenComponent},
          {path: '**', component: GepaeckAuftragCustListComponent},
          {path: '', component: GepaeckAuftragCustListComponent},
        ]
      },
      {
        path: 'edit', children: [
          {path: 'id/:id', component: GepaeckAuftragCustEditComponent},
          {path: 'fahrplan/:fp_id', component: GepaeckAuftragCustEditComponent},
          {path: '', component: GepaeckAuftragCustEditComponent}
        ]
      }
    ]
  },
  {
    path: 'gepaeckauftrag', children: [
      {
        path: 'edit', children: [
          {path: ':fp_id', component: GepaeckAuftragEditComponent},
          {path: '', component: GepaeckAuftragEditComponent}
        ]
      },
      {
        path: 'show', children: [
          {path: ':id/:tan', component: GepaeckAuftragShowComponent},
          {path: ':id/000001', component: GepaeckAuftragShowComponent},
        ]
      }
    ]
  },
  {
    path: 'schedulertasks/list', component: SchedulerTaskListComponent,
    data: {breadcrumb: 'Aufgabenliste'}, canActivate: [AuthorizationGuard]
  },

  {
    path: 'storagebox', children: [
      {
        path: 'order', children: [
          {
            path: 'cust', children: [
              {
                path: 'list', children: [
                  {path: 'fahrplan/:fp_id', component: StorageboxOrderListComponent},
                  {path: '', component: StorageboxOrderListComponent}
                ]
              },
              {
                path: 'edit', children: [
                  {path: 'id/:id', component: StorageboxOrderCustEditComponent},
                  {path: '', component: StorageboxOrderCustEditComponent}
                ]
              }
            ]
          },
          {
            path: 'guest', children: [
              {
                path: 'edit', children: [
                  {path: '', component: StorageboxOrderGuestEditComponent},
                  {path: ':gervm_ger_nr/:gervm_beginn_datzeit/:gervm_ende_datzeit', component: StorageboxOrderGuestEditComponent},
                ]
              }
            ]
          },
          {
            path: 'show', children: [
              {path: ':id/:tan', component: StorageboxOrderShowComponent},
              {path: ':id/000001', component: StorageboxOrderShowComponent}
            ]
          }
        ]
      },
      {path: 'availability', component: StorageboxAvailabilityComponent}
    ]
  },

  {
    path: 'adresse/list', component: AdresseListComponent,
    data: {breadcrumb: 'Adressen'}, canActivate: [AuthorizationGuard]
  },

  {
    path: 'tarif', children: [
      {path: 'list', component: TarifListComponent}
    ]
  },

  {
    path: 'accounting/invoice/list', component: InvoiceListComponent,
    data: {breadcrumb: 'Belege'}, canActivate: [AuthorizationGuard]
  },

  {
    path: 'admin/user/edit', children: [
      {path: ':user_id', component: UserEditComponent, canActivate: [AuthorizationGuard]}
    ]
  },

  {path: 'logout', component: LogoutComponent, data: {breadcrumb: ''}, canActivate: [AuthorizationGuard]},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {})],
  // default: imports: [RouterModule.forRoot(routes)],
  // imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  // imports: [RouterModule.forRoot(routes, /*{ enableTracing: true }  <-- debugging purposes only */  )],
  // imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [AuthorizationGuard, CanDeactivateGuard],
  declarations: []
})
export class AppRoutingModule {
}
