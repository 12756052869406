import {Component, OnInit} from '@angular/core';
import {FrachtBrief} from '../../../api/api-dtos/fracht-brief';
import {AdresseDddw} from '../../../api/api-dtos/adresse-dddw';
import {FrachtBriefService} from '../../../api/api-services/fracht-brief.service';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-frachtbrief-single',
  templateUrl: './frachtbrief-single.component.html',
  styleUrls: ['./frachtbrief-single.component.scss']
})
export class FrachtbriefSingleComponent implements OnInit {

  adresseDddw: AdresseDddw[];
  frachtBrief: FrachtBrief;
  popupDeleteFrachtbriefVisible: boolean = false;
  popupAddFrachtbriefVisible: boolean = false;
  popupEditFrachtbriefVisible: boolean = false;
  frachtbriefDeleteAllowed: boolean = false;
  frachtbriefEditAllowed: boolean = false;
  frachtbriefViewAllowed: boolean = true;
  frachtbriefCopyAllowed: boolean = false;
  DummyFrachtbriefId: number = 0;
  gridPageSize: number = 15;

  constructor(
    private route: ActivatedRoute,
    private frachtbriefService: FrachtBriefService,
    private adresseService: AdresseService,
    private authenticationService: AuthenticationService,
  ) {
    if (!this.authenticationService.authenticated || !this.authenticationService.currentUser) {
      // this.router.navigate(['login']);
    }
  }

  ngOnInit() {
    this.loadBaseData().then();
    this.getFrachtbrief();
  }

  getFrachtbrief(): void {
    const id = + this.route.snapshot.paramMap.get('id');
    this.frachtbriefService.getFrachtbrief(id).then( res => {
      this.frachtBrief = res;
    });
  }

  async loadBaseData(): Promise<void> {
    if (this.adresseDddw === undefined) {
      this.adresseDddw = [];
    }
    if (this.adresseDddw.length === 0) {
      await this.adresseService.getAllDddw().then( res => {
        this.adresseDddw = res;
      });
    }
  }

  getTitleText(): string {
    return 'Frachtbrief bearbeiten';
  }

  getFrachtbriefId(): number {
    return this.frachtBrief.frako_id;
  }

}
