import {Injectable} from '@angular/core';
import {ApiAbout} from '../api-dtos/api-about';
import {ApiService} from '../api-basics/api.service';
import {map} from 'rxjs/operators';

@Injectable()
export class ApiInformationService {
  private aboutUrl = 'about';

  constructor(private apiService: ApiService) {
  }

  public getApiInformation() {
    return this.apiService
      .get(this.aboutUrl)
      .pipe(map((res: ApiAbout) => {
        return res;
      }));
  }

}
