import {
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FrachtBrief} from '../../../api/api-dtos/fracht-brief';
import {FrachtBriefService} from '../../../api/api-services/fracht-brief.service';
import {AdresseDddw} from '../../../api/api-dtos/adresse-dddw';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {FahrplanService} from '../../../api/api-services/fahrplan.service';
import {IFrachtErfassungsStatus} from '../../../api/api-dtos/fracht-erfassungs-status';
import {FrachtErfassungsStatusService} from '../../../api/api-services/fracht-erfassungs-status.service';
import {IParitaet} from '../../../api/api-dtos/paritaet-dto';
import {ParitaetService} from '../../../api/api-services/paritaet.service';
import {ITransportStatus} from '../../../api/api-dtos/transport-status';
import {TransportStatusService} from '../../../api/api-services/transport-status.service';
import {IArtikel, IFahrplan, FrachtPosition} from '../../../api/api-dtos/api-dtos.module';
import {ArtikelService} from '../../../api/api-services/artikel.service';
import {VerpackungService} from '../../../api/api-services/verpackung.service';
import {formatDate} from '@angular/common';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {Ladungstraeger} from '../../../api/api-dtos/ladungstraeger';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DxDataGridComponent, DxFormComponent, DxTooltipComponent} from 'devextreme-angular';
import {on} from 'devextreme/events';
import notify from 'devextreme/ui/notify';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-frachtbrief-edit',
  templateUrl: './frachtbrief-edit.component.html',
  styleUrls: ['./frachtbrief-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FrachtbriefEditComponent implements OnInit, OnChanges, OnDestroy {
  positionExpanded: boolean = true;
  positionTotalCount: number;
  positionDataIsValid: boolean = true;
  positionStartEditAction: any = 'click';
  positionSelectTextOnEditStart: boolean = true;

  dropDownOptions: object;
  dropDownOptionsVerpackung: object;

  @Input() inputFrachtbriefId: number;
  @Input() inputAdresseDddw: AdresseDddw[];
  @Output() public outputFrachtbrief = new EventEmitter<FrachtBrief>();
  @ViewChild(DxDataGridComponent, {static: false}) positionGrid: DxDataGridComponent;
  @ViewChild(DxFormComponent, {static: false}) frachtbriefForm: DxFormComponent;
  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;

  adresseDddw: AdresseDddw[] = [{}];
  artikels: IArtikel[];
  fahrplans$: Observable<IFahrplan[]> = new Observable<IFahrplan[]>();
  frachtPositions$: Observable<FrachtPosition[]> = new Observable<FrachtPosition[]>();
  frachtbrief: FrachtBrief = new FrachtBrief();
  frachtErfassungsStatuss: IFrachtErfassungsStatus[];
  ladungstraegers: Ladungstraeger[];
  paritaets: IParitaet[];
  transportStatuss: ITransportStatus[];
  removedFrachtPosition: FrachtPosition;
  public tooltipText$: Observable<string> = new Observable<string>(observer => {
    observer.next('Rita');
  });
  public frachtbriefEditAllowed = false;
  public frako_trst_status_nrEditAllowed: boolean;
  public frapo_tarifDataEditAllowed: boolean;
  public frapo_amountEditAllowed: boolean;
  public frapo_artikel_amountDataEditAllowed: boolean;
  public frako_ist_fp_idDisplayVisible: boolean;
  public tooltipText: string = null;
  currentPositionRowIndex: number;
  math: Math;
  TypeArtikelPosition = 1;
  TypeTextPosition = 10;
  abFrachtortId: number;
  radioGroupItems = [
    {text: 'nach Spiekeroog', frachtortId: 2},
    {text: 'nach N´siel', frachtortId: 1}
  ];
  yesNoItems = [
    {text: 'Ja', id: 1},
    {text: 'Nein', id: 0}
  ];
  public posPrevColumnIndex: number;
  public posNewColumnIndex: number;
  public isEmployee: boolean;
  public posEditRowKey: number;
  public posEditColumnIndex: number;
  public refreshed: boolean;
  public tooltipVisible = false;
  private posPrevRowIndex: number;
  private posNewRowIndex: number;
  private posEditColumnName: string;
  private unsubscribe = new Subject<void>();

  constructor(
    private authenticationService: AuthenticationService,
    private frachtbriefService: FrachtBriefService,
    private adresseService: AdresseService,
    private fahrplanService: FahrplanService,
    private frachtErfassungsStatusService: FrachtErfassungsStatusService,
    private artikelService: ArtikelService,
    private paritaetService: ParitaetService,
    private transportStatusService: TransportStatusService,
    private verpackungService: VerpackungService
  ) {
    this.frachtbriefEditAllowed = true;
    this.math = Math;

    this.getPositionData = this.getPositionData.bind(this);
    this.lookupFahrplans = this.lookupFahrplans.bind(this);
    this.getDisplayAbfahrtzeitPlan = this.getDisplayAbfahrtzeitPlan.bind(this);
    this.getDisplayAbfahrtzeitIst = this.getDisplayAbfahrtzeitIst.bind(this);
    this.form_fieldDataChanged = this.form_fieldDataChanged.bind(this);
    this.onFormValueChanged_frako_datum = this.onFormValueChanged_frako_datum.bind(this);
    this.onFormValueChanged_frako_abgang_fort_id = this.onFormValueChanged_frako_abgang_fort_id.bind(this);
    this.calcPositions = this.calcPositions.bind(this);
    this.calcFrachtTarifOfPosition = this.calcFrachtTarifOfPosition.bind(this);
    this.checkAndFixPositionData = this.checkAndFixPositionData.bind(this);
    this.refreshFrachtbriefData = this.refreshFrachtbriefData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validateAdressen = this.validateAdressen.bind(this);
    this.dropDownOptions = {width: 500};
    this.dropDownOptionsVerpackung = {width: 500};
  }

  static checkComparison() {
    return true;
  }

  static onPositionFocusedRowChanged(e: any) {
    if (e === undefined) {
      return;
    }
  }

  getDisplayAbfahrtzeit(item: any) {
    if ((item === undefined || !item) || !item?.fp_abfahrt_datzeit) {
      return '';
    }
    return formatDate(item.fp_abfahrt_datzeit, 'HH:mm', 'de-DE');
  }

  public getDisplayAbfahrtzeitPlan(item: any): string {
    if (item === undefined || !item) {
      return 'nicht zugewiesen';
    }
    if (item?.fp_abfahrt_datzeit !== null) {
      return this.fahrplanService.getDisplayAbfahrtzeitAssembled(item.fp_abfahrt_datzeit, item.ger_name);
    } else if (this.frachtbrief !== undefined && this.frachtbrief?.frako_fahrplan_plan !== null) {
      return this.fahrplanService.getDisplayAbfahrtzeitAssembled(this.frachtbrief?.frako_fahrplan_plan?.fp_abfahrt_datzeit,
        this.frachtbrief.frako_fahrplan_plan.ger_name);
    } else {
      return 'nicht zugewiesen';
    }
  }

  getDisplayAbfahrtzeitIst(item: any): string {
    if (item === undefined || !item) {
      return 'nicht zugewiesen';
    }
    if (item?.fp_abfahrt_datzeit !== null) {
      return this.fahrplanService.getDisplayAbfahrtzeitAssembled(item.fp_abfahrt_datzeit, item.ger_name);
    } else if (this.frachtbrief !== undefined && this.frachtbrief?.frako_fahrplan_ist !== null) {
      return this.fahrplanService.getDisplayAbfahrtzeitAssembled(this.frachtbrief?.frako_fahrplan_ist?.fp_abfahrt_datzeit,
        this.frachtbrief.frako_fahrplan_ist.ger_name);
    } else {
      return 'nicht zugewiesen';
    }
  }

  getPositionData() {
    return {
      store: this.frachtbrief.frachtPositions
    };
  }

  lookupFahrplans(frachtbrief: FrachtBrief): Observable<IFahrplan[]> {
    if (frachtbrief.frako_datum === undefined || frachtbrief.frako_datum === null) {
      return this.fahrplans$;
    }

    if (this.authenticationService.isEmployee) {
      this.fahrplans$ = this.fahrplanService.getFrachtAbfahrten(
        frachtbrief.frako_datum,
        frachtbrief.frako_abgang_fort_id, 1, 1)
        .pipe(
          takeUntil(this.unsubscribe)
        );
    } else {
      this.fahrplans$ = this.fahrplanService.getFrachtAbfahrten(
        frachtbrief.frako_datum,
        frachtbrief.frako_abgang_fort_id, 0, 0)
        .pipe(
          takeUntil(this.unsubscribe)
        );
    }

    this.fahrplans$.subscribe((resFahrplan) => {
      if (!resFahrplan || resFahrplan.length < 1) {
        if (this.frachtbriefEditAllowed) {
          if (this.authenticationService.isCustomer) {
            frachtbrief.frako_plan_fp_id = null;
          }
          if (this.authenticationService.isEmployee) {
            frachtbrief.frako_ist_fp_id = null;
          }
          return this.fahrplans$;
        }
      }
      let firstEntryMatchIndex = -1;
      let currentEntryMatchCount = 0;
      if (resFahrplan && resFahrplan.length > 0) {
        currentEntryMatchCount = resFahrplan
          .reduce((counter, obj) =>
            new Date(obj.fp_abfahrt_datzeit).setUTCHours(0, 0, 0, 0) === new Date(frachtbrief.frako_datum).setUTCHours(0, 0, 0, 0)
              ? counter += 1
              : counter, 0);
        if (currentEntryMatchCount > 0) {
          firstEntryMatchIndex = resFahrplan
            .findIndex((e) =>
              new Date(e.fp_abfahrt_datzeit).setUTCHours(0, 0, 0, 0) === new Date(frachtbrief.frako_datum).setUTCHours(0, 0, 0, 0));
        }
      }

      if (currentEntryMatchCount === 1 && firstEntryMatchIndex > -1 && resFahrplan[firstEntryMatchIndex]?.fp_id) {
        if (this.frachtbriefEditAllowed
          && (!frachtbrief.frako_plan_fp_id || frachtbrief.frako_plan_fp_id !== resFahrplan[firstEntryMatchIndex]?.fp_id)
        ) {
          frachtbrief.frako_plan_fp_id = resFahrplan[firstEntryMatchIndex].fp_id;
        }
      } else if (currentEntryMatchCount > 0) {
        // mal sehen
      } else if (currentEntryMatchCount === 0) {
        // für diesen Tag und diese Orte gibt es keine Fahrt, also ...
        if (this.frachtbriefEditAllowed) {
          if (this.authenticationService.isCustomer) {
            frachtbrief.frako_plan_fp_id = null;
          }
          if (this.authenticationService.isEmployee) {
            frachtbrief.frako_ist_fp_id = null;
          }
        }
      }
    });
    return this.fahrplans$;
  }

  onFormValueChanged_frako_abgang_fort_id(e: any) {
    if (!e.previousValue || !e.value || e.previousValue === e.value) {
      return;
    }
    if (this.frachtbrief?.frako_ziel_fort_id
      && this.frachtbrief?.frako_ziel_fort_id !== this.fahrplanService.getDefaultTargetDestination(e.value)) {
      this.frachtbrief.frako_ziel_fort_id = this.fahrplanService.getDefaultTargetDestination(e.value);
      this.frachtbrief.frako_plan_fp_id = null;
      this.frachtbrief.frako_ist_fp_id = null;
    }
    this.lookupFahrplans(this.frachtbrief).toPromise().then();
  }

  public onFormValueChanged_frako_datum(e: any) {
    if (!e.previousValue || e.previousValue === e.value) {
      return;
    }
    this.lookupFahrplans(this.frachtbrief).toPromise().then();
  }

  form_fieldDataChanged(e: any) {
    if (e === undefined) {
      return;
    }
    switch (e.dataField) {
      case 'frako_paritaet_code':
        switch (e.value) {
          case 'freihs':
            // Absender bezahlt
            this.frachtbrief.frako_rgempf_adr_id = this.frachtbrief.frako_absender_adr_id;
            break;
          case 'unfrei':
            // Empfänger bezahlt
            this.frachtbrief.frako_rgempf_adr_id = this.frachtbrief.frako_empf_adr_id;
            break;
        }
        break;
      case 'frako_empf_adr_id':
        switch (this.frachtbrief.frako_paritaet_code) {
          case 'unfrei':
            // Absender bezahlt
            this.frachtbrief.frako_rgempf_adr_id = e.value;
            break;
        }
        break;
      case 'frako_absender_adr_id':
        switch (this.frachtbrief.frako_paritaet_code) {
          case 'freihs':
            // Absender bezahlt
            this.frachtbrief.frako_rgempf_adr_id = e.value;
            break;
        }
        break;
      case 'frako_bemerk_text':
        if (!e.value || e.value.toString().trim() === '') {
          this.frachtbrief.frako_bemerk_text = ' ';
        }
        break;
      case 'frako_ist_fp_id':
      case 'frako_plan_fp_id':
        // ggf. Frachtdatum anpassen
        if (!!e.value) {
          let currentFpId: number = e.value;
          this.fahrplans$.subscribe((resFahrplan) => {
            if (!resFahrplan || resFahrplan.length < 1) {
              return;
            }
            let firstEntryMatchIndex: number;
            firstEntryMatchIndex = resFahrplan
              .findIndex((e) =>
                e.fp_id === currentFpId);

            if (firstEntryMatchIndex > -1 && resFahrplan[firstEntryMatchIndex]?.fp_id && resFahrplan[firstEntryMatchIndex]?.fp_abfahrt_datzeit) {
              let selectedAbfahrtDatum: Date = new Date(new Date(resFahrplan[firstEntryMatchIndex].fp_abfahrt_datzeit).setUTCHours(0, 0, 0, 0));
              if (this.frachtbriefEditAllowed
                && (!this.frachtbrief.frako_datum || this.frachtbrief.frako_datum !== selectedAbfahrtDatum)
              ) {
                // ggf. Frachtdatum anpassen, bei der Planfähre aber nur wenn die Istfähre noch NULL ist!
                this.frachtbrief.frako_datum = selectedAbfahrtDatum;
              }
            }
          });
        }
        break;
    }
  }

  async prepareEditing() {
    if (this.inputAdresseDddw && this.inputAdresseDddw.length > 0) {
      this.adresseDddw = this.inputAdresseDddw;
    } else {
      if (this.adresseDddw == null || this.adresseDddw.length === 0) {
        this.adresseDddw = await this.adresseService.getAllDddw().then();
      }
    }
    this.artikelService.getFrachtArtikelDddw().toPromise().then(data => {
      this.artikels = data;
    });
    this.frachtErfassungsStatuss = await this.frachtErfassungsStatusService.getAll();
    this.paritaets = await this.paritaetService.getAll();
    this.transportStatusService.getAll().subscribe(data => {
      this.transportStatuss = data;
    });
    this.ladungstraegers = await this.verpackungService.getAllLadungstraeger().then();
  }

  ngOnInit() {
    this.isEmployee = this.authenticationService.currentUser.isEmployee === 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!environment.production) console.log('ngOnChanges ', changes ?? 'undefined');
    if (changes.inputFrachtbriefId) {
      this.prepareEditing().then(() => {
        this.refreshFrachtbriefData();
        return;
      });
    } else if (changes.inputFrachtbriefId?.firstChange) {
      this.refreshFrachtbriefData();
      return;
    }
  }

  ngOnDestroy(): void {
    // Emit something to stop all Observables
    this.unsubscribe.next();
    // Complete the notifying Observable to remove it
    this.unsubscribe.complete();
  }

  public refreshFrachtbriefData(): void {
    let frachtbrief: FrachtBrief = this.frachtbriefService.getNewFrachtbrief();
    if (this.inputFrachtbriefId && this.inputFrachtbriefId > 0) {
      this.frachtbriefService.getFrachtbriefPositions(this.inputFrachtbriefId)
        .subscribe((res: FrachtBrief) => {
          frachtbrief = res;
          if (!frachtbrief.frako_abgang_fort_id) {
            this.abFrachtortId = null;
          } else if (frachtbrief.frako_abgang_fort_id === 1) {
            this.abFrachtortId = 1;
          } else {
            this.abFrachtortId = 2;
          }
          if (!frachtbrief.frako_ziel_fort_id) {
            frachtbrief.frako_ziel_fort_id = this.fahrplanService.getDefaultTargetDestination(this.abFrachtortId);
          }
          this.frachtbriefEditAllowed = this.frachtbriefService.isFrachtbriefEditAllowed(frachtbrief);
          this.postRefresh(frachtbrief);
          return;
        });

      // Todo:
      // bei Kunden: das Frachtdatum muss bei neuen Frachtbriefen in der Zukunft liegen ...
      // this.selectedFrachtbriefe[0].frako_datum > new Date()
    } else {
      // neuer Frachtbrief
      this.frachtbriefEditAllowed = true;
      if (!frachtbrief.frako_abgang_fort_id) {
        this.abFrachtortId = null;
      } else if (frachtbrief.frako_abgang_fort_id === 1) {
        this.abFrachtortId = 1;
      } else {
        this.abFrachtortId = 2;
      }
      this.postRefresh(frachtbrief);
      return;
    }
  }

  onPositionCellPrepared(e: any) {
    if (e === undefined || !e.data) {
      return;
    }
    let tooltipText: string = null;
    switch (e.column?.dataField) {
      case 'art_nr':
        e.cellElement.style.fontStyle = e.data.art_frachtberechnung_yn === 1 ? 'default' : 'italic';
        break;
      case 'frapo_fracht1_amount':
      case 'frapo_fracht2_amount':
      case 'frapo_fracht3_amount':
      case 'frapo_aufschlag_proz':
      case 'frapo_trab_proz':
      case 'frapo_stellflaeche_brutto_m2':
        e.cellElement.style.color = e.data.art_frachtberechnung_yn === 1 ? 'green' : 'grey';
        break;
      case 'frapo_artikel_amount':
        e.cellElement.style.color = e.data.art_frachtberechnung_yn === 1 ? 'grey' : 'default';
        break;
      case 'frapo_frachtberechnung_manuell_yn':
        tooltipText = 'Im manuellen Modus ist die automatische Tarifberechnung deaktiviert, um individuelle Werter zu buchen. Kann nur speditionsseitig geändert werden.';

        break;
    }
    if (!!tooltipText) {
      this.tooltipText = tooltipText;
      on(e.cellElement, 'mouseover', (arg: any) => {
        this.tooltip.instance.show(arg.target).then();
      });
      on(e.cellElement, 'mouseout', (arg: any) => {
        this.tooltip.instance.hide().then();
      });
    }
    return;
  }

  onPositionChange(e: any) {
    if (this.posPrevRowIndex >= -1) {
      switch (this.posEditColumnName) {
        case null:
          break;
        case 'frapo_fracht1_amount':
        case 'frapo_fracht2_amount':
        case 'frapo_fracht3_amount':
          this.frachtbrief.frachtPositions[this.posPrevRowIndex].frapo_frachtberechnung_manuell_yn = 1;
          break;
      }

      switch (this.posEditColumnName) {
        case 'art_nr':
        case 'frapo_menge':
        case 'frapo_fracht1_amount':
        case 'frapo_fracht2_amount':
        case 'frapo_fracht3_amount':
        case 'frapo_aufschlag_proz':
        case 'frapo_trab_proz':
        case 'frapo_artikel_amount':
        case 'frapo_frachtberechnung_manuell_yn':
          this.calcFrachtTarifOfPosition(this.posPrevRowIndex).then();
          break;
      }
    }
  }

  onPositionChangedArtSelection(selectedRowKeys: any, cellInfo: any, dropDownBoxComponent: any) {
    if (!selectedRowKeys || selectedRowKeys.length === 0) {
      return;
    }
    cellInfo.setValue(selectedRowKeys[0]);
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      dropDownBoxComponent.close();
      this.calcFrachtTarifOfPosition(this.posNewRowIndex).then();
    }
  }

  onPositionChangedVerpackungSelection(selectedRowKeys: any, cellInfo: any, dropDownBoxComponent: any) {
    if (!selectedRowKeys || selectedRowKeys.length === 0) {
      return;
    }
    cellInfo.setValue(selectedRowKeys[0]);
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      dropDownBoxComponent.close();
    }
  }

  onPositionEditingStart(e: any) {
    this.posEditRowKey = e.key;
    this.posEditColumnName = e.column.name;
    this.posEditColumnIndex = e.column.index;
  }

  onPositionFocusedCellChanged(e: any) {
  }

  onPositionFocusedCellChanging(e: any) {
    this.posPrevRowIndex = e.prevRowIndex;
    this.posNewRowIndex = e.newRowIndex;
    this.posPrevColumnIndex = e.prevColumnIndex;
    this.posNewColumnIndex = e.newColumnIndex;
    return;
  }

  onPositionInitNewRow(e: any) {
    const positionData: FrachtPosition = new FrachtPosition();
    e.data = this.checkAndFixPositionData(positionData);
    this.frachtbriefForm.instance.focus();
    this.positionGrid.instance.focus();
  }

  onPositionRemoving(e: any) {
    this.removedFrachtPosition = e.data as FrachtPosition;
    if (this.removedFrachtPosition && this.removedFrachtPosition.frako_id > 0 && this.removedFrachtPosition.frapo_id > 0) {
      this.frachtbriefService.deletePosition(this.removedFrachtPosition.frapo_id);
    }
  }

  onPositionRowInserted(e: any) {
    if (e === undefined || !e) {
      return;
    }
    e.data = this.checkAndFixPositionData(e.data.promise);
  }

  onPositionRowValidating(e: any) {
    if (e === undefined || !!e.oldData || !e.newData || this.posPrevRowIndex === undefined || this.posPrevRowIndex === null || this.posPrevRowIndex < 0) {
      return;
    }
    const positionData: FrachtPosition = e.oldData;
    if (e.newData.art_nr) {
      positionData.art_nr = e.newData.art_nr;
    }
    if (e.newData.frapo_menge) {
      positionData.frapo_menge = e.newData.frapo_menge;
    }
    if (e.newData.frapo_sort_nr) {
      positionData.frapo_sort_nr = e.newData.frapo_sort_nr;
    }
  }

  onPositionToolbarPreparing(e: any) {
    if (e === undefined) {
      return;
    }
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'Tarifermittlung'
    }, {
      location: 'before',
      widget: 'dxButton',
      options: {
        disabled: !this.frachtbriefEditAllowed,
        icon: 'formula',
        onClick: this.calcPositions.bind(this)
      }
    });
  }

  /** Frachtermittlung für eine Position
   *
   * @param posIndex
   */
  async calcFrachtTarifOfPosition(posIndex: number): Promise<void> {
    if ((!this.frachtbriefEditAllowed) || (!this.frachtbrief)
      || (!this.frachtbrief.frachtPositions)
      || posIndex < -1 || posIndex + 1 > this.frachtbrief?.frachtPositions?.length
      || (!this.frachtbrief.frachtPositions[posIndex]) || (!this.frachtbriefService)
    ) {
      return;
    }

    if (this.frachtbrief.frachtPositions[posIndex].art_nr !== undefined && this.frachtbrief.frachtPositions[posIndex].art_nr !== 0) {
      let _frachtPosition: FrachtPosition;
      _frachtPosition = this.frachtbrief.frachtPositions[posIndex];
      await this.frachtbriefService.getCalcedPosition(this.frachtbrief,
        this.frachtbrief.frachtPositions[posIndex]).then( resFrapo => {
        if (resFrapo && resFrapo !== this.frachtbrief.frachtPositions[posIndex]) {
          this.frachtbrief.frachtPositions[posIndex] = resFrapo;
        }
        return this.frachtbrief.frachtPositions[posIndex];
      });
    } else {
      this.frachtbrief.frachtPositions[posIndex].frapo_fracht1_amount = 0;
      this.frachtbrief.frachtPositions[posIndex].frapo_fracht2_amount = 0;
      this.frachtbrief.frachtPositions[posIndex].frapo_fracht3_amount = 0;
      this.frachtbrief.frachtPositions[posIndex].frapo_tarif_amount = 0;
      return;
    }
  }

  async calcPositions() {
    if (!this.frachtbriefEditAllowed) {
      return;
    }

    for (let _i = 0; _i < this.frachtbrief.frachtPositions.length; _i++) {
      await this.calcFrachtTarifOfPosition(_i).then(() => {
        if (_i === this.frachtbrief.frachtPositions.length - 1) {return Promise.resolve();}
      });
    }
  }

  // not yet used
  calcPositionStellflaeche(posIndex: number): void {
    if (!this.frachtbriefEditAllowed || posIndex < 0 || posIndex > this.frachtbrief.frachtPositions.length) {
      return;
    }

    if (!this.frachtbrief.frachtPositions[posIndex]) {
      return;
    }

    if (this.frachtbrief.frachtPositions[posIndex].frapo_verpack_code?.toString().length > 0
      && this.frachtbrief.frachtPositions[posIndex].frapo_anz?.valueOf() !== 0) {
      this.frachtbrief.frachtPositions[posIndex].frapo_stellflaeche_brutto_m2 =
        (this.frachtbrief.frachtPositions[posIndex].frapo_anz.valueOf() * 1.7);
    }
    return;
  }

  /**
   * Vorbelegung von Spalten auf Basis des gewählten Artikels, insbesondere aktivieren / deaktivieren der Frachtberechnung.
   * @param newData
   * @param value
   * @param currentRowData
   */
  setPositionArtNr(newData: any, value: any, currentRowData: any): void {
    const currentColumn = (<any>this);
    if (!currentColumn) {
      return;
    }
    currentColumn.defaultSetCellValue(newData, value);
    if (newData?.art_nr && newData.art_nr > 0) {
      const currentArtikelIndex = currentColumn.lookup.dataSource.findIndex((e: any) => e.art_nr === value);
      if (currentArtikelIndex >= 0) {
        newData.art_frachtberechnung_yn = currentColumn.lookup.dataSource[currentArtikelIndex].art_frachtberechnung_yn;
        newData.frapo_fracht1_amount = 0;
        if (!currentRowData.frapo_fracht1_amount) {
          currentRowData.frapo_fracht1_amount = 0;
        }
        newData.frapo_fracht2_amount = 0;
        if (!currentRowData.frapo_fracht2_amount) {
          currentRowData.frapo_fracht2_amount = 0;
        }
        newData.frapo_fracht3_amount = 0;
        if (!currentRowData.frapo_fracht3_amount) {
          currentRowData.frapo_fracht3_amount = 0;
        }
        newData.frapo_tarif_amount = 0;
        if (!currentRowData.frapo_tarif_amount) {
          currentRowData.frapo_tarif_amount = 0;
        }
        newData.frapo_artikel_amount = 0;
        if (!currentRowData.frapo_artikel_amount) {
          currentRowData.frapo_artikel_amount = 0;
        }
        return;
      }
    }
  }

  public setCellValue(newData: any, value: any, currentRowData: any) {
    if (!environment.production) console.log('setCellValue newData, value, currentRowData', newData, value, currentRowData);
    // currentRowData contains the row data before the edit. Value contains the edited value
    const currentColumn = (<any>this);
    if (!currentColumn) {
      return;
    }
    currentColumn.defaultSetCellValue(newData, value);
    // weitere interaktive Änderungen an anderen Zellen nicht hier?
    // sondern in onPositionChange?
    return;
  }

  public checkAndFixPositionData(positionData: FrachtPosition): FrachtPosition {
    if (!positionData) {
      return positionData;
    }
    if (!positionData.frapo_sort_nr) {
      positionData.frapo_sort_nr
        = this.math.max(...this.frachtbrief.frachtPositions.map(o => o.frapo_sort_nr), 0) + 10;
    }
    if (this.frachtbriefEditAllowed) {
      if (!positionData.frapo_typ_nr) {
        positionData.frapo_typ_nr = this.TypeArtikelPosition;
      }
      if (!positionData.frapo_frachtberechnung_manuell_yn) {
        positionData.frapo_frachtberechnung_manuell_yn = 0;
      }
      if (!positionData.art_frachtberechnung_yn) {
        positionData.art_frachtberechnung_yn = 0;
      }
      if (positionData.frapo_typ_nr === this.TypeArtikelPosition) {
        if (!positionData.frapo_menge) {
          positionData.frapo_menge = 0;
        }
        if (!positionData.art_nr) {
          positionData.art_nr = null;
        }
      }
      if (!positionData.frapo_verpack_code) {
        positionData.frapo_verpack_code = null;
      }
      if (!positionData.frapo_anz) {
        positionData.frapo_anz = null;
      }
    }
    return positionData;
  }

  postRefresh(frachtbrief: FrachtBrief) {
    this.fahrplans$ = this.lookupFahrplans(frachtbrief)
      .pipe(
        takeUntil(this.unsubscribe)
      );
    this.fahrplans$.subscribe(() => {
      this.frako_trst_status_nrEditAllowed = this.frachtbriefService.isFrako_trst_status_nrEditAllowed(frachtbrief);
      this.frako_ist_fp_idDisplayVisible = this.frachtbriefService.isFrako_ist_fp_idDisplayVisible(frachtbrief);
      this.frapo_tarifDataEditAllowed = this.frachtbriefService.isFrapo_tarifDataEditAllowed(frachtbrief);
      this.frapo_amountEditAllowed = this.frapo_tarifDataEditAllowed;

      if (frachtbrief.frachtPositions.length === 0) {
        frachtbrief.frachtPositions = [{
          frapo_sort_nr: 10, frako_id: this.inputFrachtbriefId, frapo_id: null,
          art_nr: null, frapo_menge: null
        }];
      }
      this.frachtbrief = frachtbrief;
      this.refreshed = true;
      return this.frachtbrief;
    });
  }

  public validateAdressen(params: any) {
    return true;
    // todo cross adressen validation
    /*if (this.authenticationService == undefined || this.authenticationService.isEmployee) {
        return true;
    }
    if (this.authenticationService.isCustomer
        && !!this.frachtbrief.frako_absender_adr_id && !!this.frachtbrief.frako_rgempf_adr_id && !!this.frachtbrief.frako_empf_adr_id
        && this.frachtbrief.frako_absender_adr_id != this.authenticationService.customerId
        && this.frachtbrief.frako_rgempf_adr_id != this.authenticationService.customerId
        && this.frachtbrief.frako_empf_adr_id != this.authenticationService.customerId
    ) {
        return false;
    }
    return true;*/
  }

  async onSubmit(e: any) {
    let readyToLeave: boolean;
    if (!this.frachtbriefEditAllowed) {
      this.refreshed = false;
      this.inputFrachtbriefId = null;
      this.outputFrachtbrief.emit(null);
      return;
    }
    readyToLeave = !this.positionGrid.instance.hasEditData();
    if (readyToLeave || true) {
      await this.calcPositions().then();
      const result: any = this.frachtbriefForm.instance.validate();
      if (result !== undefined && result?.isValid) {
        if (this.frachtbrief.frako_id) {
          this.frachtbriefService.update(this.frachtbrief.frako_id, this.frachtbrief).then(() => {
            this.refreshed = false;
            this.inputFrachtbriefId = null;
            this.outputFrachtbrief.emit(this.frachtbrief);
          }).catch(() => {
            notify({
              message: 'Problem: Der Beleg konnte NICHT aktualisiert werden. ' +
                'Bitte Daten prüfen / korrigieren und nochmals versuchen. ',
              position: {
                my: 'center',
                at: 'center'
              }
            }, 'error', 6000);
            return;
          });
        } else {
          this.frachtbriefService.create(this.frachtbrief).then(res => {
            this.frachtbrief = res;
            this.refreshed = false;
            this.inputFrachtbriefId = null;
            this.outputFrachtbrief.emit(res);
            return;
          }).catch(() => {
            notify({
              message: 'Problem: Der Beleg konnte NICHT gespeichert werden. ' +
                'Bitte Daten prüfen / korrigieren und nochmals versuchen. ',
              position: {
                my: 'center',
                at: 'center'
              }
            }, 'error', 6000);
            return;
          });
        }
      } else {
        notify({
          message: 'Bitte erst erforderliche Daten vervollständigen.',
          position: {
            my: 'center',
            at: 'center'
          }
        }, 'error', 2500);
        return;
      }
    }
    return;
  }

}
