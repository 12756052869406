import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DxFormComponent} from 'devextreme-angular';
import {AdresseDddw} from '../../../api/api-dtos/adresse-dddw';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {TextblockService} from '../../../api/api-services/textblock.service';
import {Location} from '@angular/common';
import {StorageBoxService} from '../../../api/api-services/storage-box.service';
import {GeraeteVermietungStorageBox} from '../../../api/api-dtos/geraete-vermietung-storage-box';
import {AccountingService} from '../../../api/api-services/accounting.service';
import {ZahlKontoArt} from '../../../api/api-dtos/zahl-konto-art-dto';
import {environment} from '../../../../environments/environment';
import {GepaeckBeleg} from '../../../api/api-dtos/gepaeck-beleg';

@Component({
  selector: 'app-storagebox-order-show',
  templateUrl: './storagebox-order-show.component.html',
  styleUrls: ['./storagebox-order-show.component.scss']
})

export class StorageboxOrderShowComponent implements OnInit {
  @ViewChild(DxFormComponent, {static: false}) storageBoxForm: DxFormComponent;
  @Input() inputAdresseDddw: AdresseDddw[];
  inputStorageBoxOrderTAN: number | undefined;
  inputStorageBoxOrderID: number | undefined;

  title = 'Lagerbox-Auftrag ';
  private _componentTitle: string = 'Lagerbox-Auftrag ';
  adresseDddwGepaeck: AdresseDddw[] = [{}];
  adresseDddwAll: AdresseDddw[] = [{}];
  public paymentTypeDddw: ZahlKontoArt[] = [{}];
  colCountByScreen: Object;
  public storageBoxOrder: GeraeteVermietungStorageBox = new GeraeteVermietungStorageBox();
  public payPalMeUrl: string;
  public bankVerbindungStorageBox: string;
  public isDisableEditing: boolean = true;
  public printingMode: boolean =  false;

  set componentTitle(value: string) {
    this._componentTitle = value;
  }

  buttonCancel: any= {
    text: '< zurück',
    type: 'normal',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    onClick: function () {
      this.cancel();
      return;
    },
  };

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private adresseService: AdresseService,
    private storageBoxService: StorageBoxService,
    private textblockService: TextblockService,
    private accountingService: AccountingService,
    private readonly location: Location
  ) {
    route.params.subscribe(params => {
      if (!environment.production) console.log('paramiso', params);
      this.inputStorageBoxOrderID = params['id'];
      this.inputStorageBoxOrderTAN = params['tan'];
    });
    this.getTransportBedingungenStatus = this.getTransportBedingungenStatus.bind(this);
    this.getBezahltStatus = this.getBezahltStatus.bind(this);
    this.getZahlArtName = this.getZahlArtName.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.textblockService.getTextBlock('GERVM-LX-PAYPAL-ME-URL')
      .subscribe( resText => {
        this.payPalMeUrl = resText.txblk_plaintext;
      });
    this.textblockService.getTextBlock('GERVM-LX-BANKKONTO')
      .subscribe( resText => this.bankVerbindungStorageBox = resText.txblk_plaintext);
    this.accountingService.getZahlArtDddw().then(res => {
      this.paymentTypeDddw = res;
    });


  }

  ngAfterViewInit(): void {
    /*throw new Error('Method not implemented.');*/
    // console.log( 'StorageboxOrderShowComponent ngAfterViewInit not implemented.');
    return;
  }

  async ngOnInit() {
    this.adresseService.getGepaeckAdrDddw().then(resAdrPublic => {
      this.adresseDddwGepaeck = resAdrPublic;
      return;
    }).then();
    if (this.authenticationService.isAuthenticated) {
      this.adresseService.getAllDddw().then(resAdr => {
        this.adresseDddwAll = resAdr;
        return;
      }).then();
    }

    // Lade StorageBoxOrder anhand uebergebener TAN oder ID
    if (this.inputStorageBoxOrderTAN && this.inputStorageBoxOrderTAN > 0) {
    }
    if (this.inputStorageBoxOrderID && this.inputStorageBoxOrderID > 0) {
    }
    this.storageBoxService.getStorageBoxOrder(this.inputStorageBoxOrderID, this.inputStorageBoxOrderTAN).subscribe( (resGerVm: GeraeteVermietungStorageBox)  => {
      if (resGerVm !== undefined && !!resGerVm) {
          this.storageBoxOrder = resGerVm;
      } else {
        this.goBack()
      }
    });
  }

  public getTransportBedingungenStatus(): boolean {
    return this.storageBoxOrder?.gervm_extern_transpbed_bestaetigt_yn?.valueOf() === 1;
  }

  public getBezahltStatus(): boolean {
    // bezahlt: 1 = Ja, 2 = auf Rechnung
    return (this.storageBoxOrder?.gervm_bezahlt_kz?.toString() === '1' || this.storageBoxOrder?.gervm_bezahlt_kz?.toString() === '2');
  }

  public getZahlArtName(): string {
    // bezahlt: 1 = Ja, 2 = auf Rechnung
    // getZahlArtDddw
    if (this.storageBoxOrder?.gervm_zktoart_id === undefined) {
      return 'Zahlart fehlt.';
    }
    let firstEntryMatchIndex = -2;
    firstEntryMatchIndex = this.paymentTypeDddw.findIndex((e: ZahlKontoArt) => this.storageBoxOrder?.gervm_zktoart_id == e.zktoart_id);
    if (firstEntryMatchIndex >= 0) {
      return this.paymentTypeDddw[firstEntryMatchIndex].zktoart_name;
    }

    return this.storageBoxOrder?.gervm_zktoart_id === undefined
      ? 'Zahlart fehlt.'
      : this.storageBoxOrder?.gervm_zktoart_id?.toString();

    /*firstEntryMatchIndex = this.storageBoxDddw.findIndex((e: Geraet) => ger_nr == e.ger_nr);
    if (firstEntryMatchIndex >= 0) {
      return this.storageBoxDddw[firstEntryMatchIndex].ger_name;
    }*/

  }

  public getGeraeteVermietungIdPrefixed(): string {
    let geraeteVermietungIdPrefixed = 'id-fehlt';
    if (this.storageBoxOrder?.gervm_id) {
      geraeteVermietungIdPrefixed = 'VM-' + this.storageBoxOrder.gervm_id.toString();
    }
    return geraeteVermietungIdPrefixed;
  }

  public goBack(): void {
    this.location.back();
  }

  public get componentTitle(): string {
    return this._componentTitle;
  }

  public isAuthenticated(): boolean {
    return this.authenticationService
      ? (this.authenticationService.isAuthenticated
        && (this.authenticationService.currentUser.isEmployee === 1
          || this.authenticationService.currentUser.isCustomer === 1))
      : false;
  }

}
