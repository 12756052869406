import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {IFahrplan} from '../../../api/api-dtos/fahrplan';
import {AdresseDddw} from '../../../api/api-dtos/adresse-dddw';
import {IFrachtOrt} from '../../../api/api-dtos/fracht-ort';
import {Observable} from 'rxjs';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {FahrplanService} from '../../../api/api-services/fahrplan.service';
import {FrachtOrtService} from '../../../api/api-services/fracht-ort.service';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {GepaeckBeleg} from '../../../api/api-dtos/gepaeck-beleg';
import {GepaeckBelegService} from '../../../api/api-services/gepaeck-beleg.service';
import {formatDate, Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {DxDataGridComponent, DxTooltipComponent} from 'devextreme-angular';
import {paymentTypesEnum} from '../../../api/api-services/paymenttypes';
import {HostService} from 'src/app/shared-services/host.service';
import {TextblockService} from '../../../api/api-services/textblock.service';
import {environment} from '../../../../environments/environment';
import {StreckeSettingService} from '../../../api/api-services/strecke-setting.service';
import {ITransportArt} from 'src/app/api/api-dtos/transport-art';
import {IStreckeSetting} from '../../../api/api-dtos/strecke-setting';
import {GepaeckProcessStatus} from '../../../api/api-services/gepaeck-process-status';
import {timer} from 'rxjs/internal/observable/timer';
import {map, repeat, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {FahrplanFilter} from '../../../api/api-dtos/fahrplan-filter';
import {ClosingSize} from '../../../shared-services/closing-size';
import dxDataGrid from 'devextreme/ui/data_grid';
import {on} from 'devextreme/events';

@Component({
  selector: 'app-gepaeck-auftrag-cust-list',
  templateUrl: './gepaeck-auftrag-cust-list.component.html',
  styleUrls: ['./gepaeck-auftrag-cust-list.component.scss']
})
export class GepaeckAuftragCustListComponent implements OnInit, OnChanges, OnDestroy {

  protected today = new Date(new Date().toDateString());
  @ViewChild(DxDataGridComponent, {static: false}) grid: DxDataGridComponent;
  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;
  @Input() public inputProcessingMode: GepaeckProcessStatus | undefined;
  @Input() filterByOrtId: number;
  @Input() filterByFahrplanId: number;
  @Input() filterByDate: Date = this.today;

  private _componentTitle: string = 'Gepäckaufträge';
  protected frontendBaseUrl: string;

  public readonly environment = environment;
  public paymentTypes = paymentTypesEnum;
  public isMobileResolution = false;
  protected gepaeckBelege: GepaeckBeleg[];
  protected sortedGepaeckBelege: GepaeckBeleg[] | null;
  protected selectedGepaeckBelege: GepaeckBeleg[] = [];
  protected selectedGepaeckBelegeIds: number[] = [];
  public currentGepaeckBeleg: GepaeckBeleg = null;
  public gepaeckProcessStatus: GepaeckProcessStatus | null = null;

  protected adresseDddw: AdresseDddw[];
  protected gepaeckAdresseDddw: AdresseDddw[];

  protected fahrplans$: Observable<IFahrplan[]>;
  protected fahrplans: IFahrplan[];
  protected frachtOrts: IFrachtOrt[];
  protected gepaeckTransportArts: ITransportArt[];
  protected transportArtCode: string;
  protected streckeSettings: IStreckeSetting[];

  protected gridPageSize = 15;
  protected popupDeleteGepaeckBelegVisible = false;
  protected popoverVisible = false;
  protected gepaeckBelegCopyAllowed = false;
  protected gepaeckBelegEditAllowed = false;
  protected gepaeckBelegDeleteAllowed = false;

  protected stopTimerSubject = new Subject<void>();
  protected restartTimerSubject = new Subject<void>();
  protected unsubscribeAllSubject = new Subject<void>();

  protected counter$ = timer(60000, 120000).pipe(
    takeUntil(this.stopTimerSubject),
    repeat({delay: () => this.restartTimerSubject}),
    takeUntil(this.unsubscribeAllSubject)
  );
  public imprintText: string;
  public containerNo: string;
  protected filterByExternalOrderId: string;

  set componentTitle(value: string) {
    this._componentTitle = value;
  }

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public gepaeckBelegService: GepaeckBelegService,
    public adresseService: AdresseService,
    public fahrplanService: FahrplanService,
    public streckeSettingService: StreckeSettingService,
    public frachtOrtService: FrachtOrtService,
    public authenticationService: AuthenticationService,
    public textblockService: TextblockService,
    public readonly location: Location,
    public hostService: HostService,
  ) {
    this.deleteSelectedItems = this.deleteSelectedItems.bind(this);
    this.getAdresses = this.getAdresses.bind(this);
    this.getFrachtOrt = this.getFrachtOrt.bind(this);
    this.getGepaeckAdresses = this.getGepaeckAdresses.bind(this);
    this.getIsEmployee = this.getIsEmployee.bind(this);
    this.signAsPayedSelectedItems = this.signAsPayedSelectedItems.bind(this);
    this.selectionChangedHandler = this.selectionChangedHandler.bind(this);
    this.getFrontendBaseUrl = this.getFrontendBaseUrl.bind(this);
    this.onValueChangedFilterByDate = this.onValueChangedFilterByDate.bind(this);
    this.onValueChangedFilterByOrtId = this.onValueChangedFilterByOrtId.bind(this);
    this.getGepaeckBelegDeleteSelectedAllowed = this.getGepaeckBelegDeleteSelectedAllowed.bind(this);
    this.getGepaeckBelegDeleteAllowed = this.getGepaeckBelegDeleteAllowed.bind(this);
    if (!this.authenticationService.authenticated || !this.authenticationService.currentUser) {
      // this.router.navigate(['login']);
    }

    this.frontendBaseUrl = this.hostService.getFrontendBaseUrl();

    this.textblockService.getTextBlock('TXT-IMPRINT')
      .subscribe(resText => {
        this.imprintText = resText.txblk_plaintext
          .split('<p>').join('')
          .split('</p>').join('')
          .split('<br>').join('%0D%0A');
      });

    this.streckeSettingService.getTransportArtListByService('gepaeck')
      .subscribe(resTransport => {
        // FLUG, FAEHRE, ...
        this.gepaeckTransportArts = resTransport;
        if (resTransport !== undefined && resTransport.length > 0) {
          this.transportArtCode = resTransport[0].transportArtCode;
        }
        return;
      });

    this.frachtOrts = [];
    this.frachtOrtService.getAll().subscribe(data => {
      this.frachtOrts = data;
      return;
    });
    route.queryParams.subscribe(params => {
      this.inputProcessingMode = Number(!!params['processingMode'] ? params['processingMode'] : 0);
    });

    if(this.authenticationService.isCustomer) {
      this.filterByDate = null;
    }

    route.params.subscribe(params => {
      if (!environment.production) console.log('paramiso', params);
      this.filterByFahrplanId = Number(!!params['fp_id'] ? params['fp_id'] : 0);
      this.filterByOrtId = Number(!!params['fp_abfahrt_fort_id'] ? params['fp_abfahrt_fort_id'] : 0);
      this.filterByDate = !!params['fp_abfahrt_tag'] ? params['fp_abfahrt_tag'] : !!params['fp_ankunft_tag'] ? !!params['fp_ankunft_tag'] : this.filterByDate;
      // this.inputProcessingMode = Number(!!params['processingMode'] ? params['processingMode'] : 0);

      switch (this.inputProcessingMode ?? 0) {
        case GepaeckProcessStatus.abholen :
          this.componentTitle = 'Gepäck Abholen';
          this.streckeSettingService.getSettingsFiltered('all', 'departure').subscribe(resStreckeSettings => {
            this.streckeSettings = resStreckeSettings;
            if (this.streckeSettings.length > 0) {
              this.filterByOrtId = this.filterByOrtId > 0 ? this.filterByOrtId : this.streckeSettings[0].svstrs_ort1_fort_id;
              this.onValueChangedFilterByDate({value: this.filterByDate});
              this.refreshGepaeckAuftragList().then();
            }
          });
          this.containerNo = !!localStorage.getItem('gepaeck-abholen.containerNo') ? localStorage.getItem('gepaeck-abholen.containerNo')?.trim()?.substring(0, 26) : this.containerNo;
          break;
        case GepaeckProcessStatus.zustellen :
          this.componentTitle = 'Gepäck Zustellen';
          this.streckeSettingService.getSettingsFiltered('all', 'arrival').subscribe(resStreckeSettings => {
            this.streckeSettings = resStreckeSettings;
            if (this.streckeSettings.length > 0) {
              this.onValueChangedFilterByDate({value: this.filterByDate});
              this.filterByOrtId = this.filterByOrtId ?? this.streckeSettings[0].svstrs_ort1_fort_id;
              this.refreshGepaeckAuftragList().then();
              if (!environment.production) console.log('conni', this.today, this.gepaeckTransportArts, this.streckeSettings);
            }
          });
          break;
        default:
          this.componentTitle = 'Gepäckaufträge';
          if (this.filterByDate !== null && this.filterByFahrplanId > 0 && this.filterByOrtId !== null) {
            this.streckeSettingService.getSettingsFiltered('all', 'all').subscribe(resStreckeSettings => {
              this.streckeSettings = resStreckeSettings;
              if (this.streckeSettings.length > 0) {
                this.filterByOrtId = this.filterByOrtId > 0 ? this.filterByOrtId : this.streckeSettings[0].svstrs_ort1_fort_id;
                this.onValueChangedFilterByDate({value: this.filterByDate});
                // this.onValueChangedFilterByAbfahrtOrt({value: this.filterByAbfahrtOrtId})
                // this.refreshGepaeckAuftragList().then();
                if (!environment.production) console.log('conniGEP', this.today, this.gepaeckTransportArts, this.streckeSettings, this.frachtOrts);
              }
            });
          }
          break;
      }
    });

    if (!!localStorage.getItem('gepaeckauftrag-cust-list.grid.paging.pageSize')) {
      this.gridPageSize = +localStorage.getItem('gepaeckauftrag-cust-list.grid.paging.pageSize');
      if (this.gridPageSize < 5) {
        this.gridPageSize = 5;
      } else if (this.gridPageSize > 300) {
        this.gridPageSize = 300;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    return;
    // throw new Error('Method not implemented.');
    }

  async ngOnInit() {
    this.counter$.subscribe(() => {
      this.timerEvent();
    });
    if (!this.adresseDddw || this.adresseDddw.length === 0) {
      this.adresseDddw = [];
      this.adresseDddw = await this.adresseService.getAllDddw();
    }

    if (this.gepaeckAdresseDddw === undefined || this.gepaeckAdresseDddw.length <= 1) {
      await this.adresseService.getGepaeckAdrDddw().then(res => {
        this.gepaeckAdresseDddw = res;
        return;
      });
    }
    if (!environment.production) console.log('ngOnChanges 100');
    /*await this.fahrplanService.getNonFrachtJanusDays(365).then(resFahrplans => {
      this.fahrplans = resFahrplans;
      if (!environment.production) console.log('ngOnChanges 110 getNonFrachtJanusDays', this.fahrplans);
    });*/
    await this.loadBaseData().then(() => {
      this.refreshGepaeckAuftragList();
    });

    window.onresize = () => {
      this.isMobileResolution = window.innerWidth <= 768;
    };
  }

  ngOnDestroy(): void {
    this.unsubscribeAllSubject.next();
  }

  // --------------------------------------------------------------------------
  // DataGrid Hooks

  selectionChangedHandler(event: any) {
    if (event === undefined || event.selectedRowsData === undefined) {
      return;
    }
    this.selectedGepaeckBelege = event.selectedRowsData as GepaeckBeleg[];
    this.refreshToolbarControlsDependingOnSelectedRows(this.selectedGepaeckBelege);
  }

  refreshToolbarControlsDependingOnSelectedRows(selectedRows: GepaeckBeleg[]) {
    if (selectedRows === undefined) {
      return;
    }
    this.gepaeckBelegDeleteAllowed = false;
    this.gepaeckBelegEditAllowed = false;
    this.gepaeckBelegCopyAllowed = selectedRows.length > 0 && selectedRows.length <= 15;

    if (this.selectedGepaeckBelege && selectedRows.length === 1) {
      this.gepaeckBelegEditAllowed = this.gepaeckBelegService.isGepackBelegEditAllowed(selectedRows[0]);

      this.gepaeckBelegDeleteAllowed = this.gepaeckBelegEditAllowed;

      if (!this.gepaeckBelegDeleteAllowed && this.authenticationService.isCustomer && (this.selectedGepaeckBelege[0].gpblg_dummy_datum < new Date())) {
        this.gepaeckBelegDeleteAllowed = false;
      }
      if (!this.gepaeckBelegDeleteAllowed && (this.getIsEmployee() && this.selectedGepaeckBelege[0].gpblg_dummy_datum < new Date())) {
        // bereits bezahlte Belege nicht loeschen, sondern stornieren!!!
        this.gepaeckBelegDeleteAllowed = false;
      }

      // ob das Loeschen von RG erlaubt ist, prüft die API genauer, also erstmal laufen lassen
      // gpblg_bezahlt_kz 0 offen und 2 Rechnung duerfen geloescht werden
      this.gepaeckBelegDeleteAllowed = this.gepaeckBelegEditAllowed
        && Number(selectedRows[0].gpblg_bezahlt_kz === undefined ? '1'
          : (selectedRows[0].gpblg_bezahlt_kz == '0'
            || selectedRows[0].gpblg_bezahlt_kz == '2') ? '0'
            : Number(selectedRows[0].gpblg_bezahlt_kz)) === 0;

      if (this.gepaeckBelegDeleteAllowed) {
        if (this.selectedGepaeckBelege?.length !== 1 || environment.targetKey === '10477') {
          this.gepaeckBelegDeleteAllowed = false;
        }
        return false;
      }


    } else {
      this.gepaeckBelegEditAllowed = false;
      this.gepaeckBelegDeleteAllowed = false;
    }
  }

  optionChangedHandler(event: any) {
    if (event.fullName === 'paging.pageSize') {
      if (event.value >= 5) {
        localStorage.setItem('gepaeckauftrag-cust-list.grid.paging.pageSize', event.value);
      }
    }
  }

  // END DataGrid Hooks
  // --------------------------------------------------------------------------

  async loadBaseData() {
    if (this.adresseDddw === undefined) {
      this.adresseDddw = [];
    }
    if (this.adresseDddw.length === 0) {
      await this.adresseService.getAllDddw().then(res => {
        this.adresseDddw = res;
      });
    }
  }

  async refreshGepaeckAuftragList() {
    this.popupDeleteGepaeckBelegVisible = false;
    const gepaeckBelegFilter: GepaeckBeleg = new GepaeckBeleg();
    gepaeckBelegFilter.gpblg_dummy_datum = this.filterByDate ?? undefined;

    switch (this.inputProcessingMode ?? 0) {
      case GepaeckProcessStatus.abholen :
        gepaeckBelegFilter.gpblg_dummy_abgang_fort_id = this.filterByOrtId ?? 0;
        break;
      case GepaeckProcessStatus.zustellen :
        gepaeckBelegFilter.gpblg_dummy_ziel_fort_id = this.filterByOrtId ?? 0;
        break;
      default:
        gepaeckBelegFilter.gpblg_dummy_ziel_fort_id = 0;
        gepaeckBelegFilter.gpblg_dummy_abgang_fort_id = this.filterByOrtId ?? 0;
    }
    gepaeckBelegFilter.fp_id = this.filterByFahrplanId ?? 0
    gepaeckBelegFilter.gpblg_external_order_id = this.filterByExternalOrderId;

    return this.gepaeckBelegService.getFiltered(gepaeckBelegFilter).subscribe(res => {
      if (res !== undefined) {
        this.gepaeckBelege = res;
        this.selectedGepaeckBelege = this.grid.instance.getSelectedRowsData();
        this.cacheSortedGrid().then();
        return res;
      }
      return;
    });

  }

  // Versorge Dropdown mit Daten ...
  getAdresses(options: any) {
    return {
      store: this.adresseDddw,
      filter: options.data ? ['adr_id', '=', options.data.adr_id] : null
    };
  }

  getGepaeckAdresses(options: any) {
    return {
      store: this.gepaeckAdresseDddw,
      filter: options.data ? ['adr_id', '=', options.data.adr_id] : null
    };
  }

  getFrachtOrt(options: any) {
    return {
      store: this.frachtOrts,
      filter: options.data ? ['fort_id', '=', options.data.fort_id] : null
    };
  }

  public getIsCustomer(): boolean {
    return this.authenticationService.isAuthenticated && this.authenticationService.isCustomer;
  }

  public getIsEmployee(): boolean {
    return this.authenticationService.isAuthenticated && this.authenticationService.isEmployee;
  }

  public getIsAdminUser(): boolean {
    return (this.authenticationService?.isAuthenticated && this.authenticationService?.isAdminUser) ?? false;
  }

  public actionEditItem(): any {
    if (this.selectedGepaeckBelege?.length > 0) {
      // alte GEP bei Kunden nur anzeigen, nicht als EDIT
      if ((this.authenticationService.isCustomer || this.authenticationService.isEmployee)
        && new Date(this.selectedGepaeckBelege[0].gpblg_dummy_datum) < this.gepaeckBelegService.getMinimalStartDate()) {
        // console.log('actionEditItem this.selectedGepackBelege[0] ', this.selectedGepackBelege[0]);
        this.router.navigate(['/gepaeckauftrag/show',
          this.selectedGepaeckBelege[0].gpblg_id,
          this.selectedGepaeckBelege[0].gpblg_extern_mobiletan ? this.selectedGepaeckBelege[0].gpblg_extern_mobiletan : '000000']).then();
      } else {
        let gepaeckBeleg: GepaeckBeleg = {id: this.selectedGepaeckBelege[0].gpblg_id};
        this.router.navigate(['gepaeckauftrag-cust/edit', gepaeckBeleg]).then();
      }
    }
  }

  public actionShowItem(): any {
    if (this.selectedGepaeckBelege && this.selectedGepaeckBelege.length > 0) {
      // alte GEP bei Kunden nur anzeigen, nicht als EDIT
      this.router.navigate(['/gepaeckauftrag/show',
        this.selectedGepaeckBelege[0].gpblg_id,
        this.selectedGepaeckBelege[0].gpblg_extern_mobiletan ? this.selectedGepaeckBelege[0].gpblg_extern_mobiletan : '000000']).then();
    }
  }

  async deleteSelectedItems() {
    // this.outputFrachtbrief.emit(undefined);
    for (const o of this.selectedGepaeckBelege) {
      await this.gepaeckBelegService.delete(o.gpblg_id).then(() => {
        // this.frachtbriefe.unshift(o);
        this.selectedGepaeckBelege.splice(0);
        this.selectedGepaeckBelegeIds.splice(0);
      });
    }
    await this.refreshGepaeckAuftragList();
    return;
  }

  public goBack(): void {
    this.location.back();
  }

  public getFrontendBaseUrl(): string {
    return this.frontendBaseUrl;
  }

  public actionAddItem(): any {
    //let gepaeckBeleg: GepaeckBeleg = {id: 0};
    // this.router.navigate(['gepaeckauftrag-cust/edit', gepaeckBeleg]).then();
    this.router.navigate(['gepaeckauftrag-cust/edit']).then();
  }

  async signAsPayedSelectedItems(paymentType: paymentTypesEnum) {
    let signedCount: number = 0;
    if (this.authenticationService.isEmployee) {
      this.selectedGepaeckBelege = this.grid.instance.getSelectedRowsData();
      for (const o of this.selectedGepaeckBelege) {
        if (o.gpblg_bezahlt_kz?.toString() === '0') {
          await this.gepaeckBelegService.signAsPayed(o.gpblg_id).then(() => {
            signedCount++;
          });
        }
      }
      if (signedCount > 0) {
        await this.refreshGepaeckAuftragList().then(() => {
          this.refreshToolbarControlsDependingOnSelectedRows(this.selectedGepaeckBelege);
        });
      }
    }
  }

  async signAsUnPayedSelectedItems() {
    let signedCount: number = 0;
    if (this.authenticationService.isEmployee) {
      this.selectedGepaeckBelege = this.grid.instance.getSelectedRowsData();
      for (const o of this.selectedGepaeckBelege) {
        if (o.gpblg_bezahlt_kz?.toString() === '1') {
          await this.gepaeckBelegService.signAsUnPayed(o.gpblg_id).then(() => {
            signedCount++;
          });
        }
      }
      if (signedCount > 0) {
        await this.refreshGepaeckAuftragList().then(() => {
          this.refreshToolbarControlsDependingOnSelectedRows(this.selectedGepaeckBelege);
        });
      }
    }
  }

  public async signServicedAbgeholtSelectedItems(revokeSigning: boolean = false) {
    return this.signServicedSelectedItems(GepaeckProcessStatus.abgeholt, revokeSigning ?? false);
  }

  public async signServicedZugestelltSelectedItems(revokeSigning: boolean = false) {
    await this.signServicedSelectedItems(GepaeckProcessStatus.zugestellt,  revokeSigning ?? false);
  }

  private async signServicedSelectedItems(serviceProcessStatus: GepaeckProcessStatus, revokeSigning: boolean = false) {
    let rowKeys: any[];
    this.stopTimerSubject.next();
    if (this.authenticationService.isEmployee) {
      this.selectedGepaeckBelege = this.grid.instance.getSelectedRowsData();
      rowKeys = this.grid.instance.getSelectedRowKeys();
      if (!environment.production) console.log('signServicedSelectedItems getSelectedRowKeys', rowKeys, rowKeys.length);
      this.gepaeckBelegService.signAsServicedByKeys(rowKeys, serviceProcessStatus, this.containerNo, revokeSigning).subscribe(() => {
        this.grid.instance.clearSelection();
        this.grid.instance.deselectAll().then();
        this.refreshGepaeckAuftragList().then(() => {
          // if (this.sortedGepaeckBelege && this.sortedGepaeckBelege.length > 0 ) {
          //   console.log('signServicedSelectedItems sorted', this.sortedGepaeckBelege, this.gepaeckBelege);
          //   this.grid.instance.selectRows([this.sortedGepaeckBelege[0].gpblg_id], false);
          // }
        });
      });
    }
    this.restartTimerSubject.next();
  }

  async cacheSortedGrid(): Promise<any> {
    let sortedGrid: dxDataGrid | undefined = this.grid?.instance;
    let filterExpr = sortedGrid?.getCombinedFilter(true);
    const dataSource = sortedGrid?.getDataSource();
    const loadOptions = dataSource?.loadOptions();

    return dataSource
      ?.store()
      .load({filter: filterExpr, sort: loadOptions?.sort, group: loadOptions?.group})
      .then((result: GepaeckBeleg[]) => {
        this.sortedGepaeckBelege = result;
        return result;
      });
  }

  getSelectedGepaeckBelegeAsJoinedString(): string {
    if (this.grid.instance.getSelectedRowsData() && this.grid.instance.getSelectedRowsData().length > 0) {
      return this.grid.instance.getSelectedRowsData().map(o => o.gpblg_id).join(', ');
    } else {
      return '';
    }
  }

  public calculateCellValueExternEmail(rowData: any): string {
    return `<a href="mailto:${rowData.gpblg_extern_email}">${rowData.gpblg_extern_email}</a> `;
  }

  private async timerEvent() {
    if (this.authenticationService.isEmployee && environment.production) {
      await this.refreshGepaeckAuftragList();
    }
  }

  getDisplayFrachtOrt(item: IFrachtOrt): string {
    if (!item || !item.fort_name) {
      return '';
    }
    return item.fort_name
  }

  getDisplayAbfahrtzeitVoll(item: IFahrplan): string {
    if (!item || !item.fp_abfahrt_datzeit) {
      return '';
    }
    if (parent.window.outerWidth < 520) {
      return formatDate(item.fp_abfahrt_datzeit, 'EE dd.MM.yy HH:mm', 'de-DE');
    } else {
      return formatDate(item.fp_abfahrt_datzeit, 'EE dd.MM.yy HH:mm', 'de-DE') + ' ' + item.ger_name;
    }
  }

  getDisplayAnkunftzeitVoll(item: IFahrplan): string {
    if (!item || !item.fp_ankunft_datzeit) {
      return '';
    }
    if (parent.window.outerWidth < 520) {
      return formatDate(item.fp_ankunft_datzeit, 'EE dd.MM.yy HH:mm', 'de-DE');
    } else {
      return formatDate(item.fp_ankunft_datzeit, 'EE dd.MM.yy HH:mm', 'de-DE') + ' ' + item.ger_name;
    }
  }

  onValueChangedFilterByDate(e: any) {
    // if (!environment.production) console.log('onValueChangedFilterByDate (1) ', e, this.today, new Date());
    let fahrplanIndex: number;
    this.filterByDate = e?.value ?? null;
    // Fähren/Flüge für dieses Datum laden, für die es GEP-Auftraege gibt
    // if (!environment.production) console.log('onValueChangedFilterByDate (2) ', this.filterByDate, this.today, (this.filterByDate === this.today));

    if (this.filterByDate == null) {
      this.filterByFahrplanId = 0;
      this.filterByOrtId = this.filterByOrtId ?? 0;
      this.refreshGepaeckAuftragList().then();
      return;
    }

    // Liste neu laden
    this.lookupFahrplansAsync().then(() => {
      // if (!environment.production) console.log('onValueChangedFilterByDate (lookupFahrplans) ', this.filterByDate, this.today, (this.filterByDate === this.today));
      if ((this.fahrplans?.length ?? 0) > 0) {
        fahrplanIndex = -1;
        if(this.filterByFahrplanId !== null) {
          fahrplanIndex = this.fahrplans.findIndex(eData => eData.fp_id === this.filterByFahrplanId);
        }
        if (fahrplanIndex >= 0) {
        } else if (this.filterByDate != this.today) {
          this.filterByFahrplanId = 0;
        } else {
          // detect next scheduled transport ...
          fahrplanIndex = this.fahrplans.findIndex(eData => new Date(eData.fp_abfahrt_datzeit) > new Date());
          this.filterByFahrplanId = (fahrplanIndex >= 0) ? this.fahrplans[fahrplanIndex].fp_id : this.fahrplans[0].fp_id;
        }
      }
    });
    this.refreshGepaeckAuftragList().then();
    // if (!environment.production) console.log('onValueChangedFilterByDate (END)', this.filterByFahrplanId);
  }

  onValueChangedFilterByOrtId(e: any) {
    this.filterByOrtId = e?.selectedItem?.fort_id ?? 0;
    this.lookupFahrplansAsync().then();
    this.refreshGepaeckAuftragList().then();
  }

  onValueChangedByFahrplanId(e: any) {
    this.filterByFahrplanId = e?.selectedItem?.fp_id ?? 0;
    this.refreshGepaeckAuftragList().then();
  }


  onValueChangedExternalOrderId(e: any) {
    this.filterByExternalOrderId = e?.value.toString();
    this.refreshGepaeckAuftragList().then();
  }

  public async lookupFahrplansAsync(): Promise<any> {
    // if (!environment.production) console.log('lookupFahrplansAsync (1) ');

    this.fahrplans = [];
    let searchDate: Date = this.filterByDate;
    // if (!environment.production) console.log('lookupFahrplansAsync (10) ', searchDate, this.fahrplans);

    let fahrplanFilter: FahrplanFilter = new FahrplanFilter();

    switch (this.inputProcessingMode ?? 0) {
      case GepaeckProcessStatus.zustellen :
        fahrplanFilter.transportArt = 'personen';
        fahrplanFilter.abfahrtOrtId = undefined;
        fahrplanFilter.zielOrtId = !!this.filterByOrtId ? this.filterByOrtId : 0;
        fahrplanFilter.resultsetSize = ClosingSize.S;
        fahrplanFilter.date = !!this.filterByDate ? this.filterByDate : this.today;
        fahrplanFilter.pastDays = 10;
        fahrplanFilter.futureDays = 10;
        // if (!environment.production) console.log('lookupFahrplansAsync Zustellung, calling getFahrplanDropDownData (20) ', searchDate, fahrplanFilter);
        this.fahrplans$ = this.fahrplanService.getFahrplanDropDownData(fahrplanFilter);
        this.fahrplanService.getFahrplanDropDownData(fahrplanFilter).pipe( map(resFahrplanData => {
          this.fahrplans = resFahrplanData
          // if (!environment.production) console.log('lookupFahrplansAsync Zustellung, calling getFahrplanDropDownData (22) ', this.fahrplans);
        }));
        /*this.fahrplanService.getFahrplanDropDownData(fahrplanFilter).subscribe(resFahrplanData => {
          this.fahrplans = resFahrplanData;
          return this.fahrplans;
        });*/
        break;
      default:
        // auch GepaeckProcessStatus.abholen
        await this.fahrplanService.getGepaeckServiceFahrtenByAbfahrtOrt(
          searchDate,
          this.filterByOrtId).then((resFahrplan) => {
          // if (!environment.production) console.log('lookupFahrplansAsync (80) ', resFahrplan == undefined ? 'resFahrplan == undefined' : resFahrplan);
          this.fahrplans = resFahrplan;
          return this.fahrplans;
        }).then();
    }

    // if (!environment.production) console.log('lookupFahrplansAsync (99) ', this.fahrplans);
    if (this.fahrplans?.length > 0 && this.gepaeckProcessStatus !== null) {
      this.filterByFahrplanId = this.filterByFahrplanId ?? this.fahrplans[0].fp_id;
    } else {
      this.filterByFahrplanId = this.filterByFahrplanId ?? 0;
    }
    // if (!environment.production) console.log('lookupFahrplansAsync (100) ', this.filterByFahrplanId);

    return this.fahrplans;
  }

  public get componentTitle(): string {
    return this._componentTitle;
  }

  popoverToggleDefault() {
    this.popoverVisible = !this.popoverVisible;
  }

  protected readonly GepaeckProcessStatus = GepaeckProcessStatus;

  public getGepaeckBelegDeleteAllowed() {
    return this.gepaeckBelegDeleteAllowed;
  }

  public getGepaeckBelegDeleteSelectedAllowed() {
    /*if (this.selectedGepaeckBelege?.length > 0) {
      if (!environment.production) console.log('allowDeleteSelected', this.getIsAdminUser(), this.selectedGepaeckBelege[0], this.selectedGepaeckBelege[0].gpblg_dummy_datum > new Date());
    }*/
    if (!this.gepaeckBelegDeleteAllowed || this.selectedGepaeckBelege?.length !== 1 || environment.targetKey === '10477') {
      return false;
    }
    if (this.authenticationService.isCustomer && (this.selectedGepaeckBelege[0].gpblg_dummy_datum > new Date())) {
      return true;
    }
    return (this.getIsEmployee() && this.getIsAdminUser()) && this.selectedGepaeckBelege[0].gpblg_dummy_datum > new Date();
  }

  protected readonly Observable = Observable;
  protected readonly Number = Number;

  onCellPrepared(e: any): void {
    if (e.rowType === 'data') {
      switch (e.column.dataField) {
        case 'fp_abfahrt_datzeit':
          on(e.cellElement, 'mouseover', (arg: any) => {
            this.currentGepaeckBeleg = e.data;
            if (!! this.currentGepaeckBeleg.fp_abfahrt_datzeit
              && new Date(this.currentGepaeckBeleg.fp_abfahrt_datzeit).getUTCFullYear() != new Date().getUTCFullYear() ){
              // Tooltip nur bei Relevanz anzeigen ...
              this.tooltip.instance.show(arg.target).then();
            }
          });

          on(e.cellElement, 'mouseout', () => {
            this.tooltip.instance.hide().then();
          });
          break;
        case 'gart_name':
          break;
      }
    }
  }
}
