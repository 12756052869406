import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ServerMessage, SmsMessageResponse, SmsPhoneFormat} from '../api-dtos/api-dtos.module';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class SmsService {
  baseUrl = `${environment.restApiUrl}`;

  constructor(
    private http: HttpClient) {
  }

  private static getHttpHeader() {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  public isPhoneNumberValidAlt(phoneNumber: string): BehaviorSubject<boolean> {
    const isValidChecker = new BehaviorSubject<boolean>(false);
    if (phoneNumber !== undefined && phoneNumber.trim() !== '') {
      this.callPhoneNumberValidatorAlt(phoneNumber).subscribe( validatorData => {
        isValidChecker.next(validatorData.success !== undefined && validatorData.success === true);
      });
    }
    return isValidChecker;
  }

  public isPhoneNumberValid(phoneNumber: string): BehaviorSubject<boolean> {
    const isValidChecker = new BehaviorSubject<boolean>(false);
    if (phoneNumber !== undefined && phoneNumber.trim() !== '') {
      this.callPhoneNumberValidator(phoneNumber).then( validatorData => {
        isValidChecker.next(validatorData.success !== undefined && validatorData.success === true);
      });
    }
    return isValidChecker;
  }

  public callPhoneNumberValidatorAlt(phoneNumber: string): Observable<SmsPhoneFormat> {
    return this.http.get<SmsPhoneFormat>(`${this.baseUrl}/phonenumbervalidator/${phoneNumber}`,
      SmsService.getHttpHeader()
    );
  }

  public async callPhoneNumberValidator(phoneNumber: string): Promise<SmsPhoneFormat> {
    phoneNumber = phoneNumber ? phoneNumber.trim() : '';
    return this.http.get<SmsPhoneFormat>(`${this.baseUrl}/phonenumbervalidator/${phoneNumber}`,
      SmsService.getHttpHeader()
    ).toPromise();
  }


  public getPhoneNumberFormatted(phoneNumber: string): string {
    if (phoneNumber === undefined || phoneNumber.trim() === '') {
      return phoneNumber;
    }
    this.http.get(`${this.baseUrl}/phonenumbervalidator/${phoneNumber}`,
      SmsService.getHttpHeader()
    ).toPromise().then( (resSmsPhoneFormat: SmsPhoneFormat) => {
      if (resSmsPhoneFormat === undefined) {
        return phoneNumber;
      }
      if (resSmsPhoneFormat.success) {
        return resSmsPhoneFormat.international_formatted;
      } else {
        return phoneNumber;
      }
    });
  }

  async sendGeneratedMobileTan000000(phoneNumber: string) {
    if (phoneNumber === undefined || phoneNumber.trim() === '' || !this.isPhoneNumberValid(phoneNumber)) {
      return '';
    }

    this.http.get<SmsMessageResponse>(`${this.baseUrl}/smsSendGeneratedMobileTan/${phoneNumber}`,
      SmsService.getHttpHeader()
    ).subscribe(smsMessageResponse => {
      if (smsMessageResponse === undefined) {
        return '';
      }
      if (smsMessageResponse.mobileTan !== undefined && smsMessageResponse.mobileTan !== '') {
        return smsMessageResponse.mobileTan;
      }
    });
    return '';
  }

  async sendGeneratedMobileTanToUserPhone(phoneNumber: string): Promise<ServerMessage> {
    if (phoneNumber === undefined || phoneNumber.trim() === '' || !this.isPhoneNumberValid(phoneNumber)) {
      return new ServerMessage();
    }

    return this.http.get<ServerMessage>(`${this.baseUrl}/smsSendGeneratedMobileTan/${phoneNumber}`,
      SmsService.getHttpHeader()
    ).toPromise();
  }

  async sendGeneratedMobileTanToUserPhoneWithPurpose(phoneNumber: string, purpose: SmsTanType): Promise<ServerMessage> {
    if (phoneNumber === undefined || phoneNumber.trim() === '' || !this.isPhoneNumberValid(phoneNumber)) {
      return new ServerMessage();
    }

    return this.http.get<ServerMessage>(`${this.baseUrl}/smsSendGeneratedMobileTan/${phoneNumber}/${purpose}`,
      SmsService.getHttpHeader()
    ).toPromise();
  }

}

@Injectable()
export class smsAttributes {
  phoneNumber: string;
  processingMode: string;
  purpose?: string;

  public getAttributes(): string {
    let attributes: string[] = [];

    if (this.purpose) {
      attributes.push('purpose=' + this.purpose);
    }
    if (this.processingMode) {
      attributes.push('mode=' + this.processingMode);
    }

    let concatedStream: string;
    for (let i: number = 0; i < attributes.length; i++) {
      concatedStream += (attributes[i] ?? '');
      if (i < attributes.length - 1) {
        concatedStream += '&';
      }
    }

    if (concatedStream) {
      concatedStream = '?' + concatedStream;
    }
    return concatedStream;
  }
}

export enum SmsTanType {
  Any = "ANY",
  Sonstige = Any,
  Beleg = "BELEG",
  GepaeckBeleg = "GEPAECK",
  Frachtbrief = "FRACHTBRIEF",
  Lieferschein = "LIEFERSCHEIN",
  DeliveryNote = Lieferschein,
  LagerBox = "LAGERBOX",
  Registration = "REGISTRATION",
  Registrierung = Registration,
  StorageBox = "STORAGEBOX"
}
