import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from './shared-services/authentication.service';
import {RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';
import config from 'devextreme/core/config';
import deMessages from 'devextreme/localization/messages/de.json';
import {locale, loadMessages, formatMessage} from 'devextreme/localization';
import { timer } from 'rxjs';
import {licenseKey} from '../devextreme-license';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private timer: any;
  private sub: Subscription;
  title = 'Portal-Name';
  console = console;
  ticks = 0;
  location: Location;
  formatMessage = formatMessage;
  showSpinner: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) {
    // Specifying a currency globally
    config({defaultCurrency: 'EUR'});
    loadMessages(deMessages);
    locale('de');
    this.showSpinner = true;

    this.authenticationService.setPublicSessionId();

    this.router.events.subscribe(
      event => {
        if (event instanceof RouteConfigLoadStart) {
          // console.log('RouteConfigLoadStart');
          this.showSpinner = true;
          return;
        }
        if (event instanceof RouteConfigLoadEnd) {
          // console.log('RouteConfigLoadEnd');
          this.showSpinner = false;
          return;
        }
      }
    );
    // DevExtreme ...
    config({ licenseKey });
  }

  public static updateChecker() {
    if (!localStorage.getItem('lastClientUpdateStamp')) {
      const outdated = new Date();
      outdated.setDate(outdated.getDate() - 7);
      localStorage.setItem('lastClientUpdateStamp', outdated.toJSON());
    }
    const today = new Date();
    const yesterday = new Date();
    const lastupdate = new Date(localStorage.getItem('lastClientUpdateStamp'));
    today.setDate(today.getDate());
    yesterday.setDate(yesterday.getDate() - 1);

    if (lastupdate < yesterday) {
      localStorage.setItem('lastClientUpdateStamp', today.toJSON());
      // parent.document.location.reload();
      window.location.reload()
    }
  }

  public initMessages() {
    loadMessages(deMessages);
    // loadMessages(this.service.getDictionary());
  }

  tickerFunc(tick: any) {
    this.ticks = tick;
    // check once an hour for updates ...
    if (this.ticks % 60 === 0) {
      AppComponent.updateChecker();
    }

    if (this.authenticationService !== undefined && (this.authenticationService?.authenticated ?? false)) {
      // a: die Server-Session verlängern, wenn die lokale Session noch gültig ist
      // b: die lokale Session auf false setzen, wenn die Server-Session ungültig ist.
      if (this.ticks > 2) {
        this.authenticationService.checkRefreshLogin().then();
      }
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    // this.version = environment.version;
    if (this.authenticationService !== undefined) {
      this.authenticationService.checkRefreshLogin().then();
    }

    // should we ensure a client reload from server?
    AppComponent.updateChecker();

    // first call after 2 secs and then once a minute
    this.timer = timer(2000, 60000);
    // subscribing to a observable returns a subscription object
    this.sub = this.timer.subscribe( (t: any) => this.tickerFunc(t));
  }

  protected readonly environment = environment;
}
