import {CommonModule, NgOptimizedImage} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FahrplanListComponent} from './fahrplan-list/fahrplan-list.component';
import {FrachtbriefModule} from './frachtbrief/frachtbrief.module';
import {SchedulerModule} from './scheduler/scheduler.module';
import {TarifListComponent} from './tarif-list/tarif-list.component';
import {AdresseListComponent} from './adresse-list/adresse-list.component';
import {GepaeckAuftragEditComponent} from './gepaeck/gepaeck-auftrag-edit/gepaeck-auftrag-edit.component';
import {GepaeckAuftragCustEditComponent} from './gepaeck/gepaeck-auftrag-cust-edit/gepaeck-auftrag-cust-edit.component';
import {GepaeckAuftragCustListComponent} from './gepaeck/gepaeck-auftrag-cust-list/gepaeck-auftrag-cust-list.component';
import {GepaeckAuftragShowComponent} from './gepaeck/gepaeck-auftrag-show/gepaeck-auftrag-show.component';
import {MapComponent} from './map/map.component';
import {AccountingModule} from './accounting/accounting.module';
import {NgxBarcode6Module} from 'ngx-barcode6';
import {MaterialModule} from '../material/material.module';
import {ClarityModule} from '@clr/angular';
import {DevExpressModule} from '../shared/devexpress/dev-express.module';
import {DxoItemModule} from 'devextreme-angular/ui/nested';
import {DxBoxModule, DxScrollViewModule, DxTextBoxModule} from 'devextreme-angular';
import {SharedComponentsModule} from '../shared-components/shared-components.module';
import {UserListComponent} from './admin/user-list/user-list.component';
import {UserEditComponent} from './admin/user-edit/user-edit.component';
import {StorageboxOrderListComponent} from './storagebox/storagebox-order-list/storagebox-order-list.component';
import {StorageboxOrderGuestEditComponent} from './storagebox/storagebox-order-guest-edit/storagebox-order-guest-edit.component';
import {StorageboxOrderCustEditComponent} from './storagebox/storagebox-order-cust-edit/storagebox-order-cust-edit.component';
import {StorageboxOrderShowComponent} from './storagebox/storagebox-order-show/storagebox-order-show.component';
import { StorageboxAvailabilityComponent } from './storagebox/storagebox-availability/storagebox-availability.component';
import { FlugplanListComponent } from './flugplan-list/flugplan-list.component';
import { GepaeckInfoComponent } from './gepaeck/gepaeck-info/gepaeck-info.component';
import { TarifListInfoComponent } from './tarif-list/tarif-list-info/tarif-list-info.component';
import {QRCodeModule} from 'angularx-qrcode';
import {
  PayOneWaitForPayLinkCompletionComponent
} from './payment/pay-one-wait-for-pay-link-completion/pay-one-wait-for-pay-link-completion.component';
import { GepaeckAuftragCustListAbholenComponent } from './gepaeck/gepaeck-auftrag-cust-list-abholen/gepaeck-auftrag-cust-list-abholen.component';
import { GepaeckAuftragCustListZustellenComponent } from './gepaeck/gepaeck-auftrag-cust-list-zustellen/gepaeck-auftrag-cust-list-zustellen.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AdresseListComponent,
    FahrplanListComponent,
    TarifListComponent,
    GepaeckAuftragEditComponent,
    GepaeckAuftragCustEditComponent,
    GepaeckAuftragCustListComponent,
    GepaeckAuftragShowComponent,
    GepaeckInfoComponent,
    MapComponent,
    UserListComponent,
    UserEditComponent,
    StorageboxOrderListComponent,
    StorageboxOrderGuestEditComponent,
    StorageboxOrderCustEditComponent,
    StorageboxOrderShowComponent,
    StorageboxAvailabilityComponent,
    FlugplanListComponent,
    TarifListInfoComponent,
    PayOneWaitForPayLinkCompletionComponent,
    GepaeckAuftragCustListAbholenComponent,
    GepaeckAuftragCustListZustellenComponent,
  ],
    imports: [
        AccountingModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        FrachtbriefModule,
        NgxBarcode6Module,
        QRCodeModule,
        SchedulerModule,
        ReactiveFormsModule,
        DevExpressModule,
        MaterialModule,
        ClarityModule,
        DxoItemModule,
        SharedComponentsModule,
        DxScrollViewModule,
        DxBoxModule,
        DxTextBoxModule,
        NgOptimizedImage
    ],
  exports: []
})
export class PagesModule {
}
