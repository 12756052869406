<div class="dx-card sits-card">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header sits-print-hide">
      <dx-toolbar>
        <dxi-item widget="dxButton" location="before">
          <dx-button (click)="goBack()" icon="back" hint="zurück navigieren"></dx-button>
        </dxi-item>
        <dxi-item location="left" widget="dxButton" locateInMenu="never">
          <div *dxTemplate>
            <div class="toolbar-label sits-kachel-text-xxl">{{title}} {{gepaeckBeleg.gpblg_id}}
              <span
                *ngIf="!!gepaeckBeleg.gpblg_external_order_id"> | Ticket-Nr.: {{gepaeckBeleg.gpblg_external_order_id}}&nbsp;</span>
              <span *ngIf="(this.gepaeckBeleg.gpblg_storniert_yn ?? 0) === 1"> | <span
                class="sits-color-alert"><b>STORNIERT</b>&nbsp;</span>&nbsp;
                {{this.gepaeckBeleg.gpblg_storniert_stamp | date: 'EE dd.MM.YYYY HH:mm'}}.&nbsp;</span>
            </div>
          </div>
        </dxi-item>
      </dx-toolbar>
    </div>

    <div class="dx-fieldset-header sits-print-only">{{title}} {{gepaeckBeleg.gpblg_id}}
      <span
        *ngIf="!!gepaeckBeleg.gpblg_external_order_id"> | Ticket-Nr.: {{gepaeckBeleg.gpblg_external_order_id}}&nbsp;</span>
      <span *ngIf="(this.gepaeckBeleg.gpblg_storniert_yn ?? 0) === 1"> | <span class="sits-color-alert"><b>STORNIERT</b></span>&nbsp;
        {{this.gepaeckBeleg.gpblg_storniert_stamp | date: 'EE dd.MM.YYYY HH:mm'}}.&nbsp;
        </span><br><br>
    </div>
    <div class="dx-field">
      <div class="clr-col">
        <div *ngIf="gepaeckBeleg.gpblg_process_log !== undefined && gepaeckBeleg.gpblg_process_log.length > 0 ">
          <span class="sits-kachel-text-sm sits-text-bold sits-padding-none sits-margin-none">Bearbeitungsinformationen ...</span>
          <dx-data-grid #processLog
                        [dataSource]="gepaeckBeleg.gpblg_process_log"
                        [showColumnHeaders]="true"
                        [showBorders]="false"
                        [showColumnLines]="true"
                        [showRowLines]="true"
                        [hoverStateEnabled]="true"
                        [rowAlternationEnabled]="true"
                        id="event-container"
                        keyExpr="evlo_id">
            <dxi-column caption="Zeitpunkt, ca."
                        dataField="evlo_create_stamp"
                        dataType="date"
                        format="EE dd.MM. HH:mm"
                        width="120"
            ></dxi-column>
            <dxi-column caption="Aktion/Status"
                        dataField="evlo_status_text"
                        dataType="string"
                        width="140"
            ></dxi-column>
            <dxi-column caption="Anmerkung"
                        dataField="evlo_comment"
                        dataType="string"
                        width="200"
            ></dxi-column>
          </dx-data-grid>
          <br>
        </div>
        <div *ngIf="isAnreise !== undefined && !isAnreise">
          <div class="card-text" *ngIf="targetKey==='10426'">
            <b>Abreise</b>: Stellen Sie Ihr Gepäck bitte <b>1 1/2 Std.</b>, <b>außerorts</b> (Haus Klasing,
            Jugendhof, Haus
            Wolfgang,
            Haus am Meer, Walter-Requardt-Heim, Haus Sturmeck, Huus Jan Westend, CVJM Haus Quellerdünen)
            bitte <b>2
            Std.</b> vor Schiffsabfahrt deutlich sichtbar zur Abholung bereit.<br>
            Wir holen Ihr Gepäck von der <b>Inseladresse</b> ab und bringen es nach
            <b>{{this.gepaeckBeleg.gpblg_dummy_abgang_frachtort.fort_name}}</b>.<br>
            Sie benötigen <b>KEINE Banderole</b><br>
            Vielen Dank für Ihre Unterstützung.<br>
            <span
              *ngIf="gepaeckBeleg.gpblg_bezahlt_kz?.toString()==='0'"><br>Bitte den Betrag vor dem Transport bezahlen.</span><br>
          </div>
          <div class="card-text" *ngIf="targetKey==='10477'">
            <b>Abreise</b>: Stellen Sie Ihr Gepäck bitte <b>1 1/2 Std.</b>, <b>außerorts</b> bitte <b>2
            Std.</b> vor
            Abfahrt/Abflug
            deutlich sichtbar zur Abholung bereit.<br>
            Wir holen Ihr Gepäck von der <b>Inseladresse</b> ab und bringen es nach
            <b>{{this.gepaeckBeleg.gpblg_dummy_abgang_frachtort.fort_name}}</b>.<br>
            Vielen Dank für Ihre Unterstützung.<br>
            <span
              *ngIf="gepaeckBeleg.gpblg_bezahlt_kz?.toString()==='0'"><br>Bitte den Betrag vor dem Transport bezahlen.</span><br>
          </div>
        </div>
        <div *ngIf="isAnreise !== undefined && isAnreise">
          <div class="card-text" *ngIf="targetKey==='10426'">
            <b>Anreise</b>: Geben Sie bitte Ihr mit Ihren lesbaren Banderolen gekennzeichnetes Gepäck bei
            unserem
            Gepäckschalter von {{this.gepaeckBeleg.gpblg_dummy_abgang_frachtort.fort_name}} ab
            bzw. lassen Sie sich am Gepäckschalter Banderolen für Ihre Aufträge ausdrucken und geben dann
            die damit
            gekennzeichneten Gepäckstücke ab.<br>
            Wir bringen Ihr Gepäck von <b>{{this.gepaeckBeleg.gpblg_dummy_abgang_frachtort.fort_name}}</b>
            zur
            angegebenen <b>Inseladresse</b>.<br>
            Vielen Dank für Ihre Unterstützung.<br>
            <span
              *ngIf="gepaeckBeleg?.gpblg_bezahlt_kz?.toString() === '0'"><br>Bitte den Betrag vor dem Transport bezahlen.</span><br>
          </div>
          <div class="card-text" *ngIf="targetKey==='10477'">
            <b>Anreise</b>: Geben bitte Sie Ihr mit Ihren lesbaren Banderolen gekennzeichnetes Gepäck bei
            unserer
            Gepäckannahme im {{this.gepaeckBeleg.gpblg_dummy_abgang_frachtort.fort_name}} ab.
            Die Leistung beinhaltet die reine Gepäckzustellung auf der Insel (<b>ohne
            Fährüberfahrt!</b>).<br>
            Wir bringen Ihr Gepäck von <b>{{this.gepaeckBeleg.gpblg_dummy_abgang_frachtort.fort_name}}</b>
            zur
            angegebenen <b>Inseladresse</b>.<br>
            Vielen Dank für Ihren Unterstützung.<br>
            <span
              *ngIf="gepaeckBeleg?.gpblg_bezahlt_kz?.toString() ==='0'"><br>Bitte den Betrag vor dem Transport bezahlen.</span><br>
          </div>
        </div>
      </div>
      <div class="clr-col"
           *ngIf="!!this.gepaeckBeleg.gpblg_external_order_id || (this.gepaeckBeleg.gpblg_storniert_yn ?? 0) === 1">
        Infos:
        <span
          *ngIf="!!this.gepaeckBeleg.gpblg_external_order_id">Übernommen aus Ticket <b>{{this.gepaeckBeleg.gpblg_external_order_id}}</b>.&nbsp;</span>
        <span *ngIf="(this.gepaeckBeleg.gpblg_storniert_yn ?? 0) === 1">
          <span class="sits-color-alert"><b>STORNIERT</b></span>&nbsp;
          {{this.gepaeckBeleg.gpblg_storniert_stamp | date: 'EE dd.MM.YYYY HH:mm'}}.&nbsp;
        </span>
      </div>

      <div class="clr-col">
        <dx-form #frachtFormShow [(formData)]="gepaeckBeleg"
                 labelLocation="top"
                 [scrollingEnabled]="true"
                 [showValidationSummary]="true"
                 colCount="2"
                 [minColWidth]="500"
                 width="auto"
                 [showColonAfterLabel]="false">
          <dxi-item itemType="group" colCount="2" colSpan="2">
            <dxi-item itemType="group" colCount="1">
              <div id="barcodecontainer">
                <div class="sits-textalign-center">
                  <span *ngIf="isAnreise" class="sits-kachel-text-xxxl">Gepäck-Zustellung</span>
                  <span *ngIf="!isAnreise" class="sits-kachel-text-xxxl">Gepäck-Abholung</span>
                  <br>
                  <qrcode *ngIf="((this.gepaeckBeleg.gpblg_storniert_yn ?? 0) === 0) && targetKey === '10477' "
                          [qrdata]="getGepaeckIdPrefixed()" [width]="128"
                          [errorCorrectionLevel]="'M'"></qrcode>
                  <span *ngIf="(this.gepaeckBeleg.gpblg_storniert_yn ?? 0) === 1">
                    <span class="sits-color-alert"><b>STORNIERT</b><br></span>
                  </span>
                  <ngx-barcode6 *ngIf="targetKey==='10426'"
                                [bc-format]="'CODE128'"
                                [bc-value]=getGepaeckIdPrefixed()
                                [bc-display-value]="true"
                  ></ngx-barcode6>
                  <span class="sits-kachel-text-xs"
                        *ngIf="targetKey!=='10426'">{{getGepaeckIdPrefixed()}}</span>
                </div>

                <div id="container">
                  <div id="containertext"
                       *ngIf="!!gepaeckBeleg.gptrr_container_text && gepaeckBeleg.gptrr_container_text.valueOf() !== ''">
                    {{gepaeckBeleg.gptrr_container_text}}
                  </div>
                  <div id="containerlabel"
                       *ngIf="!!gepaeckBeleg.gptrr_container_text && gepaeckBeleg.gptrr_container_text.valueOf() !== ''">
                    Container-Nr.
                  </div>
                  <div class="card-text sits-containerhint"
                       *ngIf="(gepaeckBeleg.gptrr_container_text === undefined || gepaeckBeleg.gptrr_container_text === null)
                      && isAnreise !== undefined && !isAnreise
                      && targetKey==='99910426'">
                    Hier sehen Sie nach dem Transport die Container-Nummer (Link erneut aufrufen)
                  </div>
                </div>
              </div>

            </dxi-item>

            <dxi-item itemType="group" colCount="1">
              <dxi-item itemType="group"
                        [visible]="(!!this.gepaeckBanderoleUrl && ((this.gepaeckBeleg.gpblg_storniert_yn ?? 0) === 0))">
                <span>Link zu ...  &nbsp;</span>
                <span><a href="{{gepaeckBanderoleUrl}}"
                         target="_blank"><b>Gepäckbanderolen</b></a></span>
              </dxi-item>

              <dxi-item dataField="gpblg_dummy_datum"
                        type="date"
                        editorType="dxDateBox"
                        [editorOptions]="{
                    width: '100%',
                    type: 'date',
                    readOnly: true,
                    useMaskBehavior: 'true',
                    displayFormat: 'EE. dd.MM.yyyy',
                    hint: 'Datum an dem der Gepäcktransport erfolgen soll. Es wird für die Auswahl der Fähre benötigt. Bis heute 16:00 Uhr können Sie Gepäck für morgen aufgeben, danach ab übermorgen.',
                    dateSerializationFormat: 'yyyy-MM-dd'}">
                <dxo-label
                  text="Datum"
                  horizontalAlignment="left">
                </dxo-label>
              </dxi-item>

              <dxi-item editorType="dxSelectBox"
                        [editorOptions]="{items: adresseDddwGepaeck,
                searchEnabled: 'true',
                  valueExpr: 'adr_id',
                  displayExpr: 'adr_gepaeck_name',
                  placeholder: 'min. 3 Zeichen ...',
                  readOnly: true}"
                        dataField="adr_id">
                <dxo-label
                  text="Insel-Adresse (Straße HausNr. / Hotel)"
                  horizontalAlignment="left">
                </dxo-label>
              </dxi-item>
            </dxi-item>

          </dxi-item>


          <dxi-item dataField="gpblg_dummy_abgang_frachtort.fort_name"
                    editorType="dxTextBox"
                    [editorOptions]="{readOnly: true }">
            <dxo-label
              text="Abgangspunkt (ab ...)"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="fp_id"
                    editorType="dxSelectBox"
                    [editorOptions]="{items: fahrplans,
                    dropDownOptions: {showTitle: false, hideOnOutsideClick: true, hideRightArrow: true},
                    valueExpr: 'fp_id',
                    searchPlaceholder: 'Abfahrtzeit ...',
                    displayExpr: getDisplayAbfahrtzeitVoll,
                    readOnly: true}">
            <dxo-label
              text="{{getFahrplanLabelText()}}"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item
            [editorOptions]="{ hint: 'Anzahl Gepäckstücke',
                    readOnly: true}"
            dataField="gpblg_anz"
            editorType="dxNumberBox">
            <dxo-label
              text="Gepäckstücke"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item
            dataField="gpblg_brutto_amount"
            editorType="dxNumberBox"
            [editorOptions]="{format: ' ##0.00 EUR',
            readOnly: true,
            hint: 'Kosten für den Transport der ausgewählten Anzahl an Gepäckstücken.' }">
            <dxo-label
              text="Gesamtbetrag (inkl. MwSt.)">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gpblg_gepaeckstuecke_json"
                    editorType="dxTagBox"
                    [editorOptions]="{width: '100%',
                    items: gepaeckDescriptions,
                    acceptCustomValue: true,
                    readOnly: true,
                    maxDisplayedTags: 10,
                    disabled: true }"
                    [colSpan]="2">
            <dxo-label
              text="Gepäckstücke Beschreibung"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>


          <dxi-item dataField="gpblg_extern_name"
                    editorType="dxTextBox"
                    [editorOptions]="{readOnly: true }">
            <dxo-label
              text="Mein Name"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gpblg_extern_mobil_tel"
                    editorType="dxTextBox"
                    [editorOptions]="{readOnly: true }">
            <dxo-label
              text="Meine Mobiltelefon-Nr."
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item [editorOptions]="{
                  readOnly: true,
                  hint: 'Optional, dringend empfohlen. Erlaubt die Übermittlung ausführlicherer Informationen ergänzend zur SMS-Benachrichtigung.'}"
                    dataField="gpblg_extern_email"
                    editorType="dxTextBox">
            <dxo-label
              text="Meine E-Mail-Adresse"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gpblg_bemerk_text"
                    editorType="dxTextArea"
                    [editorOptions]="{width: '100%', height: 'auto',
                  readOnly: true}"
          >
            <dxo-label
              text="Bemerkung"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item itemType="group">
            <span>Bedingungen für den Gepäcktransport &nbsp;</span>
            <span
              [ngClass]="{'badge-success': getTransportBedingungenStatus(), 'badge-danger': !getTransportBedingungenStatus()}"
              class="badge badge-pill"
            >{{getTransportBedingungenStatus() ? 'zugestimmt' : 'NICHT zugestimmt'}}</span><br>
            <span class="sits-kachel-text-sm"><a href="{{frontendGepaeckTransportBedingungenUrl}}"
                                                 target="_blank">Zu unseren Gepäcktransportbedingungen
              ...</a></span>
          </dxi-item>

          <dxi-item itemType="empty" [visible]="!isAuthenticated()">
          </dxi-item>
          <dxi-item editorType="dxSelectBox"
                    [visible]="isAuthenticated()"
                    [editorOptions]="{items: adresseDddwAll,
                searchEnabled: 'true',
                  valueExpr: 'adr_id',
                  displayExpr: 'adr_such_name',
                  placeholder: 'min. 3 Zeichen ...',
                  readOnly: true}"
                    dataField="gpblg_rgempf_adr_id">
            <dxo-label
              text="Beauftragende, Kd.Nr. {{gepaeckBeleg.gpblg_rgempf_adr_id}}"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item itemType="group">
            <span>Transportkosten bezahlt? &nbsp;</span>
            <span [ngClass]="{'badge-success': getBezahltStatus(), 'badge-danger': !getBezahltStatus()}"
                  class="badge badge-pill"
            >{{this.gepaeckBeleg?.gpblg_bezahlt_kz?.toString() === '1'
              ? 'Ja, ist bezahlt'
              : this.gepaeckBeleg?.gpblg_bezahlt_kz?.toString() === '2'
                ? 'Bezahlung per Rechnung'
                : 'Noch nicht bzw. Betrag ist noch nicht angekommen / gebucht.'}}</span><br>
            <span *ngIf="this.gepaeckBeleg?.gpblg_bezahlt_kz?.toString() === '0'"
                  class="sits-kachel-text-sm">
              <br>Um den Auftrag zu aktivieren, überweisen Sie bitte <b>{{this.gepaeckBeleg.gpblg_brutto_amount | currency:'EUR'}}</b><br>
              (bei kurzfristiger Buchung bitte möglichst als Sofortüberweisung)<br>
              auf unser Konto <b>{{this.bankVerbindungGepaeck}}</b>,<br>
              oder zahlen Sie einfach per
              <b><a href="{{this.payPalMeUrl}}/{{this.gepaeckBeleg.gpblg_brutto_amount}}"
                    target="_blank">PayPal {{this.payPalMeUrl}}/{{this.gepaeckBeleg.gpblg_brutto_amount}}EUR</a></b><br>
              Verwendungszweck: <b>GEP-{{this.gepaeckBeleg.gpblg_id}}
              TAN-{{this.gepaeckBeleg.gpblg_extern_mobiletan}}</b></span>
            <span *ngIf="this.gepaeckBeleg?.gpblg_bezahlt_kz?.toString() === '0'"
                  class="sits-kachel-text-sm">
              Konto {{this.bankVerbindungGepaeck}}
              <span *ngIf="this.payPalMeUrl?.length > 0">
              <br>oder {{this.payPalMeUrl}}</span>
            </span>
          </dxi-item>
          <!--<dxi-item itemType="empty" [colSpan]="2">&nbsp;</dxi-item>-->
        </dx-form>
      </div>
    </div>
    <div class="clr-col">
      <app-imprint></app-imprint>
    </div>
  </div>
</div>

<div class="clr-col" *ngIf="targetKey==='10479'">
  <div class="sits-db-ticket-270" *ngIf="targetKey==='10477'">
    <div class="sits-db-box-hell" style="min-width: 120px;">
      &nbsp;
    </div>
    <div class="sits-db-box-content" style="min-width: 340px;">
      <ngx-barcode6
        [bc-format]="'CODE128'"
        [bc-value]=getGepaeckIdPrefixed()
        [bc-display-value]="true"
      ></ngx-barcode6>
      Inseladresse ...<br>
      <dx-select-box
        [dataSource]="adresseDddwAll"
        valueExpr="adr_id"
        displayExpr="adr_such_name"
        readOnly="true"
        [showDropDownButton]="false"
        [value]=gepaeckBeleg.adr_id
      ></dx-select-box>

    </div>
    <div class="sits-db-box-dunkel" style="min-width: 120px;">
      &nbsp;
    </div>
    <div class="sits-db-box-content" style="min-width: 340px;">
      <img src="assets/images/logo_frei.png" alt="Logo"/><br>
      <span class="sits-kachel-text-xl sits-textalign-center sits-text-bold sits-padding-none sits-margin-none">Gepäck-Zustellung</span>
      <span class="sits-textalign-right sits-padding-none sits-margin-none"><ngx-barcode6
        [bc-format]="'CODE128'"
        [bc-value]=getGepaeckIdPrefixed()
        [bc-display-value]="true"
      ></ngx-barcode6></span>
      {{getDisplayAbfahrtzeitVoll(gepaeckBeleg)}}

    </div>
    <div class="sits-db-box-hell" style="min-width: 120px;">
    </div>

  </div>
</div>

