export class SmsPhoneFormat {
  success?: boolean;
  national?: string;
  international?: string;
  international_formatted?: string;
  country_name?: string;
  country_code?: string;
  country_iso?: string;
  carrier?: string;
  network_type?: string;
}
