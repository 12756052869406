import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  DxAccordionModule, DxBoxModule,
  DxButtonModule,
  DxCalendarModule,
  DxCheckBoxModule,
  DxDataGridModule, DxDateBoxModule,
  DxFormModule,
  DxLookupModule, DxPopoverModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxSelectBoxModule, DxTextAreaModule, DxTextBoxModule,
  DxToolbarModule, DxTooltipModule, DxValidationSummaryModule, DxValidatorModule
} from 'devextreme-angular';
import {ChangepasswordComponent} from './changepassword/changepassword.component';
import {LoginComponent} from './login.component';
import {PasswordforgotComponent} from './passwordforgot/passwordforgot.component';
import {LogoutComponent} from './logout/logout.component';
import {RenewforgottenpasswordComponent} from './renewforgottenpassword/renewforgottenpassword.component';
import {RouterModule} from '@angular/router';
import {DxiFieldModule} from 'devextreme-angular/ui/nested';
import {MatCardModule} from '@angular/material/card';
import { RegistrationformComponent } from './registrationform/registrationform.component';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    ChangepasswordComponent,
    PasswordforgotComponent,
    RenewforgottenpasswordComponent,
    RegistrationformComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        DxAccordionModule,
        DxButtonModule,
        DxCalendarModule,
        DxCheckBoxModule,
        DxDateBoxModule,
        DxDataGridModule,
        DxiFieldModule,
        DxFormModule,
        DxLookupModule,
        DxPopupModule,
        DxPopoverModule,
        DxRadioGroupModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxToolbarModule,
        DxTooltipModule,
        DxValidatorModule,
        DxValidationSummaryModule,
        MatCardModule,
        DxBoxModule,
        DxTextAreaModule,
    ],
  exports: [
    LoginComponent,
    LogoutComponent,
    ChangepasswordComponent,
    PasswordforgotComponent,
    RenewforgottenpasswordComponent
  ]
})
export class LoginModule { }
