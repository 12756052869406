export class SmsMessageResponse {
  success?: boolean;
  returnCode ?: number;
  Verbucht?: number;
  Preis?: number;
  Guthaben?: number;
  Text?: string;
  SMS_Typ?: string;
  Flash_SMS?: string;
  Encoding?: string;
  GSM0338?: boolean;
  Debug?: boolean;
  mobileTan?: string;
}
