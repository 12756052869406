import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Injectable} from '@angular/core';
import {AlertService} from '../../shared-services/alert.service';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import notify from 'devextreme/ui/notify';

@Injectable()

export class HttpErrorInterceptor implements HttpInterceptor {
  // https://stackoverflow.com/questions/53146071/how-i-can-display-a-matsnackbar-from-a-service-with-angular-material
   constructor(private snackBar: MatSnackBar,
               private alertService: AlertService,
               private authenticationService: AuthenticationService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('HttpErrorInterceptor');
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        let noteMessage: string;
        // console.log('HttpErrorInterceptor error', error );
        if (error.error && error.error instanceof ErrorEvent) {
          errorMessage = `Client|unbekannt|${error.error.message}`;
        } /*else if (error.error && error.error.length > 0) {
            console.log('HttpErrorInterceptor error.error', error.error );
            errorMessage = error.error[0].error;
            this.alertService.error(errorMessage, false, 5000);
            // return error.error;
        }*/ else {
          // server-side error
          // console.log('HttpErrorInterceptor error switcher', error );
          switch (true) {
            case error.status < 300:
              this.snackBar.dismiss();
              this.alertService.clear();
              break;
            case error.status === 400:
              if (!!error.error && error.error.count  ) {
                noteMessage = error.error[0];
              } else {
                noteMessage = 'HTTP Error 400 Bad request / Ungültige Anfrage'
              }
              notify({
                message: noteMessage,
                position: {
                  my: 'center',
                  at: 'center'
                }
              }, 'error', 5000);
              break;

            case error.status === 401:
              this.authenticationService.logout();
              errorMessage = '401 Fehlende Authentifizierung/Berechtigung. Ggf. neu anmelden.';
              this.alertService.error(errorMessage, false, 4000);
              return;

            case error.status === 404:
              noteMessage = 'Code: 404 Not found / Ungültige Anfrage an den Server'
              notify({
                message: noteMessage,
                position: {
                  my: 'center',
                  at: 'center'
                }
              }, 'error', 5000);
              return;

            case error.status === 500:
              errorMessage = '500 Serverseitiger Fehler.';
              this.alertService.error(errorMessage, false, 3000);
              return;

            default:
              if (error.statusText && error.statusText !== '' ) {
                console.log('HttpErrorInterceptor default:', `Code: ${error.status}: ${error.statusText}`);
                errorMessage = `Code: ${error.status}: ${error.statusText}`;
              }
          }

          try {
            if (error.error && error.error.errors) {
              const validationErrorDictionary = JSON.parse(JSON.stringify(error.error.errors));
              for ( const fieldName in validationErrorDictionary) {
                if (validationErrorDictionary.hasOwnProperty(fieldName)) {
                  errorMessage = `Server|${fieldName}|${validationErrorDictionary[fieldName]}`;
                  this.snackBar.open(fieldName + ': ' + validationErrorDictionary[fieldName], 'X');
                }
              }
            }
          } catch {
          } finally {
            if (errorMessage.length < 1) {
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            this.alertService.error(errorMessage, false, 5000);
          }
        }
        return throwError(errorMessage);
      })
    );
  }
}
