export enum GepaeckProcessStatus {
  storniert = 99,
  einlagern = 50,
  eingelagert = 51,
  umladen = 60,
  umgeladen = 61,
  abholen = 80,
  abgeholt = 81,
  zustellen = 90,
  zugestellt = 91,
}
