import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertService} from './alert.service';
import {AuthenticationService} from './authentication.service';
import {ConfigurationService} from './configuration.service';
import {NotificationService} from './notification.service';
import {UnitService} from './unit.service';
import {UserService} from './user.service';

export {AlertService} from './alert.service';
export {AuthenticationService} from './authentication.service';
export {ConfigurationService} from './configuration.service';
export {UserService} from './user.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    AlertService,
    AuthenticationService,
    ConfigurationService,
    NotificationService,
    { provide: 'Window', useValue: window },
    UnitService,
    UserService
  ],
  declarations: []
})
export class SharedServicesModule { }
