import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiInformationService } from './api-information.service';

import {AccountingService} from './accounting.service';
import {AdresseFilter, AdresseService} from './adresse.service';
import {ArtikelService} from './artikel.service';
import {FahrplanService} from './fahrplan.service';
import {FrachtPositionService} from './fracht-position.service';
import {FrachtErfassungsStatusService} from './fracht-erfassungs-status.service';
import {FrachtOrtService} from './fracht-ort.service';
import {GepaeckBelegService} from './gepaeck-beleg.service';
import {GeraetService} from './geraet.service';
import {MitarbeiterService} from './mitarbeiter.service';
import {LockCategory} from './lock.service';
import {LockService} from './lock.service';
import {PaymentService} from './payment.service';
import {ParitaetService} from './paritaet.service';
import {SchedulerService} from './scheduler.service';
import {StreckeSettingService} from './strecke-setting.service';
import {TarifService} from './tarif.service';
import {TaskService} from './task.service';
import {TransportStatusService} from './transport-status.service';
import {VerpackungService} from './verpackung.service';
import {UserDataService} from './user-data.service';
import {ApiBasicsModule} from '../api-basics/api-basics.module';
import {ApiService} from '../api-basics/api.service';
import {SmsService} from './sms.service';
import {paymentTypesEnum} from './paymenttypes';

export {ApiInformationService} from './api-information.service';
export {ApiBasicsModule} from '../api-basics/api-basics.module';

export {ZahlungStatus} from './accounting.service';
export {AccountingService} from './accounting.service';
export {AdresseFilter, AdresseService} from './adresse.service';
export {ArtikelService} from './artikel.service';
export {FahrplanService} from './fahrplan.service';
export {FrachtPositionService} from './fracht-position.service';
export {FrachtErfassungsStatusService} from './fracht-erfassungs-status.service';
export {FrachtOrtService} from './fracht-ort.service';
export {GepaeckBelegService} from './gepaeck-beleg.service';
export {GeraetService} from './geraet.service';
export {LockCategory} from './lock.service';
export {LockService} from './lock.service';
export {MitarbeiterService} from './mitarbeiter.service';
export {paymentTypesEnum} from './paymenttypes';
export {PaymentService} from './payment.service';
export {ParitaetService} from './paritaet.service';
export {SchedulerService} from './scheduler.service';
export {StreckeSettingService} from './strecke-setting.service';
export {TarifService} from './tarif.service';
export {TaskService} from './task.service';
export {TransportStatusService} from './transport-status.service';
export {VerpackungService} from './verpackung.service';
export {UserDataService} from './user-data.service';
export {SmsService} from './sms.service';


@NgModule({
  imports: [
    CommonModule,
    ApiBasicsModule
  ],
  providers: [
    ApiInformationService,
    ApiService,
    AccountingService,
    AdresseService,
    AdresseFilter,
    ArtikelService,
    FahrplanService,
    FrachtErfassungsStatusService,
    FrachtOrtService,
    FrachtPositionService,
    GepaeckBelegService,
    GeraetService,
    LockService,
    MitarbeiterService,
    ParitaetService,
    PaymentService,
    SmsService,
    SchedulerService,
    StreckeSettingService,
    TarifService,
    TaskService,
    TransportStatusService,
    UserDataService
  ]
})
export class ApiServicesModule { }
