import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings/settings.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ButtonsModule} from 'ngx-bootstrap/buttons';
import { PopoverModule} from 'ngx-bootstrap/popover';
import { TooltipModule} from 'ngx-bootstrap/tooltip';
import {AboutComponent} from './about/about.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import {MaterialModule} from '../material/material.module';
import {DevExpressModule} from '../shared/devexpress/dev-express.module';
import {ClrAccordionModule} from "@clr/angular";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedComponentsModule,
        ClrAccordionModule,
        PopoverModule.forRoot(),
        ButtonsModule.forRoot(),
        TooltipModule.forRoot(),
        DevExpressModule,
    ],
  declarations: [
    SettingsComponent,
    AboutComponent,
    DatenschutzComponent
  ]
})
export class ConfigurationModule {}
