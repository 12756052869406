import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ApiService} from './api.service';

export { DataElement } from './data-element';
export { BasicDataElement } from './basic-data-element';
export {TransportTypes} from './transportTypes';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    ApiService,
  ]
})
export class ApiBasicsModule { }
