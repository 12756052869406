import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared-services/authentication.service';
import {Location} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  public isAuthenticated: boolean;
  private history: string[] = [];
  private subscribeAuth: Subscription;
  public componentTitle: string = 'Abmelden / Logout' ;

  constructor(
    private router: Router,
    private _authenticationService: AuthenticationService,
    private readonly location: Location) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });

    this.subscribeAuth = this._authenticationService.authenticated.subscribe( item => {
      this.isAuthenticated = item.valueOf();
      // this.refreshList();
    });
  }

  public ngOnInit() {
  }

  public goBack(): void {
    if (this.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl("/").then();
    }
  }

  public logout(): void {
    this._authenticationService.logout();

    // to be moved to a better place ...
    localStorage.removeItem('gepaeck-abholen.containerNo');

    if (!this._authenticationService.authenticated) {
      this.router.navigate(['login']).then();
    }
  }

}
