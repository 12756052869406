
<div class="row">
  <div class="col-md-8 col-md-offset-2 col-xs-12">
    <h1>Under Construction / Wartungsarbeiten</h1>
  </div>
</div>
<div class="row">
  <div class="col-md-8 col-md-offset-2 col-xs-12 text-right">
    <button class="btn btn-primary" (click)="goBack()"><span class="glyphicons glyphicons-chevron-left icon-with-text-right"></span><span>Back</span></button>
  </div>
</div>
