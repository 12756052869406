import { UnitMeta } from './unit-meta';

export class Unit {
    constructor(
        public readonly code: string,
        public readonly name: string,
        public readonly meta: UnitMeta
    ) {
    }
}
