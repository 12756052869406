import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IFahrplan} from '../../api/api-dtos/fahrplan';
import {IFrachtOrt} from '../../api/api-dtos/fracht-ort';
import {FrachtOrtService} from '../../api/api-services/fracht-ort.service';
import {FahrplanService} from '../../api/api-services/fahrplan.service';
import {Geraet} from '../../api/api-dtos/geraet';
import {GeraetService} from '../../api/api-services/geraet.service';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {User} from '../../api/api-dtos/user';
import {NavigationEnd, Router} from '@angular/router';
import {DxDataGridComponent, DxTooltipComponent} from 'devextreme-angular';
import {on} from 'devextreme/events';
import {Location} from '@angular/common';
import {timer} from 'rxjs/internal/observable/timer';
import {StreckeSettingService} from '../../api/api-services/strecke-setting.service';
import {environment} from '../../../environments/environment';
import {ColumnButtonClickEvent} from 'devextreme/ui/data_grid';
import {TransportTypes} from '../../api/api-basics/transportTypes';
import {LocalCompilationExtraImportsTracker} from '@angular/compiler-cli/src/ngtsc/imports';

@Component({
  selector: 'app-fahrplan-list',
  templateUrl: 'fahrplan-list.component.html',
  styleUrls: ['fahrplan-list.component.css']
})
export class FahrplanListComponent implements OnInit {
  protected today = new Date(new Date().toDateString());
  @ViewChild(DxTooltipComponent) tooltip: DxTooltipComponent;
  @ViewChild('grid', {static: false}) grid: DxDataGridComponent;
  @Input() filterByDate: Date = this.today;
  private isLoadingResults: boolean;
  popoverVisible = false;
  private history: string[] = [];
  componentTitle: string;
  fahrplans: IFahrplan[] = [];
  frachtOrts: IFrachtOrt[];
  faehres: Geraet[];
  gridPageSize = 15;
  public sitsRowButtonIconClass: string = 'sits-row-button-icon';
  public currentUser = new User;
  public currentFahrPlan: IFahrplan = null;
  private counter = timer(0, 60000);
  public currentFilter: any = "auto";

  constructor(private fahrplanService: FahrplanService,
              private frachtOrtService: FrachtOrtService,
              private geraetService: GeraetService,
              private streckeSettingService: StreckeSettingService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private readonly location: Location
  ) {
    if (!!localStorage.getItem('fahrplan-list.grid.paging.pageSize')) {
      this.gridPageSize = +localStorage.getItem('fahrplan-list.grid.paging.pageSize');
      if (this.gridPageSize < 5) {
        this.gridPageSize = 5;
      } else if (this.gridPageSize > 300) {
        this.gridPageSize = 300;
      }
    }
    this.componentTitle = 'Fahrplan ab ';
    this.getFrachtOrts = this.getFrachtOrts.bind(this);
    this.getFaehres = this.getFaehres.bind(this);
    this.createGepaeckAuftrag = this.createGepaeckAuftrag.bind(this);
    this.cmdNavigateGepaeckAuftragCustList = this.cmdNavigateGepaeckAuftragCustList.bind(this);
    this.isCreateGepaeckAuftragButtonEnabled = this.isCreateGepaeckAuftragButtonEnabled.bind(this);
    this.isCreateGepaeckAuftragButtonDisabled = this.isCreateGepaeckAuftragButtonDisabled.bind(this);
    this.isVisibleTransportArtForCustomer = this.isVisibleTransportArtForCustomer.bind(this);
    this.isVisibleNavigateGepaeckAuftragCustListButton = this.isVisibleNavigateGepaeckAuftragCustListButton.bind(this);
    this.goBack = this.goBack.bind(this);
    this.getIsEmployee = this.getIsEmployee.bind(this);

    this.currentUser = this.authenticationService.currentUser;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  getFaehres(options: any) {
    return {
      store: this.faehres,
      filter: options.data ? ['ger_nr', '=', options.data.ger_nr] : null
    };
  }

  getFrachtOrts(options: any) {
    return {
      store: this.frachtOrts,
      filter: options.data ? ['fort_id', '=', options.data.fort_id] : null
    };
  }

  async ngOnInit() {
    this.counter.subscribe(() => {
      this.timerEvent();
    });
    setTimeout(() => {
      this.isLoadingResults = false;
    }, 10000);
    this.frachtOrts = [];
    this.frachtOrtService.getAllFaehre().subscribe(data => {
      this.frachtOrts = data === undefined || data === null
        ? []
        : data;
    });
    this.faehres = [];
    this.faehres = await this.geraetService.getFaehreDddw(true);
    await this.refreshFahrplanList();
  }

  async refreshFahrplanList() {
    if (this.filterByDate === null ) {
      this.filterByDate = this.today
    }
    let pastDays: number = 0;
    let futureDays: number = 180;

    this.fahrplanService.getAll(TransportTypes.Faehre, this.filterByDate, pastDays, futureDays).subscribe(resfp => {
      if (resfp === undefined || resfp == null || resfp.length === 0) {
        this.fahrplans = [{} as IFahrplan];
      } else {
        this.fahrplans = resfp;
      }
    });
  }

  optionChangedHandler(event: any) {
    if (event.fullName === 'paging.pageSize') {
      if (event.value >= 5) {
        localStorage.setItem('fahrplan-list.grid.paging.pageSize', event.value);
      }
    }
  }

  onCellPrepared(e: any): void {
    if (e.rowType === 'data') {
      switch (e.column.dataField) {
        case 'fp_abfahrt_datzeit':
          on(e.cellElement, 'mouseover', (arg: any) => {
            this.currentFahrPlan = e.data;
            // Tooltip nur bei Relevanz anzeigen ...
            if (!!this.currentFahrPlan && (this.currentFahrPlan?.fp_abfahrtzeitgeaendert_yn ?? 1) === 0
              && (this.currentFahrPlan?.fp_faelltaus_yn ?? 1) === 0
              && ((this.currentFahrPlan?.svstrs_gepaeck_yn ?? 0) === 1 && (this.currentFahrPlan?.fp_gepaeckservice_yn ?? 1) === 1
                 || (this.currentFahrPlan?.svstrs_gepaeck_yn ?? 0) === 0 ))
            {
              this.tooltip.instance.hide().then();
            } else {
              this.tooltip.instance.show(arg.target).then();
            }
          });

          on(e.cellElement, 'mouseout', () => {
            this.tooltip.instance.hide().then();
          });
          break;
        case 'gart_name':
          // e.cellElement.style.backgroundColor = e.data.ger_gart_kz === 'FSCHIFF' ? 'lightgreen' : 'default';
          break;
      }
    }
  }

  public isVisibleTransportArtForCustomer(): boolean {
    switch (environment.targetKey) {
      case '10477':
        return false;
      default:
        return true;
    }
  }

  public isVisibleForSpecialUsers(): boolean {
    return this.authenticationService
      ? (this.authenticationService.isAuthenticated
        && (this.authenticationService.currentUser.isEmployee === 1
          || this.authenticationService.currentUser.isCustomer === 1))
      : false;
  }

  public isGepaeckManager(): boolean {
    return this.authenticationService
      ? (this.authenticationService.isAuthenticated
        && (this.authenticationService.currentUser.isEmployee === 1
          && !this.authenticationService.currentUser.userName.includes('nsb')))
      : false;
  }

  public isFrachtManager(): boolean {
    return this.authenticationService
      ? (this.authenticationService.isAuthenticated
        && (this.authenticationService.currentUser.isEmployee === 1
          || this.authenticationService.currentUser.isCustomer === 1)
          && '10426,'.includes(environment.targetKey))
      : false;
  }

  public calcTransportArtCellValue(data: any) {
    if (data.fp_faelltaus_yn === 1) {
      return 'CANCEL';
    } else if (data.ger_gart_kz === 'PSCHIFF' && data.fp_frachttransport_yn === 1) {
      return 'PF';
    } else if (data.ger_gart_kz === 'FSCHIFF' || data.fp_frachttransport_yn === 1) {
      return 'F';
    }
    return 'P';
  }

  public calcAbfahrtzeitGeaendertStyleClass(data: any): string {
    if (!!data && data.fp_faelltaus_yn === 1) {
      return 'scheduleDiscarded';
    } else if (!!data && data.fp_abfahrtzeitgeaendert_yn === 1) {
      return 'scheduleChanged';
    } else {
      return 'solassen';
    }
  }

  /**
   * Gepaeckauftrag Button sichtbar und aktiviert schalten
   * Momentan NOCH nicht fuer registrierte User (Mitarbeiter, Kunden, ...)
   * @param e
   */
  public isCreateGepaeckAuftragButtonEnabled(e: any) {
    return (!!e.row.data && e.row.data.ger_gart_kz.indexOf('SCHIFF') >= 0
      && e.row.data.svstrs_gepaeck_yn === 1
      && (e.row.data.fp_gepaeckservice_yn ?? 1) === 1 &&
      new Date(e.row.data.fp_abfahrt_datzeit)?.getHours() * 60
      + new Date(e.row.data.fp_abfahrt_datzeit)?.getMinutes() >= this.streckeSettingService.getGepaeckFirstOrderLimitMinutesOfDay()
      && new Date(e.row.data.fp_abfahrt_datzeit)?.getHours() * 60
      + new Date(e.row.data.fp_abfahrt_datzeit)?.getMinutes() <= this.streckeSettingService.getGepaeckLastOrderLimitMinutesOfDay()
      && this.streckeSettingService.getMinimalStartDateByPattern(e.row.data.svstrs_gepaeckbuchungsfristregel_code) <= new Date(e.row.data.fp_abfahrt_datzeit)
      && e.row.data.fp_faelltaus_yn === 0);
  };

  /**
   * Gepaeckauftrag Button sichtbar aber inaktiviert schalten
   * Momentan NOCH nicht fuer registrierte User (Mitarbeiter, Kunden, ...)
   * @param e
   */
  public isCreateGepaeckAuftragButtonDisabled(e: any) {
    // if (!environment.production) console.log("isCreateGepaeckAuftragButtonDisabled", e.row.data.fp_gepaeckservice_yn, e.row.data.ger_name);
    // nur Abfahrten bis 19:00 Uhr zulassen, oder so ...
    return (!!e.row.data && e.row.data.ger_gart_kz.indexOf('SCHIFF') >= 0
      && e.row.data.svstrs_gepaeck_yn === 1
      && (e.row.data.fp_gepaeckservice_yn ?? 1) === 1 &&
      !(new Date(e.row.data.fp_abfahrt_datzeit)?.getHours() * 60
        + new Date(e.row.data.fp_abfahrt_datzeit)?.getMinutes() >= this.streckeSettingService.getGepaeckFirstOrderLimitMinutesOfDay()
        && new Date(e.row.data.fp_abfahrt_datzeit)?.getHours() * 60
        + new Date(e.row.data.fp_abfahrt_datzeit)?.getMinutes() <= this.streckeSettingService.getGepaeckLastOrderLimitMinutesOfDay()
        && this.streckeSettingService.getMinimalStartDateByPattern(e.row.data.svstrs_gepaeckbuchungsfristregel_code) <= new Date(e.row.data.fp_abfahrt_datzeit)
        && e.row.data.fp_faelltaus_yn === 0));
  };

  /**
   * Durchstieg zum Gepaeckauftrag
   * @param e
   */
  createGepaeckAuftrag(e: any) {
    const fahrplanData: IFahrplan = {fp_id: e.row.data.id, fp_typeOfTransport: e.row.data.fp_typeOfTransport};
    if (this.currentUser.isEmployee === 1 || this.currentUser.isCustomer === 1) {
      // todo: für registrierte User den anderen GEP-Auftrag starten!
      this['router'].navigate(['gepaeckauftrag-cust/edit', fahrplanData]).then();
      // return;
    } else {
      // fuer nicht angemeldete Benutzer (Gaeste)
      this['router'].navigate(['gepaeckauftrag/edit', fahrplanData]).then();
    }
  };

  isVisibleNavigateGepaeckAuftragCustListButton(e: any) :boolean{
    // if (!environment.production) console.log('isVisibleNavigateGepaeckAuftragCustListButton', e.row.data, isNaN(e.row.data.gpblg_anz_sum?.value), isNaN(e.row.data.gpblg_anz_sum));
    if (!(this.authenticationService.isEmployee || this.authenticationService.isCustomer)) {
      return false;
    }
    return e.row.data.gpblg_anz_sum !== null;
  };


  cmdNavigateGepaeckAuftragCustList(e: ColumnButtonClickEvent): void {
    // if (!environment.production) console.log('cmdNavigateGepaeckAuftragCustList', e.row.data);
    if (!(this.authenticationService.isEmployee || this.authenticationService.isCustomer)) {
      return;
    }
    const fahrplanData: IFahrplan = {
      fp_abfahrt_tag: e.row.data.fp_abfahrt_tag,
      fp_abfahrt_fort_id: e.row.data.fp_abfahrt_fort_id,
      fp_id: e.row.data.id
    };
    this['router'].navigate(['gepaeckauftrag-cust/list', fahrplanData] ).then();
  }


  public goBack(): void {
    if (this.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl("/").then();
    }
  }

  popoverToggleDefault() {
    this.popoverVisible = !this.popoverVisible;
  }

  private async timerEvent() {
    if (this.authenticationService.isEmployee) {
      await this.refreshFahrplanList();
    }
  }

  onValueChangedFilterByDate(e: any) {
    this.filterByDate = e?.value ?? null;
    this.refreshFahrplanList().then();
    return;
  }

  public getIsEmployee(): boolean {
    return this.authenticationService.isAuthenticated && this.authenticationService.isEmployee;
  }

  protected readonly environment = environment;

}
