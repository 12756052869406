import {DataElement} from '../api-basics/data-element';

export class Mitarbeiter implements DataElement {
  id?: number;
  mand_id?: number;
  mit_id?: number;
  anr_id?: number;
  mit_name?: string;
  mit_firstname?: string;
  mit_strasse?: string;
  mit_hausnr_von_nra?: string;
  mit_hausnr_bis_nra?: string;
  mit_strasse_plz?: string;
  mit_ort?: string;
  mit_zusatz_ort?: string;
  mit_teil_ort?: string;
  land_id?: number;
  mit_such_name?: string;
  mit_tel?: string;
  mit_fax_tel?: string;
  mit_mobil_tel?: string;
  mit_email?: string;
  mit_internet_url?: string;
  mit_text?: string;
  mit_type_code?: string;
  mit_briefanrede_text?: string;
  mit_aktiv_yn?: number;
  mit_create_stamp?: Date;
  mit_change_stamp?: Date;
}

