import {Component, OnInit, ViewChild} from '@angular/core';
import {DxDataGridComponent} from 'devextreme-angular';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {Op} from '../../../api/api-dtos/op';
import {AccountingService} from '../../../api/api-services/accounting.service';
import {Docstore} from '../../../api/api-dtos/docstore';
import {AdresseDddw} from '../../../api/api-dtos/adresse-dddw';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
// considered hints from: https://roytuts.com/download-file-from-server-using-angular/
export class InvoiceListComponent implements OnInit {
  private isLoadingResults: boolean;
  ops: Op[];
  adresseDddw: AdresseDddw[];
  today = new Date(new Date().toDateString());
  firstDayOfLastYear = new Date(this.today.getFullYear() - 1, 0, 1);
  lastDayOfNextYear = new Date(this.today.getFullYear() + 1, 12, 31);
  componentTitle: string;
  gridPageSize = 15;
  // currentUserName: string = null;
  @ViewChild('grid', {static: false}) grid: DxDataGridComponent;

  constructor(private accountingService: AccountingService,
              private authenticationService: AuthenticationService,
              private adresseService: AdresseService,
              private readonly location: Location) {
    if (!!localStorage.getItem('invoice-list.grid.paging.pageSize')) {
      this.gridPageSize = +localStorage.getItem('invoice-list.grid.paging.pageSize');
      if (this.gridPageSize < 5) {
        this.gridPageSize = 5;
      } else if (this.gridPageSize > 300) {
        this.gridPageSize = 300;
      }
    }
    this.downloadInvoiceDocument = this.downloadInvoiceDocument.bind(this);
    this.showInvoiceDocument = this.showInvoiceDocument.bind(this);
    this.getAdresses = this.getAdresses.bind(this);
  }

  async ngOnInit() {
    this.componentTitle = 'Beleg-Archiv';
    setTimeout(() => {
      this.isLoadingResults = false;
    }, 10000);
    /*[this.adresseDddw as AdresseDddw] = await this.adresseService.getAllDddw();*/
    await this.adresseService.getAllDddw().then( (resAdr: AdresseDddw[]) => {
      this.adresseDddw = resAdr;
    });
    await this.refreshList();
  }

  async refreshList() {
    [this.ops] = await Promise.all([this.accountingService.getAll()]);
  }

  // Versorge Dropdown mit Daten ...
  getAdresses(options: any) {
    return {
      store: this.adresseDddw,
      filter: options.data ? ['adr_id', '=', options.data.adr_id] : null
    };
  }

  getInvoiceDocument(e: any, fileAction: FileAction) {
    const docId: number = e.row?.data?.op_doc_id;
    if (!docId) {
      return;
    }
    const belegArtCode: string = e.row?.data?.op_belegart_code;
    const belegNr: number = e.row?.data?.op_beleg_nr;

    this.accountingService.getArchivedDocumentInfo(belegArtCode, belegNr).then((docRecs: Docstore[]) => {
      this.accountingService.getArchivedDocumentBinary(e.row?.data, docRecs[0]).then((docBinRes: Blob) => {
        // open directly in browser ...
        /*const url = window.URL.createObjectURL(docBinRes);
        window.open(url);*/
        // save as file ...
        switch (fileAction) {
          case FileAction.show:
            const url = window.URL.createObjectURL(docBinRes);
            window.open(url);
            break;
          case FileAction.download:
            // file-saver 2.0.5 causes implicit any issue. therefore ...
            let saveAs = require('file-saver');
            saveAs(docBinRes, docRecs[0].doc_filename);
            break;
        }
      });
    });
  }

  downloadInvoiceDocument(e: any) {
    this.getInvoiceDocument(e, FileAction.download);
  }

  showInvoiceDocument(e: any) {
    this.getInvoiceDocument(e, FileAction.show);
  }

  public goBack(): void {
    this.location.back();
  }

  onExporting($event: any) {
    $event.fileName = 'Belegdaten';
  }


}

enum FileAction {
  download = 'download',
  show = 'show'
}
