import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {IArtikel} from '../api-dtos/artikel';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {lastValueFrom} from 'rxjs/internal/lastValueFrom';

@Injectable({
  providedIn: 'root'
})

export class ArtikelService {
  baseUrl = environment.restApiUrl;
  artikelDddwCache: Observable<IArtikel[]>;
  gepaeckTransport01ArtNrDefault = 0;
  gepaeckTransport01ArtNrCurrent = 0;

  constructor(private http: HttpClient) {
  }

  public getArtikel(art_nr: number): Promise<IArtikel> {
    if (art_nr === undefined || art_nr === null) {
      // return Promise.reject('Invalid article number');
      return Promise.resolve(null);
    }
    return lastValueFrom(this.http
      .get<IArtikel>(`${this.baseUrl}/artikel/${art_nr}`, this.getHttpHeader())
      .pipe(map(data => {
        return data;
      })));
  }

  public getFrachtArtikelDddw(): Observable<IArtikel[]> {
    if (!this.artikelDddwCache) {
      this.artikelDddwCache = this.http
        .get<IArtikel[]>(this.baseUrl + '/frachtartikel/dropdownlist', this.getHttpHeader());
    }
    return this.artikelDddwCache;
  }

  public async getGepaeckTransport01ArtNr() {
    if (this.gepaeckTransport01ArtNrCurrent !== undefined && this.gepaeckTransport01ArtNrCurrent > 0) {
      return this.gepaeckTransport01ArtNrCurrent;
    }
    return await this.getGepaeckTransport01ArtNrFromBackend().then(res => {
      this.gepaeckTransport01ArtNrCurrent = res.gepaeckTransport01ArtNr !== undefined ? res.gepaeckTransport01ArtNr : this.gepaeckTransport01ArtNrDefault;
      return this.gepaeckTransport01ArtNrCurrent;
    });
  }

  public async getGepaeckTransport01Artikel(): Promise<IArtikel> {
    let url = `${this.baseUrl}/artikel/gepaecktransport01artikel`;
    return lastValueFrom(this.http.get<IArtikel>(
      url, this.getHttpHeader()));
  }

  public async getGepaeckTransport01ArtNrFromBackend(): Promise<any> {
    let url = `${this.baseUrl}/artikel/gepaecktransport01artnr`;
    return lastValueFrom(this.http.get<any>(
      url, this.getHttpHeader()));
  }

  public getArtikelByDate(art_nr: number, preisDate: Date): Promise<IArtikel> {
    preisDate = new Date(preisDate?.setHours(0, 0, 0, 0) ?? new Date().setHours(0, 0, 0, 0));
    return this.getArtikel(art_nr).then(art => {
      // todo: fast workaround
      if (art !== undefined && art !== null && (art_nr ?? 0) === 9110) {
        if (preisDate !== undefined && preisDate.getFullYear() > 2024) {
          art.art_preis1 = 134.45;
          art.art_brutto_preis1 = 160;
          return art;
        }
        art.art_preis1 = 126.05;
        art.art_brutto_preis1 = 150;
        return art;
      }
      return art;
    });


    /*return lastValueFrom(this.http
      .get<IArtikel>(`${this.baseUrl}/artikel/${art_nr}&preisDate=${preisDate}`, this.getHttpHeader())
      .pipe(map(data => {
        return data;
      }))) ;*/
  }

  private getHttpHeader() {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }
}
