import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {AlertService} from '../../shared-services/alert.service';
import {Location} from '@angular/common';
import {User} from '../../api/api-dtos/user';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import notify from 'devextreme/ui/notify';
import {SmsService, SmsTanType} from '../../api/api-services/sms.service';
import {DxFormComponent} from 'devextreme-angular';
import {ServerMessage} from '../../api/api-dtos/server-message';
import {GepaeckBelegService} from '../../api/api-services/gepaeck-beleg.service';
import {FrachtBriefService} from '../../api/api-services/fracht-brief.service';

@Component({
  templateUrl: './registrationform.component.html',
  styleUrls: ['./registrationform.component.scss']
})
export class RegistrationformComponent implements OnInit {
  title = 'Registrieren';
  returnUrl: string;
  isProcessing = false;
  isValidOrderData = false;
  private history: string[] = [];
  private isValidPhoneNumber = false;
  public maxCardWidth = 'auto';
  popoverVisible = false;
  submitOrderDisabled = new BehaviorSubject<boolean>(true);
  mobileTanButtonDisabled = new BehaviorSubject<boolean>(true);
  userData: User = new User();
  validatePhoneNumber = new BehaviorSubject<boolean | undefined>(false);
  frontendGepaeckTransportBedingungenUrl: string;
  frontendFrachtTransportBedingungenUrl: string;

  public isMobileTanGenerated = false;
  public isDisableEditing = false;
  public isEditMobileTanDisabled = true;

  buttonCheckDataAndGetApprovalCode: any = {
    text: 'Daten prüfen & mobile TAN anfordern',
    type: 'default',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    hint: 'Sendet nach erfolgreicher Prüfung der Daten eine mobile Transaktionsnummer (TAN) an Ihr mobiles Endgerät, mit der Sie die Registrierung im nächsten Schritt bestätigen und freigeben können.',
    onClick: (e: any) => {
      this.checkDataAndGetTan(e).then(res => {
        return;
      });
    },
  };
  buttonSubmitRegistrationData: any = {
    text: 'Jetzt registrieren',
    type: 'default',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    hint: 'Sendet Ihre Registrierungsdaten an unseren Server. Sie erhalten dann eine E-Mail mit weiteren Informationen.',
    onClick: (e: any) => {
      this.onSubmitOrderData(e);
    },
  };

  @ViewChild(DxFormComponent) registrationForm: DxFormComponent;
  @ViewChild('formcontainer') formContainer: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private gepaeckBelegService: GepaeckBelegService,
    private frachtBriefService: FrachtBriefService,
    private smsService: SmsService,
    private location: Location,
    private alertService: AlertService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });

    this.checkDataAndGetTan = this.checkDataAndGetTan.bind(this);
    this.getIsValidPhoneNumber = this.getIsValidPhoneNumber.bind(this);
    this.getIsValidOrderData = this.getIsValidOrderData.bind(this);
    this.getIsEditMobileTanDisabled = this.getIsEditMobileTanDisabled.bind(this);
    this.getMaxCardWidth = this.getMaxCardWidth.bind(this);
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if (this.authenticationService.authenticated && (this.authenticationService.isEmployee || this.authenticationService.isCustomer)) {
      this.router.navigate(['/']);
    }
    this.frontendGepaeckTransportBedingungenUrl = this.gepaeckBelegService.loadFrontendTransportBedingungenUrl();
    this.frontendFrachtTransportBedingungenUrl = this.frachtBriefService.loadFrontendTransportBedingungenUrl()
  }

  checkComparison() {
    return true;
  }

  form_fieldDataChanged(e: any) {
    // console.log('DIREKT form_fieldDataChanged ', e);
    this.userData = e.component.option('formData');
    if (this.userData === undefined) {
      return;
    }
    switch (e.dataField) {
      case 'mobilePhone':
        if (e.value === null || e.value.toString().trim() === '') {
          this.validatePhoneNumber.next(false);
          break;
        } else {
          if (e.value.substring(0, 4) === '0049') {
            this.userData.mobilePhone = '+49' + e.value.substring(4, 99);
          }
        }
        this.smsService.callPhoneNumberValidator(e.value).then(resSmsPhoneFormat => {
          if (!!resSmsPhoneFormat && resSmsPhoneFormat?.success === true) {
            this.isValidPhoneNumber = true;
            this.userData.mobilePhone = resSmsPhoneFormat.international_formatted;
            this.getIsValidOrderData();
          } else {
            this.isValidPhoneNumber = false;
            this.getIsValidOrderData();
          }
        });
        break;

      case 'rita':
        return;
    }
  }

  async checkDataAndGetTan(e: any) {
    console.log('Register checkDataAndGetTan ', e);
    // let mobileTan: string;
    this.mobileTanButtonDisabled.next(true);
    if (this.isDisableEditing) {
      notify({
        message: 'Für diesen Vorgang kann keine weitere TAN angefordert werden.',
        position: {
          my: 'center',
          at: 'center'
        }
      }, 'warning', 600);
      return;
    }

    if (this.getIsValidOrderData()) {
      notify({
        message: 'Eingaben erfolgreich geprüft. TAN wird an Ihr Mobiltelefon gesendet.',
        position: {
          my: 'center',
          at: 'center'
        }
      }, 'success', 1000);

      this.userData.mobilePhone = this.userData.mobilePhone.trim();
      await this.smsService.sendGeneratedMobileTanToUserPhoneWithPurpose(this.userData.mobilePhone, SmsTanType.Registration)
        .then((resSmsResponse: ServerMessage) => {
          console.log('CheckDataAndGetTan after sendGeneratedMobileTan resSmsResponse', resSmsResponse);
          if (resSmsResponse?.status === undefined || resSmsResponse?.status?.valueOf() !== 'success') {
            this.isMobileTanGenerated = false;
            this.submitOrderDisabled.next(true);
            this.mobileTanButtonDisabled.next(false);
            this.buttonCheckDataAndGetApprovalCode.disabled = false;
            this.isEditMobileTanDisabled = true;
            this.userData.registration_mobiletan = null;
            // this.userData.mobilePhone = null;
          } else {
            this.submitOrderDisabled.next(false);
            this.mobileTanButtonDisabled.next(true);
            this.isMobileTanGenerated = true;
            this.buttonCheckDataAndGetApprovalCode.disabled = true;
            this.isEditMobileTanDisabled = false;
          }
        }).catch(error => {
          console.log('CheckDataAndGetTan failed ', error);
          notify({
            message: 'TAN konnte nicht generiert werden.',
            position: {
              my: 'center',
              at: 'center'
            }
          }, 'error', 3000);
        });
      return;
    }
    return;
  }

  public getIsEditMobileTanDisabled(): boolean {
    return this.isEditMobileTanDisabled;
  }

  public getIsValidPhoneNumber(e: any): boolean {
    if (e.value === null || e.value.trim() === '') {
      this.isValidPhoneNumber = false;
      return false;
    }
    return this.isValidPhoneNumber;
  }

  getIsValidOrderData(): boolean {
    this.isValidOrderData = this.registrationForm.instance.validate().isValid;
    this.buttonCheckDataAndGetApprovalCode.disabled = !this.registrationForm.instance.validate().isValid;
    this.mobileTanButtonDisabled.next(!this.registrationForm.instance.validate().isValid);
    return this.registrationForm.instance.validate().isValid;
  }

  getMaxCardWidth(): string {
    return this.maxCardWidth;
  }

  public goBack(): void {
    if (this.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl("/").then();
    }
  }

  public isGetMobileTanButtonDisabled(): boolean {
    return this.mobileTanButtonDisabled.getValue();
  }

  public isSubmitOrderDisabled(): boolean {
    return this.submitOrderDisabled.getValue();
  }

  onSubmitOrderData(e: Event) {
    if (!this.getIsValidOrderData()) {
      alert('Bitte erst die Daten vervollständigen, eine TAN anfordern und eintragen.');
      this.isDisableEditing = false;
      this.isProcessing = false;
      return;
    }

    // console.log('onSubmitOrderData ', e, this.userData.gpblg_extern_mobiletan, this.userData);
    if (!this.userData?.registration_mobiletan || this.userData?.registration_mobiletan?.toString().trim().length !== 6) {
      notify({
        message: 'Bitte erst die korrekte per SMS übermittelte TAN eintragen.',
        position: {
          my: 'center',
          at: 'center'
        }
      }, 'error', 2000);
      this.isDisableEditing = false;
      this.isProcessing = false;
      return;
    }
    this.isProcessing = true;
    this.isDisableEditing = true;
    this.authenticationService.registerUser(this.userData).then((res: ServerMessage) => {
        console.log('onSubmitOrderData userData after CREATE', res, this.userData);
        if (res && !!res.status && res.status === 'success') {
          this.authenticationService.passwordForgot(this.userData.userName);
          notify({
            message: 'Ihr Registrierungsdaten wurden erfolgreich verarbeitet. ' +
              'Siehe Benachrichtigung per E-Mail. Folgen Sie bitte den Hinweise in der Mail.',
            position: {
              my: 'center',
              at: 'center'
            }
          }, 'success', 4000);
          this.isDisableEditing = true;
          this.isProcessing = false;
          this.goBack();
        } else {
          notify({
            message: !!res.message ? res.message : 'Die Registrierung ist fehlgeschlagen.',
            position: {
              my: 'center',
              at: 'center'
            }
          }, 'error', 4000);
          this.isDisableEditing = false;
          this.isProcessing = false;
          this.isValidOrderData = false;
          this.isMobileTanGenerated = true;
        }
      }
    ).catch(error => {
      notify({
        message: 'Problem: Die Registrierung war nicht erfolgreich. ' +
          'Bitte nochmals versuchen. ',
        position: {
          my: 'center',
          at: 'center'
        }
      }, 'error', 6000);
      this.isDisableEditing = false;
      this.isProcessing = false;
    });
    return;
  }

  popoverToggleDefault() {
    this.popoverVisible = !this.popoverVisible;
  }

}
