<div class="sits-container">
  <div class="dx-card sits-card-large sits-card-center">
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">
        <dx-toolbar>
          <dxi-item widget="dxButton" location="before">
            <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
          </dxi-item>
          <dxi-item location="left" widget="dxButton" locateInMenu="never">
            <div *dxTemplate>
              <div class="toolbar-label">{{ [this.componentTitle] }} <span *ngIf="!isVisibleForSpecialUsers()">heute</span>
              </div>
            </div>
          </dxi-item>
          <dxi-item id="dateselection" locateInMenu="auto" location="before" widget="dxDateBox" [hidden]=!isVisibleForSpecialUsers() >
            <dx-date-box
              type="date"
              displayFormat="EE. dd.MM.yy"
              width="165px"
              useMaskBehavior=true
              showClearButton="true"
              [value]="filterByDate"
              applyValueMode="instantly"
              (onValueChanged)="onValueChangedFilterByDate($event)">
            </dx-date-box>
          </dxi-item>
          <dxi-item widget="dxButton" location="before" locateInMenu="auto">
            <dx-button id="link1" icon="info" hint="Hinweise zum Flugplan"
                       (click)="this.popoverToggleDefault()"></dx-button>
          </dxi-item>
        </dx-toolbar>
      </div>
      <dx-popover
        target="#link1"
        position="bottom"
        cdkScrollable
        [width]="300"
        [showTitle]="true"
        title="Hinweise zum Flugplan ..."
        [(visible)]="popoverVisible">
        <div *dxTemplate="let data = model of 'content'">
          <dx-scroll-view width="100%" height="100%">
            Der Flugplan basiert auf dem von FLN FRISIA-Luftverkehr GmbH (Inselflieger) bereitgestellten Flugplan.<br>
            Wir bemühen uns, gelegentliche Änderungen zeitnah einzupflegen.<br>
            Geänderte Abflugzeiten sind <span class="scheduleChanged">markiert</span>.<br>
            Ausfallende Flüge werden meistens von der Fluggesellschaft auf alternative Abflugzeiten geändert (sind dann
            auch
            markiert).<br>
            Für mit "Gepäck-Service" gekennzeichnete Flüge bieten wir unseren <b>Gepäcktransport-Service auf der Insel</b> an, <b>zwischen Flughafen und Inseladresse</b>.<br>
            Mit <img src="assets/iconexperience/iconex_o2/o_collection_svg/dark_grey/suitcase.svg"
                     class="sits-toolbar-button-inline"> gelangen Sie direkt zum Gepäckauftrag.<br>
            Alle Angaben ohne Gewähr.
          </dx-scroll-view>
        </div>
      </dx-popover>
      <dx-toolbar>
        <dxi-item widget="dxButton" location="before" locateInMenu="auto">
          <dx-button icon="refresh" (click)="grid.instance.clearSelection(); refreshFahrplanList()"></dx-button>
        </dxi-item>
      </dx-toolbar>
      <dx-data-grid #grid
                    id="grid-container"
                    [dataSource]="fahrplans"
                    [allowColumnReordering]="true"
                    [allowColumnResizing]="true"
                    keyExpr="id"
                    (onOptionChanged)="optionChangedHandler($event)"
                    [hoverStateEnabled]="true"
                    [showBorders]="true"
                    [showColumnLines]="false"
                    [showRowLines]="true"
                    (onCellPrepared)="onCellPrepared($event)"
                    [rowAlternationEnabled]="true">
        <dxo-paging [enabled]="true" [pageSize]="gridPageSize"></dxo-paging>
        <dxo-pager
          [visible]="true"
          [showPageSizeSelector]="true"
          [showNavigationButtons]="true"
          displayMode="compact"
          [allowedPageSizes]="[7,8,9,10,12,15,20,100]"></dxo-pager>
        <dxo-remote-operations
          [filtering]="true"
          [groupPaging]="true"
          [grouping]="true"
          [paging]="true"
          [sorting]="true"
          [summary]="false">
        </dxo-remote-operations>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-selection selectAllMode="allPages" mode="multiple" showCheckBoxesMode="none"></dxo-selection>
        <dxo-scrolling mode="standard" [useNative]="true"></dxo-scrolling>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-editing
          mode="row"
          [allowUpdating]="false"
          [allowDeleting]="false"
          [useIcons]="true"
        ></dxo-editing>
        <dxi-column dataField="id"
                    [visible]="false"
                    caption="Nr."
                    width="auto"
        ></dxi-column>

        <dxi-column
          caption="Gep.service"
          type="buttons" [visible]="true">
          <dxi-button
            hint="zur Gepäckservice-Buchung für diesen Flug ..."
            icon="assets/iconexperience/iconex_o2/o_collection_svg/dark_grey/suitcase.svg"
            [visible]="isCreateGepaeckAuftragButtonEnabled"
            [onClick]="createGepaeckAuftrag"
          ></dxi-button>
          <dxi-button
            hint="Gepäckservice kann für diesen Flug nicht (mehr) gebucht werden."
            icon="assets/iconexperience/iconex_o2/o_collection_svg/light_grey/suitcase.svg"
            [visible]="isCreateGepaeckAuftragButtonDisabled"
          ></dxi-button>
        </dxi-column>


        <dxi-column caption="Abflug"
                    width="120"
                    dataField="fp_abfahrt_datzeit"
                    cellTemplate="abfahrtzeitTemplate"
                    [sortIndex]="1"
                    sortOrder="asc"
        ></dxi-column>

        <dxi-column dataField="fp_abfahrt_fort_id" caption="von ..." width="auto">
          <dxo-lookup [dataSource]="getFrachtOrts"
                      valueExpr="fort_id"
                      displayExpr="fort_name"
          ></dxo-lookup>
        </dxi-column>
        <dxi-column
          [visible]="!isVisibleForSpecialUsers()"
          dataField="fp_ankunft_fort_id"
          caption="nach ..."
          width="auto">
          <dxo-lookup [dataSource]="getFrachtOrts"
                      valueExpr="fort_id"
                      displayExpr="fort_name"
          ></dxo-lookup>
        </dxi-column>
        <dxi-column dataField="fp_ger_nr" caption="Flug" width="auto"
                    [visible]="false">
          <dxo-lookup [dataSource]="getFaehres"
                      valueExpr="ger_nr"
                      displayExpr="ger_name"
          ></dxo-lookup>
        </dxi-column>

        <dxi-column caption="Art" cellTemplate="transportArtTemplate"
                    [calculateCellValue]="calcTransportArtCellValue"
                    alignment="center"
                    width="auto"
                    [visible]="false">
        </dxi-column>

        <dxi-column
          [visible]="isVisibleForGepaeckManagers()"
          caption="GEP Stücke" dataField="gpblg_anz_sum"
          width="auto">
        </dxi-column>
        <dxi-column
          [visible]="isVisibleForGepaeckManagers()"
          caption="GEP Aufträge" dataField="gptra_beleg_count"
          width="auto">
          <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
        </dxi-column>

        <div
          *dxTemplate="let data of 'abfahrtzeitTemplate'"
          [class]=calcAbfahrtzeitGeaendertStyleClass(data.data).toString()
        >
          {{ data.value | date: 'EE dd.MM. HH:mm' }}
        </div>

        <div *dxTemplate="let data of 'transportArtTemplate'">
          <span class="badge"
                [ngClass]="{'badge-success': data.value === 'P', 'badge-secondary': data.value  === 'F', 'badge-info': data.value  === 'PF', 'badge-danger': data.value  === 'CANCEL'  }"
          >{{data.value === 'F' ? 'Fracht' : data.value === 'PF' ? 'Pers.&Fracht' : data.value === 'P' ? 'Personen' : data.value === 'CANCEL' ? 'fällt aus!' : '?' }}</span>
        </div>

      </dx-data-grid>

      <dx-tooltip position="right"
                  [hidden]="!this.currentFahrPlan || this.currentFahrPlan.fp_abfahrtzeitgeaendert_yn === 0 && currentFahrPlan.fp_faelltaus_yn === 0">
        <div *dxTemplate="let data of 'content'">
          <div class='tooltipContent'>
            <div [hidden]="this.currentFahrPlan.fp_abfahrtzeitgeaendert_yn===0">Ursprünglicher Abflug ...<br>
              <b>{{currentFahrPlan.fp_initial_abfahrt_datzeit | date: 'EE dd.MM. HH:mm'}}</b></div>
            <div [hidden]="this.currentFahrPlan.fp_faelltaus_yn===0"><span class="badge badge-danger">!</span> Flug findet
              nicht statt!<br>
              {{currentFahrPlan.fp_bemerk_text}}</div>
          </div>
        </div>
      </dx-tooltip>

    </div>
  </div>
</div>
