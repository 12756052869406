import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-tarif-list-info',
  templateUrl: !!environment.targetKey
    ? './tarif-list-info-' + environment.targetKey + '.component.html'
    : './tarif-list-info.component.html',

  styleUrls: ['./tarif-list-info.component.scss']
})
export class TarifListInfoComponent implements OnInit {
  @Input() inputPopoverVisible: boolean | false;
  public titleText: string = "Hinweise zu den Tarifen ...";

  constructor() { }

  ngOnInit(): void {
    return;
  }

}
