<div class="dx-card sits-card">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header">
      <dx-toolbar>
        <dxi-item widget="dxButton" location="before">
          <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
        </dxi-item>
        <dxi-item location="left" widget="dxButton" locateInMenu="never">
          <div *dxTemplate>
            <div class="toolbar-label">{{[this.componentTitle]}}</div>
          </div>
        </dxi-item>
      </dx-toolbar>
    </div>
    <dx-toolbar class="py-2 px-1">
      <dxi-item widget="dxButton" location="before" locateInMenu="auto">
        <dx-button icon="refresh" (click)="grid.instance.clearSelection(); refreshStorageboxOrderList()"></dx-button>
      </dxi-item>

      <dxi-item id="btnadd" location="before" widget="dxButton">
        <dx-button (onClick)="actionAddItem()"
                   hint="Neuen Lagerbox-Auftrag anlegen."
                   icon="plus"></dx-button>
      </dxi-item>

      <dxi-item id="btnedit" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button (onClick)="actionEditItem()"
                   hint="Lagerbox-Auftrag bearbeiten"
                   [disabled]="(selectedStorageBoxOrders.length || 0 ) !== 1"
                   icon="edit"></dx-button>
      </dxi-item>
      <dxi-item id="btnshow" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button (onClick)="actionShowItem()"
                   hint="Lagerbox-Auftrag Druckansicht"
                   [disabled]="(selectedStorageBoxOrders.length || 0 ) !== 1"
                   icon="assets/iconexperience/iconex_o2/o_collection_svg/dark_grey/eye.svg"></dx-button>
      </dxi-item>

      <dxi-item id="btnsignAsPayed" locateInMenu="auto" location="before" widget="dxButton" [hidden]="!getIsEmployee()">
        <dx-button [disabled]="(selectedStorageBoxOrders.length || 0 ) !== 1"
                   hint="Selektierte Aufträge als bezahlt markieren"
                   (onClick)="signAsPayedSelectedItems(paymentTypes.payPal)">
          <span class="badge badge-pill badge-success">bezahlt</span>
        </dx-button>
      </dxi-item>

      <dxi-item id="selectpaymenttype" locateInMenu="auto" location="before" widget="dxSelectBox"
                [hidden]="!getIsEmployee()"
                [options]="toolbarSelectPaymentTypeOptions">
      </dxi-item>


      <dxi-item id="btndelete" locateInMenu="auto" location="after" widget="dxButton" [hidden]="!getIsEmployee()">
        <dx-button (onClick)="popupDeleteStorageBoxOrderVisible = true"
                   [disabled]="(selectedStorageBoxOrders.length || 0) > 3 || (storageBoxOrderDeleteAllowed === false)"
                   icon="trash"></dx-button>
      </dxi-item>

      <dxi-item id="btnstop" locateInMenu="auto" location="after" widget="dxButton" [hidden]="!getIsEmployee()">
        <dx-button [disabled]="(selectedStorageBoxOrders.length || 0 ) < 1"
                   hint="Selektierte Aufträge als NICHT bezahlt markieren"
                   (onClick)="signAsUnPayedSelectedItems()">
          <span class="badge badge-pill badge-danger">NICHT bez.</span>
        </dx-button>
      </dxi-item>
    </dx-toolbar>

    <dx-popup #popupDeleteStorageBoxOrder
              class="popup"
              height="auto"
              [width]="330"
              title="Lagerbox-Auftrag verwerfen"
              [(visible)]="popupDeleteStorageBoxOrderVisible">
      <div *dxTemplate="let data of 'content'">
        <p>Selektierter Lagerbox-Auftrag: {{getSelectedGepaeckBelegeAsJoinedString()}}</p>
        <p>Das Verwerfen ist nur möglich für Belege ...<br>
          die noch nicht bezahlt sind.<br>
          Lagerbox-Auftrag endgültig verwerfen?</p>
        <p>
          <button (click)="popupDeleteStorageBoxOrderVisible = false"
                  class="btn btn-outline-secondary  mr-3"
                  data-dismiss="modal"
                  type="button">Abbrechen
          </button>
          <button (click)="actionDeleteSelectedItems(); popupDeleteStorageBoxOrderVisible = false"
                  class="btn btn-outline-danger  mr-3"
                  data-dismiss="modal"
                  type="button">
            Löschen
          </button>
        </p>
      </div>
    </dx-popup>


    <dx-data-grid #grid
                  (onCellPrepared)="onCellPrepared($event)"
                  (onSelectionChanged)="selectionChangedHandler($event)"
                  (onOptionChanged)="optionChangedHandler($event)"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [dataSource]="storageBoxOrders"
                  [columnHidingEnabled]="false"
                  [hoverStateEnabled]="true"
                  [rowAlternationEnabled]="true"
                  [selectedRowKeys]="selectedStorageBoxBelegeIds"
                  columnResizingMode="widget"
                  [showBorders]="true"
                  [showColumnLines]="false"
                  [showRowLines]="true"
                  id="grid-container"
                  keyExpr="gervm_id">
      <dxo-paging [enabled]="true" [pageSize]="gridPageSize"></dxo-paging>
      <dxo-pager [visible]="true"
                 [allowedPageSizes]="[7,8,9,10, 15, 20, 30, 50, 100]"
                 [showInfo]="true"
                 [showNavigationButtons]="true"
                 [showPageSizeSelector]="true"></dxo-pager>
      <dxo-column-chooser [enabled]="false" mode="select"></dxo-column-chooser>
      <dxo-remote-operations
        [filtering]="true"
        [groupPaging]="true"
        [grouping]="true"
        [paging]="true"
        [sorting]="true"
        [summary]="false">
      </dxo-remote-operations>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="none"></dxo-selection>
      <dxo-scrolling mode="standard" [useNative]="true"></dxo-scrolling>
      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <!--      <dxo-grouping [contextMenuEnabled]="false"> </dxo-grouping>
            <dxo-group-panel [visible]="true"></dxo-group-panel>-->
      <dxi-column caption="VM-Nr. Vermietungsauftrag"
                  dataField="gervm_id"
                  dataType="number"
                  [sortIndex]="2"
                  sortOrder="desc"
                  width="70"
      ></dxi-column>
      <dxi-column caption="Beginn"
                  dataField="gervm_beginn_datzeit"
                  dataType="date"
                  format="EE dd.MM.yy"
                  [sortIndex]="1"
                  sortOrder="desc"
                  width="110"
      ></dxi-column>
      <dxi-column caption="Ende"
                  dataField="gervm_ende_datzeit"
                  dataType="date"
                  format="EE dd.MM.yy"
                  width="110"
      ></dxi-column>
      <dxi-column caption="Box"
                  dataField="gervm_ger_name"
                  dataType="string"
                  width="70"
      > </dxi-column>

      <dxi-column [allowFiltering]="true" caption="Belegempfänger" dataField="gervm_rgempf_adr_id" width="180"
                  cellTemplate="cellTemplate_smaller">
        <dxo-lookup [dataSource]="getAdresses"
                    displayExpr="adr_such_name"
                    valueExpr="adr_id"
        ></dxo-lookup>
      </dxi-column>
      <dxi-column caption="Anz."
                  id="gervm_anz"
                  dataField="gervm_anz"
                  dataType="number"
                  width="60"
      > </dxi-column>

      <dxi-column alignment="center" caption="Bezahlt" cellTemplate="activeTemplate" dataField="gervm_bezahlt_kz" width="45"></dxi-column>

      <div *dxTemplate="let data of 'activeTemplate'">
          <span [ngClass]="{
           'badge-secondary' : !data.value || data.value <= -1 || data.value >= 3,
           'badge-danger': data.value === 0,
            'badge-success': data.value === 1,
            'badge-secondary' : data.value === 2 }"
                class="badge badge-pill"
          >{{ data.value === 0 ? 'Nein'
            : data.value === 1 ? ' Ja '
              : data.value === 2 ? ' Rg ' : '?'}}</span>
      </div>

      <dxi-column alignment="center" caption="Zahlart tatsächlich" cellTemplate="cellTemplate_bezahlt_provider" dataField="gervm_bezahlt_provider_name" width="49"></dxi-column>
      <div *dxTemplate="let data of 'cellTemplate_bezahlt_provider'">
          <span [ngClass]="{
           'badge-danger' : !data.value || (data.value !== 'Bar' && data.value !== 'Vorkasse' && data.value !== 'PayPal' && data.value !== 'Rechnung'),
            'badge-success': (data.value === 'Bar' || data.value === 'Vorkasse' || data.value === 'PayPal' || data.value === 'Rechnung')}"
                class="badge badge-pill"
          >{{ !data.value || (data.value !== 'Bar' && data.value !== 'Vorkasse' && data.value !== 'PayPal' && data.value !== 'Rechnung') ? '?'
            : data.value === 'Rechnung' ? 'RG' : data.value }}</span>
      </div>

      <dxi-column alignment="center" caption="Zahlart lt. Shop" cellTemplate="cellTemplate_zahlart" dataField="gervm_zktoart_id" width="49"></dxi-column>
      <div *dxTemplate="let data of 'cellTemplate_zahlart'">
          <span *ngIf="!!data.value" [ngClass]="{
           'badge-danger' : data.value <= 0 || data.value === 3 || data.value > 4,
            'badge-success': data.value === 2 || data.value === 4}"
                class="badge badge-pill"
          >{{ !data.value || data.value <= 0 || data.value === 3 || data.value > 4 ? '?'
            : data.value === 1 ? ' Bar '
              : data.value === 2 ? ' Bank '
                : data.value === 4 ? ' PayPal ' : ' ? '}}</span>
      </div>

      <dxi-column caption="Insel-Adresse"
                  dataField="gervm_ladestelle_adr_id"
                  width="170"
                  cellTemplate="cellTemplate_smaller"
      ><dxo-lookup [dataSource]="getGepaeckAdresses"
                   displayExpr="adr_gepaeck_name"
                   valueExpr="adr_id"
      ></dxo-lookup>
      </dxi-column>

      <dxi-column caption="Gast"
                  dataField="gervm_extern_name"
                  width="100"
                  cellTemplate="cellTemplate_smaller"
      > </dxi-column>

      <dxi-column caption="Gast, Telefon"
                  dataField="gervm_extern_mobil_tel"
                  width="110"
                  cellTemplate="cellTemplate_gervm_extern_mobil_tel"
      > </dxi-column>
      <div *dxTemplate="let cell of 'cellTemplate_gervm_extern_mobil_tel'">
        <div style="color:blue; font-size:small"><a href="tel:{{cell.text.split(' ').join('')}}">{{cell.text.split('+49 ').join('0').split(' ').join('-')}}</a></div>
      </div>

      <dxi-column caption="Gast, E-Mail"
                  dataField="gervm_extern_email"
                  width="120"
                  cellTemplate="cellTemplate_gervm_extern_email"
      > </dxi-column>
      <div *dxTemplate="let cell of 'cellTemplate_gervm_extern_email'">
        <div style="color:blue; font-size:small"><a href="mailto:{{cell.text}}?subject=Lagerbox-Auftrag [VM-{{cell.data.gervm_id}}] -> Info
        &body=Moin {{cell.data.gervm_extern_name}},%0D%0A %0D%0Aes geht um Ihren Gepäckauftrag {{this.getFrontendBaseUrl()}}/storagebox/order/show/{{cell.data.gervm_id}}/{{cell.data.gervm_extern_mobiletan || '000000'}}%0D%0A %0D%0A
Mit freundlichem Gruß %0D%0A %0D%0A">{{cell.text}}</a></div>
      </div>

      <dxi-column caption="Betrag"
                  dataField="gervm_brutto_amount"
                  dataType="number"
                  width="70"
                  format="##0.00"
      ></dxi-column>

      <dxi-column caption="Bemerkung"
                  dataField="gervm_bemerk_text"
                  width="170"
                  cellTemplate="cellTemplate_smaller"
      ></dxi-column>
      <dxi-column caption="Online"
                  [visible]="this.authenticationService.isEmployee"
                  dataField="gervm_extern_kz"
                  width="60"
                  cellTemplate="cellTemplate_smaller"
      ></dxi-column>
      <dxi-column caption="created"
                  [visible]="this.authenticationService.isEmployee"
                  dataField="gervm_create_stamp"
                  dataType="date"
                  format="dd.MM.yy HH:mm"
                  width="100"
                  cellTemplate="cellTemplate_smaller"
      ></dxi-column>

      <dxi-column caption="changed"
                  [visible]="this.authenticationService.isEmployee"
                  dataField="gervm_change_stamp"
                  dataType="date"
                  format="dd.MM.yy HH:mm"
                  width="100"
                  cellTemplate="cellTemplate_smaller"
      ></dxi-column>

      <div *dxTemplate="let cell of 'cellTemplate_smaller'">
        <div style="font-size: small">{{cell.text}}</div>
      </div>
    </dx-data-grid>

  </div>
</div>

