import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';

import {SharedServicesModule} from '../shared-services/shared-services.module';
import {SharedComponentsModule} from '../shared-components/shared-components.module';

import { LayoutModule } from '@angular/cdk/layout';
import {FlexLayoutModule} from '@angular/flex-layout';

import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {NaviComponent} from './navi/navi.component';
import {MaterialModule} from '../material/material.module';
import {AlertComponent} from '../directives';
import {ApiServicesModule} from '../api/api-services/api-services.module';


@NgModule({
    imports: [
      ApiServicesModule,
      BrowserModule,
      CommonModule,
      FormsModule,
      FlexLayoutModule,
      RouterModule,
      SharedServicesModule,
      SharedComponentsModule,
      LayoutModule,
      MaterialModule,
    ],
    declarations: [
      AlertComponent,
      BreadcrumbComponent,
      NaviComponent,
    ],
    exports: [
      NaviComponent,
    ]
  }
)
export class NavigationModule {
}
