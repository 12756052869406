import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IStreckeSetting} from '../api-dtos/strecke-setting';
import {ITransportArt} from '../api-dtos/transport-art';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';

export enum TypeOfTransport {
  Faehre = 'FAEHRE',
  Flug = 'FLUG',
  LokalTransport = 'LOKAL'
}

@Injectable({
  providedIn: 'root'
})
export class StreckeSettingService {
  baseUrl = `${environment.restApiUrl}`;

  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient) {
  }

  private async getHttpHeaderAsync() {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  private getHttpHeader() {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  public async getSettingsByTransportType(transportArtCode: String): Promise<IStreckeSetting[]> {
    if (!(!!transportArtCode)) {
      transportArtCode = 'all';
    }
    return this.http.get<IStreckeSetting[]>(
      `${this.baseUrl}/streckesetting?transportArt=${transportArtCode.toLowerCase()}`,
      this.getHttpHeader()
    ).toPromise();
  }

  public async getTransportArtListByServicePromise(serviceCode: String): Promise<ITransportArt[]> {
    // gepaeck, fracht, all
    if (!(!!serviceCode)) {
      serviceCode = 'all';
    }
    return await this.http.get<ITransportArt[]>(
      `${this.baseUrl}/streckesetting/transportartlist/${serviceCode.toLowerCase()}`,
      this.getHttpHeader()
    ).toPromise();
  }

  public getTransportArtListByService(serviceCode: String): Observable<ITransportArt[]> {
    // refactored: 31.01.2024 (vorher Promise)
    // gepaeck, fracht, all
    if (!(!!serviceCode)) {
      serviceCode = 'all';
    }
    return this.http.get<ITransportArt[]>(
      `${this.baseUrl}/streckesetting/transportartlist/${serviceCode.toLowerCase()}`,
      this.getHttpHeader())
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  public getStreckeRadioGroupItems(streckeSetting:IStreckeSetting[]): any{
    // ZIEL-Orte der Strecken ...
    let returnRadioGroupItems: any[] = [];
    if (streckeSetting == undefined || streckeSetting.length == 0 ) {
      return returnRadioGroupItems;
    }
    if (!environment.production) console.log('getStreckeRadioGroupItems streckeSettings', streckeSetting);
    streckeSetting.forEach(item => {
      returnRadioGroupItems.push({text: item.svstrs_anfahrt_yn === 1
          ? item.svstrs_ort1_frachtort.fort_name.concat(' (Anreise)') : item.svstrs_ort1_frachtort.fort_name.concat(' (Abreise)')
        , frachtortId: item.svstrs_ort1_fort_id});
    });
    return returnRadioGroupItems;
  }

  public getGroupItemsTypeOfTransport(typeOfTransportList: ITransportArt[]): any {
    let returnRadioGroupItems: any[]  = [];
    if (typeOfTransportList == undefined || typeOfTransportList.length == 0 ) {
      return returnRadioGroupItems;
    }
    typeOfTransportList.forEach(item => {
      returnRadioGroupItems.push({text: item.transportArtName, fp_art_kz: item.transportArtCode});
    });
    if (!environment.production) console.log('getGroupItemsTypeOfTransport', returnRadioGroupItems);
    return returnRadioGroupItems;
  }


  public getMinimalStartDateByPattern(pattern: string): Date {
    // if (!environment.production) console.log('getMinimalStartDateByPattern pattern: ', pattern, this.authenticationService.isEmployee);
    if (this.authenticationService.isEmployee) {
      // heute - 62 Tage
      // Mitarbeiter koennen auch nachtraeglich erfassen, bis viele Tage rueckwirkend
      return new Date(new Date().setHours(0, 0, 0, 0) - (62 * 24 * 60 * 60 * 1000));
    }

    // wenn die folgenden Regeln nicht anwendbar sind, nimm ein ziemlich in der Zukunft liegendes Datum
    let startDate = new Date(new Date().setHours(0, 0, 0, 0) + (31 * 24 * 60 * 60 * 1000));
    let nowDate = new Date();

    if (!!pattern && pattern.trim().length > 0) {
      let hour: number;
      let minutes: number;
      let reg1 = /(BIS)[0-1][0-9][0-6][0-9](MOR:\+1D)/ig;
      let reg2 = /(MIN)[0-1][0-9][0-6][0-9](VORABF|VORANK)/ig;

      if (pattern.match(reg1) != null) {
        // BIS1600MOR:+1D, BIS1700MOR:+1D ...
        // Bis 16:00 Uhr ab morgen, danach ab übermorgen (oder aehnlich)...
        // siehe Stamm / Fracht / StreckenSettings
        hour = Number(pattern.substring(3,5));
        minutes = Number(pattern.substring(5,7));
        if ( (nowDate.getHours() * 60 + nowDate.getMinutes())  < (hour * 60 + minutes)  ) {
          // heute + 1 Tag
          startDate = new Date(new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000));
        } else {
          // heute + 2 Tage
          startDate = new Date(new Date().setHours(0, 0, 0, 0) + (48 * 60 * 60 * 1000));
          /*console.log('Regel fuer Gepaeckkunden nach 16', this.gepaeckBeleg.gpblg_dummy_datum);*/
        }
      } else if (pattern.match(reg2) != null) {
        // MIN0230VORABF, MIN0030VORANK
        // mindestens 2:30 vor Abfahrt, min. 30 Min. vor Ankunft, ...
        hour = Number(pattern.substring(3,5));
        minutes = Number(pattern.substring(5,7));
        startDate = new Date(nowDate.getTime() + ((hour * 60 + minutes) * 60 * 1000));
      }
    }
    // if (!environment.production) console.log('getMinimalStartDateByPattern startDate: ', startDate);
    return startDate;
  }

  // todo: Wert aus StreckeSetting beziehen ...
  public getGepaeckLastOrderLimitMinutesOfDay(): number {
    switch (environment.targetKey) {
      case '10426':
        // ab 19 Uhr ist Feierabend!
        return 19 * 60;
      default:
        // kein Limit
        return (23 * 60) + 59;
    }
  }

  // todo: Wert aus StreckeSetting beziehen ...
  public getGepaeckFirstOrderLimitMinutesOfDay(): number {
    switch (environment.targetKey) {
      case '10426':
        // ab 8 Uhr sind wir fuer Euch da!
        return 8 * 60;
      default:
        // keine Einschraenkung
        return 0;
    }
  }

  public getSettingsFiltered(transportArtCode: string, direction: string): Observable<IStreckeSetting[]> {
    transportArtCode = (transportArtCode ?? 'all').trim();
    direction = (direction ?? 'all').trim();
    return this.http.get<IStreckeSetting[]>(
      `${this.baseUrl}/streckesetting?transportArt=${transportArtCode.toLowerCase()}&direction=${direction.toLowerCase()}`,
      this.getHttpHeader()
    );
  }
}
