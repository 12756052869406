import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthenticationService} from '../shared-services/authentication.service';

/* The JWT Interceptor intercepts http requests from the application
to add a JWT auth token to the Authorization header if the user is logged in.
It's implemented using the HttpInterceptor class
that was introduced in Angular 4.3 as part of the new HttpClientModule.
By extending the HttpInterceptor class you can create a custom interceptor
to modify http requests before they get sent to the server.
*/

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    // console.log('JwtInterceptor intercept');
    // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // if (this.authenticationService.currentUser && this.authenticationService.currentUser.token) {
    // const currentUser = this.authenticationService.currentUser;
    // if (this.authenticationService && currentUser.token) {
    // if (currentUser && currentUser.token) {
    if (this.authenticationService && this.authenticationService.sessionKey) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authenticationService.sessionKey}`
        }
      });
    }
    return next.handle(request);
  }
}
