import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {AlertService} from '../../shared-services/alert.service';
import {ServerMessage} from '../../api/api-dtos/server-message';
import notify from 'devextreme/ui/notify';
import {Location} from '@angular/common';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  newpassword = '';
  currentpassword = '';
  passwordPattern: any = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

  editForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  processed = false;
  returnUrl: string;
  responseCode: string;
  messageText: string;
  // validationService: ValidationService;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private readonly location: Location) {
    // this.validationService = new ValidationService;
  }

  ngOnInit() {
    this.responseCode = this.route.snapshot.queryParamMap.get('responsecode');
    this.editForm = this.formBuilder.group({
      currentpassword: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20)])],
      newpassword: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20)])],
      confirmpassword: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20)])],
    });
  }

  // convenience getter for easy access to form fields
  f() {
    return this.editForm.controls;
  }

  public cancel(): void {
    this.location.back();
  }

  onSubmit( ) {
    this.alertService.getMessage();
    this.submitted = true;
    // validations
    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    this.loading = true;
    this.processed = false;
    let serviceResult: ServerMessage;
    this.authenticationService.changePassword(
      this.editForm.controls['currentpassword'].value,
      this.editForm.controls['newpassword'].value
    )
      .then(
        data => {
          serviceResult = data;
          this.processed = serviceResult.status === 'success';
          if (!serviceResult.message || serviceResult.message.trim() === '') {
            serviceResult.message = 'Passwort ergolgreich geändert.';
          }
          this.setMessageText(serviceResult.message);
        },
        error => {
          this.processed = false;
          this.alertService.error(error + ', evtl. Netzwerkverbindungsprobleme.');
        });
    this.loading = false;
  }

  public formDisabled() {
    return (!this.responseCode || this.responseCode.trim() === '');
  }

  setMessageText(message: string) {
    this.messageText = message;
  }

  onFormSubmit(e: Event) {
    this.alertService.getMessage();
    this.submitted = true;
    // validations
    // stop here if form is invalid

    this.loading = true;
    this.processed = false;
    let serviceResult: ServerMessage;
    this.authenticationService.changePassword(
      this.currentpassword, this.newpassword
    )
      .then(
        data => {
          serviceResult = data;
          this.processed = serviceResult.status === 'success';
          if (!serviceResult.message || serviceResult.message.trim() === '') {
            serviceResult.message = 'Passwort ergolgreich geändert.';
          }
          notify({
            message: serviceResult.message,
            position: {
              my: 'center',
              at: 'center'
            }
          }, 'success', 5000);
        },
        error => {
          this.processed = false;
          notify({
            message: error + ', evtl. Netzwerkverbindungsprobleme.',
            position: {
              my: 'center middle',
              at: 'center middle'
            }
          }, 'error', 8000);
        });
    this.loading = false;
    e.preventDefault();
  }

  passwordComparison = () => {
    return this.newpassword;
  }

  checkComparison() {
    return true;
  }

}

