import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {AlertService} from '../../shared-services/alert.service';
import {ServerMessage} from '../../api/api-dtos/server-message';

@Component({
  selector: 'app-renewforgottenpassword',
  templateUrl: './renewforgottenpassword.component.html',
  styleUrls: ['./renewforgottenpassword.component.scss']
})

export class RenewforgottenpasswordComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  processed = false;
  returnUrl: string;
  responseCode: string;
  messageText: string;
  userName = '';
  password = '';
  confirmPassword = '';
  // validationService: ValidationService;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) {
      // this.validationService = new ValidationService;
  }

  ngOnInit() {
    this.responseCode = this.route.snapshot.queryParamMap.get('responsecode');
    this.loginForm = this.formBuilder.group({
      currentpassword: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(64)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20)])],
      confirmpassword: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20)])],
    });
  }

  onSubmit(e: Event) {
    this.alertService.clear();
    this.submitted = true;
    // validations
    this.loading = true;
    this.processed = false;
    let serviceResult: ServerMessage;
    this.authenticationService.setNewPasswordAfterForget(
      this.userName,
      this.password,
      this.responseCode
    )
      .then(
        data => {
          serviceResult = data;
          this.processed = serviceResult.status.toLowerCase() === 'success';
          this.setMessageText(serviceResult.message);
        },
        error => {
          this.processed = false;
          this.alertService.error(error + ', evtl. Netzwerkverbindungsprobleme.');
        });
    this.loading = false;
  }

  public formDisabled() {
    return (!this.responseCode || this.responseCode.trim() === '');
  }

  setMessageText(message: string) {
    this.messageText = message;
  }

  passwordComparison = () => {
    return this.password;
  }

  checkComparison() {
    return true;
  }

}

