import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DxiColumnModule, DxiValidationRuleModule, DxoHatchingModule, DxoHoverStyleModule} from 'devextreme-angular/ui/nested';
import {
  DxAccordionModule, DxAutocompleteModule,
  DxButtonModule,
  DxBoxModule,
  DxCalendarModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxFormModule,
  DxLoadPanelModule,
  DxLookupModule, DxMapModule,
  DxPopupModule,
  DxPopoverModule,
  DxRadioGroupModule,
  DxSelectBoxModule, DxTagBoxModule,
  DxToolbarModule,
  DxTooltipModule,
  DxValidationSummaryModule,
  DxValidatorModule, DxDropDownBoxModule, DxDataGridComponent, DxRangeSelectorModule, DxDateBoxModule, DxValidationGroupModule
} from 'devextreme-angular';
import {DevExpressSitsService} from './dev-express-sits.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DxAccordionModule,
    DxAutocompleteModule,
    DxBoxModule,
    DxButtonModule,
    DxCalendarModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDropDownBoxModule,
    DxFormModule,
    DxLookupModule,
    DxiColumnModule,
    DxDateBoxModule,
    DxLoadPanelModule,
    DxMapModule,
    DxPopupModule,
    DxPopoverModule,
    DxRadioGroupModule,
    DxRangeSelectorModule,
    DxSelectBoxModule,
    DxTagBoxModule,
    DxToolbarModule,
    DxTooltipModule,
    DxiValidationRuleModule,
    DxValidationGroupModule,
    DxValidatorModule,
    DxValidationSummaryModule,
  ],
  exports: [
    DxAccordionModule,
    DxAutocompleteModule,
    DxBoxModule,
    DxButtonModule,
    DxCalendarModule,
    DxCheckBoxModule,
    DxDataGridComponent,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxFormModule,
    DxiColumnModule,
    DxLoadPanelModule,
    DxLookupModule,
    DxMapModule,
    DxPopupModule,
    DxPopoverModule,
    DxRadioGroupModule,
    DxRangeSelectorModule,
    DxSelectBoxModule,
    DxTagBoxModule,
    DxToolbarModule,
    DxTooltipModule,
    DxValidationGroupModule,
    DxiValidationRuleModule,
    DxoHatchingModule,
    DxoHoverStyleModule,
    DxValidatorModule,
    DxValidationSummaryModule,
  ],
  providers: [
    DevExpressSitsService,
  ]
})
export class DevExpressModule {
}
