<div>
  <form (submit)="onSubmit($event)" class="frachtbrief-form">
    <dx-form #frachtForm
             [(formData)]="frachtbrief"
             labelLocation="top"
             validationGroup="totalFrachtbrief"
             [scrollingEnabled]="false"
             [showValidationSummary]="true"
             (onFieldDataChanged)="form_fieldDataChanged($event)">
      <dxi-item itemType="tabbed" #tabFolder>
        <dxi-tab title="Allgemein">
          <dxi-item itemType="group">
            <dxo-col-count-by-screen [xs]="1" [sm]="1" [md]="2" [lg]="2"></dxo-col-count-by-screen>
            <dxi-item itemType="simple"
                      dataField="frako_stop_kz"
                      editorType="dxCheckBox"
                      [editorOptions]="{disabled: !frachtbriefEditAllowed }">
              <dxo-label
                text="Stop"
                alignment="left">
              </dxo-label>
            </dxi-item>

            <dxi-item itemType="simple"
                      dataField="frako_datum"
                      type="date"
                      caption="FB-Datum"
                      editorType="dxDateBox"
                      hint="Datum an dem der Transport erfolgt. Wird für die Auswahl der Fähre benötigt."
                      [editorOptions]="{
                      id: 'frako_datum',
                      width: '150',
                      type: 'date',
                      useMaskBehavior: 'true',
                      displayFormat: 'EE dd.MM.yyyy',
                      dateSerializationFormat: 'yyyy-MM-dd',
                      invalidDateMessage: 'Das Datum muss ein gültiges Format haben: MM/dd/yyyy',
                      onValueChanged: onFormValueChanged_frako_datum,
                      pickerType:'calendar',
                      showClearButton:false,
                      disabled: !frachtbriefEditAllowed }">
              <dxi-validation-rule type="required"
                                   message="Das Frachtdatum ist erforderlich. Voraussetzung für den Transport und die Tarifermittlung."></dxi-validation-rule>
              <dxo-label
                text="Frachtdatum"
                alignment="left">
              </dxo-label>
            </dxi-item>

            <dxi-item itemType="simple"
                      dataField="frako_abgang_fort_id"
                      editorType="dxRadioGroup"
                      [editorOptions]="{items: radioGroupItems,
                    displayExpr: 'text',
                    layout: 'horizontal',
                    valueExpr: 'frachtortId',
                    onValueChanged: onFormValueChanged_frako_abgang_fort_id,
                    disabled: !frachtbriefEditAllowed }">
              <dxo-label
                text="Richtung"
                alignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Die Transportrichtung ist erforderlich, damit eine Fährverbindung gewählt werden kann."></dxi-validation-rule>
            </dxi-item>

            <dxi-item itemType="simple"
                      dataField="frako_plan_fp_id"
                      editorType="dxSelectBox"
                      [editorOptions]="{items: fahrplans$ | async,
                        dropDownOptions: {showTitle: false, hideOnOutsideClick: true},
                        valueExpr: 'fp_id',
                        searchPlaceholder: 'Abfahrtzeit ...',
                        displayExpr: getDisplayAbfahrtzeitPlan,
                        disabled: !frachtbriefEditAllowed }">
              <dxo-label
                text="Fähre (geplante Abfahrt)"
                alignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Bitte die für den Transport geplante Fähre auswählen."></dxi-validation-rule>
            </dxi-item>

            <dxi-item itemType="simple"
                      dataField="frako_ist_fp_id"
                      editorType="dxSelectBox"
                      [visible]=frako_ist_fp_idDisplayVisible
                      [editorOptions]="{items: fahrplans$ | async,
                        dropDownOptions: {showTitle: false, hideOnOutsideClick: true},
                        valueExpr: 'fp_id',
                        searchPlaceholder: 'Abfahrtzeit ...',
                        displayExpr: getDisplayAbfahrtzeitIst,
                        disabled: !frako_trst_status_nrEditAllowed }">
              <dxo-label
                text="Fähre (tats. Abfahrt)"
                alignment="left">
              </dxo-label>
            </dxi-item>

            <dxi-item itemType="simple"
                      dataField="frako_paritaet_code"
                      editorType="dxSelectBox"
                      [editorOptions]="{items: paritaets,
                    dropDownOptions: {showTitle: false, hideOnOutsideClick: true},
                    width: '100%',
                    valueExpr: 'code',
                    displayExpr: 'name',
                    disabled: !frachtbriefEditAllowed }">
              <dxo-label
                text="Parität"
                alignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Die Parität ist erforderlich. Sie regelt, wer die Leistung bezahlt."></dxi-validation-rule>
            </dxi-item>

            <dxi-item itemType="simple"
                      dataField="frako_absender_adr_id"
                      editorType="dxLookup"
                      [editorOptions]="{items: adresseDddw,
                    width: '100%',
                    dropDownOptions: {showTitle: false, hideOnOutsideClick: true},
                    valueExpr: 'adr_id',
                    searchMode: 'contains',
                    minSearchLength: 0,
                    searchPlaceholder: 'bitte suchen ...',
                    displayExpr: 'adr_such_name',
                    disabled: !frachtbriefEditAllowed }"
                      [colSpan]="2">
              <dxo-label
                text="Absender"
                alignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Der Absender der Frachtsendung ist erforderlich."></dxi-validation-rule>
            </dxi-item>

            <dxi-item itemType="simple"
                      dataField="frako_empf_adr_id"
                      editorType="dxLookup"
                      [editorOptions]="{items: adresseDddw,
                    width: '100%',
                    dropDownOptions: {showTitle: false, hideOnOutsideClick: true},
                    valueExpr: 'adr_id',
                    searchMode: 'contains',
                    minSearchLength: 0,
                    searchPlaceholder: 'bitte suchen ...',
                    displayExpr: 'adr_such_name',
                    applyValueMode: 'instantly',
                    deferRendering: true,
                    disabled: !frachtbriefEditAllowed }">
              <dxo-label
                text="Empfänger"
                alignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Der Empfänger der Frachtsendung ist erforderlich."></dxi-validation-rule>
            </dxi-item>

            <dxi-item itemType="simple"
                      dataField="frako_rgempf_adr_id"
                      editorType="dxLookup"
                      [editorOptions]="{items: adresseDddw,
                    width: '100%',
                    dropDownOptions: {showTitle: false, hideOnOutsideClick: true},
                    valueExpr: 'adr_id',
                    searchMode: 'contains',
                    minSearchLength: 0,
                    searchPlaceholder: 'bitte suchen ...',
                    displayExpr: 'adr_such_name',
                    disabled: !frachtbriefEditAllowed }">
              <dxo-label
                text="RG-Empfänger"
                alignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Der Belegempfänger der Frachtsendung ist erforderlich (bezahlt die Frachtdienstleistung."></dxi-validation-rule>
              <dxi-validation-rule
                type="custom"
                [validationCallback]="validateAdressen"
                message="Als Kunde bitte in mindestens einem der Adressfelder die eigene Adresse verwenden. Erfassung für Dritte ist nicht erlaubt."
              >
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item itemType="simple"
                      dataField="frako_bemerk_text"
                      editorType="dxTextArea"
                      [editorOptions]="{width: '100%',
                    disabled: !frachtbriefEditAllowed }"
                      [colSpan]="2">
              <dxo-label
                text="Bemerkung"
                alignment="left">
              </dxo-label>
            </dxi-item>

            <dxi-item itemType="simple"
                      dataField="frako_fest_status_nr"
                      editorType="dxSelectBox"
                      [editorOptions]="{items: frachtErfassungsStatuss,
                    width: '100%',
                    dropDownOptions: {showTitle: false, hideOnOutsideClick: true},
                    valueExpr: 'fest_status_nr',
                    displayExpr: 'fest_name',
                    disabled: !frachtbriefEditAllowed }">
              <dxo-label
                text="Erfassungsstatus"
                alignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Erforderlich. Den Erfassungsstatus auf <Freigabe zum Transport> setzen, damit der Transportauftrag gültig und aktiv wird."></dxi-validation-rule>
            </dxi-item>

            <dxi-item itemType="simple"
                      dataField="frako_trst_status_nr"
                      editorType="dxSelectBox"
                      [editorOptions]="{items: transportStatuss,
                    width: '100%',
                    dropDownOptions: {showTitle: false, hideOnOutsideClick: true},
                    valueExpr: 'trst_status_nr',
                    displayExpr: 'trst_name',
                    disabled: !frako_trst_status_nrEditAllowed}">
              <dxo-label
                text="Transportstatus"
                alignment="left">
              </dxo-label>
            </dxi-item>
          </dxi-item>
        </dxi-tab>
        <dxi-tab title="Fracht">
          <dxi-item itemType="group">
            <dx-data-grid #positionGrid
                          (change)="onPositionChange($event)"
                          (onCellPrepared)="onPositionCellPrepared($event)"
                          (onEditingStart)="onPositionEditingStart($event)"
                          (onFocusedCellChanging)="onPositionFocusedCellChanging($event)"
                          (onFocusedCellChanged)="onPositionFocusedCellChanged($event)"
                          (onInitNewRow)="onPositionInitNewRow($event)"
                          (onRowInserted)="onPositionRowInserted($event)"
                          (onRowRemoving)="onPositionRemoving($event)"
                          (onRowValidating)="onPositionRowValidating($event)"
                          (onToolbarPreparing)="onPositionToolbarPreparing($event)"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [columnAutoWidth]="true"
                          [columnHidingEnabled]="true"
                          [dataSource]="frachtbrief.frachtPositions"
                          [highlightChanges]="true"
                          [hoverStateEnabled]="true"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          [showColumnLines]="true"
                          [showRowLines]="true"
                          id="grid">
              <dxo-grouping [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>
              <dxo-group-panel [visible]="false"
                               emptyPanelText="Benutzen Sie bitte das Contextmenü der Kopfspalten, um die Daten zu gruppieren"></dxo-group-panel>
              <dxo-scrolling mode="standard" [useNative]="true"></dxo-scrolling>
              <dxo-sorting
                mode="single"> <!-- or "multiple" | "none" -->
              </dxo-sorting>
              <dxo-remote-operations
                [filtering]="true"
                [paging]="false"
                [sorting]="true"
                [summary]="true"
                [grouping]="true"
                [groupPaging]="true">
              </dxo-remote-operations>
              <dxo-load-panel [enabled]="true"></dxo-load-panel>

              <dxo-editing
                mode="cell"
                useIcons="true"
                [allowAdding]="frachtbriefEditAllowed"
                [allowDeleting]="frachtbriefEditAllowed"
                [allowUpdating]="frachtbriefEditAllowed"
                [selectTextOnEditStart]="positionSelectTextOnEditStart"
                [startEditAction]="positionStartEditAction">
                <dxo-texts [confirmDeleteMessage]="'Position löschen?'"></dxo-texts>
              </dxo-editing>

              <dxi-column [allowGrouping]="false"
                          caption="Frachtgut / Artikel"
                          dataField="art_nr"
                          editCellTemplate="artikelDropDownBoxEditor"
                          [allowSorting]="false"
                          [setCellValue]="setPositionArtNr">
                <dxo-lookup [dataSource]="artikels"
                            valueExpr="art_nr"
                            displayExpr="art_name1">
                  <dxi-validation-rule type="range" min="1" max="9000000"
                                       message="nur positive ArtikelNr. erlaubt."></dxi-validation-rule>
                  <dxi-validation-rule type="required" [reevaluate]="true"></dxi-validation-rule>
                </dxo-lookup>
              </dxi-column>

              <dxi-column [allowGrouping]="true"
                          dataField="frapo_menge"
                          dataType="number"
                          caption="Menge kg" [width]="80"
                          [allowEditing]="frachtbriefEditAllowed">
                <dxi-validation-rule type="range" min="0" max="599900" message="max. 599900 kg"
                                     [reevaluate]="true"></dxi-validation-rule>
              </dxi-column>
              <dxi-column [allowGrouping]="true"
                          dataField="frapo_anz"
                          alignment="right"
                          dataType="number"
                          caption="Anz. Colli"
                          [width]="80"
                          [allowEditing]="frachtbriefEditAllowed">
              </dxi-column>
              <dxi-column [allowGrouping]="true"
                          caption="Colli" [width]="110"
                          dataField="frapo_verpack_code"
                          editCellTemplate="verpackungDropDownBoxEditor"
                          [setCellValue]="setCellValue"
                          [allowEditing]="frachtbriefEditAllowed"
              >
                <dxo-lookup [dataSource]="ladungstraegers"
                            valueExpr="ltr_code"
                            displayExpr="ltr_name"
                ></dxo-lookup>
              </dxi-column>

              <dxi-column [allowGrouping]="true"
                          id="frapo_stellflaeche_brutto_m2"
                          dataField="frapo_stellflaeche_brutto_m2"
                          alignment="right"
                          dataType="number"
                          caption="Stellfläche m²"
                          width="70">
                <dxo-format type="fixedPoint" [precision]="1"></dxo-format>
                <dxi-validation-rule type="range" min="0" max="500"
                                     message="'Wert muss positiv sein. Maximal 500%"></dxi-validation-rule>
              </dxi-column>
              <dxi-column alignment="right" caption="Aufschlag %" dataField="frapo_aufschlag_proz" dataType="number">
                <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                <dxi-validation-rule type="range" min="0" max="500"
                                     message="'Wert muss positiv sein. Maximal 500%"></dxi-validation-rule>
              </dxi-column>
              <dxi-column dataField="frapo_trab_proz" alignment="right" dataType="number" caption="Tonnagerab. %">
                <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                <dxi-validation-rule type="range" min="0" max="100"
                                     message="'Wert muss positiv sein. Maximal 100%"></dxi-validation-rule>
              </dxi-column>
              <dxi-column dataField="frapo_fracht1_amount" alignment="right" dataType="number" caption="Umschlag"
                          [allowEditing]="frapo_tarifDataEditAllowed">
                <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
              </dxi-column>
              <dxi-column dataField="frapo_fracht3_amount" alignment="right" dataType="number" caption="Seefracht"
                          [allowEditing]="frapo_tarifDataEditAllowed">
                <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
              </dxi-column>
              <dxi-column dataField="frapo_fracht2_amount" alignment="right" dataType="number" caption="Rollgeld"
                          [allowEditing]="frapo_tarifDataEditAllowed">
                <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
              </dxi-column>
              <dxi-column dataField="frapo_artikel_amount" alignment="right" dataType="number" caption="Artikelwert"
                          [allowEditing]="frapo_amountEditAllowed">
                <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
              </dxi-column>
              <dxi-column dataField="frapo_sort_nr" dataType="number" caption="Sort" [sortIndex]="1" sortOrder="asc"
                          [allowEditing]="frapo_tarifDataEditAllowed">
              </dxi-column>
              <dxi-column dataField="art_frachtberechnung_yn"
                          [allowEditing]="false"
                          [visible]="true"
                          caption="Art"
                          cellTemplate="activeTemplate"
                          dataType="number">
              </dxi-column>

              <div *dxTemplate="let data of 'activeTemplate'">
                      <span
                        [ngClass]="{'badge-secondary': !data.value || data.value !== 1 , 'badge-success': data.value}"
                        class="badge badge-pill">{{(data?.value === undefined || data.value === null) ? '?' : data.value === 1 ? 'Frachttarif' : 'non Fracht'}}</span>
              </div>

              <dxi-column [allowGrouping]="true"
                          caption="Manuell" [width]="80"
                          dataField="frapo_frachtberechnung_manuell_yn"
                          cellTemplate="frachtberechnung_manuell_template"
                          dataType="number"
                          allowEditing="false"
                          visible="true">
              </dxi-column>
              <div *dxTemplate="let data of 'frachtberechnung_manuell_template'">
                        <span
                          [ngClass]="{'badge-secondary': !data.value || data.value !== 1, 'badge-danger': data.value}"
                          class="badge badge-pill">{{(data.value === undefined || data.value === null) ? '?' : data.value === 1 ? 'Ja' : 'Nein'}}</span>
              </div>

              <dxi-column dataField="frapo_id" dataType="number" caption="Id" [sortIndex]="2" sortOrder="asc"
                          [allowEditing]="false" [visible]="this.isEmployee">
              </dxi-column>

              <dx-validation-summary></dx-validation-summary>

              <div *dxTemplate="let cellInfo of 'artikelDropDownBoxEditor'">
                <dx-drop-down-box
                  [dropDownOptions]="dropDownOptions"
                  [dataSource]="artikels"
                  [(value)]="cellInfo.value"
                  displayExpr="art_name1"
                  valueExpr="art_nr"
                  contentTemplate="artikelDddwTemplate">
                  <div *dxTemplate="let art of 'artikelDddwTemplate'">
                    <dx-data-grid
                      [dataSource]="artikels"
                      [remoteOperations]="true"
                      [height]="350"
                      [selectedRowKeys]="[cellInfo.value]"
                      [focusedRowEnabled]="true"
                      [focusedRowKey]="cellInfo.value"
                      [hoverStateEnabled]="true"
                      [filterSyncEnabled]="true"
                      [filterValue]="['art_aktiv_yn', '=', 1]"
                      keyExpr="art_nr"
                      (onSelectionChanged)="onPositionChangedArtSelection($event.selectedRowKeys, cellInfo, art.component)"
                    >
                      <dxo-sorting mode="multi"></dxo-sorting>
                      <dxi-column dataField="art_nr" caption="Art.Nr."
                                  [width]="50"></dxi-column>
                      <dxi-column dataField="art_name1" caption="Artikel" sortIndex="1" sortOrder="asc"
                                  [width]="220"></dxi-column>
                      <dxi-column caption="Fracht" cellTemplate="activeTemplate" dataField="art_frachtberechnung_yn"
                                  [width]="100">
                        <div *dxTemplate="let data of 'activeTemplate'">
                        <span [ngClass]="{'badge-secondary': !data.value, 'badge-success': data.value}"
                              class="badge badge-pill">{{data.value.toString() === '1' ? 'Frachttarif' : 'non Fracht'}}</span>
                        </div>
                      </dxi-column>
                      <dxi-column dataField="art_aktiv_yn"
                                  [visible]="false"
                                  [width]="50"></dxi-column>
                      <dxo-scrolling mode="standard"></dxo-scrolling>
                      <dxo-paging [enabled]="false" [pageSize]="100"></dxo-paging>
                      <dxo-selection mode="single" [deferred]="false"></dxo-selection>
                    </dx-data-grid>
                  </div>
                </dx-drop-down-box>
              </div>

              <div *dxTemplate="let cellInfo of 'verpackungDropDownBoxEditor'">
                <dx-drop-down-box
                  [dropDownOptions]="dropDownOptionsVerpackung"
                  [dataSource]="ladungstraegers"
                  [(value)]="cellInfo.value"
                  displayExpr="ltr_name"
                  valueExpr="ltr_code"
                  contentTemplate="verpackungDddwTemplate">
                  <div *dxTemplate="let verp of 'verpackungDddwTemplate'">
                    <dx-data-grid
                      [dataSource]="ladungstraegers"
                      [remoteOperations]="true"
                      [height]="250"
                      [selectedRowKeys]="[cellInfo.value]"
                      [focusedRowEnabled]="true"
                      [focusedRowKey]="cellInfo.value"
                      [hoverStateEnabled]="true"
                      headerFilter="true"
                      keyExpr="ltr_code"
                      (onSelectionChanged)="onPositionChangedVerpackungSelection($event.selectedRowKeys, cellInfo, verp.component)"
                    >
                      <dxi-column dataField="ltr_code" caption="Code" [width]="70"></dxi-column>
                      <dxi-column dataField="ltr_name" caption="Verpackung" sortIndex="1" sortOrder="asc"></dxi-column>
                      <dxi-column dataField="ltr_stellflaeche_brutto_m2"
                                  caption="Stellfläche m² (Bruttofläche, die ein Ladungsträger beansprucht)"
                                  [width]="70"
                                  alignment="center"
                                  dataType="number"></dxi-column>
                      <dxo-scrolling mode="standard"></dxo-scrolling>
                      <dxo-paging [enabled]="false" [pageSize]="100"></dxo-paging>
                      <dxo-selection mode="single" [deferred]="false"></dxo-selection>
                    </dx-data-grid>
                  </div>
                </dx-drop-down-box>
              </div>
            </dx-data-grid>

            <p class="notesmall">Durch Frachtkunden erfasste Frachtmengen und weitere tarifrelevanten Daten sind
              zunächst nur informativ. Sie werden durch die am Transport beteiligten Frachtführer geprüft und ggf.
              angepasst. Erst von Frachtführern freigegebene Werte sind Grundlage für die finale Frachtberechnung.
              Umschlag, Seefracht und Rolle sind Nettotarife zzgl. der ges. MwSt.
            </p>
          </dxi-item>
        </dxi-tab>
      </dxi-item>
      <dxi-item itemType="group"
                name="summary">
        <dx-validation-summary id="summary"></dx-validation-summary>
      </dxi-item>
      <dxi-item itemType="group"
                name="buttons">
        <dx-button [disabled]="!frachtbriefEditAllowed"
                   id="btn_save"
                   text="Speichern"
                   stylingMode="contained"
                   type="default"
                   [useSubmitBehavior]="true">
        </dx-button>&nbsp;
      </dxi-item>
      <dxi-item itemType="empty" horizontalAlignment="left">
      </dxi-item>
    </dx-form>
  </form>

  <dx-tooltip position="right" class='sits-tooltip'>
    <div *dxTemplate="let data of 'content'">
      <div class="sits-tooltip-content">
        <span>{{tooltipText}}</span>
      </div>
    </div>
  </dx-tooltip>
</div>
