import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {Mitarbeiter} from '../api-dtos/mitarbeiter-dto';

@Injectable({
  providedIn: 'root'
})
export class MitarbeiterService {
  baseUrl = `${environment.restApiUrl}/mitarbeiter`;
  mitarbeiterDddwCache: Mitarbeiter[] = [];
  mitarbeiterCache: Mitarbeiter[] = [];
  protected customerIdUsedForCaching: number;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) {
  }

  static handleError(error: any) {
    let errorMessage: string;

    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return;
  }

  public async getAllDddw(): Promise<Mitarbeiter[]> {
    if (!this.isApprovedForProcessing()) {
      // await console.log('Mitarbeiter Service getAllDddw 0', false);
      this.resetCaches();
      return this.mitarbeiterCache;
    }

    if ( this.mitarbeiterDddwCache && this.mitarbeiterDddwCache.length > 0
      && (this.authenticationService.isEmployee)) {
      return this.mitarbeiterDddwCache;
    } else {
      this.mitarbeiterDddwCache = await this.http.get<Mitarbeiter[]>(
        this.baseUrl + '/dropdownlist', await this.getHttpHeader())
        .toPromise();
      return await this.mitarbeiterDddwCache;
    }
  }

  private async getHttpHeader() {
    const accessToken: string = await this.authenticationService.sessionKey;
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  isApprovedForProcessing() {
    return this.authenticationService.authenticated && this.authenticationService.isEmployee;
  }

  public resetCaches() {
    if (!this.mitarbeiterCache) {
      this.mitarbeiterCache = [new Mitarbeiter()];
    }
    if (!this.mitarbeiterDddwCache) {
      this.mitarbeiterDddwCache = [new Mitarbeiter ()];
    }
    this.mitarbeiterDddwCache.splice(0, this.mitarbeiterDddwCache.length);
    this.mitarbeiterCache.splice(0, this.mitarbeiterCache.length);
    this.customerIdUsedForCaching = null;
  }
}
