import { Injectable } from '@angular/core';

import { IColor } from './color';
import { IFahrplan } from './fahrplan';
import { DataElementCodeName } from '../api-basics/data-element-code-name';
import { ISession } from './session';
import { User } from './user';
import { IToken } from './token';

/**
 * This Factory creates Data transfer objects implementing
 * an Interfaces and Initializing it with default values.
 *
 * Implementing a Factory method follows the 3 steps:
 * 1. creating a new instance an casting it to the interface
 * 2. initializing with default values
 * 3. override the values with values from a template (optional)
 */
@Injectable()
export class ApiDtoFactory {

    public Color(template?: IColor): IColor {
        let instance = {} as IColor;

        instance.r = 0;
        instance.g = 0;
        instance.b = 0;
        instance.a = 0;
        instance = this.flatCopy(template, instance);

        return instance;
    }

    public Fahrplan(template?: IFahrplan): IFahrplan {
      let instance = {} as IFahrplan;
      instance = this.flatCopy(template, instance);
      return instance;
    }

    public User(template?: User): User {
        let instance = {} as User ;

        instance = this.flatCopy(template, instance);
        return instance;
    }

    public Token(template?: IToken): IToken {
        let instance = {} as IToken;

        instance.expiration = new Date();

        instance = this.flatCopy(template, instance);
        return instance;
    }

    public Session(template?: ISession): ISession {
        let instance = {} as ISession;

        instance.user = this.User();
        instance.token = this.Token();
        instance.timestamp = new Date();

        instance = this.flatCopy(template, instance);
        return instance;
    }

    public DataElementCodeName<T extends DataElementCodeName>(template?: T): T {
        let instance: T = {} as T;
        instance = this.flatCopy(template, instance);
        return instance;
    }

    private flatCopy<T extends Object>(template?: T, instance?: T): T {
        const target = instance ? instance : {};
        if (template) {
            return Object.assign(target, template);
        }
        return target as T;
    }

}
