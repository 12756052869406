import { Component } from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-imprint',
  templateUrl: (!!environment.targetKey  ? './imprint-' + environment.targetKey + '.component.html' : './imprint.component.html'),
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent {

  constructor() { }

}
