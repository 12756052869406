<div class="card-text sits-print-only">
  Tarifliste für Frachttransporte zwischen Neuharlingersiel Hafen und Spiekeroog.<br>Wir arbeiten aufgrund der
  ADSP (neueste Fassung). Eine Haftungsversicherung ist über Carl Schröter GmbH in Bremen abgedeckt.<br> Stand
  01.01.2010<br><br>
</div>
<dx-popover
  target="#link1"
  position="bottom"
  cdkScrollable
  [width]="300"
  [showTitle]="true"
  title="Hinweise zu den Tarifen ..."
  [(visible)]="inputPopoverVisible">
  <div *dxTemplate="let data = model of 'content'">
    <dx-scroll-view width="100%" height="100%">
      Tarifliste für Frachttransporte zwischen Neuharlingersiel Hafen und Spiekeroog.<br>Wir arbeiten aufgrund der
      ADSP (neueste Fassung). Eine Haftungsversicherung ist über Carl Schröter GmbH in Bremen abgedeckt.<br> Stand
      01.01.2010<br>
    </dx-scroll-view>
  </div>
</dx-popover>

