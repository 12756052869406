import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {User} from '../api/api-dtos/user';
import {environment} from '../../environments/environment';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<User[]>(`${environment.restApiUrl}/users`);
  }

  getById(id: number) {
    return this.http.get(`${environment.restApiUrl}/users/byid/` + id);
  }

  register(user: User) {
    return this.http.post(`${environment.restApiUrl}/users/register`, user);
  }

  update(user: User) {
    return this.http.put(`${environment.restApiUrl}/users/` + user.id, user);
  }

  delete(id: number) {
    return this.http.delete(`${environment.restApiUrl}/users/` + id);
  }
}
