import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User} from '../../../api/api-dtos/user';
import {Subject} from 'rxjs/internal/Subject';
import {DxFormComponent} from 'devextreme-angular';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AlertService} from '../../../shared-services/alert.service';
import {SmsService} from '../../../api/api-services/sms.service';
import {Location} from '@angular/common';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnChanges, OnDestroy {
  public maxCardWidth = 'auto';
  public isValidFormData = false;
  public isProcessing = false;
  public isDisableEditing = false;
  popoverVisible = false;
//  validatePhoneNumber = new BehaviorSubject<boolean>(false);
  userData: User = new User();
  buttonSubmit: any = {
    text: 'Speichern',
    type: 'default',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: true,
    hint: 'Speichert vorgenommene Änderungen.',
    onClick: (e: any) => {
      this.onSubmitData(e);
    },
  };
  @ViewChild(DxFormComponent) userForm: DxFormComponent;
  @ViewChild('formcontainer') formContainer: ElementRef;
  @Input() inputUserId: number | undefined;
  @Input() inputUserName: string | undefined;
  public componentTitle: string = 'Meine Daten';
  private history: string[] = [];
  private isValidPhoneNumber = false;
  private unsubscribe = new Subject<void>();

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private smsService: SmsService,
    private location: Location
  ) {
    this.getIsValidFormData = this.getIsValidFormData.bind(this);
    this.getIsValidPhoneNumber = this.getIsValidPhoneNumber.bind(this);
    this.getMaxCardWidth = this.getMaxCardWidth.bind(this);
    // this.authenticationService.update = this.authenticationService.update.bind(this);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });

    route.params.subscribe(params => {
      this.inputUserId = params['user_id'];
    });
  }

  ngOnInit(): void {
    if (this.authenticationService === undefined || !this.authenticationService.isAuthenticated) {
      this.goBack();
    }

    if (this.inputUserId !== undefined && this.inputUserId > 0) {
      this.authenticationService.getUser(this.inputUserId, null).toPromise().then((resUserData: any) => {
        if (resUserData === undefined) {
          return;
        }
        // console.log('ngOnInit getUser ', this.inputUserId, resUserData);
        this.userData = resUserData.userData;
        this.getIsValidFormData();
        this.isDisableEditing = false;
      });
    } else {
      this.userData = new User();
      this.isDisableEditing = false;

      if (this.authenticationService.isAuthenticated && this.authenticationService.isCustomer) {
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: any): void {
    this.authenticationService.getUser(this.inputUserId, null).toPromise().then((resUserData: User) => {
      if (resUserData === undefined) {
        return;
      }
      this.userData = resUserData;
      // console.log('useredit userData', this.userData)
    });
  }

  form_fieldDataChanged(e: any) {
    this.userData = e.component.option('formData');
    if (this.userData === undefined) {
      return;
    }
    switch (e.dataField) {
      case 'mobilePhone':
        if (e.value === null || e.value.toString().trim() === '') {
          this.userData.mobilePhone = null;
          this.isValidPhoneNumber = true;
          break;
        } else {
          if (e.value.substring(0, 4) === '0049') {
            this.userData.mobilePhone = '+49' + e.value.substring(4, 99);
          }
        }
        this.smsService.callPhoneNumberValidator(e.value).then(resSmsPhoneFormat => {
          if (resSmsPhoneFormat !== undefined && resSmsPhoneFormat?.success === true) {
            this.userData.mobilePhone = resSmsPhoneFormat.international_formatted;
            this.isValidPhoneNumber = true;
            this.getIsValidFormData();
          } else {
            this.isValidPhoneNumber = false;
            this.getIsValidFormData();
          }
        });
        break;
    }
  }

  onSubmitData(e: Event) {
    if (this.getIsValidFormData()) {
      // console.log('onSubmitOrderData ', e, this.gepaeckBeleg.gpblg_extern_mobiletan, this.gepaeckBeleg);
      this.isProcessing = true;
      this.authenticationService.update(this.userData).then(res => {
          console.log('onSubmitData after UPDATE', res);
          if (res && res.status) {
            if (res.status === '200') {
              notify({
                message: 'Die Änderungen wurden gespeichert. ',
                position: {
                  my: 'center',
                  at: 'center'
                }
              }, 'success', 4000);
              this.isDisableEditing = true;
              this.isProcessing = false;
              return;
            } else if (res.status === '404') {
              notify({
                message: 'Fehler beim Speichern (Backend). ',
                position: {
                  my: 'center',
                  at: 'center'
                }
              }, 'warning', 4000);
              this.isProcessing = false;
              return;
            }
            notify({
              message: 'Die Daten konnten nicht gespreichert werden. ',
              position: {
                my: 'center',
                at: 'center'
              }
            }, 'error', 4000);
            this.isProcessing = false;
            return;

          }
        }
      ).catch(error => {
        notify({
          message: 'Problem: Benutzerdaten konnten NICHT erfolgreich gespeichert/geändert werden. ' +
            'Bitte nochmals versuchen. ',
          position: {
            my: 'center',
            at: 'center'
          }
        }, 'error', 6000);
        this.isDisableEditing = false;
        this.isProcessing = false;
      });
      return;
    }
    alert('Bitte erst die Daten vervollständigen, eine TAN anfordern und eintragen.');
    this.isDisableEditing = false;
    this.isProcessing = false;
    return;
  }


  public getIsValidPhoneNumber(e: any): boolean {
    if (e.value === null || e.value.trim() === '') {
      this.isValidPhoneNumber = false;
    }
    return this.isValidPhoneNumber;
  }

  getIsValidFormData(): boolean {
    this.isValidFormData = this.userForm.instance.validate().isValid;
    return this.isValidFormData;
  }


  public goBack(): void {
    if (this.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/').then();
    }
  }

  popoverToggleDefault() {
    this.popoverVisible = !this.popoverVisible;
  }

  getMaxCardWidth(): string {
    return this.maxCardWidth;
  }
}
