<mat-card>
  <mat-card-title>{{getTitleText}}</mat-card-title>
  <div *dxTemplate="let data of 'content'">
    <app-frachtbrief-edit
      [inputAdresseDddw]="adresseDddw"
      [inputFrachtbriefId]="getFrachtbriefId()">
    </app-frachtbrief-edit>
  </div>

</mat-card>
