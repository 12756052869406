import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HostService {

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  public getHostname(): string {
    // Remember to use this.document as unscoped document also exists but is not mockable.
    // return this.document.defaultView.window.location.hostname;

    // Or use the location on document.
    return this.document.location.hostname;
    // return 'logistik-test.inselspedition.de';
  }

  public getFrontendBaseUrl(): string {
    let port: string = (!!this.document.location?.port && this.document.location.port !== '80') ? ':' + this.document.location.port : '';
    return this.document.location.protocol + '//' + this.getHostname() + port ;
    // return 'https://logistik-test.inselspedition.de';

  }

}
