import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {IFrachtOrt} from '../../api/api-dtos/fracht-ort';
import {FrachtOrtService} from '../../api/api-services/fracht-ort.service';
import {TarifService} from '../../api/api-services/tarif.service';
import {Tarif} from '../../api/api-dtos/tarif';
import {TarifPosition} from '../../api/api-dtos/tarif-position';
import {formatDate, Location} from '@angular/common';
import {DxDataGridComponent} from 'devextreme-angular';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {environment} from '../../../environments/environment.prod';

@Component({
  selector: 'app-tarif-list',
  templateUrl: './tarif-list.component.html',
  styleUrls: ['./tarif-list.component.scss']
})

export class TarifListComponent implements OnInit, OnChanges{
  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;
  @Input() inputTimeScope: string | undefined;
  protected isLoadingResults: boolean;
  popoverVisible = false;
  private history: string[] = [];
  componentTitle: string;
  frachtOrts: IFrachtOrt[];
  tarifs: Tarif[] = [];
  tarifPositions: TarifPosition[] = [];
  gridPageSize = 15;
  additionalInfo =  [{title: 'Hinweise zu den Tarifen ...',
    text1: 'Wir arbeiten aufgrund der ADSP (neueste Fassung). ' +
          'Eine Haftungsversicherung ist über Carl Schröter GmbH in Bremen abgedeckt. Stand 01.01.2010',
    text2: 'Standardfrachttarife für Frachttransporte zwischen Neuharlingersiel und Spiekeroog ' +
      '(zzgl. der gesetzlichen Mehrwertsteuer). Sondertarife (Sperrgut, ...) bitte separat anfragen. ',
    text3: 'Siehe auch https://www.inselspedition.de/fracht/frachttarife/',
    },
  ];
  additionalInfoNicer =  [{title: 'Hinweise zu den Tarifen ...',
    items: [{text: 'Tarifliste für Frachttransporte zwischen Neuharlingersiel Hafen und Spiekeroog.'},
      {text: 'Wir arbeiten aufgrund der ADSP (neueste Fassung). ' +
          'Eine Haftungsversicherung ist über Carl Schröter GmbH in Bremen abgedeckt. Stand 01.01.2010'},
      {text: 'Stand 01.01.2010.'},
      ]},
    {title: '2.Hinweise zu den Tarifen ...',
      items: [{text: 'Tarifliste für Frachttransporte zwischen Neuharlingersiel Hafen und Spiekeroog.'},
        {text: 'Wir arbeiten aufgrund der ADSP (neueste Fassung). ' +
            'Eine Haftungsversicherung ist über Carl Schröter GmbH in Bremen abgedeckt. Stand 01.01.2010'},
        {text: 'Stand 01.01.2010.'},
      ]},
    {title: '3.Hinweise zu den Tarifen ...',
      items: [{text: 'Tarifliste für Frachttransporte zwischen Neuharlingersiel Hafen und Spiekeroog.'},
        {text: 'Wir arbeiten aufgrund der ADSP (neueste Fassung). ' +
            'Eine Haftungsversicherung ist über Carl Schröter GmbH in Bremen abgedeckt. Stand 01.01.2010'},
        {text: 'Stand 01.01.2010.'},
      ]},
  ];

  constructor(private frachtOrtService: FrachtOrtService,
              private tarifService: TarifService,
              private router: Router,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private readonly location: Location
  ) {
    this.getFrachtOrts = this.getFrachtOrts.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.goBack = this.goBack.bind(this);

    if ( !!localStorage.getItem('tarif-list.grid.paging.pageSize')) {
      this.gridPageSize = +localStorage.getItem('tarif-list.grid.paging.pageSize');
      if (this.gridPageSize < 5) {
        this.gridPageSize = 5;
      } else if (this.gridPageSize > 300) {
        this.gridPageSize = 300;
      }
    }
    this.componentTitle = 'Frachttarife';

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });

    this.route.queryParams.subscribe(params => {
      if (!environment.production) console.log('Tarifliste PARMs', params);
      this.inputTimeScope = !!params['timeScope'] ? params['timeScope'].toString().toUpperCase() : this.tarifService.timeScopeCurrent;
      if (!environment.production) console.log('Tarifliste PARM timeScope', this.inputTimeScope)
      this.refreshList().then(r => {});
    });
  }


  calculateCellTarifArt(data: any) {
    switch (data.tarpo_intervall_menge ) {
      case 0:
        return 'pauschal';
      case 100:
        return 'je weitere angefangene 100 kg';
    }
  }

  getFrachtOrts(options: any) {
    return {
      store: this.frachtOrts,
      filter: options.data ? ['fort_id', '=', options.data.fort_id] : null
    };
  }

  async ngOnInit() {
    setTimeout(() => {
      this.isLoadingResults = false;
    }, 10000);
    this.tarifs = [];
    this.frachtOrts = [];
    this.frachtOrtService.getAll().subscribe(data => {
      this.frachtOrts = data === undefined || data === null
        ? []
        : data;
    });
    await this.refreshList().then();
  }

  public async refreshList() {
    this.isLoadingResults = true;
    this.tarifService.getAllByTimeScope(this.inputTimeScope) .subscribe( (res: Tarif[]) =>{
      this.tarifs = res
      this.isLoadingResults = false;
      if (this.tarifs.length === 1 ) {
        this.tarifPositions = this.tarifs[0].tarifPositions;
      } else {
        this.tarifPositions = [];
      }
      this.getGridPageSize();
    });
  }

  optionChangedHandler(event: any) {
    if (event.fullName === 'paging.pageSize') {
      if (event.value >= 5) {
        localStorage.setItem('tarif-list.grid.paging.pageSize', event.value);
      }
    }
  }

  getGridPageSize() {
    // devExtr Bug?
    if ( !!this.tarifs && this.tarifs.length > 0 && this.gridPageSize >= this.tarifs[0].tarifPositions.length
      && this.tarifs[0].tarifPositions.length > 25 && this.gridPageSize > 25) {
      this.gridPageSize = 25;
    }
    return this.gridPageSize;
  }

  getTarifvonDatum() {
    // tarifs[0].tarli_von_datum | date: 'dd.MM.yyyy'
    if (!!this.tarifs && this.tarifs.length > 0 ) {
      return 'ab ' + formatDate(this.tarifs[0].tarli_von_datum, 'dd.MM.yyyy', 'de-DE');
    } else {
      return '';
    }
  }

  public goBack(): void {
    if (this.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl("/").then();
    }
  }

  popoverToggleDefault() {
    this.popoverVisible = !this.popoverVisible;
  }

  isInternalUser() {
    return this.authenticationService.authenticated && this.authenticationService.isEmployee;
  }

  ngOnChanges(changes: SimpleChanges): void {
    return;
  }

}
