import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FrachtbriefEditComponent} from './frachtbrief-edit/frachtbrief-edit.component';
import {FrachtbriefListComponent} from './frachtbrief-list/frachtbrief-list.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {FrachtbriefSingleComponent} from './frachtbrief-single/frachtbrief-single.component';
import {MatCardModule} from '@angular/material/card';
import {ClrTooltipModule} from '@clr/angular';
import {DevExpressModule} from '../../shared/devexpress/dev-express.module';

@NgModule({
  declarations: [
    FrachtbriefEditComponent,
    FrachtbriefListComponent,
    FrachtbriefSingleComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        TooltipModule,
        ClrTooltipModule,
        DevExpressModule
    ],
  exports: [
    FrachtbriefEditComponent,
    FrachtbriefListComponent,
    FrachtbriefSingleComponent
  ]
})
export class FrachtbriefModule {
}
