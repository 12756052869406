import {DataElement} from '../api-basics/data-element';
import {Adresse} from './adresse-dto';
import {IFahrplan} from './fahrplan';
import {IFrachtOrt} from './fracht-ort';
import {EventLog} from './event-log';

export class GepaeckBeleg implements DataElement {
  id?: number;
  mand_id?: number;
  gpblg_dummy_fp_art_kz?: string;
  gpblg_dummy_datum?: Date;
  gpblg_dummy_abgang_fort_id?: number;
  gpblg_dummy_abgang_frachtort?: IFrachtOrt;
  fp_abfahrt_fort_id?: number;
  gpblg_dummy_ziel_fort_id?: number;
  gpblg_dummy_ziel_frachtort?: IFrachtOrt;
  fp_id?: number;
  fp_abfahrt_datzeit?: Date;
  gpblg_fahrplan_plan?: IFahrplan;
  gptra_id?: number;
  gpblg_id?: number;
  adr_id?: number;
  gpblg_adresse?: Adresse;
  gptr_id?: number;
  gpblg_adr_name?: string;
  gpblg_adr_gepaeck_name?: string
  gpblg_anz?: number;
  gpblg_gepaeckstuecke_json?: string[];
  gpblg_brutto_amount?: number;
  gpblg_bezahlt_kz?: string;
  gpblg_separat_kz?: string;
  gpblg_bemerk_text?: string;
  gpblg_sort_nr?: number;
  gpblg_create_stamp?: Date;
  gpblg_change_stamp?: Date;
  gpblg_extern_kz?: string;
  gpblg_extern_name?: string;
  gpblg_extern_mobil_tel?: string;
  gpblg_extern_email?: string;
  gpblg_extern_approved_yn?: number;
  gpblg_extern_mobiletan?: string;
  gpblg_extern_personalKey?: string;   // todo: relikt!!!
  gpblg_extern_personalkey?: string;
  gpblg_extern_transpbed_bestaetigt_yn?: number|boolean;
  gpblg_external_order_id?: string;
  gpblg_external_order_provider?: string;
  gpblg_container_krz?: string;
  gptrr_container_text?: string;
  gpblg_reportgroup_nr?: number;
  gpblg_rgempf_adr_id?: number;
  gpblg_zktoart_id?: number;
  gpblg_art_nr?: number;
  gpblg_bezahlt_provider_name?: string;
  gpblg_bezahlt_transaction_id_text?: string;
  gpblg_bezahlt_transaction_data?: string;
  gpblg_bezahlt_stamp?: Date;
  gpblg_storniert_yn?: number|boolean;
  gpblg_storniert_stamp?: Date;
  gpblg_storniert_user?: Date;
  tpol_id?: number;
  message?: string;
  status?: string;
  gpblg_shoporder_guid?: string;
  gpblg_processstatuslist?: string;
  gpblg_process_log?: EventLog[];
}
