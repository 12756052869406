import { Injectable } from '@angular/core';
import { User } from '../api-dtos/user';
import { ApiService } from '../api-basics/api.service';
import { GenericDataService } from '../api-basics/generic-data.service';

@Injectable()
export class UserDataService extends GenericDataService<User> {
    public readonly settingsUrl = 'settings';

    constructor(public readonly apiService: ApiService) {
        super(apiService, 'users');
    }

    public getSettings(user: User,
                          onSuccess: (result: {}) => void,
                          onError: (message: any) => void = console.error): void {
        this.apiService.get(this.serviceUrl, user.id, this.settingsUrl).subscribe(values => onSuccess(values), onError);
    }

    public updateSettings(user: User, settings: any,
                          onSuccess: (result: any) => void,
                          onError: (message: any) => void = console.error): void {
        this.apiService.put(this.serviceUrl, settings, user.id,
                          this.settingsUrl).subscribe(values => onSuccess(values), onError);
    }
}
