import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-pay-one-wait-for-pay-link-completion',
  templateUrl: './pay-one-wait-for-pay-link-completion.component.html',
  styleUrls: ['./pay-one-wait-for-pay-link-completion.component.scss']
})
export class PayOneWaitForPayLinkCompletionComponent implements OnInit {
  @Input() public inputPopupVisible: boolean | false;
  @Input() public isPayOnePopupShowCancelButton: boolean | true;
  @Input() public inputStatusText: string | undefined;
  @Output() public outputCancelButtonClicked = new EventEmitter<boolean>();

  popupVisible = true;
  popupStatusText: string = null;
  cancelButtonOptions: any;

  constructor() {
    this.cancelButtonClicked = this.cancelButtonClicked.bind(this);
    this.cancelButtonOptions = {
      text: 'Close',
      onClick: (e: any) => {
        this.popupVisible = false;
        this.cancelButtonClicked();
      }
    };
  }

  ngOnInit(): void {
   }

  ngOnChanges(changes: SimpleChanges): void {
    if (!environment.production) console.log('ngOnChanges', changes);
    this.popupVisible = this.inputPopupVisible ?? false;
    this.popupStatusText = this.inputStatusText ?? null;
    this.cancelButtonOptions.disabled = !this.isPayOnePopupShowCancelButton;
  }

  public cancelButtonClicked(): void {
    this.popupVisible = false;
    this.outputCancelButtonClicked.emit(true);
    return;
  }

}
