import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  settingsForm: UntypedFormGroup;

  constructor(
    private readonly location: Location,
    private formBuilder: UntypedFormBuilder,
  ) { }

  public ngOnInit() {
    this.settingsForm = this.formBuilder.group({
      readMaxRowsPerPageCount: [
        localStorage.getItem('readMaxRowsPerPageCount') ? localStorage.getItem('readMaxRowsPerPageCount') : 300,
        Validators.compose(
          [Validators.required,
            Validators.min(8) ,
            Validators.max(1000)
          ]
        )]
    });
  }

  public cancel(): void {
    this.location.back();
  }

  public onSubmit(): void {
    // console.log(this.settingsForm);
    if (this.settingsForm.status === 'VALID') {
      // console.log('sieht gut aus');
      // console.log(this.settingsForm.value['readMaxRowsPerPageCount']);
      localStorage.setItem('readMaxRowsPerPageCount', this.settingsForm.value['readMaxRowsPerPageCount']);
      // this.cancel();
    }
  }
}
