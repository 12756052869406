<div class="sits-container">
  <div class="sits-card-center dx-card sits-card-small">
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">
        <dx-toolbar class="sits-print-hide">
          <dxi-item widget="dxButton" location="before">
            <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
          </dxi-item>
          <dxi-item location="left" widget="dxButton" locateInMenu="never">
            <div *dxTemplate>
              <div class="toolbar-label">{{[componentTitle]}}</div>
            </div>
          </dxi-item>
        </dx-toolbar>
      </div>
      <div class="dx-field-item-content" *ngIf="isAuthenticated">Nach dem erfolgreichen Abmelden stehen nur noch Dialoge
        und Inhalte zur Verfügung, die öffentlich sind
        oder keiner Zugriffsbeschränkung unterliegen.
      </div>
      <div class="dx-field-empty-item" *ngIf="isAuthenticated"><br></div>
      <div class="dx-fieldset" *ngIf="isAuthenticated">
        <dx-validation-summary id="summary"></dx-validation-summary>
        <dx-button [disabled]="false"
                   id="cancel"
                   text="Abbruch"
                   stylingMode="contained"
                   (click)="goBack()">
        </dx-button>&nbsp;
        <dx-button [disabled]="false"
                   id="logout"
                   text="Abmelden"
                   stylingMode="contained"
                   type="default"
                   (click)="logout()">
        </dx-button>
      </div>
      <div class="dx-fieldset" *ngIf="!isAuthenticated">
        <div class="dx-field-empty-item"><br></div>
        <div class="dx-field-item-content"><h1>Die Sitzung ist beendet.</h1></div>
        <div class="dx-field-empty-item"><br></div>
        <dx-button [disabled]="false"
                   id="login"
                   text="Erneut anmelden ..."
                   stylingMode="contained"
                   type="default"
                   [routerLink]="['/login']">
        </dx-button>&nbsp;
        <dx-button [disabled]="false"
                   id="home"
                   text="< Home"
                   stylingMode="contained"
                   [routerLink]="['/']">
        </dx-button>&nbsp;
      </div>
    </div>
  </div>
</div>


