import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {StorageBoxService} from '../../../api/api-services/storage-box.service';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {formatDate, Location} from '@angular/common';
import {GeraetService} from '../../../api/api-services/geraet.service';
import {Geraet} from '../../../api/api-dtos/geraet';
import {GeraeteVermietungStorageBox} from '../../../api/api-dtos/geraete-vermietung-storage-box';
import {User} from '../../../api/api-dtos/user';
import {DxDateBoxComponent} from 'devextreme-angular';
import {map, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {IArtikel} from '../../../api/api-dtos/artikel';
import {ArtikelService} from '../../../api/api-services/artikel.service';

@Component({
  selector: 'app-storagebox-availability',
  templateUrl: './storagebox-availability.component.html',
  styleUrls: ['./storagebox-availability.component.scss']
})

export class StorageboxAvailabilityComponent implements OnInit {
  @ViewChild('dateSelector', {static: false}) dateSelector: DxDateBoxComponent;
  componentTitle = 'Lagerbox Verfügbarkeit prüfen';
  public rangeStartDate: Date = new Date();
  public rangeEndDate: Date = new Date();
  public selectedStartDate: Date;
  public selectedEndDate: Date;
  storageBoxData: Geraet[] = [{}];
  selectedStorageBoxIds: number[] = [];
  selectedstorageBoxs: Geraet[] = [];
  defaultLagerboxServiceArtikel: IArtikel;

  public currentUser = new User;

  constructor(
    private router: Router,
    private storageBoxService: StorageBoxService,
    private geraetService: GeraetService,
    private artikelService: ArtikelService,
    public authenticationService: AuthenticationService,
    private readonly location: Location
  ) {
    this.currentUser = this.authenticationService.currentUser;

    this.getCustomizedLabel = this.getCustomizedLabel.bind(this);
    this.getCustomizedSliderMarker = this.getCustomizedSliderMarker.bind(this);
    this.createStorageBoxOrder = this.createStorageBoxOrder.bind(this);
    this.selectionChangedHandler = this.selectionChangedHandler.bind(this);
    this.rangeStartDate = this.storageBoxService.getMinimalStartDate();
    this.rangeEndDate = this.storageBoxService.addYearPeriods(this.rangeStartDate, 2);
    // at least tomorrow
    this.selectedStartDate = this.rangeStartDate < new Date(new Date().setHours(6, 0, 0, 0) + (24 * 60 * 60 * 1000))
      ? new Date(new Date().setHours(6, 0, 0, 0) + (24 * 60 * 60 * 1000))
      : this.rangeStartDate;
    this.selectedEndDate = this.storageBoxService.addYearPeriods(this.rangeStartDate, 1);

    this.storageBoxService.getDefaultLagerboxServiceArtikel().then(resArt => {
      if (resArt.art_nr) {
        this.defaultLagerboxServiceArtikel = resArt;
        this.reloadStorageBoxData();
      }
    });
  }

  public getCustomizedSliderMarker(data: any): string {
    if (data === undefined) {
      return '';
    }
    return formatDate(new Date(data.value), 'EE dd.MM.yy ', 'de-DE');
  };

  public getCustomizedLabel(data: any): string {
    if (data === undefined) {
      return '';
    }
    return formatDate(new Date(data.value), 'MMM yy ', 'de-DE');
  };

  ngOnInit(): void {
    return;
  }

  public goBack(): void {
    this.location.back();
  }

  toolbarDateChanged($event: any) {
    if ($event.value == 'undefined') {
      console.log('toolbarDateChanged ');
      return;
    }
    if ($event.value === null) {
      return;
    }
    this.selectedStartDate = $event.value;
    this.selectedEndDate = this.storageBoxService.addYearPeriods(this.selectedStartDate, 1);
    this.reloadStorageBoxData();
    return;
  }

  onRangeValueChanged($event: any) {
    if ($event.value == 'undefined') {
      return;
    }
    if ($event.value.length < 2) {
      return;
    }
    this.selectedStartDate = $event.value[0];
    this.selectedEndDate = $event.value[1];
    this.reloadStorageBoxData();
    return;
  }

  public isCreateStorageBoxOrderButtonEnabled(): boolean {
    return true;
  };

  public isCreateStorageBoxOrderButtonDisabled(): boolean {
    return false;
  };

  /**
   * Durchstieg zum Gepaeckauftrag
   * @param e
   */
  createStorageBoxOrder(e: any) {
    // console.log('createStorageBoxOrder e' , e);
    let gerNr: number;
    if (e.ger_nr !== undefined && e.ger_nr == 0 && (!this.selectedstorageBoxs || this.selectedstorageBoxs.length < 1)) {
      gerNr = this.storageBoxData[0].ger_nr;
    } else if (e.ger_nr !== undefined && e.ger_nr == 0 && this.selectedstorageBoxs.length > 0) {
      gerNr = this.selectedstorageBoxs[0].ger_nr;
    } else {
      gerNr = e.row.data.ger_nr ? e.row.data.ger_nr : 0;
    }
    // console.log('createStorageBoxOrder gerNr' , gerNr);

    let storageBoxOrderData: GeraeteVermietungStorageBox = new GeraeteVermietungStorageBox();
    storageBoxOrderData.gervm_ger_nr = gerNr;
    storageBoxOrderData.gervm_beginn_datzeit = this.selectedStartDate;
    storageBoxOrderData.gervm_ende_datzeit = this.selectedEndDate;

    // console.log('createStorageBoxOrder storageBoxOrderData' , storageBoxOrderData);

    if (this.currentUser !== undefined && (this.currentUser.isEmployee === 1 || this.currentUser.isCustomer === 1)) {
      this['router'].navigate(['storagebox/order/cust/edit', storageBoxOrderData]).then();
      // return;
    } else {
      // fuer nicht angemeldete Benutzer (Gaeste)
      this['router'].navigate(['storagebox/order/guest/edit', storageBoxOrderData]).then();
    }

  };

  toolbarAction(mode: string) {
    switch (mode) {
      case 'plus01': {
        this.selectedStartDate.setDate(this.selectedStartDate.getDate() + 1);
        if (this.selectedStartDate > this.rangeEndDate) {
          this.selectedStartDate = this.rangeEndDate;
        }
        this.dateSelector.instance.repaint();
        this.selectedEndDate = this.storageBoxService.addYearPeriods(this.selectedStartDate, 1);
        this.reloadStorageBoxData();
        break;
      }
      case 'minus01': {
        this.selectedStartDate.setDate(this.selectedStartDate.getDate() - 1);
        if (this.selectedStartDate < this.rangeStartDate) {
          this.selectedStartDate = this.rangeStartDate;
        }
        this.dateSelector.instance.repaint();
        this.selectedEndDate = this.storageBoxService.addYearPeriods(this.selectedStartDate, 1);
        this.reloadStorageBoxData();
        break;
      }
      case 'exportxlsx': {
        // this.grid.instance.exportToExcel(false);
        break;
      }
    }
  }

  selectionChangedHandler(event: any) {
    this.selectedstorageBoxs = event.selectedRowsData as Geraet[];
  }

  // --------------------------------------------------------------------------
  // DataGrid Hooks

  public reloadStorageBoxData() {
    if (!environment.production) console.log('reloadStorageBoxData() ', this.selectedStartDate, this.selectedEndDate, (!!this.selectedStartDate && !!this.selectedEndDate) );
    if (!!this.selectedStartDate && !!this.selectedEndDate) {
      let alwaysIncludeGerNrOfGervmId: number = null;
      if (!environment.production) console.log('reloadStorageBoxData calling getStorageStorageBoxDddwAvailableInRange ');
      // 150 oder 160 ...;
      this.artikelService.getArtikelByDate(this.defaultLagerboxServiceArtikel.art_nr, this.selectedStartDate).then( res => {
        this.defaultLagerboxServiceArtikel.art_preis1 = res.art_preis1;
        this.defaultLagerboxServiceArtikel.art_brutto_preis1 = res.art_brutto_preis1;
      });

      this.geraetService.getStorageStorageBoxDddwAvailableInRange(this.selectedStartDate, this.selectedEndDate, alwaysIncludeGerNrOfGervmId)
        .pipe(tap((resStorageBox) => {
          this.storageBoxData = resStorageBox;
        })).subscribe();
    } else {
      this.storageBoxData = [{}];
    }
  }

}
