import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, take} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {ServerMessage, User} from '../api/api-dtos/api-dtos.module';
import {of} from 'rxjs/internal/observable/of';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public authenticated:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isAuthenticated);
  http: HttpClient;
  serverMessage: ServerMessage;
  private headers: HttpHeaders = this.getHeaders();
  private options: any = {headers: this.headers};
  private _isEmployee: boolean = false;
  private _isAuthenticated = false;
  private _customerId: number;
  private _isCustomer: boolean = false;
  private _customerCompanyName: string;
  private _currentUser: User;
  private _sessionKey: string;
  private _isAdminUser: boolean;

  constructor(
    private router: Router,
    http: HttpClient) {
    this.http = http;
    this._sessionKey = localStorage.getItem('sessionKey') ?? '';
  }

  public get isCustomerCompanyName(): string {
    return this._customerCompanyName;
  }

  get isAdminUser(): boolean {
    return this._isAdminUser;
  }

  set isAdminUser(value: boolean) {
    this._isAdminUser = value;
  }

  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  set isAuthenticated(value: boolean) {
    this._isAuthenticated = value;
  }

  public get sessionKey(): string {
    return this._sessionKey;
  }

  public set sessionKey(value: string) {
    this._sessionKey = value;
    if (!this._sessionKey || this._sessionKey.trim() === '') {
      this.isAuthenticated = false;
    }
  }

  public get isEmployee(): boolean {
    return this._isEmployee;
  }

  set isEmployee(value: boolean) {
    this._isEmployee = value;
  }

  public get isCustomer(): boolean {
    return this._isCustomer;
  }

  set isCustomer(value: boolean) {
    this._isCustomer = value;
  }

  public get customerId(): number {
    return this._customerId;
  }

  public get currentUser(): User {
    return this._currentUser;
  }

  public checkIsAuthenticated(): boolean {
    if (this.isAuthenticated) {
      return this.isAuthenticated;
    } else if (!!this._sessionKey && (!this.isCustomer && !this.isEmployee)) {
      this.checkRefreshLogin().then();
      return this.isAuthenticated;
    } else {
      return this.isAuthenticated;
    }
  }

  public login(userName: string, password: string) {
    return this.http.post<any>(`${environment.restApiUrl}/users/authenticate`,
      {userName: userName, password: password})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.sessionKey && user.status === 1) {
          this._currentUser = user.userData;
          this._sessionKey = user.sessionKey;
          this.isAuthenticated = true;
          this._customerId = user.userData.customerId;
          this.isCustomer = user.userData.isCustomer === 1;
          this._customerCompanyName = user.userData.customerCompanyName;
          this.isEmployee = user.userData.isEmployee === 1;
          this.isAdminUser = user.userData.isAdminUser === 1;
          localStorage.setItem('sessionKey', user.sessionKey);
          this.authenticated.next(true);
          this.router.navigate(['/loginsuccess']);
          return user;
        } else {
          // localStorage.removeItem('sessionKey');
          this._sessionKey = '';
          this.authenticated.next(false);
          this._isAuthenticated = false;
          user.sessionKey = null;
          return user;
        }
      }));
  }

  public logout() {
    const url = `${environment.restApiUrl}/users/logout`;
    const options = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.sessionKey)
    };

    if (this._isAuthenticated) {
      this.http.get<ServerMessage>(url, options).toPromise().then(data => {
        // this.serverMessage = data;
        this.sessionCleanUp('');
        this.router.navigate(['/login']).then();
      });
    } else {
      // auf jeden Fall die Session lokal beenden, auch wenn die Server-Session nicht beendet werden kann,
      // damit ein neuer Login ermoeglicht wird und eine neue Session generiert werden kann...
      this.authenticated.next(false);
      this.sessionCleanUp('');
      this.router.navigate(['/']).then();
    }
  }

  public async checkRefreshLogin(): Promise<any> {
    let serviceResult: ServerMessage = new ServerMessage;
    if (!(!!this._sessionKey)) {
      this.authenticated.next(false);
      this.sessionCleanUp('');
      return new Promise<boolean>(() => {return false});
    }

    const url = `${environment.restApiUrl}/users/sessioncheckrefresh`;
    const options = {headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.sessionKey)};

    this.http.put<ServerMessage>(url, options).pipe( take(1)).subscribe(resCheck => {
      serviceResult = resCheck;
      if (!environment.production) console.log('checkRefreshLogin result ',serviceResult );
      if (serviceResult && serviceResult.status && serviceResult.status === 'success') {
        this._isAuthenticated = true;
        this.authenticated.next(true);
        this._currentUser = serviceResult.userData;
        this._customerId = serviceResult.userData.customerId;
        this.isCustomer = serviceResult.userData.isCustomer === 1;
        this._customerCompanyName = serviceResult.userData.customerCompanyName;
        this._isEmployee = serviceResult.userData.isEmployee === 1;
        return new Promise<boolean>(() => {return true});
      } else {
        this.authenticated.next(false);
        this.sessionCleanUp('');
        return new Promise<boolean>(() => {return false});
      }
    });
  }

  private getHeadersSimple(): HttpHeaders {
    return new HttpHeaders()
      .delete('Content-Type')
      .append('Accept', 'application/json')
      .append('Content-Type', 'application/json');
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .delete('Content-Type')
      .append('Accept', 'application/json')
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + this._sessionKey);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      // continue even if we got an error...
      return of(result as T);
    };
  }

  private sessionCleanUp(data?: string): ServerMessage {
    // eslint-disable-next-line prefer-const
    let serviceResult: ServerMessage = new ServerMessage();
    this._currentUser = new User();
    this._sessionKey = null;
    this._isAuthenticated = false;
    this._isCustomer = false;
    this._customerId = null;
    this._customerCompanyName = null;
    this._isEmployee = false;
    this.authenticated.next(false);

    serviceResult.userData = new User();
    serviceResult.userIsEmployee = false;
    serviceResult.userIsCustomer = false;
    serviceResult.userData.isCustomer = 0;
    serviceResult.userData.isEmployee = 0;
    serviceResult.EmployeeId = null;
    serviceResult.sessionKey = null;

    localStorage.removeItem('sessionKey');
    return serviceResult;
  }

  initializeAuth(): Promise<any> {
    return new Promise(() => {
      // was so zu tun ist, bevor es losgeht ...
      /*const promise = this.checkRefreshLogin().then( res => {
        // return res;
      });*/


      setTimeout(() => {
        // doing something
        // resolve();
      }, 3000);
    });
  }

  public async passwordForgot(userName: string): Promise<ServerMessage> {
    let serviceResult = new ServerMessage;
    if (userName ===undefined || !(!!userName)  ){
      serviceResult.status='error';
      return serviceResult;
    }
    return this.http.post<ServerMessage>(
      `${environment.restApiUrl}/users/passwordforgot`,
      {
        userName: userName,
        renewPasswordUrl: window.location.protocol + '//' + window.location.host + '/renewforgottenpassword'
      },
      {headers: await this.getHeaders()})
      .toPromise()
      .then(resCheck => {
        serviceResult = resCheck;
        if (serviceResult && serviceResult.status && serviceResult.status === 'success') {
          return serviceResult;
        } else {
          return serviceResult;
        }
      }).catch(error => {
        Promise.reject(error);
        return serviceResult;
      });
  }

  public async changePassword(currentPassword: string, newPassword: string): Promise<ServerMessage> {
    let serviceResult = new ServerMessage;
    return this.http.post<ServerMessage>(
      `${environment.restApiUrl}/users/changepassword`,
      {currentPassword: currentPassword, newPassword: newPassword},
      {headers: await this.getHeaders()})
      .toPromise()
      .then(resCheck => {
        serviceResult = resCheck;
        if (serviceResult && serviceResult.status && serviceResult.status === 'success') {
          return serviceResult;
        } else {
          return serviceResult;
        }
      }).catch(error => {
        Promise.reject(error);
        return serviceResult;
      });
  }

  public async setNewPasswordAfterForget(userName: string, password: string, responseCode: string): Promise<ServerMessage> {
    let serviceResult = new ServerMessage;
    return this.http.post<ServerMessage>(
      `${environment.restApiUrl}/users/setnewpasswordafterforget`,
      {userName: userName, password: password, sessionKey: responseCode},
      {headers: await this.getHeaders()})
      .toPromise()
      .then(resCheck => {
        serviceResult = resCheck;
        if (serviceResult && serviceResult.status && serviceResult.status === 'success') {
          return serviceResult;
        } else {
          return serviceResult;
        }
      }).catch(error => {
        Promise.reject(error);
        return serviceResult;
      });
  }

  public async registerUser(userData: User): Promise<ServerMessage> {
    let serviceResult = new ServerMessage;
    return this.http.post<ServerMessage>(
      `${environment.restApiUrl}/users/registeruser`,
      {userData: userData},
      {headers: await this.getHeaders()})
      .toPromise()
      .then(resCheck => {
        serviceResult = resCheck;
        if (serviceResult && serviceResult.status && serviceResult.status === 'success') {
          return serviceResult;
        } else {
          return serviceResult;
        }
      }).catch(error => {
        Promise.reject(error);
        return serviceResult;
      });
  }

  public getUser(inputUserId: number, tan?: number): Observable<User> {
    if (!inputUserId || inputUserId < 1 || !this.authenticated) {
      return of();
    }

    return this.http.get<User>(
      `${environment.restApiUrl}/users/byid`,
      {headers: this.getHeaders(), params: {usrId: inputUserId}}
    );
  }

  public update(userData: User): Promise<ServerMessage> {
    let serviceResult = new ServerMessage;
    const url = `$${environment.restApiUrl}/${userData.id}`;
    return this.http.put(
      url,
      userData,
      {headers: this.getHeaders()}).toPromise()
      .then(resCheck => {
        serviceResult = resCheck;
        if (serviceResult && serviceResult.status && serviceResult.status === 'success') {
          return serviceResult;
        } else {
          return serviceResult;
        }
      }).catch(error => {
        console.log('update error ', error);
      // Promise.reject(error);
      return serviceResult;
    });
  }

  setPublicSessionId() {
    if (!localStorage.getItem('publicSessionId')) {
      this.getGuid().then(resGuid => {
        if (!!resGuid) {
          localStorage.setItem('publicSessionId', resGuid);
          return;
        }
      })
    }
  }

  public getPublicSessionId(): string {
    if (!localStorage.getItem('publicSessionId')) {
      this.setPublicSessionId();
    }
    return localStorage.getItem('publicSessionId');
  }

  async getGuid(): Promise<string> {
    console.log('getGuid ');
    const url = `${environment.restApiUrl}/guid`;
    return await this.http.get<any>(url,
      {headers: this.getHeadersSimple()}).toPromise().then(data => {
      console.log('getGuid data', data, data.guid);
      return data.guid;
    });
  }
}
