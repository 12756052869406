// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
//   restApiUrl: 'https://db1042610-test.einfachmacher.de/api/v1',
export const environment = {
  production: false,
  version: '2.3.53.529',
  restApiUrl: 'https://api1042611-test.einfachmacher.de/api/v1',
  sitsApiKey: 'rLT2NLPUdjRFQqvQ',
  aes256CtrVector: 'k6qRXoA1bZglk',
  statisticServiceApiUrl: '',
  logStatistics: false,
  imprintUrl: 'https://www.inselspedition.de/impressum',
  sessionKey: '',
  targetKey: '10426',
  mainlandLocationName: 'Neuharlingersiel,DE',
  islandLocationName: 'Spiekeroog,DE',
  enableServiceWorker: false,
  externalGepaeckInterface: false
};

