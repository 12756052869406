import {DataElement} from '../api-basics/data-element';
import {Adresse, IFahrplan, FrachtPosition, IParitaet} from './api-dtos.module';

// b_fracht_kopf
export class FrachtBrief implements DataElement {
  id?: number;
  mand_id?: number;
  frako_id?: number;
  frako_datum?: Date;
  frako_absender_adr_id?: number;
  frako_absender?: Adresse;
  frako_empf_adr_id?: number;
  frako_empf?: Adresse;
  frako_rgempf_adr_id?: number;
  frako_rgempf?: Adresse;
  frako_paritaet_code?: string;
  frako_paritaet?: IParitaet;
  frako_bemerk_text?: string;
  frako_rg_nr?: number;
  frako_storno_kz?: string;
  frako_gs_nr?: number;
  frako_einzel_rg_kz?: string;
  frako_stop_kz?: boolean;
  frako_belegart_code?: string;
  frako_vor_id?: number ;
  frako_abgang_fort_id?: number;
  frako_ziel_fort_id?: number;
  frako_abgangsort_name?: string;
  frako_zielort_name?: string;
  frako_belegtyp_code?: string;
  frako_tpl_kz?: string;
  frako_tpl_name?: string;
  frako_create_stamp?: Date;
  frako_change_stamp?: Date;
  frako_create_user?: string;
  frako_change_user?: string;
  frako_copymark_text?: string;
  zbd_id?: number;
  frako_fest_status_nr?: number;
  frako_trst_status_nr?: number;
  frako_plan_fp_id?: number;
  frako_fahrplan_plan?: IFahrplan;
  frako_ist_fp_id?: number;
  frako_fahrplan_ist?: IFahrplan;
  frako_fakstat_status_nr?: number;
  frapo_menge_sum?: number;
  frapo_notarif_cnt?: number;
  frachtPositions?: FrachtPosition[];

  constructor() {}

}
