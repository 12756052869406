import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ApiDtoFactory} from './api-dto-factory';

export {IArtikel} from './artikel';
export {IColor} from './color';
export {IFahrplan} from './fahrplan';
export {IFont} from './font';
export {IFrachtErfassungsStatus} from './fracht-erfassungs-status';
export {IFrachtOrt} from './fracht-ort';
export {IStreckeSetting} from './strecke-setting';
export {IParitaet} from './paritaet-dto';
export {ISession} from './session';
export {ITan} from './itan';
export {ITextBlock} from './text-block';
export {IToken} from './token';
export {ITransportStatus} from './transport-status';

export {Adresse} from './adresse-dto';
export {ColumnValueFilter} from './column-value-filter';
export {EventLog} from './event-log';
export {FrachtBrief} from './fracht-brief';
export {FrachtPosition} from './fracht-position';
export {GepaeckBeleg} from './gepaeck-beleg';
export {GepaeckTransaction} from './gepaeck-transaction';
export {Geraet} from './geraet';
export {GeraeteVermietungStorageBox} from './geraete-vermietung-storage-box';
export {Lock} from './lock';
export {Patch} from './patch';
export {PaymentLog} from './payment-log';
export {ServerMessage} from './server-message';
export {ShopOrder} from './shop-order';
export {ShopOrderItem} from './shop-order-item';
export {SmsPhoneFormat} from './sms-phone-format';
export {SmsMessageResponse} from './sms-message-response';
export {User} from './user';
export {ZahlKontoArt} from './zahl-konto-art-dto';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ApiDtoFactory
  ]
})
export class ApiDtosModule {
}
