import { Injectable } from '@angular/core';
import { Unit } from './unit';
import { UnitMeta } from './unit-meta';
import { UnitSystem } from './unit-system';
import { UnitType } from './unit-type';

@Injectable()
export class UnitService {
    private readonly supportedUnits: any;

    private readonly targetLengthUnit: Unit;
    private readonly targetAreaUnit: Unit;

    // Selected unit will be stored in configurationService!
    constructor() {
        const unitList: { [id: string]: Unit; } = {};
        unitList['m'] = new Unit('m', 'meters',
          new UnitMeta(1, UnitSystem.Metric, UnitType.Length, 'meters', 'Meters [m]'));
        unitList['km'] = new Unit('km', 'kilometers',
          new UnitMeta(0.001, UnitSystem.Metric, UnitType.Length, 'kilometers', 'Kilometers [km]'));
        unitList['ft'] = new Unit('ft', 'feet',
          new UnitMeta(3.2808398950131233595800524934383, UnitSystem.NonMetric, UnitType.Length, 'feet', 'Feet (FT)'));
        unitList['yd'] = new Unit('yd', 'yards',
          new UnitMeta(1.09361329833771, UnitSystem.NonMetric, UnitType.Length, 'yards', 'Yards (YD)'));
        unitList['mi'] = new Unit('mi', 'miles',
          new UnitMeta(0.000621371192237334, UnitSystem.NonMetric, UnitType.Length, 'miles', 'Miles (MI)'));
        unitList['nm'] = new Unit('nm', 'nautical miles',
          new UnitMeta(0.000539956803455724, UnitSystem.NonMetric, UnitType.Length, 'nautical_miles', 'Nautical Miles (NM)'));

        unitList['sme'] = new Unit('sme', 'squaremeters',
          new UnitMeta(1, UnitSystem.Metric, UnitType.Area, 'square-meters', 'Square Meters [m²]'));
        unitList['ha'] = new Unit('ha', 'hectares',

          new UnitMeta(0.0001, UnitSystem.Metric, UnitType.Area, 'hectares', 'Hectares [ha]'));
        unitList['skm'] = new Unit('skm', 'squarekilometers',
          new UnitMeta(0.000001, UnitSystem.Metric, UnitType.Area, 'square-kilometers', 'Square Kilometers [km²]'));
        unitList['sf'] = new Unit('sf', 'squarefeet',
          new UnitMeta(10.7639104167097, UnitSystem.NonMetric, UnitType.Area, 'square-feet', 'Square Feet (US) [SF]'));
        unitList['sy'] = new Unit('sy', 'Square Yards',
          new UnitMeta(1.19599004630108, UnitSystem.NonMetric, UnitType.Area, 'square-yards', 'Square Yards [SY]'));
        unitList['sm'] = new Unit('sm', 'Square Miles',
          new UnitMeta(0.000000386102158542446, UnitSystem.NonMetric, UnitType.Area, 'square-miles', 'Square Miles [SM]'));

        this.supportedUnits = unitList;
        this.targetLengthUnit = unitList['m']; // Target Length Unit = Meters, Factor = 1
        this.targetAreaUnit = unitList['sme']; // Target Area Unit = SquareMeters, Factor = 1
    }

  public static toDecimalMinuteSecondFormat(value: number): string {
    const a = parseInt(value.toString(), 10);
    value = (value - a) * 100;
    const b = parseInt((value / 6000).toString(), 10);
    value = (value - (b / 60 * 100));
    const c = (value / 100 * 3600).toFixed(3);
    return `${a}°${b}'${c}\"`;
  }

  //  -------------------------------Coord-System-Transformators -----------------------------------------------
  public static decimalDegreesToDecimalDegrees(input: string): number {
    const parsed = UnitService.parseFloat(input);
    return (Number(parsed));
  }

  public static degreesMinSecondsToDecimalDegrees(input: string): number {
    const parsed = UnitService.parseFloat(input);
    const degree = parsed.split('°', 1)[0];
    let minutes = parsed.split('\'', 1)[0];
    minutes = minutes.slice(degree.length + 1, minutes.length);
    const seconds = parsed.slice(degree.length + minutes.length + 2, parsed.length - 2);
    return (+degree + (+minutes / 60) + (+seconds / 3600));
  }

  public static degreesDecMinutesToDecimalDegrees(input: string): number {
    const parsed = UnitService.parseFloat(input);
    const degree = parsed.split('°', 1)[0];
    const minutes = parsed.slice(degree.length + 1, parsed.length - 1);
    return (+degree + (+minutes / 60));
  }

  public static utmToDecimalDegrees(input: string): number {
    const parsed = UnitService.parseFloat(input);
    return (Number(parsed));
  }

  public static parseFloat(input: string): string {
    return input.replace(',', '.').replace(' ', '');
  }

  public getUnitByTag(tag: string): Unit {
        return (this.supportedUnits[tag]);
    }

    public getTagByUnit(unit: Unit): string {
        for (const key of Object.keys(this.supportedUnits)) {
            if (unit.code === this.supportedUnits[key].code) {
                return key;
            }
        }
        return null;
    }

    public getUnitByType(type: UnitType, system: UnitSystem ): Unit[] {
        const output: Array<Unit> = [];

        for (const key of Object.keys(this.supportedUnits)) {
            const current = this.supportedUnits[key];

            if (current.meta.type === type && (!system || system === current.meta.system)) {
                output.push(current);
            }
        }
        return output;
    }

    /**
     * calculates a value from basic to target unit
     * @value {number} value for calculation
     * @basicUnit {Unit} basic unit, when null the target unit is used
     * @targetUnit {Unit} target unit, when null the target unit is used
     */
    public calculateValue(value: number, basicUnit: Unit = null, targetUnit: Unit = null) {
        if (basicUnit === targetUnit) {
            return value; // Return value when units are null or equal
        }

        if (targetUnit == null) {
            targetUnit = basicUnit.meta.type === UnitType.Length ? this.targetLengthUnit : this.targetAreaUnit;
        } else if (basicUnit == null) {
            basicUnit = targetUnit.meta.type === UnitType.Length ? this.targetLengthUnit : this.targetAreaUnit;
        }

        return (value / basicUnit.meta.factor * targetUnit.meta.factor);
    }

}
