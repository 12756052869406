import { UnitSystem } from './unit-system';
import { UnitType } from './unit-type';

export class UnitMeta {
    constructor(// Factor to normal
        public readonly factor: number,
        // Metric / Non-Metric
        public readonly system: UnitSystem,
        // Area / Length
        public readonly type: UnitType,
        // Native Code, e.g. hectares
        public readonly native: string,
        // Display name
        public readonly display: string) {
    }
}
