import {ClosingSize} from '../../shared-services/closing-size';

export class FahrplanFilter {
  date?: Date;
  pastDays?: number;
  futureDays?: number;
  transportArt?: string;
  abfahrtOrtId?: number;
  zielOrtId?: number;
  resultsetSize?: ClosingSize;
}
