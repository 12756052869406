import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IParitaet} from '../api-dtos/paritaet-dto';

@Injectable({
  providedIn: 'root'
})
export class ParitaetService {
  baseUrl = `${environment.restApiUrl}/paritaet`;
  paritaetCache: IParitaet[];

  constructor(
    private http: HttpClient) { }

  public async getAll(): Promise<IParitaet[]> {
    if (this.paritaetCache && this.paritaetCache.length > 0) {
      return this.paritaetCache;
    } else {
      this.paritaetCache = await this.http
        .get<IParitaet[]>(this.baseUrl, await this.getHttpHeader())
        .toPromise();
      return this.paritaetCache;
    }
  }

  public async getParitaet(code: string): Promise<IParitaet> {
    return await this.http.get<IParitaet>(
      `${this.baseUrl}/${code}`,
      await this.getHttpHeader()
    ).toPromise();
  }

  private async getHttpHeader() {
    const accessToken: string = await localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }
}


