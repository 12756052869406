<div class="sits-container">
  <div class="sits-card-center dx-card sits-card-small">
    <form action="your-action" (submit)="onFormSubmit($event)" autocomplete="on">
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">Passwort ändern</div>
        <div class="dx-fieldset">
          <div class="dx-field" >
            <div class="dx-field-label">Altes Passwort</div>
            <div class="dx-field-value">
              <dx-text-box
                mode="password"
                [(value)]="currentpassword">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Passwort ist erforderlich"></dxi-validation-rule>
                  <dxi-validation-rule type="stringLength" [min]="3" message="Mindestens 3 Zeichen"></dxi-validation-rule>
                  <dxi-validation-rule type="stringLength" [max]="20" message="Maximal 20 Zeichen"></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Neues Passwort</div>
            <div class="dx-field-value">
              <dx-text-box
                mode="password"
                [(value)]="newpassword">
                <dx-validator>
                  <dxi-validation-rule type="pattern" [pattern]="passwordPattern" message="Mindestens 8 Zeichen, keine Leerzeichen, mindestens einen Buchstaben, eine Ziffer, ein Sonderzeichen"></dxi-validation-rule>
                  <dxi-validation-rule type="stringLength" [max]="20" message="Maximal 20 Zeichen"></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Passwort bestätigen</div>
            <div class="dx-field-value">
              <dx-text-box mode="password">
                <dx-validator>
                  <dxi-validation-rule type="required" message="Passwort bestätigen ist erforderlich"></dxi-validation-rule>
                  <dxi-validation-rule type="stringLength" [max]="20" message="Maximal 20 Zeichen"></dxi-validation-rule>
                  <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
                                       message="Passwort und Passwort bestätigen stimmen nicht überein"></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
          <div class="dx-field-empty-item"><br></div>
        </div>
        <div class="dx-fieldset">
          <dx-validation-summary id="summary"></dx-validation-summary>
          <dx-button [disabled]="false"
                     id="cancel"
                     text="Abbruch"
                     stylingMode="contained"
                     (click)="cancel()">
          </dx-button>&nbsp;
          <dx-button [disabled]="loading || processed"
                     id="button"
                     text="Passwort ändern"
                     stylingMode="contained"
                     type="default"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
        <div class="dx-fieldset" [hidden]="!messageText || messageText.toString().trim() === '' ">
          <div class="dx-field-empty-item"><br></div>
          <div class="dx-field-item-content">{{messageText}}</div>
        </div>
      </div>
    </form>
  </div>
</div>

