// src/app/directives/csp-nonce.directive.ts
import { Directive, ElementRef, OnInit } from '@angular/core';
import {CspNonceService} from '../shared-services/csp-nonce.service';

@Directive({
  selector: '[appCspNonce]',
})
export class CspNonceDirective implements OnInit {
  constructor(private el: ElementRef, private nonceService: CspNonceService) {}

  ngOnInit(): void {
    const nonceValue = this.nonceService.getNonceValue();
    if (nonceValue) {
      this.el.nativeElement.setAttribute('nonce', nonceValue);
    }
  }
}
