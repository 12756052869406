import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Geraet} from '../api-dtos/geraet';
import {catchError, map} from 'rxjs/operators';
import {formatDate} from '@angular/common';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {Observable} from 'rxjs/internal/Observable';
import {throwError} from 'rxjs/internal/observable/throwError';
import {GeraeteVermietungStorageBox} from '../api-dtos/geraete-vermietung-storage-box';

@Injectable({
  providedIn: 'root'
})
export class GeraetService {
  baseUrl = `${environment.restApiUrl}`;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) {
  }

  private getHttpHeaders(): HttpHeaders {
    const accessToken: string = localStorage.getItem('sessionKey');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  }

  private async getHttpHeaderAsync() {
    const accessToken: string = await localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  private handleError(error: any) :void {
    if (error.error instanceof ErrorEvent) {
      console.log(error.error.message)

    } else {
      console.log(error.status)
    }
  };

  public async getAll(): Promise<Geraet[]> {
    return await this.http.get<Geraet[]>(
      this.baseUrl + `/geraete`,
      await this.getHttpHeaderAsync()
    ).toPromise();
  }

  public async getFaehreAll(): Promise<Geraet[]> {
    return await this.http.get<Geraet[]>(
      this.baseUrl + `/faehre`,
      await this.getHttpHeaderAsync()
    ).toPromise();
  }


  public async getDddw(): Promise<Geraet[]> {
    return await this.http.get<Geraet[]>(
      this.baseUrl + `/geraete/dropdownlist`,
      await this.getHttpHeaderAsync()
    ).toPromise();
  }

  public async getFaehreDddw(activeOnly: boolean = false): Promise<Geraet[]> {
    let useUrl;
    useUrl = this.baseUrl + `/faehre/dropdownlist`;
    if (activeOnly) {
      useUrl += `/aktiv`;
    }
    return await this.http.get<Geraet[]>(
      useUrl,
      await this.getHttpHeaderAsync()
    ).toPromise();
  }

  public async getStorageStorageBoxDddw(activeOnly: boolean = false): Promise<Geraet[]> {
    let useUrl;
    useUrl = this.baseUrl + `/storagebox/dropdownlist`;
    if (activeOnly) {
      useUrl += `/aktiv`;
    }
    return await this.http.get<Geraet[]>(
      useUrl,
      await this.getHttpHeaderAsync()
    ).toPromise();
  }

  public getStorageStorageBoxDddwAvailableInRange( fromDate: Date, toDate: Date, alwaysIncludeGerNrOfGervmId: number | undefined): Observable<Geraet[]> {
    let useUrl;
    useUrl = this.baseUrl + `/storagebox/dropdownlist/available`;
    const options: { headers: HttpHeaders } = {headers: this.getHttpHeaders()}

    let fromDateParam = fromDate ? formatDate(fromDate, 'yyyy-MM-dd', 'de-DE') : '2099-01-01';
    let toDateParam = toDate ? formatDate(toDate, 'yyyy-MM-dd', 'de-DE') : '1990-01-01';

    useUrl += `?fromDate=${fromDateParam}`+ `&toDate=${toDateParam}`;

    if (alwaysIncludeGerNrOfGervmId !== undefined && !!alwaysIncludeGerNrOfGervmId) {
      useUrl += `&alwaysIncludeGerNrOfGervmId=${alwaysIncludeGerNrOfGervmId}`;
    }
    if (!!this.authenticationService.getPublicSessionId()) {
      useUrl += `&alwaysIncludeLockInternalTag=${this.authenticationService.getPublicSessionId()}`;
    }
    if (!environment.production) console.log('getStorageStorageBoxDddwAvailableInRange() ', useUrl );

    return this.http.get<Geraet[]>(
      useUrl, options
    ).pipe(
      catchError((error) => {
        this.handleError(error);
        return throwError(error);
      })
    );

  }

  public getStorageStorageBoxCountAvailablePerDayInRange( fromDate: Date, toDate: Date, alwaysIncludeGerNrOfGervmId: number | undefined): Observable<any> {
    let useUrl;
    useUrl = this.baseUrl + `/storagebox/availableperday`;
    const options: { headers: HttpHeaders } = {headers: this.getHttpHeaders()}

    let fromDateParam = fromDate ? formatDate(fromDate, 'yyyy-MM-dd', 'de-DE') : '2099-01-01';
    let toDateParam = toDate ? formatDate(toDate, 'yyyy-MM-dd', 'de-DE') : '1990-01-01';

    useUrl += `?fromDate=${fromDateParam}`+ `&toDate=${toDateParam}`;

    if (alwaysIncludeGerNrOfGervmId !== undefined && !!alwaysIncludeGerNrOfGervmId) {
      useUrl += `&alwaysIncludeGerNrOfGervmId=${alwaysIncludeGerNrOfGervmId}`;
    }
    if (!!this.authenticationService.getPublicSessionId()) {
      useUrl += `&alwaysIncludeLockInternalTag=${this.authenticationService.getPublicSessionId()}`;
    }

    return this.http.get(
      useUrl, options
    ).pipe(
      catchError((error) => {
        this.handleError(error);
        return throwError(error);
      })
    );
  }

}
