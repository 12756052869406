import {Injectable} from '@angular/core';
import {PaymentService} from './payment.service';
import {paymentTypesEnum} from './paymenttypes';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ShopOrder} from '../api-dtos/shop-order';
import {PaymentLog} from '../api-dtos/payment-log';
import {ColumnValueFilter} from '../api-dtos/column-value-filter';
import {base64_encode} from 'devextreme/data/utils';
import {UtilsService} from '../../shared-services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentPayOneService extends PaymentService {
  protected baseUrl = environment.restApiUrl;
  protected payOneBaseUrl = '';

  constructor(private utilsService: UtilsService,
              private http: HttpClient) {
    super();
    this.paymentType = paymentTypesEnum.payOne;
    this.payOneBaseUrl = this.getProviderBaseUrl();
  }

  private static async getHttpHeaderAsync() {
    const accessToken: string = await localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json', 'Pink-Blotter': environment.sitsApiKey})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  public async getHashedAccount(): Promise<any> {
    let url = `${this.baseUrl}`;
    url = url + '/paymenthashed/account';
    return this.http.post<any>(
      url, {}, PaymentPayOneService.getHttpHeader()).toPromise();
  }

  public async getPayLink(orderData: ShopOrder): Promise<any> {
    if (!environment.production) console.log('getPayLink orderData ', orderData);
    let url = `${this.baseUrl}`;
    url = url + '/payment/create/p1/paylink';
    return this.http.post<any>(
      url, orderData, await PaymentPayOneService.getHttpHeaderAsync()).toPromise();
  }

  public async getHash(orderData: ShopOrder): Promise<any> {
    let url = `${this.baseUrl}`;
    url = url + '/paymenthash';
    return this.http.post<any>(
      url, orderData, PaymentPayOneService.getHttpHeader()).toPromise();
  }

  public getProviderBaseUrl(): string {
    return 'https://frontend.pay1.de/frontend/v2/';
  }

  public async getPaymentLogByFilter(filters: ColumnValueFilter[]): Promise<PaymentLog[]> {
    if (filters == undefined || !filters) {
      if (!environment.production) console.log('getPaymentLogByFilter NO FILTER');
      return [new PaymentLog()];
    }

    let paymentLog: PaymentLog[];
    let url = `${this.baseUrl}`;
    url = url + '/payment/log';
    url = url + `?filters=${base64_encode(JSON.stringify(filters))}`;
    return this.http.get<any>(
      url, await PaymentPayOneService.getHttpHeaderAsync()).toPromise().then((resLog: PaymentLog[]) => {
      paymentLog = resLog;
      if (!environment.production) console.log('getPaymentLog paymentLog', paymentLog);
      return paymentLog;
    });
  }

  public async getPaymentLog(guid: string): Promise<PaymentLog[]> {
    let url = `${this.baseUrl}`;
    url = url + '/payment/log';
    return this.http.get<any>(
      url, await PaymentPayOneService.getHttpHeaderAsync()).toPromise().then(resLog => {
      let paymentLog: PaymentLog[];
      paymentLog = JSON.parse(Buffer.from(resLog, 'base64').toString('binary'));
      if (!environment.production) console.log('getPaymentLog', Buffer.from(resLog, 'base64').toString('binary'), paymentLog);
      return paymentLog;
    });
  }

  public processPayment(shopOrder: ShopOrder, payOneHash: any) {
    let url: string = this.getProviderBaseUrl();
    let amount: number = 0;
    if (shopOrder == undefined || shopOrder.items == undefined || shopOrder.items.length == 0) {
      return;
    }

    for (let i: number = 0; i < shopOrder.items.length; i++) {
      amount += shopOrder.items[i].amountGross ?? 0;
    }
    if (!environment.production) console.log('processPayment amount, shopOrder, payOneHash', amount, shopOrder, payOneHash);

    let params = new HttpParams()
      .append('aid', 56570)
      .append('amount', amount)
      .append('clearingtype', 'wlt')
      .append('curreny', shopOrder.currency)
      .append('de[1]', shopOrder.orderTypeText)
      .append('id[1]', shopOrder.id)
      .append('mode', 'test')
      .append('no[1]', shopOrder.items[0].itemNo)
      .append('poralid', 2041329)
      .append('pr[1]', Number(shopOrder.items[0].priceGross) * 100)
      .append('reference', 1234567)
      .append('request', 'authorization')
      .append('va[1]', 19)
      .append('hash', payOneHash);

    let headers: any = this.getHttpHeaderPayOne();
    headers.params = params;

    if (!environment.production) console.log('calling P1 ', url, headers);

    this.http.get(url, headers).toPromise().then(httpRes => {
      if (!environment.production) console.log('processPayment result', httpRes);
    });


    // Hosted Iframe umsetzen. Javascript einbinden. Evtl. component mit PayoneFormular anlegen
    // https://frontend.pay1.de/frontend/v2/?aid=56570&amount=1499&clearingtype=wlt&currency=EUR&de[1]=Gepaeckauftrag%2012435&id[1]=1&mode=test&no[1]=1&portalid=2041329&pr[1]=1499&reference=1234567&request=authorization&va[1]=19&hash=70eaec2a33fa1b4674c0b112f6982966
    // https://frontend.pay1.de/frontend/v2/?aid=56570&amount=1499&clearingtype=wlt&currency=EUR&de[1]=Gepaeckauftrag%2012435&id[1]=1&mode=test&no[1]=1&portalid=2041329&pr[1]=1499&reference=1234567&request=authorization&va[1]=19&hash=70eaec2a33fa1b4674c0b112f6982966
    //
    /*<script type="text/javascript" src="https://secure.pay1.de/client-api/js/v1/payone_hosted_min.js"></script>*/
    // https://docs.payone.com/display/PLATFORM/CA+-+Simple+Examples
    // https://docs.payone.com/display/public/PLATFORM/CA+-+General+Interface+Definitions
  }

  private getHttpHeaderPayOne() {
    return {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
    };
  }

  private async getHttpHeaderPayOneAsync() {
    const accessToken: string = localStorage.getItem('sessionKey') ?? '';
    const httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Access-Control-Allow-Headers', 'Content-Type, *')
        .append('Access-Control-Allow-Methods', 'GET, POST')
    };
    // .append('Access-Control-Allow-Origin', '*')

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }
}
