import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ITextBlock} from '../api-dtos/text-block';
import {Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';

@Injectable({
  providedIn: 'root'
})
export class TextblockService {
  baseUrl: string = `${environment.restApiUrl}/textblock`;

  constructor(
    private http: HttpClient) {
  }

  public getTextBlock(txblk_shortcut_code: string): Observable<ITextBlock> {
    if (txblk_shortcut_code === undefined || txblk_shortcut_code.trim() === '') {
      return of();
    }
    const language = 'de';
    return this.http.get<ITextBlock>(`${this.baseUrl}?shortcut=${txblk_shortcut_code}&lng=${language}`,
      this.getHttpHeader());
  }

  private getHttpHeader() {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

}
