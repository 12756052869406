import {DataElement} from '../api-basics/data-element';
import {User} from './user';

export class ServerMessage implements DataElement {
  id?: number;
  status?: string;
  sessionStatus?: string;
  sessionKey?: string;
  message?: string;
  userIsEmployee?: boolean;
  EmployeeId?: number;
  userIsCustomer?: boolean;
  userCustomerId?: number;
  sessionUsername?: string;
  customerCompanyName?: string;
  userData?: User;

  constructor() {
  }
}
