import {Component, OnInit, ViewChild} from '@angular/core';
import {Geraet} from '../../../api/api-dtos/geraet';
import {GeraetService} from '../../../api/api-services/geraet.service';
import {Scheduler} from '../../../api/api-dtos/scheduler-dto';
import {SchedulerService} from '../../../api/api-services/scheduler.service';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {TaskService} from '../../../api/api-services/task.service';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {Adresse} from '../../../api/api-dtos/adresse-dto';
import {Mitarbeiter} from '../../../api/api-dtos/mitarbeiter-dto';
import {MitarbeiterService} from '../../../api/api-services/mitarbeiter.service';
import {DxDataGridComponent, DxDateBoxComponent} from 'devextreme-angular';
import { Task } from '../../../api/api-dtos/task-dto';
import {Location} from '@angular/common';

@Component({
  selector: 'app-scheduler-task-list',
  templateUrl: './scheduler-task-list.component.html',
  styleUrls: ['./scheduler-task-list.component.scss']
})
export class SchedulerTaskListComponent implements OnInit {
  private isLoadingResults: boolean;
  today = new Date();
  firstDayOfLastYear = new Date(this.today.getFullYear() - 1, 0, 1);
  lastDayOfNextYear = new Date(this.today.getFullYear() + 1, 12, 31);
  componentTitle: string;
  schedulers: Scheduler[];
  adresses: Adresse[];
  geraets: Geraet[];
  mitarbeiters: Mitarbeiter[];
  tasks: Task[];
  gridPageSize = 15;
  schedulerDate: Date;
  employeeId: number;
  currentUserName: string = null;
  selectedSchedulerIds: number[];
  selectedSchedulers: Scheduler[] = [];
  @ViewChild('grid', { static: false }) grid: DxDataGridComponent;
  @ViewChild('dateSelector', { static: false }) dateSelector: DxDateBoxComponent;
  private schedulerSetDoneAllowed: boolean;

  constructor(private schedulerService: SchedulerService,
              private adresseService: AdresseService,
              private geraetService: GeraetService,
              private mitarbeiterService: MitarbeiterService,
              private taskService: TaskService,
              private authenticationService: AuthenticationService,
              private readonly location: Location,
  ) {
    if (!!localStorage.getItem('schedkulertask-list.grid.paging.pageSize')) {
      this.gridPageSize = +localStorage.getItem('schedkulertask-list.grid.paging.pageSize');
      if (this.gridPageSize < 5) {
        this.gridPageSize = 5;
      } else if (this.gridPageSize > 300) {
        this.gridPageSize = 300;
      }
    }

    if (!this.schedulerDate || (this.schedulerDate < this.today)) {
      this.schedulerDate = this.today;
    }

    this.componentTitle = 'Aufgaben';
    this.getAdresses = this.getAdresses.bind(this);
    this.getGeraets = this.getGeraets.bind(this);
    this.getMitarbeiters = this.getMitarbeiters.bind(this);
    this.getTasks = this.getTasks.bind(this);
  }

  getAdresses(options: any) {
    return {
      store: this.adresses,
      filter: options.data ? ['adr_id', '=', options.data.adr_id] : null
    };
  }

  getMitarbeiters(options: any) {
    return {
      store: this.mitarbeiters,
      filter: options.data ? ['mit_id', '=', options.data.mit_id] : null
    };
  }

  getGeraets(options: any) {
    return {
      store: this.geraets,
      filter: options.data ? ['ger_nr', '=', options.data.ger_nr] : null
    };
  }

  getTasks(options: any) {
    return {
      store: this.tasks,
      filter: options.data ? ['tsk_id', '=', options.data.tsk_id] : null
    };
  }

  async ngOnInit() {
    setTimeout(() => {
      this.isLoadingResults = false;
    }, 10000);
    this.adresses = await [];
    this.geraets = await [];
    this.mitarbeiters = await [];
    this.tasks = await [];
    await this.adresseService.getAllDddw().then(resAdresses => {
      this.adresses = resAdresses;
      this.taskService.getAll().then(resTasks => {
        this.tasks = resTasks;
        this.geraetService.getDddw().then(resGeraets => {
          this.geraets = resGeraets;
          this.mitarbeiterService.getAllDddw().then(resMitarbeiter => {
            this.mitarbeiters = resMitarbeiter;
            if (!!localStorage.getItem('schedulertask-list.filter.employee')) {
              this.employeeId = +localStorage.getItem('schedulertask-list.filter.employee');
              if (this.employeeId < 0) {
                this.employeeId = null;
              }
            } else {
              this.employeeId = this.authenticationService.currentUser.employeeId;
            }
            this.refreshList();
          });
        });
      });
    });
  }

  async refreshList() {
    this.currentUserName = await this.authenticationService.currentUser.displayName;
    [this.schedulers] = await Promise.all([this.schedulerService.getFiltered(this.schedulerDate, this.employeeId)]);
  }

  toolbarAction(mode: string) {
    switch (mode) {
      case 'plus01': {
        this.schedulerDate.setDate(this.schedulerDate.getDate() + 1);
        // this.dateSelector._setOption('value', this.schedulerDate);
        this.dateSelector.instance.repaint();
        this.refreshList();
        break;
      }
      case 'minus01': {
        this.schedulerDate.setDate(this.schedulerDate.getDate() - 1);
        this.dateSelector.instance.repaint();
        this.refreshList();
        break;
      }
      case 'exportxlsx': {
        // this.grid.instance.exportToExcel(false);
        break;
      }
    }
  }

  dateChanged($event: any) {
    this.refreshList();
  }

  onExporting($event: any) {
    $event.fileName = 'Ausfgabenliste_' + this.currentUserName;
  }

  onValueChangedMitarbeiter($event: any) {
    this.employeeId = $event.value;
    localStorage.setItem('schedulertask-list.filter.employee', $event.value);
    this.refreshList();
  }

  onMultiTagPreparing(args: any) {
    const selectedItemsLength = args.selectedItems.length;
    const totalCount = 2;
    if (selectedItemsLength < totalCount) {
      args.cancel = true;
    } else {
      args.text = 'Anz.(' + selectedItemsLength + ')';
    }
  }

  public goBack(): void {
    this.location.back();
  }

}
