import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IFrachtErfassungsStatus} from '../api-dtos/fracht-erfassungs-status';

@Injectable({
  providedIn: 'root'
})
export class FrachtErfassungsStatusService {
  baseUrl = `${environment.restApiUrl}/frachterfassungsstatus`;
  frachtErfassungsStatusCache: IFrachtErfassungsStatus[];

  constructor(
    private http: HttpClient) { }

  public async getAllUncached(): Promise<IFrachtErfassungsStatus[]> {
    return await this.http.get<IFrachtErfassungsStatus[]>(
      this.baseUrl,
      await this.getHttpHeader()
    ).toPromise();
  }

  public async getAll(): Promise<IFrachtErfassungsStatus[]> {
    if (this.frachtErfassungsStatusCache && this.frachtErfassungsStatusCache.length > 0) {
      return this.frachtErfassungsStatusCache;
    } else {
      this.frachtErfassungsStatusCache = await this.http
        .get<IFrachtErfassungsStatus[]>(this.baseUrl, await this.getHttpHeader())
        .toPromise();
      return this.frachtErfassungsStatusCache;
    }
  }

  public async getFrachtErfassungsStatus(id: number): Promise<IFrachtErfassungsStatus> {
    return await this.http.get<IFrachtErfassungsStatus>(
      `${this.baseUrl}/${id}`,
      await this.getHttpHeader()
    ).toPromise();
  }

  private async getHttpHeader() {
    const accessToken: string = await localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }
}


