import {ShopOrderItem} from './shop-order-item';
import {ShopAddress} from './shop-address';

export class ShopOrder {
  id?: number;
  guid?: string;
  orderId?: number;
  orderNoFull?: string;
  currency?: string = 'EUR';
  typeCode?: string;            // GEP, FB, LS, ...
  orderTypeText?: string;       // Gepäckauftrag, ...
  orderText?: string;           // Gepäck-Zustellung, ...
  deliveryDate?: Date;
  invoiceDate?: Date;
  canceled?: boolean;
  cancelDate?: Date;
  customerId?: string;
  customerTan?: string;
  billingAddress?: ShopAddress;
  shippingAddress?: ShopAddress;    // auch als Gepäckadresse verwenden = Inseladresse
  deliveryAddress?: ShopAddress;
  pickupAddress?: ShopAddress;
  items?: ShopOrderItem[];
}
