import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {AlertService, AuthenticationService} from '../shared-services/shared-services.module';
import notify from 'devextreme/ui/notify';
import {ServerMessage} from '../api/api-dtos/server-message';
import {environment} from '../../environments/environment';
import {Location} from '@angular/common';
import {firstValueFrom} from 'rxjs/internal/firstValueFrom';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loading:boolean = false;
  submitted:boolean = false;
  returnUrl: string;
  processed: boolean;
  userName: string = '';
  password: string = '';
  messageText: string;
  isDevelopment:boolean = !environment.production;
  private history: string[] = [];
  previousUrl: string;
  public componentTitle: string = 'Anmelden';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private readonly location: Location) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if (this.authenticationService.authenticated && (this.authenticationService.isEmployee || this.authenticationService.isCustomer)) {
      this.router.navigate(['/']).then();
    }
  }

  async onFormSubmit(e: Event): Promise<void> {
    this.submitted = true;
    this.loading = true;
    this.processed = false;
    this.alertService.clear();
    let serviceResult: ServerMessage;
    try {
      let loginResult$ = this.authenticationService.login(this.userName, this.password);
      serviceResult = await firstValueFrom(loginResult$);
      this.processed = serviceResult.status === '1';
      serviceResult.message = 'erfolgreich angmeldet.';
      notify({
        message: serviceResult.message,
        position: {
          my: 'center',
          at: 'center'
        }
      }, 'success', 300);

      // to be moved to a better place ...
      localStorage.removeItem('gepaeck-abholen.containerNo');

      await this.router.navigate([this.returnUrl]);
    } catch (error) {
      this.processed = false;
      let msg: string = 'Login fehlgeschlagen. Ungültige Zugangsdaten oder Netzwerkverbindungsprobleme.';
      notify({
        message: environment.production
          ? error + ' ' + msg
          : msg,
        position: {
          my: 'center middle',
          at: 'center middle'
        }
      }, 'error', 8000);
    }
    this.loading = false;
    e.preventDefault();
  }

  gotoPasswordForgot():void {
    this.router.navigate(['/passwordforgot']).then();
  }

  public goBack(): void {
    if (this.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/').then();
    }
  }

  gotoRegistrationForm():void {
    this.router.navigate(['/registrationform']);
  }
}

