import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {formatDate, Location} from '@angular/common';
import {environment} from '../../../../environments/environment';
import {GepaeckAuftragCustListComponent} from '../gepaeck-auftrag-cust-list/gepaeck-auftrag-cust-list.component';
import {ActivatedRoute, Router} from '@angular/router';
import {GepaeckBelegService} from '../../../api/api-services/gepaeck-beleg.service';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {FahrplanService} from '../../../api/api-services/fahrplan.service';
import {StreckeSettingService} from '../../../api/api-services/strecke-setting.service';
import {FrachtOrtService} from '../../../api/api-services/fracht-ort.service';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {TextblockService} from '../../../api/api-services/textblock.service';
import {HostService} from '../../../shared-services/host.service';

@Component({
  selector: 'gepaeck-auftrag-cust-list-zustellen',
  templateUrl: 'gepaeck-auftrag-cust-list-zustellen.component.html',
  styleUrls: ['./gepaeck-auftrag-cust-list-zustellen.component.scss']
})

export class GepaeckAuftragCustListZustellenComponent extends GepaeckAuftragCustListComponent implements OnInit, OnChanges, OnDestroy  {

  protected readonly onclick = onclick;
  public readonly formatDate = formatDate;
  public readonly formatTime = formatDate;
  public readonly environment = environment;

  constructor(router: Router,
              route: ActivatedRoute,
              gepaeckBelegService: GepaeckBelegService,
              adresseService: AdresseService,
              fahrplanService: FahrplanService,
              streckeSettingService: StreckeSettingService,
              frachtOrtService: FrachtOrtService,
              authenticationService: AuthenticationService,
              textblockService: TextblockService,
              readonly location: Location,
              hostService: HostService,
  ) {
    super(router, route, gepaeckBelegService, adresseService, fahrplanService, streckeSettingService, frachtOrtService, authenticationService, textblockService, location, hostService);

    this.frachtOrts = [];
    this.frachtOrtService.getAll().subscribe(data => {
      this.frachtOrts = data;
      this.frachtOrts = this.frachtOrts.filter(frachtOrt => frachtOrt.fort_tags.toUpperCase().includes('INSEL') && frachtOrt.fort_tags.toUpperCase().includes('GEP') );
      return;
    });
  }

  public customizeTotalAnzahl(data: any) {
    return data.value.toString();
  }

  public customizeTotalAuftraege(data: any) {
    return 'Aufträge: ' + data.value.toString();
  }


}
