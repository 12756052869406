import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})

export class WarningModalComponent implements OnInit {

  public state = new Subject<boolean>();

  constructor(private bsModalRef: BsModalRef) { }

  public ngOnInit() {
  }

  public agree() {
    this.state.next(true);
    this.bsModalRef.hide();
  }

  public disagree() {
    this.state.next(false);
    this.bsModalRef.hide();
  }

}
