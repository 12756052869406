<div class="sits-container">
  <div class="sits-card-center dx-card sits-card-small">
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">{{ title }}
      </div>
      <div class="card-text sits-print-only">
        Wenn Sie nur gelegentlich unseren Gepäckservice nutzen, benötigen Sie <b>keine</b> Registrierung, denn Ihren
        Gepäckauftrag können Sie auch so buchen. Siehe Home > Gepäckauftrag.<br>
        Die Registrierung ist sinnvoll für alle, die unsere Frachtdienstleistungen und / oder den Lagerboxen-Service
        nutzen oder künftig nutzen wollen.
      </div>
      <dx-popover
        target="#link1"
        position="bottom"
        cdkScrollable
        [width]="300"
        [showTitle]="true"
        title="Hinweise zur Registrierung ..."
        [(visible)]="popoverVisible">
        <div *dxTemplate="let data = model of 'content'">
          Wenn Sie nur gelegentlich unseren Gepäckservice nutzen, benötigen Sie <b>keine</b> Registrierung, denn Ihren
          Gepäckauftrag können Sie auch so buchen. Siehe Home > Gepäckauftrag.<br>
          Die Registrierung ist sinnvoll für alle, die unsere Frachtdienstleistungen und / oder den Lagerboxen-Service
          nutzen oder künftig nutzen wollen.<br>
          Nach der erfolgreichen Registrierung können Sie Ihre Daten später im Portal ergänzen.
        </div>
      </dx-popover>
      <dx-toolbar>
        <dxi-item widget="dxButton" location="before" locateInMenu="auto">
          <dx-button icon="back" text="zurück" (click)="this.goBack()"></dx-button>
        </dxi-item>
        <dxi-item widget="dxButton" location="before" locateInMenu="auto">
          <dx-button id="link1" icon="info" hint="Details zum Gepäcktransport"
                     (click)="this.popoverToggleDefault()"></dx-button>
        </dxi-item>
      </dx-toolbar>
      <div class="dx-field" #formcontainer>
        <form class="registration-form" autocomplete="on">
          <dx-form #registrationForm [(formData)]="userData"
                   labelLocation="top"
                   [scrollingEnabled]="true"
                   [showValidationSummary]="true"
                   colCount="1"
                   [width]="getMaxCardWidth()"
                   [showColonAfterLabel]="false"
                   (onFieldDataChanged)="form_fieldDataChanged($event)">

            <dxi-item [editorOptions]="{disabled: false,
                        placeholder: 'Anmeldename, z.B. wattwurm23, hanno23@gmx.de, ',
                        inputAttr: { autocomplete: 'on'},
                        name: 'username',
                        hint: 'Eindeutiger Name, mit dem Sie sich später anmelden. Kann, aber muss nicht identisch sein mit der E-Mail-Adresse. Kann nur von unseren Admins nachträglich geändert werden. Beispiele: andrea_v, andrea.vogel@gmx.de. Bitte Groß-/Kleinschreibung beachten.' }"
                      dataField="userName"
                      editorType="dxTextBox"
                      id="username">
              <dxo-label
                text="Benutzername"
                horizontalAlignment="left">
              </dxo-label>

              <dxi-validation-rule type="required"
                                   message="Zur Vermeidung von Verwechslungen benötigen wir Ihren Namen."></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [min]="8"
                                   message="Benutzername: mindestens 8 Zeichen"></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="64"
                                   message="Benutzername: maximal 64 Zeichen"></dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="mobilePhone"
                      id="telephone"
                      editorType="dxTextBox"
                      [editorOptions]="{ disabled: false,
                        inputAttr: { autocomplete: 'on'},
                        placeholder: 'z.B. 0177-11119999',
                        hint: 'Wir senden gleich eine TAN (Kennung) an Ihr Mobiltelefon, die Sie für diese Registrierung benötigen, um Missbrauch zu vermeiden.  ',
                        name: 'telephone'}">
              <dxo-label
                text="Mobiltelefon-Nr."
                horizontalAlignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Wir benötigen eine SMS-fähige TelefonNr. zur Zustellung des Freigabecodes, damit Sie die Registrierung auslösen können."></dxi-validation-rule>
              <dxi-validation-rule type="custom"
                                   [validationCallback]="getIsValidPhoneNumber"
                                   [reevaluate]="true"
                                   message="Ungültiges Format oder unerlaubte Zeichen in der Telefonnummer. Beispiele: 0177-1234567, 0177-99999999, +49.177.99999999 oder ähnlich.">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item [editorOptions]="{disabled: false,
                  inputAttr: { autocomplete: 'on'},
                  name: 'email',
                  hint: 'Optional, dringend empfohlen. Erlaubt uns die Übermittlung ausführlicherer Informationen ergänzend zur SMS-Benachrichtigung.'}"
                      dataField="mail"
            >
              <dxo-label
                text="E-Mail-Adresse"
                horizontalAlignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Für die Registrierung und die anschließende Zusammenarbeit mit Ihnen benötigen wir eine gültige E-Mail-Adresse."></dxi-validation-rule>
              <dxi-validation-rule type="email"
                                   [reevaluate]="true"
                                   message="Ungültige E-Mail-Adresse.">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item
              [disabled]="false"
              itemType="text"
              horizontalAlignment="left">
              Damit wir für Sie tätig werden können, benötigen wir Ihre Zustimmung zu unseren&nbsp;
              <span [hidden]="!frontendGepaeckTransportBedingungenUrl"><a class="dx-icon-link"
                                                                          href="{{frontendGepaeckTransportBedingungenUrl}}"
                                                                          target="_blank">Gepäcktransportbedingungen</a>&nbsp;</span>
              <span [hidden]="!frontendFrachtTransportBedingungenUrl"><a class="dx-icon-link"
                                                                         href="{{frontendFrachtTransportBedingungenUrl}}"
                                                                         target="_blank"
                                                                         *ngIf="!!frontendFrachtTransportBedingungenUrl">Frachttransportbedingungen</a></span>
              sowie zu unserer <a class="dx-icon-link" href="https://www.inselspedition.de/datenschutz" target="_blank">Datenschutzerklärung</a>.
            </dxi-item>

            <dxi-item
              dataField="gepaeckTranspBedingungenBestaetigt_yn"
              editorType="dxCheckBox"
              [editorOptions]="{
                    text: 'Ich stimme den Bedingungen für den Gepäcktransport zu.',
                    value: this.userData.gepaeckTranspBedingungenBestaetigt_yn,
                    disabled: this.isDisableEditing
                }">
              <dxo-label
                [visible]="false">
              </dxo-label>
              <dxi-validation-rule
                type="compare"
                [comparisonTarget]="checkComparison"
                message="Ihre Zustimmung zu unseren Gepäcktransportbedingungen ist erforderlich.">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item
              dataField="frachtTranspBedingungenBestaetigt_yn"
              editorType="dxCheckBox"
              [editorOptions]="{
                    text: 'Ich stimme den Bedingungen für den Frachttransport zu.',
                    value: this.userData.frachtTranspBedingungenBestaetigt_yn,
                    disabled: this.isDisableEditing
                }">
              <dxo-label
                [visible]="false">
              </dxo-label>
              <dxi-validation-rule
                type="compare"
                [comparisonTarget]="checkComparison"
                message="Ihre Zustimmung zu unseren Frachttransportbedingungen ist erforderlich.">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item
              dataField="datenschutzBedingungenBestaetigt_yn"
              editorType="dxCheckBox"
              [editorOptions]="{
                    text: 'Ich stimme den Datenschutzbedingungen zu.',
                    value: this.userData.datenschutzBedingungenBestaetigt_yn,
                    disabled: this.isDisableEditing
                }">
              <dxo-label
                [visible]="false">
              </dxo-label>
              <dxi-validation-rule
                type="compare"
                [comparisonTarget]="checkComparison"
                message="Ihre Zustimmung zu unseren Datenschutzbedingungen ist erforderlich.">
              </dxi-validation-rule>
            </dxi-item>


            <dxi-item
              [disabled]="false"
              itemType="button"
              horizontalAlignment="left"
              [buttonOptions]=buttonCheckDataAndGetApprovalCode>
            </dxi-item>

            <dxi-item
              [editorOptions]="{disabled: false, showClearButton: true, showSpinButtons:false, step: 0, hint: 'Mobile TAN, siehe SMS-Eingang Ihres Mobiltelefons'}"
              [visible]="!(isEditMobileTanDisabled || this.isDisableEditing || this.isProcessing)"
              dataField="registration_mobiletan"
              editorType="dxNumberBox">
              <dxo-label
                text="Mobile TAN"
                horizontalAlignment="left">
              </dxo-label>
            </dxi-item>


            <dxi-item
              [disabled]="false"
              [visible]="!(isEditMobileTanDisabled || this.isDisableEditing || this.isProcessing)"
              itemType="button"
              horizontalAlignment="left"
              [buttonOptions]="buttonSubmitRegistrationData">
            </dxi-item>
            <dxi-item itemType="empty" [colSpan]="2">&nbsp;</dxi-item>
          </dx-form>
        </form>
      </div>
    </div>
  </div>
</div>


