<div class="sits-canvas">
  <!--<mat-toolbar class="toolbar-main-horizontal">
    <a class="logo" [routerLink]="'/home'">
      <img src="assets/images/logo_frei.png" alt="Logo"/>
      <span [hidden]="isProductionEnvironment()">&nbsp;&nbsp;&nbsp;TEST-SYSTEM</span>
    </a>&nbsp;
    <span class="spacer"></span>
  </mat-toolbar>-->
  <div class="sits-canvas-header">
    <a class="logo" [routerLink]="'/home'">
      <img src="assets/images/logo_frei.png" alt="Logo"/>
      <span [hidden]="isProductionEnvironment()">&nbsp;&nbsp;&nbsp;TEST-SYSTEM</span>
    </a>&nbsp;
    <span class="spacer"></span>
  </div>
  <mat-sidenav-container fxFlexFill class="sits-canvas-container">
    <mat-sidenav-content fxFlexFill>
      <div fxFlex>
        <app-alert></app-alert>
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <div class="footer">
        <span>
          <a [routerLink]="'/about'">Impressum</a>
            &nbsp;|&nbsp;<a [routerLink]="'/datenschutz'">Datenschutz</a>
            &nbsp;|&nbsp;<a (click)=reloadClient()>Update</a>
        &nbsp;    |&nbsp;V. {{version}}
        </span>
    <span [hidden]="isHideSitsLink()">
          | <a href="https://www.scheltwort-its.de/webentwicklung/business-web-apps" target="_blank">
          <img src="assets/images/sits_favicon-16x16.png" alt="made by SITS"
               title="SITS: wir sorgen für Betrieb"/>
        </a>
        </span>
  </div>
</div>
