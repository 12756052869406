<div class="sits-container" id="pagecard">
  <div class="sits-card-center dx-card sits-card-large">
    <div class="dx-fieldset">
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          <dx-toolbar class="sits-print-hide">
            <dxi-item widget="dxButton" location="before">
              <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
            </dxi-item>
            <dxi-item location="left" widget="dxButton" locateInMenu="never">
              <div *dxTemplate>
                <div class="toolbar-label">{{[componentTitle]}} {{[gepaeckBeleg.gpblg_id]}}</div>
              </div>
            </dxi-item>
            <dxi-item widget="dxButton" location="before" locateInMenu="auto">
              <dx-button id="link1" icon="info" hint="Details zum Gepäcktransport"
                         (click)="this.popoverToggleDefault()"></dx-button>
            </dxi-item>
          </dx-toolbar>
        </div>
      </div>
      <app-gepaeck-info [inputPopoverVisible]="popoverVisible"
                        [inputTypeOfTransportName]="gepaeckBeleg.gpblg_dummy_fp_art_kz">
      </app-gepaeck-info>
      <div class="dx-field" #formcontainer>
        <form class="gepaeckbeleg-form" autocomplete="on">
          <dx-form #frachtForm [(formData)]="gepaeckBeleg"
                   labelLocation="top"
                   [scrollingEnabled]="true"
                   [showValidationSummary]="true"
                   colCount="1"
                   [width]="getMaxCardWidth()"
                   [showColonAfterLabel]="false"
                   (onFieldDataChanged)="form_fieldDataChanged($event)">

            <dxi-item dataField="gpblg_dummy_fp_art_kz"
                      editorType="dxRadioGroup"
                      [visible]="gepaeckTransportArts !== undefined && gepaeckTransportArts.length >= 2"
                      [editorOptions]="{items: radioGroupItemsTypeOfTransport,
                    displayExpr: 'text',
                    layout: 'horizontal',
                    valueExpr: 'fp_art_kz',
                    disabled: this.isDisableEditingData || gepaeckTransportArts === undefined || gepaeckTransportArts.length < 2 }">
              <dxi-validation-rule type="required"
                                   message="Die Transportart ist erforderlich, damit aus dem passenden Fahrplan / Flugplan gewählt werden kann."></dxi-validation-rule>
              <dxo-label
                text="TransportArt"
                horizontalAlignment="left"
                [visible]="false">
              </dxo-label>
            </dxi-item>

            <dxi-item dataField="gpblg_dummy_datum"
                      type="date"
                      editorType="dxDateBox"
                      [editorOptions]="{
                    width: '100%',
                    type: 'date',
                    useMaskBehavior: 'true',
                    displayFormat: 'EE. dd.MM.yyyy',
                    min: startDate,
                    hint: 'Datum an dem der Gepäcktransport erfolgen soll.',
                    dateSerializationFormat: 'yyyy-MM-dd',
                    invalidDateMessage: 'Das Datum muss ein gültiges Format haben: MM/dd/yyyy',
                    disabled: this.isDisableEditingData }">
              <dxi-validation-rule type="required"
                                   message="Das Transportdatum ist erforderlich. Voraussetzung für den Transport."></dxi-validation-rule>
              <dxi-validation-rule
                type="async"
                message="Bitte das Mindestdatum nicht unterschreiten."
                [validationCallback]="asyncValidationDatum"
                [reevaluate]="true"
              ></dxi-validation-rule>
              <dxo-label
                [text]="getDatumLabelText()"
                horizontalAlignment="left">
              </dxo-label>
            </dxi-item>

            <dxi-item dataField="gpblg_dummy_abgang_fort_id"
                      editorType="dxRadioGroup"
                      [editorOptions]="{items: radioGroupItemsFrachtOrte,
                    displayExpr: 'text',
                    layout: 'horizontal',
                    valueExpr: 'frachtortId',
                    disabled: this.isDisableEditingData || gepaeckTransportArts === undefined || gepaeckTransportArts.length < 2 }">
              <dxo-label
                text="Ausgangspunkt (ab ...)"
                horizontalAlignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Die Transportrichtung ist erforderlich, damit eine Fährverbindung gewählt werden kann."></dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="fp_id"
                      editorType="dxSelectBox"
                      [disabled]="getIsDisableEditingFaehre()"
                      [editorOptions]="{items: fahrplans,
                    deferRendering: 'false',
                    dropDownOptions: {showTitle: false, hideOnOutsideClick: true},
                    valueExpr: 'fp_id',
                    searchPlaceholder: 'Abfahrtzeit ...',
                    displayExpr: getDisplayAbfahrtzeitVoll}">
              <dxi-validation-rule type="required"
                                   message="Die Fähre ist erforderlich. Hinweise: eine Fähre kann erst ausgewählt werden, wenn ein Datum eingetragen ist. Es gibt auch Tage, an denen keine Fähre fährt."
                                   [reevaluate]="true"
              ></dxi-validation-rule>
              <dxo-label
                [text]="getFahrplanLabelText()"
                horizontalAlignment="left">
              </dxo-label>
            </dxi-item>

            <dxi-item editorType="dxSelectBox"
                      [editorOptions]="{items: gepaeckAdresseDddw,
                      searchEnabled: 'true',
                      valueExpr: 'adr_id',
                      displayExpr: 'adr_gepaeck_name',
                      placeholder: 'min. 3 Zeichen ...',
                      disabled: this.isDisableEditingData,
                      hint: 'Adresse, Hotelname, Hausname, ... von der wir Ihre Gep.stücke abholen bzw. an die wir zustellen sollen. Falls Sie die Adresse nicht in der Auswahlliste finden, dann bitte weiter unten als Bemerkung eintragen. '}"
                      dataField="adr_id">
              <dxo-label
                text="Insel-Adresse (Straße HausNr. / Hotel)"
                horizontalAlignment="left">
              </dxo-label>
              <dxi-validation-rule
                message="Die Adresse wird benötigt. Geben Sie gern als Bemerkungen (unten) einen weiteren Hinweis, wenn es unter der gleichen Adresse mehrere Wohnungen geben sollte."
                type="required"
                [reevaluate]="true"
              ></dxi-validation-rule>
            </dxi-item>

            <dxi-item
              [editorOptions]="{ showSpinButtons:true, hint: 'Anzahl Gepäckstücke', disabled: this.isDisableEditingData,
                          inputAttr: { ariaLabel: 'Integer'}, step: '1', format: '#'}"
              dataField="gpblg_anz"
              editorType="dxNumberBox">
              <dxo-label
                text="Gepäckstücke"
                horizontalAlignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Die Anzahl Gepäckstücke ist erforderlich für unsere Tourenplanung."></dxi-validation-rule>
              <dxi-validation-rule
                type="async"
                message="Bitte die Mindestanzahl an Gepäckstücken nicht unterschreiten"
                [validationCallback]="asyncValidationGpBlgAnz"
                [reevaluate]="true"
              ></dxi-validation-rule>
              <dxi-validation-rule type="range" min="1" max="99"
                                   message="Bitte den Bereich von 1 bis 99 für Anzahl an Gepäckstücken verwenden."></dxi-validation-rule>
            </dxi-item>

            <dxi-item
              dataField="gpblg_brutto_amount"
              editorType="dxNumberBox"
              [editorOptions]="{format: ' ##0.00 EUR', disabled: true,
                      hint: 'Kosten für den Transport der gewählten Anzahl an Gepäckstücken.' }">
              <dxo-label
                text="Gesamtbetrag (inkl. MwSt.)"
                horizontalAlignment="left">
              </dxo-label>
              <dxi-validation-rule type="range" min="0" max="999"
                                   message="Bitte den Bereich von 1 bis 999 EUR für Bruttobetrag verwenden."></dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="gpblg_gepaeckstuecke_json"
                      editorType="dxTagBox"
                      [editorOptions]="{
                      width: '100%',
                items: gepaeckDescriptions,
                acceptCustomValue: true,
                onCustomItemCreating: onGepaeckStueckTagCreatingDescriptions,
                onInput: onGepaeckStueckTagInput,
                onKeyDown: onGepaeckStueckTagKeyDown,
                onFocusOut: onGepaeckStueckTagFocusOut,
                maxDisplayedTags: 10,
                placeholder: 'Auswählen oder selbst schreiben (bitte kurz und prägnant) ...',
                hint: 'Hier bitte jedes Gepäckstück beschreiben, zumindest ein markantes Gepäckstück. Entweder Beschreibungen auswählen oder selbst eintragen.',
                    disabled: this.isDisableEditingData }"
                      [colSpan]="2">
              <dxo-label
                text="Gepäckstücke Beschreibung"
                horizontalAlignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Zur Vermeidung von Verwechslungen Beschreibungen Ihres Gepäcks, zumindest markante Stücke beschreiben. Bitte Manuell ergänzen, wenn Sie in der Auswahl keinen passenden Eintrag finden."></dxi-validation-rule>
            </dxi-item>

            <dxi-item [editorOptions]="{disabled: this.isDisableEditingData,
                      placeholder: 'Vor- und Nachname',
                      inputAttr: { autocomplete: 'on'},
                      name: 'lastname' }"
                      dataField="gpblg_extern_name"
                      editorType="dxTextBox">
              <dxo-label
                text="Mein Name"
                horizontalAlignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Zur Vermeidung von Verwechslungen benötigen wir Ihren Namen."></dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="gpblg_extern_mobil_tel"
                      id="telephone"
                      editorType="dxTextBox"
                      [editorOptions]="{ disabled: this.isDisableEditingData,
                      inputAttr: { autocomplete: 'on'},
                      hint: 'Wir senden gleich eine TAN (Kennung) an Ihr Mobiltelefon, die Sie dann für die Buchung verwenden können. Dies ist eine einfache Möglichkeit, Missbrauch zu vermeiden.  ',
                      name: 'telephone'}">
              <dxo-label
                text="Meine Mobiltelefon-Nr."
                horizontalAlignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Wir benötigen eine SMS-fähige TelefonNr. zur Zustellung des Freigabecodes, damit Sie die Buchung abschließen können."></dxi-validation-rule>
              <dxi-validation-rule type="custom"
                                   [validationCallback]="getIsValidPhoneNumber"
                                   [reevaluate]="true"
                                   message="Ungültiges Format oder unerlaubte Zeichen in der Telefonnummer. Beispiele: 0177-1234567, 0177-99999999, +49.177.99999999 oder ähnlich.">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item [editorOptions]="{disabled: this.isDisableEditingData,
                inputAttr: { autocomplete: 'on'},
                name: 'email',
                hint: 'Für Benachrichtigungen und die Bestätigung der Auftragsinformationen benötigen wir eine gültige E-Mail-Adresse.'}"
                      editorType="dxTextBox"
                      dataField="gpblg_extern_email"
            >
              <dxo-label
                text="Meine E-Mail-Adresse"
                horizontalAlignment="left">
              </dxo-label>
              <dxi-validation-rule type="required"
                                   message="Für Benachrichtigungen und die Bestätigung der Auftragsinformationen benötigen wir eine gültige E-Mail-Adresse."></dxi-validation-rule>
              <dxi-validation-rule type="email"
                                   [reevaluate]="true"
                                   message="Ungültige E-Mail-Adresse.">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="gpblg_bemerk_text"
                      editorType="dxTextArea"
                      [editorOptions]="{width: '100%',
                placeholder: 'Hier z.B. die Inseladresse eintragen, wenn sie nicht in der Auswahlliste (Feld: -Insel-Adresse-) zu finden sein sollte.',
                    disabled: this.isDisableEditingData }"
                      [colSpan]="2">
              <dxo-label
                text="Bemerkung"
                horizontalAlignment="left">
              </dxo-label>
            </dxi-item>
            <dxi-item itemType="group">
              <a href="{{frontendGepaeckTransportBedingungenUrl}}" target="_blank">Zu unseren Gepäcktransportbedingungen
                ...</a>
            </dxi-item>

            <dxi-item
              dataField="gpblg_extern_transpbed_bestaetigt_yn"
              editorType="dxCheckBox"
              [editorOptions]="{
                    text: 'Ich stimme den Bedingungen für den Gepäcktransport zu.',
                    value: this.gepaeckBeleg.gpblg_extern_transpbed_bestaetigt_yn,
                    disabled: this.isDisableEditingData
                }">
              <dxo-label
                [visible]="false">
              </dxo-label>
              <dxi-validation-rule
                type="compare"
                [comparisonTarget]="checkComparison"
                message="Ihre Zustimmung zu unseren Gepäcktransportbedingungen ist erforderlich, damit wir Ihren Auftrag bearbeiten können.">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item #buttonCheckData
                      itemType="button"
                      horizontalAlignment="left"
                      [buttonOptions]=buttonCheckDataAndGetApprovalCode>
            </dxi-item>

            <dxi-item
              [editorOptions]="{disabled: false, showClearButton: true, showSpinButtons:false, step: 0, hint: 'Mobile TAN, siehe SMS-Eingang Ihres Mobiltelefons'}"
              dataField="gpblg_extern_mobiletan"
              editorType="dxNumberBox">
              <dxo-label
                text="Mobile TAN"
                horizontalAlignment="left">
              </dxo-label>
            </dxi-item>

            <dxi-item
              dataField="gpblg_zktoart_id"
              [editorOptions]="{items: paymentTypeDddw,
               layout: 'horizontal',
               valueExpr: 'zktoart_id',
               displayExpr: 'zktoart_name',
               disabled: isDisableEditingPaymentType,
               onValueChanging: paymentTypeChanged,
               onValueChanged: paymentTypeChanged }"
              editorType="dxRadioGroup">
              <dxo-label
                text="Zahlungsdienstleister"
                horizontalAlignment="left">
              </dxo-label>
            </dxi-item>

            <dxi-item #buttonGoToPayPal
                      [visible]="(!this.isSubmitOrderDataAndPayPalDisabled) && this.isMobileTanGenerated  && this.paymentType === paymentTypeByPayPal
                      && (this.gepaeckBeleg.gpblg_id === undefined || !this.gepaeckBeleg.gpblg_id)
                      && (this.gepaeckBeleg.gpblg_bezahlt_kz === undefined || (this.gepaeckBeleg.gpblg_bezahlt_kz ?? '0' === '0'))
               && !this.isDisableEditingPaymentType && !this.isProcessing && this.isValidOrderData"
                      itemType="button"
                      horizontalAlignment="left"
                      [buttonOptions]="buttonSubmitOrderDataAndPayPal">
            </dxi-item>

            <dxi-item #buttonGoToPayOne
                      [visible]="(!this.isSubmitOrderDataAndPayOneDisabled) && this.isMobileTanGenerated  && this.paymentType === paymentTypeByPayOne
                      && !this.isProcessing && this.isValidOrderData"
                      itemType="button"
                      horizontalAlignment="left"
                      [buttonOptions]="buttonSubmitOrderDataAndPayOne">
            </dxi-item>

            <dxi-item
              [disabled]="this.paymentType === undefined || !this.paymentType"
              [visible]="this.isPaymentCashInAdvancePermitted && this.isMobileTanGenerated
               && !this.isDisableEditingPaymentType && !this.isProcessing  && this.isValidOrderData
               && !!this.paymentType
               && (this.paymentType === paymentTypeByBankTransfer || this.paymentType === paymentTypeByCash)"

              itemType="button"
              horizontalAlignment="left"
              [buttonOptions]="buttonSubmitOrderDataAndCashInAdvance">
            </dxi-item>
            <dxi-item itemType="empty" [colSpan]="2">&nbsp;</dxi-item>
            <dxi-item itemType="empty" [colSpan]="2">&nbsp;<br></dxi-item>
          </dx-form>
        </form>
        <div [hidden]="this.getIsPayPalButtonsDisabled()" id="paypalButtons" class="sits-paypal-buttons" crossorigin="anonymous"></div>
      </div>
    </div>
    <app-pay-one-wait-for-pay-link-completion [inputPopupVisible]="isPayOnePopupVisible"
                                              [inputStatusText]="payOneCurrentStatusText">
    </app-pay-one-wait-for-pay-link-completion>

  </div>
</div>
