<div class="dx-card sits-card" xmlns="http://www.w3.org/1999/html">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header">
      <dx-toolbar class="sits-print-hide">
        <dxi-item widget="dxButton" location="before">
          <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
        </dxi-item>
        <dxi-item location="left" widget="dxButton" locateInMenu="never">
          <div *dxTemplate>
            <div class="toolbar-label">{{[componentTitle]}} {{[gepaeckBeleg.gpblg_id]}} <span
                    *ngIf="!!gepaeckBeleg.gpblg_external_order_id">  /  Ticket-Nr.: {{gepaeckBeleg.gpblg_external_order_id}} </span></div>
          </div>
        </dxi-item>
        <dxi-item widget="dxButton" location="before" locateInMenu="auto">
          <dx-button id="link1" icon="info" hint="Details zum Gepäcktransport"
                     (click)="this.popoverToggleDefault()"></dx-button>
        </dxi-item>
      </dx-toolbar>
    </div>
    <app-gepaeck-info [inputPopoverVisible]="popoverVisible"
                      [inputTypeOfTransportName]="gepaeckBeleg.gpblg_dummy_fp_art_kz">
    </app-gepaeck-info>
    <dx-toolbar class="sits-print-hide">
      <dxi-item widget="dxButton" location="before" locateInMenu="auto"
                [hidden]="this.gepaeckBeleg.gpblg_id?.valueOf() < 1">
        <dx-button icon="print" text="Druckansicht" (click)="this.printPreview()"></dx-button>
      </dxi-item>
    </dx-toolbar>
    <div class="dx-field">
      <div *ngIf="!!this.gepaeckBeleg.gpblg_external_order_id || (this.gepaeckBeleg.gpblg_storniert_yn ?? 0) === 1">
        Infos:
        <span *ngIf="!!this.gepaeckBeleg.gpblg_external_order_id">Übernommen aus Ticket <b>{{this.gepaeckBeleg.gpblg_external_order_id}}</b>.&nbsp;</span>
        <span *ngIf="(this.gepaeckBeleg.gpblg_storniert_yn ?? 0) === 1">
          <span class = "sits-color-alert" ><b>STORNIERT</b></span>&nbsp;
          {{this.gepaeckBeleg.gpblg_storniert_stamp | date: 'EE dd.MM.YYYY HH:mm'}}.&nbsp;
        </span>
      </div>
      <form class="gepaeckbeleg-form" autocomplete="on">
        <dx-form #frachtForm [(formData)]="gepaeckBeleg"
                 labelLocation="top"
                 [scrollingEnabled]="true"
                 [showValidationSummary]="true"
                 colCount="2"
                 [width]="getMaxCardWidth()"
                 [minColWidth]="500"
                 width="auto"
                 [showColonAfterLabel]="false"
                 (onFieldDataChanged)="form_fieldDataChanged($event)">

          <dxi-item dataField="gpblg_dummy_fp_art_kz"
                    editorType="dxRadioGroup"
                    [visible]="gepaeckTransportArts !== undefined && gepaeckTransportArts.length >= 2"
                    [editorOptions]="{items: radioGroupItemsTypeOfTransport,
                    displayExpr: 'text',
                    layout: 'horizontal',
                    valueExpr: 'fp_art_kz',
                    disabled: this.isDisableEditing || gepaeckTransportArts === undefined || gepaeckTransportArts.length < 2 }">
            <dxi-validation-rule type="required"
                                 message="Die Transportart ist erforderlich, damit aus dem passenden Fahrplan / Flugplan gewählt werden kann."></dxi-validation-rule>
            <dxo-label
              text="TransportArt"
              horizontalAlignment="left"
              [visible]="false">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gpblg_rgempf_adr_id"
                    editorType="dxSelectBox"
                    [editorOptions]="{items: adresseDddw,
                    searchEnabled: 'true',
                    valueExpr: 'adr_id',
                    displayExpr: 'adr_such_name',
                    placeholder: 'min. 3 Zeichen ...',
                    applyValueMode: 'instantly',
                    deferRendering: true,
                    disabled: this.isDisableEditingBelegEmpfaenger
                   }">
            <dxo-label
              text="Beauftragende"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gpblg_dummy_datum"
                    type="date"
                    editorType="dxDateBox"
                    [editorOptions]="{
                    width: '100%',
                    type: 'date',
                    useMaskBehavior: 'true',
                    displayFormat: 'EE. dd.MM.yyyy',
                    min: startDate,
                    showSpinButtons: 'true',
                    hint: 'Datum an dem der Gepäcktransport erfolgen soll.',
                    dateSerializationFormat: 'yyyy-MM-dd',
                    invalidDateMessage: 'Das Datum muss ein gültiges Format haben: MM/dd/yyyy',
                    disabled: this.isDisableEditing }">
            <dxi-validation-rule type="required"
                                 message="Das Transportdatum ist erforderlich. Voraussetzung für den Transport."></dxi-validation-rule>
            <dxi-validation-rule
              type="async"
              message="Bitte das Mindestdatum nicht unterschreiten."
              [validationCallback]="asyncValidationDatum"
              [reevaluate]="true"
            ></dxi-validation-rule>
            <dxo-label
              [text]="getDatumLabelText()"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gpblg_dummy_abgang_fort_id"
                    editorType="dxRadioGroup"
                    [editorOptions]="{items: radioGroupItemsFrachtOrte,
                    displayExpr: 'text',
                    layout: 'horizontal',
                    valueExpr: 'frachtortId',
                    disabled: this.isDisableEditing || gepaeckTransportArts === undefined || gepaeckTransportArts.length < 2 }">
            <dxo-label
              text="Ausgangspunkt (ab ...)"
              horizontalAlignment="left">
            </dxo-label>
            <dxi-validation-rule type="required"
                                 message="Die Transportrichtung ist erforderlich, damit eine Fährverbindung gewählt werden kann."></dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="fp_id"
                    editorType="dxSelectBox"
                    [editorOptions]="{items: fahrplans,
                    dropDownOptions: {showTitle: false, hideOnOutsideClick: true},
                    valueExpr: 'fp_id',
                    searchPlaceholder: 'Abfahrtzeit ...',
                    displayExpr: getDisplayAbfahrtzeitVoll,
                    disabled: this.isDisableEditing}">
            <dxo-label
              text="Fähre (geplante Abfahrt)"
              horizontalAlignment="left">
            </dxo-label>
            <dxi-validation-rule type="range" min="1"
                                 message="Die Fähre ist erforderlich. Hinweise: eine Fähre kann erst ausgewählt werden, wenn ein Datum eingetragen ist. Es gibt auch Tage, an denen keine Fähre fährt."
                                 [reevaluate]="true"
            ></dxi-validation-rule>
          </dxi-item>

          <dxi-item editorType="dxSelectBox"
                    [editorOptions]="{items: gepaeckAdresseDddw,
                    searchEnabled: 'true',
                    valueExpr: 'adr_id',
                    displayExpr: 'adr_gepaeck_name',
                    placeholder: 'min. 3 Zeichen ...',
                    disabled: this.isDisableEditing}"
                    dataField="adr_id">
            <dxo-label
              text="Insel-Adresse (Straße HausNr. / Hotel)"
              horizontalAlignment="left">
            </dxo-label>
            <dxi-validation-rule
              message="Die Adresse wird benötigt. Geben Sie gern als Bemerkungen (unten) einen weiteren Hinweis, wenn es unter der gleichen Adresse mehrere Wohnungen geben sollte."
              type="required"></dxi-validation-rule>
          </dxi-item>

          <dxi-item
            [editorOptions]="{ showSpinButtons:true,
             hint: 'Anzahl Gepäckstücke',
             disabled: this.isDisableEditing || (!!gepaeckBeleg?.gpblg_bezahlt_provider_name && !!gepaeckBeleg?.gpblg_bezahlt_stamp) ,
             format: '##0'}"
            dataField="gpblg_anz"
            editorType="dxNumberBox">
            <dxo-label
              text="Gepäckstücke"
              horizontalAlignment="left">
            </dxo-label>
            <dxi-validation-rule type="required"
                                 message="Die Anzahl Gepäckstücke ist erforderlich für unsere Tourenplanung."></dxi-validation-rule>
            <dxi-validation-rule
              type="async"
              message="Bitte die Mindestanzahl an Gepäckstücken nicht unterschreiten"
              [validationCallback]="asyncValidationGpBlgAnz"
              [reevaluate]="true"
            ></dxi-validation-rule>
            <dxi-validation-rule type="range" min="1" max="99"
                                 message="Bitte den Bereich von 1 bis 99 für Anzahl an Gepäckstücken verwenden."></dxi-validation-rule>
          </dxi-item>

          <dxi-item
            dataField="gpblg_brutto_amount"
            editorType="dxNumberBox"
            [editorOptions]="{format: ' ##0.00 EUR',
             disabled: true,
             hint: 'Kosten für den Transport der ausgewählten Anzahl an Gepäckstücken.' }">
            <dxo-label
              text="Gesamtbetrag (inkl. MwSt.)"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gpblg_gepaeckstuecke_json"
                    editorType="dxTagBox"
                    [editorOptions]="{width: '100%',
                items: gepaeckDescriptions,
                acceptCustomValue: true,
                onCustomItemCreating: onGepaeckStueckTagCreatingDescriptions,
                onInput: onGepaeckStueckTagInput,
                onKeyDown: onGepaeckStueckTagKeyDown,
                onFocusOut: onGepaeckStueckTagFocusOut,
                maxDisplayedTags: 10,
                placeholder: 'Auswählen oder selbst schreiben (bitte kurz) ...',
                hint: 'Hier bitte jedes Gepäckstück beschreiben. Entweder Beschreibungen auswählen oder selbst eintragen.',
                    disabled: this.isDisableEditing }"
                    [colSpan]="2">
            <dxo-label
              text="Gepäckstücke Beschreibung"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gpblg_extern_name"
                    id="lastname"
                    editorType="dxTextBox"
                    [editorOptions]="{disabled: this.isDisableEditing,
                                 inputAttr: { autocomplete: 'on'},
                name: 'lastname' }">
            <dxo-label
              text="Gast, Name (optional)"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gpblg_extern_mobil_tel"
                    id="telephone"
                    editorType="dxTextBox"
                    [editorOptions]="{ disabled: this.isDisableEditing,
                                       inputAttr: { autocomplete: 'on'},
                                       placeholder: 'Mobil-Nr. der GepäckInhabenden für SMS-Benachrichtigung.',
                                       hint: 'Für die SMS-Benachrichtigung der Gepäckinhabenden.'
                                       }">
            <dxo-label
              text="Mobiltelefon-Nr. (GepäckInhabende benachrichtigen, optional)"
              horizontalAlignment="left">
            </dxo-label>
            <dxi-validation-rule type="custom"
                                 [validationCallback]="getIsValidPhoneNumber"
                                 [reevaluate]="true"
                                 message="Ungültiges Format oder unerlaubte Zeichen in der Telefonnummer. Beispiele: 0177-99999999, +49.177.99999999 oder ähnlich.">
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item [editorOptions]="{disabled: this.isDisableEditing,
                inputAttr: { autocomplete: 'on'},
                name: 'email',
                placeholder: 'E-Mail der GepäckInhabenden für Benachrichtigung.',
            hint: 'Optional, dringend empfohlen. Erlaubt die Übermittlung ausführlicherer Informationen ergänzend zur SMS-Benachrichtigung.'}"
                    dataField="gpblg_extern_email"
          >
            <dxo-label
              text="E-Mail-Adresse (GepäckInhabende benachrichtigen, optional)"
              horizontalAlignment="left">
            </dxo-label>
            <dxi-validation-rule type="email"
                                 [reevaluate]="true"
                                 message="Ungültige E-Mail-Adresse.">
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="gpblg_bemerk_text"
                    editorType="dxTextArea"
                    [editorOptions]="{width: '100%',
                placeholder: 'Hier z.B. die Inseladresse eintragen, wenn sie nicht in der Auswahlliste (Feld: -Insel-Adresse-) zu finden sein sollte.',
                    disabled: this.isDisableEditing }"
                    [colSpan]="2">
            <dxo-label
              text="Bemerkung"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item itemType="group">
            <a href="{{frontendGepaeckTransportBedingungenUrl}}" target="_blank">Zu unseren Gepäcktransportbedingungen
              ...</a>
          </dxi-item>

          <dxi-item
            dataField="gpblg_extern_transpbed_bestaetigt_yn"
            editorType="dxCheckBox"
            [editorOptions]="{
                    text: 'Ich stimme den Bedingungen für den Gepäcktransport zu.',
                    valueExpr: this.gepaeckBeleg.gpblg_extern_transpbed_bestaetigt_yn === 1,
                    disabled: this.isDisableEditing
                }">
            <dxo-label
              [visible]="false">
            </dxo-label>
            <dxi-validation-rule
              type="compare"
              [comparisonTarget]="checkComparison"
              message="Ihre Zustimmung zu unseren Gepäcktransportbedingungen ist erforderlich, damit wir Ihren Auftrag bearbeiten können.">
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item itemType="group" colCount="2" [colSpan]="1">
          </dxi-item>
        </dx-form>
        <div class="dx-fieldset">
          <dx-button [disabled]="this.isDisableEditing || this.isProcessing"
                     id="btn_submitOrderData"
                     [text]="getSaveButtonLabelText()"
                     hint="Änderung buchen und Kundin/Gast per Mail/SMS benachrichtigen."
                     type="default"
                     stylingMode="contained"
                     (click)="onSubmitOrderData()">
          </dx-button>&nbsp;
        </div>
      </form>
    </div>
  </div>
</div>


