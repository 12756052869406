import { Component } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {Location} from '@angular/common';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-datenschutz',
  templateUrl:  !!environment.targetKey
    ? './datenschutz-' + environment.targetKey + '.component.html'
    : './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.scss']
})
export class DatenschutzComponent {
  private history: string[] = [];
  public componentTitle: string = 'Datenschutzerklärung';

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private readonly location: Location
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  public goBack(): void {
    if (this.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl("/").then();
    }
  }
}
