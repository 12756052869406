import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {FrachtBrief} from '../../../api/api-dtos/fracht-brief';
import {FrachtBriefService} from '../../../api/api-services/fracht-brief.service';
import {AdresseDddw} from '../../../api/api-dtos/adresse-dddw';
import {IFrachtErfassungsStatus} from '../../../api/api-dtos/fracht-erfassungs-status';
import {IParitaet} from '../../../api/api-dtos/paritaet-dto';
import {ITransportStatus} from '../../../api/api-dtos/transport-status';
import {
  FahrplanService,
  FrachtErfassungsStatusService,
  FrachtOrtService,
  ParitaetService,
  TransportStatusService
} from '../../../api/api-services/api-services.module';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {IFrachtOrt} from '../../../api/api-dtos/fracht-ort';
import {FrachtbriefEditComponent} from '../frachtbrief-edit/frachtbrief-edit.component';
import {IFahrplan} from '../../../api/api-dtos/fahrplan';
import {formatDate, Location} from '@angular/common';
import {Observable} from 'rxjs';
import {DxDataGridComponent} from 'devextreme-angular';

@Component({
  selector: 'app-frachtbrief-list',
  templateUrl: './frachtbrief-list.component.html',
  styleUrls: ['./frachtbrief-list.component.scss']
})
export class FrachtbriefListComponent implements OnInit, OnChanges {

  @ViewChild('grid', {static: false}) grid: DxDataGridComponent;
  @Output() outputFrachtbrief: EventEmitter<FrachtBrief>;
  @Input() inputFrachtbrief: FrachtBrief;
  // @ViewChild('popupEditFrachtbrief', {static: false}) popupEditFrachtbrief: FrachtbriefEditComponent;
  @ViewChild('popupAddFrachtbrief', {static: false}) popupAddFrachtbrief: FrachtbriefEditComponent;

  public componentTitle: string = 'Frachtbriefe';
  frachtbriefe: FrachtBrief[];
  adresseDddw: AdresseDddw[];
  fahrplans$: Observable<IFahrplan[]>;
  fahrplans: IFahrplan[];
  frachtErfassungsStatuss: IFrachtErfassungsStatus[];
  frachtOrts: IFrachtOrt[];
  paritaets: IParitaet[];
  transportStatuss: ITransportStatus[];

  filterValue = '';
  popupDeleteFrachtbrief: any;
  popupDialogName: string;
  popupDeleteFrachtbriefVisible = false;
  popupAddFrachtbriefVisible = false;
  popupEditFrachtbriefVisible = false;
  frachtbriefDeleteAllowed = false;
  frachtbriefEditAllowed = false;
  frachtbriefViewAllowed = true;
  frachtbriefCopyAllowed = false;
  selectedFrachtbriefe: FrachtBrief[] = [];
  selectedFrachtbriefIds: number[] = [];
  public dummyFrachtbriefId = 0;
  gridPageSize = 15;
  private editAction = 'edit';
  private addAction = 'add';

  constructor(
    private frachtbriefService: FrachtBriefService,
    private adresseService: AdresseService,
    private fahrplanService: FahrplanService,
    private frachtErfassungsStatusService: FrachtErfassungsStatusService,
    private frachtOrtService: FrachtOrtService,
    private paritaetService: ParitaetService,
    private transportStatusService: TransportStatusService,
    private authenticationService: AuthenticationService,
    private readonly location: Location,
  ) {
    this.deleteSelectedItems = this.deleteSelectedItems.bind(this);
    this.outputFrachtbrief = new EventEmitter<FrachtBrief>();
    this.getAdresses = this.getAdresses.bind(this);
    this.getFahrplans = this.getFahrplans.bind(this);
    this.getFrachtErfassungsStatuss = this.getFrachtErfassungsStatuss.bind(this);
    this.getFrachtOrts = this.getFrachtOrts.bind(this);
    this.getParitaets = this.getParitaets.bind(this);
    this.getTransportStatuss = this.getTransportStatuss.bind(this);
    /*this.getEmptyDummyFrachtbriefId = this.getEmptyDummyFrachtbriefId.bind(this);*/
    this.actionShowPopup = this.actionShowPopup.bind(this);

    if (!this.authenticationService.authenticated || !this.authenticationService.currentUser) {
      // this.router.navigate(['login']);
    }

    if (!!localStorage.getItem('frachtbrief-list.grid.paging.pageSize')) {
      this.gridPageSize = +localStorage.getItem('frachtbrief-list.grid.paging.pageSize');
      if (this.gridPageSize < 5) {
        this.gridPageSize = 5;
      } else if (this.gridPageSize > 300) {
        this.gridPageSize = 300;
      }
    }
  }

  calculateCellBelegNr(data: any) {
    // return [data.frako_belegtyp_code.substr(0, 2), data.frako_belegart_code, data.frako_rg_nr].join(' ');
    return [data.frako_belegart_code, data.frako_rg_nr].join(' ');
  }

  // Versorge Dropdown mit Daten ...
  getAdresses(options: any) {
    return {
      store: this.adresseDddw,
      filter: options.data ? ['adr_id', '=', options.data.adr_id] : null
    };
  }

  // Versorge Dropdown mit Daten ...
  getFahrplans(options: any) {
    return {
      store: this.fahrplans,
      filter: options.data ? ['fp_id', '=', options.data.frako_plan_fp_id] : null
    };
  }

  // Versorge Dropdown mit Daten ...
  getFrachtErfassungsStatuss(options: any) {
    return {
      store: this.frachtErfassungsStatuss,
      filter: options.data ? ['fest_status_nr', '=', options.data.fest_status_nr] : null
    };
  }

  getFrachtOrts(options: any) {
    return {
      store: this.frachtOrts,
      filter: options.data ? ['fort_id', '=', options.data.fort_id] : null
    };
  }

  // Versorge Dropdown mit Daten ...
  getParitaets(options: any) {
    return {
      store: this.paritaets,
      filter: options.data ? ['code', '=', options.data.code] : null
    };
  }

  // Versorge Dropdown mit Daten ...
  getDisplayAbfahrtzeitVoll(item: IFahrplan) {
    if (!item) {
      return '';
    }
    const abfahrtzeit = new Date(item.fp_abfahrt_datzeit);
    if (parent.window.outerWidth < 1200) {
      return formatDate(abfahrtzeit, 'dd.MM.yy HH:mm', 'de-DE');
    } else {
      return formatDate(abfahrtzeit, 'dd.MM.yy HH:mm', 'de-DE');
    }
  }

  getTransportStatuss(options: any) {
    return {
      store: this.transportStatuss,
      filter: options.data ? ['trst_status_nr', '=', options.data.trst_status_nr] : null
    };
  }

  async ngOnInit() {
    if (!this.adresseDddw || this.adresseDddw.length === 0) {
      this.adresseDddw = [];
      this.adresseDddw = await this.adresseService.getAllDddw();
    }
    this.fahrplanService.getFrachtJanusDays(365).subscribe( resFahrplans => {
      this.fahrplans = resFahrplans;
    });
    this.frachtErfassungsStatuss = [];
    this.frachtErfassungsStatuss = await this.frachtErfassungsStatusService.getAll();
    this.frachtOrts = [];
    this.frachtOrtService.getAll().subscribe(data => {
      this.frachtOrts = data === undefined || data === null
        ? []
        : data;
    });
    this.paritaets = [];
    this.paritaets = await this.paritaetService.getAll();
    this.transportStatuss = [];
    this.transportStatusService.getAll().subscribe(data => {
      this.transportStatuss = data;
    });
    await this.loadBaseData().then(() => {
      this.refreshFrachtbriefList();
    });
  }

  async loadBaseData() {
    if (this.adresseDddw === undefined) {
      this.adresseDddw = [];
    }
    if (this.adresseDddw.length === 0) {
      await this.adresseService.getAllDddw().then(res => {
        this.adresseDddw = res;
      });
    }
  }

  async refreshFrachtbriefList() {
    this.popupDeleteFrachtbriefVisible = false;
    this.popupAddFrachtbriefVisible = false;
    this.popupEditFrachtbriefVisible = false;
    this.frachtbriefe = await this.frachtbriefService.getAll();
  }

  optionChangedHandler(event: any) {
    if (event.fullName === 'paging.pageSize' && event.value >= 5) {
      localStorage.setItem('frachtbrief-list.grid.paging.pageSize', event.value);
    }
  }

  selectionChangedHandler(event: any) {
    this.selectedFrachtbriefe = event.selectedRowsData as FrachtBrief[];
    this.frachtbriefCopyAllowed = this.selectedFrachtbriefe.length > 0 && this.selectedFrachtbriefe.length <= 15;
    if (this.selectedFrachtbriefe && this.selectedFrachtbriefe.length === 1) {
      // this.frachtbriefCopyAllowed = this.selectedFrachtbriefe[0].frako_belegart_code === 'RG';
      this.frachtbriefEditAllowed = this.frachtbriefService.isFrachtbriefEditAllowed(this.selectedFrachtbriefe[0]);
      this.frachtbriefDeleteAllowed = this.frachtbriefEditAllowed;

      this.outputFrachtbrief.emit(this.selectedFrachtbriefe[0]);
    } else {
      // this.frachtbriefCopyAllowed = false;
      this.outputFrachtbrief.emit(undefined);
    }
  }

  getFirstSelectedFrachtbriefId(): number {
    if (this.selectedFrachtbriefe && this.selectedFrachtbriefe.length > 0) {
      // Nimm den ersten, falls mehrere selektiert sein sollten
      // return this.selectedFrachtbriefe.map(o => o.frako_id).indexOf(1);
      return this.selectedFrachtbriefe[0].frako_id;
    } else {
      return 0;
    }
  }

  getEmptyDummyFrachtbriefId(): number {
    return this.dummyFrachtbriefId ? this.dummyFrachtbriefId : 0;
  }

  getSelectedFrachtbriefeAsJoinedString(): string {
    if (this.selectedFrachtbriefe && this.selectedFrachtbriefe.length > 0) {
      return this.selectedFrachtbriefe.map(o => o.frako_id).join(', ');
    } else {
      return '';
    }
  }

  onCellPrepared(e: any) {
    if (e.rowType === 'data') {
      switch (e.column.dataField) {
        case 'frako_fest_status_nr':
          e.cellElement.style.color = e.data.frako_fest_status_nr === 50 ? 'green' : 'blue';
          break;
        case 'frako_trst_status_nr':
          e.cellElement.style.color = e.data.frako_trst_status_nr >= 90 ? 'green' : 'default';
          break;
        case 'frako_absender_adr_id':
          e.cellElement.style.color = (this.authenticationService.currentUser.isEmployee === 1
            || e.data.frako_absender_adr_id === this.authenticationService.currentUser.customerId) ? 'default' : 'green';
          break;
        case 'frako_empf_adr_id':
          e.cellElement.style.color = (this.authenticationService.currentUser.isEmployee === 1
            || e.data.frako_empf_adr_id === this.authenticationService.currentUser.customerId) ? 'default' : 'green';
          break;
        case 'frako_rgempf_adr_id':
          e.cellElement.style.color = (this.authenticationService.currentUser.isEmployee === 1
            || e.data.frako_rgempf_adr_id === this.authenticationService.currentUser.customerId) ? 'default' : 'green';
          break;
        case 'frapo_menge_sum':
          e.cellElement.style.color = e.data.frapo_menge_sum === 0 ? 'red' : 'default';
          break;
        case 'frapo_notarif_cnt':
          e.cellElement.style.color = e.data.frapo_notarif_cnt === 0 ? 'grey' : 'red';
          break;
        case 'frako_ist_fp_id':
          e.cellElement.style.color = (!e.data.frako_plan_fp_id || !e.data.frako_ist_fp_id
            || e.data.frako_ist_fp_id === e.data.frako_plan_fp_id) ? 'default' : 'brown';
          break;

      }
    }
  }

  async deleteSelectedItems() {
    for (const o of this.selectedFrachtbriefe) {
      await this.frachtbriefService.delete(o.frako_id).then(() => {
        this.selectedFrachtbriefe.splice(0);
        this.selectedFrachtbriefIds.splice(0);
      });
    }
    await this.refreshFrachtbriefList();
    return;
  }

  async addedFrachtbrief(frachtbrief: FrachtBrief) {
    if (frachtbrief.frako_id && frachtbrief.frako_id !== 0) {
      this.frachtbriefService.getFrachtbriefPositions(frachtbrief.frako_id).subscribe(resFrapo => {
        this.frachtbriefe.push(resFrapo);
        this.selectedFrachtbriefe = [resFrapo];
        this.selectedFrachtbriefIds = this.selectedFrachtbriefe.map(o => o.frako_id);
      });
    }
    this.popupAddFrachtbriefVisible = false;
  }

  async editedFrachtbrief(frachtbrief: FrachtBrief) {
    this.popupEditFrachtbriefVisible = false;
    await this.refreshFrachtbriefList();
    this.selectedFrachtbriefe = [frachtbrief];
  }

  async ngOnChanges(changes: SimpleChanges) {
    return;
  }

  async approveSelectedItemsForTransportation() {
    for (const o of this.selectedFrachtbriefe) {
      await this.frachtbriefService.approveForTransportation(o.frako_id);
    }
    await this.refreshFrachtbriefList();
  }

  async duplicateSelectedItem() {
    for (const o of this.selectedFrachtbriefe) {
      await this.frachtbriefService.duplicate(o.frako_id);
    }
    await this.refreshFrachtbriefList();
  }

  async stopSelectedItems() {
    for (const o of this.selectedFrachtbriefe) {
      await this.frachtbriefService.stopProcessing(o.frako_id);
    }
    await this.refreshFrachtbriefList();
  }

  async unstopSelectedItems() {
    for (const o of this.selectedFrachtbriefe) {
      await this.frachtbriefService.unStopProcessing(o.frako_id);
    }
    await this.refreshFrachtbriefList();
  }

  actionShowPopup(mode: string) {
    if ([this.editAction, this.addAction].indexOf(mode) < 0) {
      return;
    }

    this.popupAddFrachtbriefVisible = false;
    this.popupEditFrachtbriefVisible = false;
    this.frachtbriefCopyAllowed = false;

    if (mode === this.addAction) {
      if (this.popupAddFrachtbrief) {
        this.dummyFrachtbriefId -= 1;
        this.popupAddFrachtbrief.frachtbrief = this.frachtbriefService.getNewFrachtbrief();
      }
      this.frachtbriefEditAllowed = true;
      this.popupAddFrachtbriefVisible = true;
    } else if (mode === this.editAction) {
      this.popupEditFrachtbriefVisible = true;
    }
  }

  public goBack(): void {
    this.location.back();
  }

}
