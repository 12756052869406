import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {Location} from '@angular/common';
import {DxDataGridComponent} from 'devextreme-angular';
import {timer} from 'rxjs/internal/observable/timer';
import {AdresseDddw} from '../../../api/api-dtos/adresse-dddw';
import {GeraeteVermietungStorageBox} from '../../../api/api-dtos/geraete-vermietung-storage-box';
import {StorageBoxService} from '../../../api/api-services/storage-box.service';
import {paymentTypesEnum} from '../../../api/api-services/paymenttypes';
import notify from 'devextreme/ui/notify';
import {HostService} from '../../../shared-services/host.service';

@Component({
  selector: 'app-storagebox-order-list',
  templateUrl: './storagebox-order-list.component.html',
  styleUrls: ['./storagebox-order-list.component.scss'],
  preserveWhitespaces: true,
})
export class StorageboxOrderListComponent implements OnInit {
  @ViewChild('grid', {static: false}) grid: DxDataGridComponent;
  componentTitle = 'Lagerbox-Aufträge';
  private readonly frontendBaseUrl: string;

  public paymentTypes = paymentTypesEnum;
  public payedByTypes: any[] = [];
  toolbarSelectPaymentTypeOptions: any;
  toolbarPaymentType: string = "PayPal";
  public isMobileResolution = false;
  storageBoxOrders: GeraeteVermietungStorageBox[];
  selectedStorageBoxOrders: GeraeteVermietungStorageBox[] = [];
  selectedStorageBoxBelegeIds: number[] = [];

  adresseDddw: AdresseDddw[];
  gepaeckAdresseDddw: AdresseDddw[];

  gridPageSize = 15;
  popupDeleteStorageBoxOrderVisible = false;
  storageBoxOrderCopyAllowed = false;
  private storageBoxOrderEditAllowed = false;
  storageBoxOrderDeleteAllowed = false;

  private counter = timer(60000, 120000);

  constructor(
    private router: Router,
    private storageBoxService: StorageBoxService,
    private adresseService: AdresseService,
    public authenticationService: AuthenticationService,
    private readonly location: Location,
    private hostService: HostService,
  ) {
    this.actionDeleteSelectedItems = this.actionDeleteSelectedItems.bind(this);
    this.getAdresses = this.getAdresses.bind(this);
    this.getFrontendBaseUrl = this.getFrontendBaseUrl.bind(this);
    this.getGepaeckAdresses = this.getGepaeckAdresses.bind(this);
    this.getIsEmployee = this.getIsEmployee.bind(this);
    this.signAsPayedSelectedItems = this.signAsPayedSelectedItems.bind(this);
    this.signAsUnPayedSelectedItems = this.signAsUnPayedSelectedItems.bind(this);
    this.selectionChangedHandler = this.selectionChangedHandler.bind(this);
    this.refreshStorageboxOrderList = this.refreshStorageboxOrderList.bind(this);
    this.onCellPrepared = this.onCellPrepared.bind(this);
    this.onValueChangedPaymentType = this.onValueChangedPaymentType.bind(this);

    this.frontendBaseUrl = this.hostService.getFrontendBaseUrl();

    if (!!localStorage.getItem('storagebox-order-list.grid.paging.pageSize')) {
      this.gridPageSize = Number(localStorage.getItem('storagebox-order-list.grid.paging.pageSize'));
      if (this.gridPageSize < 5) {
        this.gridPageSize = 5;
      } else if (this.gridPageSize > 300) {
        this.gridPageSize = 300;
      }
    }

    this.payedByTypes = this.storageBoxService.getPayedByTypes();

    this.toolbarSelectPaymentTypeOptions = {
      width: 140,
      items: this.payedByTypes,
      hint: 'Zahlart mit der gezahlt wurde. Vorbelegung für den <bezahlt>-Button',
      disabled: false,
      valueExpr: 'code',
      displayExpr: 'name',
      value: this.toolbarPaymentType,
      onValueChanged: (args: any) => {
        if (!!args.value) {
          this.onValueChangedPaymentType(args);
        }
      }
    };

  }

  async ngOnInit() {
    this.counter.subscribe(() => {
      this.timerEvent();
    });

    if (!this.adresseDddw || this.adresseDddw.length === 0) {
      this.adresseDddw = [];
      this.adresseDddw = await this.adresseService.getAllDddw();
    }

    if (this.gepaeckAdresseDddw === undefined || this.gepaeckAdresseDddw.length <= 1) {
      await this.adresseService.getGepaeckAdrDddw().then(res => {
        this.gepaeckAdresseDddw = res;
        return;
      });
    }

    await this.loadBaseData().then(() => {
      this.refreshStorageboxOrderList();
    });

    window.onresize = () => {
      this.isMobileResolution = window.innerWidth <= 768;
    };
  }

  // --------------------------------------------------------------------------
  // DataGrid Hooks

  selectionChangedHandler(event: any) {
    this.selectedStorageBoxOrders = event.selectedRowsData as GeraeteVermietungStorageBox[];
    this.storageBoxOrderCopyAllowed = this.selectedStorageBoxOrders.length > 0 && this.selectedStorageBoxOrders.length <= 15;
    if (this.selectedStorageBoxOrders && this.selectedStorageBoxOrders.length === 1) {
      this.storageBoxOrderEditAllowed = this.storageBoxService.isStorageBoxOrderEditAllowed(this.selectedStorageBoxOrders[0]);
      this.storageBoxOrderDeleteAllowed = this.storageBoxOrderEditAllowed
        && (this.selectedStorageBoxOrders[0].gervm_bezahlt_kz !== undefined && this.selectedStorageBoxOrders[0].gervm_bezahlt_kz != '1');
    } else {
      this.storageBoxOrderEditAllowed = false;
      this.storageBoxOrderDeleteAllowed = false;
    }
  }


  optionChangedHandler(event: any) {
    if (event.fullName === 'paging.pageSize') {
      if (event.value >= 5) {
        localStorage.setItem('storagebox-order-list.grid.paging.pageSize', event.value);
      }
    }
  }

  onCellPrepared(e: any) {
    if (e.rowType === 'data') {
      //console.log('onCellPrepared', e.column.dataField);
      let startDateNum: number;
      let endDateNum: number;
      let currentDateNum: number
      switch (e.column.dataField) {
        case 'gervm_beginn_datzeit':
          startDateNum = !!e.data.gervm_beginn_datzeit ? new Date(e.data.gervm_beginn_datzeit).setHours(0, 0, 0, 0) : null;
          endDateNum = !!e.data.gervm_ende_datzeit ? new Date(e.data.gervm_ende_datzeit).setHours(0, 0, 0, 0) : null;
          currentDateNum = new Date().setHours(0, 0, 0, 0);

          e.cellElement.style.color = startDateNum === currentDateNum
            ? 'red'
            : currentDateNum > startDateNum && currentDateNum <= endDateNum ? 'green'
              : currentDateNum < startDateNum && currentDateNum < endDateNum ? 'blue'
                : currentDateNum > startDateNum && currentDateNum > endDateNum ? 'gray'
                  : 'default';
          break;
        case 'gervm_ende_datzeit':
          endDateNum = !!e.data.gervm_ende_datzeit ? new Date(e.data.gervm_ende_datzeit).setHours(0, 0, 0, 0) : null;
          startDateNum = !!e.data.gervm_beginn_datzeit ? new Date(e.data.gervm_beginn_datzeit).setHours(0, 0, 0, 0) : null;
          currentDateNum = new Date().setHours(0, 0, 0, 0);

          e.cellElement.style.color = endDateNum === currentDateNum
            ? 'red'
            : currentDateNum >= startDateNum && currentDateNum <= endDateNum ? 'green'
              : currentDateNum < startDateNum && currentDateNum < endDateNum ? 'blue'
                : currentDateNum > startDateNum && currentDateNum > endDateNum ? 'gray'
                  : 'default';
          break;
      }
    }
  }

  public onValueChangedPaymentType(e: any) {
    console.log('onSelectionChangedPaymentType', e);
    if (e && e.value && !!e.value) {
      this.toolbarPaymentType = e.value.toString();
      console.log('onSelectionChangedPaymentType this.toolbarPaymentType', this.toolbarPaymentType);
    }
  };

  // END DataGrid Hooks
  // --------------------------------------------------------------------------

  async loadBaseData() {
    if (this.adresseDddw === undefined) {
      this.adresseDddw = [];
    }
    if (this.adresseDddw.length === 0) {
      await this.adresseService.getAllDddw().then(res => {
        this.adresseDddw = res;
      });
    }
  }

  async refreshStorageboxOrderList() {
    this.popupDeleteStorageBoxOrderVisible = false;
    await this.storageBoxService.getAll().then(res => {
      this.storageBoxOrders = res;
      this.selectedStorageBoxOrders = this.grid.instance.getSelectedRowsData() as GeraeteVermietungStorageBox[];
    });
  }

  // Versorge Dropdown mit Daten ...
  getAdresses(options: any) {
    return {
      store: this.adresseDddw,
      filter: options.data ? ['adr_id', '=', options.data.adr_id] : null
    };
  }

  getGepaeckAdresses(options: any) {
    return {
      store: this.gepaeckAdresseDddw,
      filter: options.data ? ['adr_id', '=', options.data.adr_id] : null
    };
  }

  public getIsEmployee(): boolean {
    return this.authenticationService.isAuthenticated && this.authenticationService.isEmployee;
  }


  public actionEditItem(): any {
    if (this.selectedStorageBoxOrders && this.selectedStorageBoxOrders.length > 0) {
      // alte GEP bei Kunden nur anzeigen, nicht als EDIT
      if ((this.authenticationService.isCustomer || this.authenticationService.isEmployee)
        && new Date(this.selectedStorageBoxOrders[0].gervm_beginn_datzeit) < this.storageBoxService.getMinimalStartDate()) {
        this.router.navigate(['/storagebox/order/show',
          this.selectedStorageBoxOrders[0].gervm_id,
          this.selectedStorageBoxOrders[0].gervm_extern_mobiletan ? this.selectedStorageBoxOrders[0].gervm_extern_mobiletan : '000000']).then();
      } else {
        let storageBoxOrder: GeraeteVermietungStorageBox = {id: this.selectedStorageBoxOrders[0].gervm_id};
        this.router.navigate(['storagebox/order/cust/edit', storageBoxOrder]).then();
      }
    }
  }

  public actionAddItem(): any {
    this.router.navigate(['storagebox/order/cust/edit/id', 0]).then();
  }

  public actionShowItem(): any {
    if (this.selectedStorageBoxOrders && this.selectedStorageBoxOrders.length > 0) {
      // alte LX bei Kunden nur anzeigen, nicht als EDIT
      this.router.navigate(['/storagebox/order/show',
        this.selectedStorageBoxOrders[0].gervm_id,
        this.selectedStorageBoxOrders[0].gervm_extern_mobiletan ? this.selectedStorageBoxOrders[0].gervm_extern_mobiletan : '000000']).then();
    }
  }

  async actionDeleteSelectedItems() {
    // this.outputFrachtbrief.emit(undefined);
    for (const o of this.selectedStorageBoxOrders) {
      await this.storageBoxService.delete(o.gervm_id).then(() => {
        this.selectedStorageBoxOrders.splice(0);
        this.selectedStorageBoxBelegeIds.splice(0);
      }, err => {
        let noteMessage: string;
        console.log('stbx-list.Delete() Fehler ', err);
        if (!!err) {
          noteMessage = !!err ? err : `Problem: Lagerbox-Auftrag ${o.gervm_ger_name} konnte NICHT erfolgreich angelegt werden.`;
        }
        err = ((err ?? '') + ' Bitte nochmals versuchen. ').trim();
        notify({
          noteMessage,
          message: err,
          position: {
            my: 'center',
            at: 'center'
          }
        });
      });
    }
    await this.refreshStorageboxOrderList();
    return;
  }

  public goBack(): void {
    this.location.back();
  };

  async signAsPayedSelectedItems(paymentType: paymentTypesEnum) {
    let signedCount: number = 0;
    if (this.authenticationService.isEmployee) {
      this.selectedStorageBoxOrders = this.grid.instance.getSelectedRowsData();
      for (const o of this.selectedStorageBoxOrders) {
        if (o.gervm_bezahlt_kz?.toString() === '0') {
          await this.storageBoxService.signAsPayed(o.gervm_id, this.toolbarPaymentType).then(() => {
            signedCount++;
          });
        }
      }
      if (signedCount > 0) {
        await this.refreshStorageboxOrderList();
      }
    }
  }

  async signAsUnPayedSelectedItems() {
    let signedCount: number = 0;
    if (this.authenticationService.isEmployee) {
      this.selectedStorageBoxOrders = this.grid.instance.getSelectedRowsData();
      for (const o of this.selectedStorageBoxOrders) {
        if (o.gervm_bezahlt_kz === null || o.gervm_bezahlt_kz?.toString() === '1') {
          await this.storageBoxService.signAsUnPayed(o.gervm_id);
          signedCount++;
        }
      }
      if (signedCount > 0) {
        await this.refreshStorageboxOrderList();
      }
    }
  }

  getSelectedGepaeckBelegeAsJoinedString(): string {
    if (this.grid.instance.getSelectedRowsData() && this.grid.instance.getSelectedRowsData().length > 0) {
      return this.grid.instance.getSelectedRowsData().map(o => o.gervm_id).join(', ');
    } else {
      return '';
    }
  }

  private async timerEvent() {
    if (!this.authenticationService.isAuthenticated) {
      this.goBack();
    }

    if (this.authenticationService.isEmployee) {
      await this.refreshStorageboxOrderList();
      return;
    }
  }

  public getFrontendBaseUrl(): string {
    console.log('getFrontendBaseUrl', this.frontendBaseUrl);
    return this.frontendBaseUrl;
  }
}
