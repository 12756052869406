<div class="dx-card sits-card" id="pagecard" xmlns="http://www.w3.org/1999/html">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header">{{title}} <span
      *ngIf="!!this.storageBoxOrder.gervm_id">VM-</span>{{this.storageBoxOrder.gervm_id}}
    </div>
    <div class="card-text sits-print-only">
      Bitte folgendes beachten.<br>
      Miete und Service für eine Lagerbox für den maximalen Zeitraum von 12 Monaten, beginnend ab gewähltem Leistungsbeginn.<br>
      Eine ggf. gewünschte Verlängerung bitte frühzeitig vor Ablauf buchen und dabei die bisherige Lagerbox wieder auswählen.<br>
      Vielen Dank für Ihre Unterstützung.<br>
      Um alles weitere kümmern wir uns.<br>
      Den Lagerbox-Service gibt es das ganze Jahr über.<br>
      Siehe auch... <a class="dx-icon-link" href="https://www.inselspedition.de/lagerbox-service" target="_blank">www.inselspedition.de/lagerbox-service</a>
    </div>
    <dx-load-panel
      [hideOnOutsideClick]="true"
      [visible]="isProcessing"
      message="Processing" >
    </dx-load-panel>
    <dx-popover
      target="#link1"
      position="bottom"
      [width]="300"
      [showTitle]="true"
      title="Details ..."
      [(visible)]="popoverVisible">
      <div *dxTemplate="let data = model of 'content'">
        Bitte folgendes beachten.<br>
        Miete und Service für eine Lagerbox für den <br>
        maximalen Zeitraum von 12 Monaten, <br>
        beginnend ab gewähltem Leistungsbeginn.<br>
        Eine ggf. gewünschte Verlängerung<br>
        bitte frühzeitig vor Ablauf buchen und <br>
        dabei die bisherige Lagerbox wieder auswählen.<br>
        Vielen Dank für Ihre Unterstützung.<br>
        Um alles weitere kümmern wir uns.<br>
        Den LagerboxService gibt es das ganze Jahr über.<br>
        Siehe auch... <a class="dx-icon-link" href="https://www.inselspedition.de/lagerbox-service" target="_blank">www.inselspedition.de/lagerbox-service</a>
      </div>
    </dx-popover>
    <div class="sits-print-hide">
      <dx-toolbar>
        <dxi-item widget="dxButton" location="before" locateInMenu="auto">
          <dx-button icon="back" (click)="this.goBack()"></dx-button>
        </dxi-item>
        <dxi-item widget="dxButton" location="before" locateInMenu="auto">
          <dx-button id="link1" icon="info" hint="Details zum Lagerbox-Service"
                     (click)="this.popoverToggleDefault()"></dx-button>
        </dxi-item>

        <dxi-item widget="dxButton" location="before" locateInMenu="auto"
                  [hidden]="this.storageBoxOrder.gervm_id?.valueOf() < 1">
          <dx-button icon="print" text="Druckansicht" (click)="this.printPreview()"></dx-button>
        </dxi-item>
      </dx-toolbar>
    </div>
    <div class="dx-field">
      <!--<form class="storagebox-form" autocomplete="on">-->
        <dx-form id="storageboxform" [(formData)]="storageBoxOrder"
                 labelLocation="top"
                 [scrollingEnabled]="true"
                 [showValidationSummary]="true"
                 colCount="2"
                 [width]="getMaxCardWidth()"
                 [minColWidth]="500"
                 width="auto"
                 [showColonAfterLabel]="false"
                 (onFieldDataChanged)="form_fieldDataChanged($event)">

          <dxi-item dataField="gervm_beginn_datzeit"
                    type="date"
                    editorType="dxDateBox"
                    [editorOptions]="{
                    width: '100%',
                    type: 'date',
                    useMaskBehavior: 'true',
                    displayFormat: 'EE. dd.MM.yyyy',
                    min: startDate,
                    showSpinButtons: 'true',
                    hint: 'Datum, an dem die Lagerbox bereitgestellt werden soll. Bis heute 16:00 Uhr können Sie für morgen aufgeben, danach ab übermorgen.',
                    dateSerializationFormat: 'yyyy-MM-dd',
                    invalidDateMessage: 'Das Datum muss ein gültiges Format haben: MM/dd/yyyy',
                    disabled: this.isDisableEditingData }">
            <dxi-validation-rule type="required"
                                 message="Das Transportdatum ist erforderlich. Voraussetzung für den Transport."></dxi-validation-rule>
            <dxo-label
              text="Beginn-Datum"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gervm_ende_datzeit"
                    type="date"
                    editorType="dxDateBox"
                    [editorOptions]="{
                    width: '100%',
                    type: 'date',
                    useMaskBehavior: 'true',
                    displayFormat: 'EE. dd.MM.yyyy',
                    min: startDate,
                    showSpinButtons: 'true',
                    hint: 'Datum, an dem die Lagerbox abgeholt werden soll. Bis heute 16:00 Uhr können Sie für morgen aufgeben, danach ab übermorgen.',
                    dateSerializationFormat: 'yyyy-MM-dd',
                    invalidDateMessage: 'Das Datum muss ein gültiges Format haben: MM/dd/yyyy',
                    disabled: this.isDisableEditingData }">
            <dxi-validation-rule type="required"
                                 message="Das Ende-Datum ist erforderlich."></dxi-validation-rule>
            <dxi-validation-rule type="custom"
                                 [validationCallback]="getIsValidEndeDatzeit"
                                 [reevaluate]="true"
                                 [message]="notValidEndeDatzeitMessage"></dxi-validation-rule>
            <dxo-label
              text="Ende-Datum"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <!--<dx-validation-group id="validationStorageBoxGroup">-->
            <dxi-item itemType="simple"
                      dataField="gervm_ger_nr"
                      editorType="dxSelectBox"
                      [editorOptions]="gervmGerNrEditorOptions">
              <dxo-label
                text="Lagerbox"
                horizontalAlignment="left">
              </dxo-label>
              <dxi-validation-rule
                message="Lagerbox wird benötigt. Bitte wählen Sie für den gewünschten Zeitraum aus dem verfügbaren Bestand 'Ihre' Lagerbox aus."
                type="required"></dxi-validation-rule>
              <dxi-validation-rule type="custom"
                                   [validationCallback]="getIsValidGervmGerNr"
                                   [reevaluate]="true"
                                   message="Die gewählte Lagerbox wurde inzwischen von anderen gebucht oder reserviert. Die Liste wurde erneut geladen. Bitte eine andere wählen.">
              </dxi-validation-rule>
            </dxi-item>
          <!--</dx-validation-group>-->

          <dxi-item editorType="dxSelectBox"
                    [editorOptions]="{items: ladestelleAdresseDddw,
                searchEnabled: 'true',
                  valueExpr: 'adr_id',
                  displayExpr: 'adr_gepaeck_name',
                  placeholder: 'min. 3 Zeichen ...',
                  disabled: true }"
                    dataField="gervm_ladestelle_adr_id">
            <dxo-label
              text="Insel-Adresse (Straße HausNr. / Hotel / Zeltplatz)"
              horizontalAlignment="left">
            </dxo-label>
            <dxi-validation-rule
              message="Die Adresse wird benötigt. Diesen Service gibt es derzeit nur am Zeltplatz"
              type="required"></dxi-validation-rule>
          </dxi-item>

          <dxi-item
            dataField="gervm_brutto_amount"
            editorType="dxNumberBox"
            [editorOptions]="{format: ' ##0.00 EUR', disabled: true,
                      hint: 'Kosten für Miete und Service von Lagerboxen.' }">
            <dxo-label
              text="Gesamtbetrag (inkl. MwSt.)"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gervm_extern_name"
                    id="lastname"
                    editorType="dxTextBox"
                    [editorOptions]="{disabled: this.isDisableEditingData,
                                 inputAttr: { autocomplete: 'on'},
                name: 'lastname' }">
            <dxo-label
              text="Boxmieter/-in, Name"
              horizontalAlignment="left">
            </dxo-label>
            <dxi-validation-rule
              message="Ihr Name wird benötigt für die Auftragsabwicklung."
              type="required"></dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="gervm_extern_mobil_tel"
                    id="telephone"
                    editorType="dxTextBox"
                    [editorOptions]="{ disabled: this.isDisableEditingData,
                                       inputAttr: { autocomplete: 'on'},
                                       placeholder: 'Mobil-Nr. der Lagerbox-Mietenden für SMS-Benachrichtigung.',
                                       hint: 'Für die SMS-Benachrichtigung der Lagerbox-Mietenden.'
                                       }">
            <dxo-label
              text="Mobiltelefon-Nr. (Lagerbox-Mietende benachrichtigen)"
              horizontalAlignment="left">
            </dxo-label>
            <dxi-validation-rule type="required"
                                 message="Eine SMS-fähige Telefon-Nr. ist erforderlich für die Authentizierung und für die Kommunikation während der Abwicklung."></dxi-validation-rule>
            <dxi-validation-rule type="custom"
                                 [validationCallback]="getIsValidPhoneNumber"
                                 [reevaluate]="true"
                                 message="Ungültiges Format oder unerlaubte Zeichen in der Telefonnummer. Beispiele: 0177-99999999, +49.177.99999999 oder ähnlich.">
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item [editorOptions]="{disabled: this.isDisableEditingData,
                inputAttr: { autocomplete: 'on'},
                name: 'email',
                placeholder: 'E-Mail der Lagerbox-Mietenden für Benachrichtigung.',
            hint: 'Optional, dringend empfohlen. Erlaubt die Übermittlung ausführlicherer Informationen ergänzend zur SMS-Benachrichtigung.'}"
                    dataField="gervm_extern_email"
          >
            <dxo-label
              text="E-Mail-Adresse (Lagerbox-Mietende benachrichtigen)"
              horizontalAlignment="left">
            </dxo-label>
            <dxi-validation-rule type="required"
                                 message="Eine gültige E-Mail-Adresse ist erforderlich für die Kommunikation während der Abwicklung."></dxi-validation-rule>
            <dxi-validation-rule type="email"
                                 [reevaluate]="true"
                                 message="Ungültige E-Mail-Adresse.">
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="gervm_bemerk_text"
                    editorType="dxTextArea"
                    [editorOptions]="{width: '100%',
                placeholder: 'Hier z.B. die Inseladresse eintragen, wenn sie nicht in der Auswahlliste (Feld: -Insel-Adresse-) zu finden sein sollte.',
                    disabled: false }"
                    [colSpan]="2">
            <dxo-label
              text="Bemerkung"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item itemType="group">
            <a href="https://www.inselspedition.de/lagerbox-service" target="_blank">Zu unseren
              Lagerbox-Service-Bedingungen
              ...</a>
          </dxi-item>

          <dxi-item
            dataField="gervm_extern_transpbed_bestaetigt_yn"
            editorType="dxCheckBox"
            [editorOptions]="{
                    text: 'Ich stimme den Bedingungen für den Lagerbox-Service zu.',
                    valueExpr: this.storageBoxOrder.gervm_extern_transpbed_bestaetigt_yn === 1,
                    disabled: this.isDisableEditingData
                }">
            <dxo-label
              [visible]="false">
            </dxo-label>
            <dxi-validation-rule
              type="compare"
              [comparisonTarget]="checkComparison"
              message="Ihre Zustimmung zu unseren Lagerbox-Service-Bedingungen ist erforderlich, damit wir Ihren Auftrag bearbeiten können.">
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item itemType="group" [colSpan]="1">&nbsp;

            <dxi-item #buttonCheckData
              itemType="button"
              horizontalAlignment="left"
              [buttonOptions]=buttonCheckDataAndGetApprovalCode>
            </dxi-item>

            <dxi-item
              [editorOptions]="{disabled: false,
               showClearButton: true,
               showSpinButtons:false,
               step: 0,
               hint: 'Mobile TAN, siehe SMS-Eingang Ihres Mobiltelefons'}"
              dataField="gervm_extern_mobiletan"
              editorType="dxNumberBox">
              <dxo-label
                text="Mobile TAN"
                horizontalAlignment="left">
              </dxo-label>
            </dxi-item>

            <dxi-item
              [editorOptions]="{items: paymentTypeDddw,
               layout: 'horizontal',
               valueExpr: 'zktoart_id',
               displayExpr: 'zktoart_name',
               onValueChanged: paymentTypeChanged }"
              dataField="gervm_zktoart_id"
              editorType="dxRadioGroup">
              <dxo-label
                text="Zahlungsweise"
                horizontalAlignment="left">
              </dxo-label>
            </dxi-item>

            <dxi-item #buttonGoToPayPal
              [visible]="(!this.isSubmitOrderDataAndPayPalDisabled) && isMobileTanGenerated && !this.isDisableEditingData && !this.isProcessing && this.isDataValid"
              itemType="button"
              horizontalAlignment="left"
              [buttonOptions]="buttonSubmitOrderDataAndPayPal">
            </dxi-item>

            <dxi-item
              [visible]="(!this.isSubmitOrderDataAndCashInAdvanceDisabled) && isMobileTanGenerated && !this.isDisableEditingData && !this.isProcessing && this.isDataValid"
              itemType="button"
              horizontalAlignment="left"
              [buttonOptions]="buttonSubmitOrderDataAndCashInAdvance">
            </dxi-item>
          </dxi-item>
          <dxi-item itemType="empty" [colSpan]="2">&nbsp;</dxi-item>
          <dxi-item itemType="empty" [colSpan]="2">&nbsp;<br></dxi-item>
        </dx-form>
      <!--</form>-->
      <div [hidden]="this.isPayPalButtonsDisabled" id="paypalButtons" class="sits-paypal-buttons"></div>
    </div>
  </div>
  <div class="dx-fieldset sits-print-only">&nbsp;
    <app-imprint></app-imprint>
  </div>
</div>
