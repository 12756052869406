import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Task } from '../api-dtos/task-dto';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  baseUrl = `${environment.restApiUrl}/tasks`;
  taskCache: Task[];
  lastCachingStamp: Date;
  todayNow = new Date();

  constructor(
    private http: HttpClient) {
    this.lastCachingStamp = new Date();
  }

  public async getAll(): Promise<Task[]> {
    if (this.taskCache && this.taskCache.length > 0) {
      return this.taskCache;
    } else {
      this.taskCache = await this.http
        .get<Task[]>(this.baseUrl, await TaskService.getHttpHeader())
        .toPromise();
      return this.taskCache;
    }
  }

  public async getTask(id: number): Promise<Task> {
    return await this.http.get<Task>(
      `${this.baseUrl}/${id}`,
      await TaskService.getHttpHeader()
    ).toPromise();
  }

  private static async getHttpHeader() {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }
}


