import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {ActivatedRoute, Params} from '@angular/router';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {FahrplanService} from '../../../api/api-services/fahrplan.service';
import {GepaeckBelegService} from '../../../api/api-services/gepaeck-beleg.service';
import {AdresseDddw} from '../../../api/api-dtos/adresse-dddw';
import {IFahrplan} from '../../../api/api-dtos/fahrplan';
import {GepaeckBeleg} from '../../../api/api-dtos/gepaeck-beleg';
import {formatDate, Location} from '@angular/common';
import {DxFormComponent} from 'devextreme-angular';
import {TextblockService} from '../../../api/api-services/textblock.service';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-gepaeck-auftrag-show',
  templateUrl: 'gepaeck-auftrag-show.component.html',
  styleUrls: ['./gepaeck-auftrag-show.component.scss']
})

export class GepaeckAuftragShowComponent implements OnInit {
  @ViewChild(DxFormComponent, {static: false}) gepaeckForm: DxFormComponent;
  @Input() inputAdresseDddw: AdresseDddw[];
  inputGepaeckTAN: number | undefined;
  inputGepaeckID: number | undefined;

  title = 'Gepäckauftrag ';
  public frontendGepaeckTransportBedingungenUrl: string;
  public gepaeckBanderoleUrl: string = null;
  public isAnreise: boolean | undefined = undefined;
  public isTransportTitle: string = 'Gepäcktransport';

  adresseDddwGepaeck: AdresseDddw[] = [{}];
  adresseDddwAll: AdresseDddw[] = [{}];
  fahrplans: IFahrplan[];
  colCountByScreen: Object;
  public gepaeckBeleg: GepaeckBeleg = new GepaeckBeleg();
  radioGroupItems = [
    {text: 'nach Spiekeroog', frachtortId: 2},
    {text: 'nach N´siel', frachtortId: 1}
  ];
  public gepaeckDescriptions: string[] = [];
  public payPalMeUrl: string;
  public bankVerbindungGepaeck: string;
  public isDisableEditing: boolean = true;
  public printingMode: boolean = false;
  public targetKey: string = environment.targetKey;

  buttonCancel: any = {
    text: '< zurück',
    type: 'normal',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    onClick: () => {
      this.cancel();
      return;
    },
  };

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private adresseService: AdresseService,
    private fahrplanService: FahrplanService,
    private gepaeckBelegService: GepaeckBelegService,
    private textblockService: TextblockService,
    private readonly location: Location
  ) {
    this.route.params.subscribe((params: Params) => {
      this.inputGepaeckID = params['id'];
      this.inputGepaeckTAN = params['tan'];
    });
    this.getTransportBedingungenStatus = this.getTransportBedingungenStatus.bind(this);
    this.getBezahltStatus = this.getBezahltStatus.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getFahrplanLabelText = this.getFahrplanLabelText.bind(this);
    this.textblockService.getTextBlock('GPBLG-PAYPAL-ME-URL')
      .subscribe(resText => this.payPalMeUrl = resText.txblk_plaintext);
    this.textblockService.getTextBlock('GPBLG-BANKKONTO')
      .subscribe(resText => this.bankVerbindungGepaeck = resText.txblk_plaintext);
  }

  async ngOnInit() {
    this.frontendGepaeckTransportBedingungenUrl = this.gepaeckBelegService.loadFrontendTransportBedingungenUrl();
    await this.adresseService.getGepaeckAdrDddw().then(res => {
      this.adresseDddwGepaeck = res;
    });
    if (this.authenticationService.isAuthenticated) {
      await this.adresseService.getAllDddw().then(res => {
        this.adresseDddwAll = res;
      });
    }

    this.gepaeckDescriptions = this.gepaeckBelegService.getGepaeckDescriptions();

    // Lade Gepaeckauftrag anhand uebergebener TAN oder ID
    if (this.inputGepaeckTAN && this.inputGepaeckTAN > 0) {
    }
    if (this.inputGepaeckID && this.inputGepaeckID > 0) {
    }
    this.gepaeckBelegService.getGepaeckBeleg(this.inputGepaeckID, this.inputGepaeckTAN).subscribe((res: GepaeckBeleg) => {
      if (res !== undefined && !!res) {
        if (res) {
          this.gepaeckBeleg = res;
          this.lookupFahrplans().then();
        }
        this.isAnreise = this.fahrplanService.isAnreise(this.gepaeckBeleg?.gpblg_dummy_ziel_frachtort);
        this.isTransportTitle = this.isAnreise ? this.isTransportTitle + ' (Anreise)' : this.isTransportTitle + ' (Abreise)';
        switch (this.targetKey) {
          case '10477':
            this.isTransportTitle = this.isAnreise ? 'Gepäck-Zustellung' : 'Gepäck-Abholung';
            if (!!this.gepaeckBeleg?.gpblg_shoporder_guid) {
              this.gepaeckBanderoleUrl = environment.restApiUrl + '/gepaeckbeleg/banderole?guid=' + this.gepaeckBeleg?.gpblg_shoporder_guid;
            }
            break;
          default:
            break;
        }
      }
    });
  }

  async lookupFahrplans() {
    this.fahrplans = [];
    if (!this.gepaeckBeleg) {
      return;
    }
    this.fahrplanService.getPersonenAbfahrten(
      this.gepaeckBeleg.gpblg_dummy_datum,
      this.gepaeckBeleg.gpblg_dummy_abgang_fort_id)
      .pipe(map((res: IFahrplan[]): void => {
        if (res == undefined || !res || res.length < 1) {
          return;
        }
        this.fahrplans = res;
        if (!this.fahrplans || this.fahrplans.length < 1) {
          this.gepaeckBeleg.fp_id = null;
          return;
        }
        if (this.fahrplans && this.fahrplans.length === 1 && this.fahrplans[0].fp_id) {
          if (!this.gepaeckBeleg.fp_id) {
            this.gepaeckBeleg.fp_id = this.fahrplans[0].fp_id;
          }
        } else {
          if (this.gepaeckBeleg.fp_id && this.gepaeckBeleg.fp_id > 0 && !this.fahrplans.find((e: IFahrplan):boolean=> e.fp_id === this.gepaeckBeleg.fp_id)) {
            this.gepaeckBeleg.fp_id = null;
          }
        }
      }));
  }

  getDisplayAbfahrtzeitVoll(item: any): string {
    if (!item || !item.fp_abfahrt_datzeit) {
      return '';
    }
    if (parent.window.outerWidth < 520) {
      return formatDate(item.fp_abfahrt_datzeit, 'EE dd.MM.yy HH:mm', 'de-DE');
    } else {
      return formatDate(item.fp_abfahrt_datzeit, 'EE dd.MM.yy HH:mm', 'de-DE') + ' ' + (!!item.ger_name ? item.ger_name : '');
    }
  }

  public getTransportBedingungenStatus(): boolean {
    return this.gepaeckBeleg?.gpblg_extern_transpbed_bestaetigt_yn?.valueOf() === 1;
  }

  public getBezahltStatus(): boolean {
    // bezahlt: 1 = Ja, 2 = auf Rechnung
    return (this.gepaeckBeleg?.gpblg_bezahlt_kz?.toString() === '1' || this.gepaeckBeleg?.gpblg_bezahlt_kz?.toString() === '2');
  }

  public getGepaeckIdPrefixed(): string {
    let gepaeckIdPrefixed = 'id-fehlt';
    switch (environment.targetKey) {
      case '10477':
        if (this.gepaeckBeleg?.gpblg_external_order_id) {
          gepaeckIdPrefixed = this.gepaeckBeleg.gpblg_external_order_id;
          break;
        }
        if (this.gepaeckBeleg?.gpblg_id) {
          gepaeckIdPrefixed = 'GEP-' + this.gepaeckBeleg.gpblg_id.toString();
        }
        break;
      default:
        if (this.gepaeckBeleg?.gpblg_id) {
          gepaeckIdPrefixed = 'GEP-' + this.gepaeckBeleg.gpblg_id.toString();
        }
        break;
    }
    return gepaeckIdPrefixed;
  }

  public getFahrplanLabelText(): string {
    console.log('getFahrplanLabelText');
    switch (this.gepaeckBeleg.gpblg_dummy_fp_art_kz) {
      case 'FLUG':
        return 'Flug (geplanter Abflug)';
      default:
        return 'Fähre (geplante Abfahrt)';
    }
  };


  public goBack(): boolean {
    this.location.back();
    return false;
  }

  public cancel(): void {
    this.location.back();
  }

  public isAuthenticated(): boolean {
    return this.authenticationService
      ? (this.authenticationService.isAuthenticated
        && (this.authenticationService.currentUser.isEmployee === 1
          || this.authenticationService.currentUser.isCustomer === 1))
      : false;
  }

  // protected readonly toString = toString;
  // protected readonly undefined = undefined;
}
