import {Injectable} from '@angular/core';
import {UserDataService} from '../api/api-services/user-data.service';
import {NotificationService} from './notification.service';

@Injectable()
export class ConfigurationService {

    private static readonly storageKey = 'settings';
    private static readonly _systemDefault = ConfigurationService.createSystemDefault();
    private static readonly _unitDefault = ConfigurationService.createUnitDefault();

    private _settings: any;
    private _dirty = false;

    // different FontSizes can be stored here!
    public fontSizes: { [fontSizeTag: string]: number; } = {};

    constructor(
        private readonly _userService: UserDataService,
        private readonly _mapStatusMessageService: NotificationService,

    ) {
        this.refresh();
    }

    static get initialZoom(): number {
        return 16;
    }

    static get default(): any {
      return {};
    }

    public updateFontSize(tag: string, value: number) {
        this.fontSizes[tag] = value;
    }

    private refresh(): void {
        this._settings = JSON.parse(localStorage.getItem(ConfigurationService.storageKey) || '{}');

        this.initActivityViewerSettings();
        for (const key of Object.keys(ConfigurationService._unitDefault)) {
            if (!this._settings[key]) {
                this._settings[key] = ConfigurationService._unitDefault[key];
            }
        }
        this._dirty = false;
    }

    // Loads and Sorts Activity Filter User Settings from Database
    private initActivityViewerSettings(): void {
    }

    private save() {
        if (!this._dirty) {
            return;
        }
        this.parameteredSave(this._settings);
    }

    private parameteredSave(settings: any, message: string = '') {
    }

    private static createUnitDefault(): any {
      return {};
    }

    private static createSystemDefault(): any {
      return {};
    }
}

