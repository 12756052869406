<div class="sits-container">
  <div class="dx-card sits-card-large sits-card-center">
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">
        <dx-toolbar>
          <dxi-item widget="dxButton" location="before">
            <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
          </dxi-item>
          <dxi-item location="left" widget="dxButton" locateInMenu="never">
            <div *dxTemplate>
              <div class="toolbar-label">{{[this.componentTitle]}} {{getTarifvonDatum()}}</div>
            </div>
          </dxi-item>
          <dxi-item widget="dxButton" location="before" locateInMenu="auto">
            <dx-button id="link1" icon="info" hint="Hinweise zum Fahrplan"
                       (click)="this.popoverToggleDefault()"></dx-button>
          </dxi-item>
        </dx-toolbar>
      </div>
      <app-tarif-list-info [inputPopoverVisible] = "popoverVisible"></app-tarif-list-info>
      <dx-data-grid #grid class="tarif-selection-list"
                    id="grid-container"
                    [dataSource]="tarifPositions"
                    [allowColumnReordering]="true"
                    [allowColumnResizing]="true"
                    [wordWrapEnabled]="true"
                    keyExpr="tarpo_id"
                    (onOptionChanged)="optionChangedHandler($event)"
                    [showBorders]="true"
                    [hoverStateEnabled]="true"
                    [showColumnLines]="false"
                    [showRowLines]="true"
                    [rowAlternationEnabled]="true">
        <dxo-paging [enabled]="true" [pageSize]="getGridPageSize()"></dxo-paging>
        <dxo-pager
          [visible]="true"
          [showPageSizeSelector]="true"
          [allowedPageSizes]="[7,8,9,10,12,15,20,100]"></dxo-pager>
        <dxo-remote-operations
          [filtering]="true"
          [paging]="true"
          [sorting]="true"
          [summary]="false"
          [grouping]="true"
          [groupPaging]="true">
        </dxo-remote-operations>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-selection selectAllMode="allPages" mode="multiple" showCheckBoxesMode="none"></dxo-selection>
        <dxo-scrolling mode="standard" [useNative]="true"></dxo-scrolling>
        <dxo-load-panel
          [enabled]="true">
        </dxo-load-panel>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <!--    <dxo-export
              [enabled]="isInternalUser()"
              [allowExportSelectedData]="false"
              fileName="Tarifdaten-{{getTarifvonDatum()}}">
            </dxo-export>-->
        <dxi-column dataField="tarpo_id"
                    [visible]="false"
                    caption="Nr."
                    width="auto"
        ></dxi-column>

        <dxi-column dataField="art_nr"
                    caption="Frachtgut"
                    width="auto"
                    [visible]="false"
                    [sortIndex]="2"
                    sortOrder="asc"
        ></dxi-column>
        <dxi-column dataField="tarpo_intervall_menge"
                    caption="Interval"
                    width="auto"
                    [visible]="false"
                    [sortIndex]="2"
                    sortOrder="asc"
        ></dxi-column>
        <dxi-column caption="Berechnung"
                    width="110"
                    [sortIndex]="3"
                    sortOrder="desc"
                    [calculateCellValue]="calculateCellTarifArt"
        ></dxi-column>
        <dxi-column dataField="tarpo_von_menge"
                    dataType="number"
                    caption="ab kg" width="auto"
                    [sortIndex]="1"
                    sortOrder="asc">
          <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
        </dxi-column>
        <dxi-column dataField="tarpo_bis_menge"
                    dataType="number"
                    caption="bis kg" width="auto">
          <dxo-format type="fixedPoint" [precision]="1"></dxo-format>
        </dxi-column>
        <dxi-column dataField="tarpo_fracht1_preis"
                    dataType="number"
                    caption="Umschlag €" width="auto">
          <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
        </dxi-column>
        <dxi-column dataField="tarpo_fracht2_preis"
                    dataType="number"
                    caption="Rollgeld €" width="auto">
          <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
        </dxi-column>
        <dxi-column dataField="tarpo_fracht3_preis"
                    dataType="number"
                    caption="Seefracht €" width="auto">
          <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
        </dxi-column>
      </dx-data-grid>
      <br/>
    </div>
  </div>
</div>
