<div class="sits-container">
  <div class="dx-card sits-card-large sits-card-center">
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">
        <dx-toolbar>
          <dxi-item widget="dxButton" location="before">
            <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
          </dxi-item>
          <dxi-item location="left" widget="dxButton" locateInMenu="never">
            <div *dxTemplate>
              <div class="toolbar-label">{{[this.componentTitle]}}</div>
            </div>
          </dxi-item>
        </dx-toolbar>
      </div>
      <dx-toolbar class="py-2 px-1">
        <dxi-item widget="dxButton" location="before" locateInMenu="auto">
          <dx-button icon="refresh" (click)="grid.instance.clearSelection(); refreshList()"></dx-button>
        </dxi-item>

      </dx-toolbar>
      <dx-data-grid #grid
                    id="grid-container"
                    [dataSource]="ops"
                    [allowColumnReordering]="true"
                    [allowColumnResizing]="true"
                    [hoverStateEnabled]="true"
                    [showBorders]="true"
                    [showColumnLines]="false"
                    [showRowLines]="true"
                    [rowAlternationEnabled]="true"
                    (onExporting)="onExporting($event)">
        <dxo-export [enabled]="false"></dxo-export>
        <dxo-paging [enabled]="true" [pageSize]="gridPageSize"></dxo-paging>
        <dxo-pager
          [visible]="true"
          [showPageSizeSelector]="true"
          [allowedPageSizes]="[7,8,9,10,12,15,20,100]"></dxo-pager>
        <dxo-remote-operations
          [filtering]="true"
          [paging]="true"
          [sorting]="true"
          [summary]="false"
          [grouping]="true"
          [groupPaging]="true">
        </dxo-remote-operations>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-selection selectAllMode="allPages" mode="multiple" showCheckBoxesMode="none"></dxo-selection>
        <dxo-scrolling mode="standard" [useNative]="true"></dxo-scrolling>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-column-fixing [enabled]="false"></dxo-column-fixing>
        <dxi-column dataField="op_beleg_datum"
                    caption="Datum"
                    width="auto"
                    dataType="date"
                    format=" dd.MM.yy "
                    [sortIndex]="1"
                    sortOrder="desc"
                    [fixed]="false" fixedPosition="left"
        ></dxi-column>
        <dxi-column dataField="op_belegart_code"
                    visible="true"
                    width="47px"
                    caption="Art">
        </dxi-column>

        <dxi-column dataField="op_beleg_nr"
                    visible="true"
                    caption="Beleg-Nr."
                    width="60px"
        ></dxi-column>
        <dxi-column dataField="op_erledigt_yn"
                    visible="true"
                    caption="Bezahlt"
                    width="60px"
                    alignment="center"
                    cellTemplate="erledigtTemplate">
          <div *dxTemplate="let data of 'erledigtTemplate'">
          <span [ngClass]="{'badge-success': data.value, 'badge-yellow': !data.value}"
                class="badge"
          >{{data.value === 1 ? ' ' : 'offen'}}</span>
          </div>
        </dxi-column>
        <dxi-column [allowFiltering]="true" caption="Belegempfänger" dataField="adr_id" width="200">
          <dxo-lookup [dataSource]="getAdresses"
                      displayExpr="adr_such_name"
                      valueExpr="adr_id"
          ></dxo-lookup>
        </dxi-column>

        <dxi-column type="buttons" caption="Download">
          <dxi-button
            [onClick]="downloadInvoiceDocument"
            name="downloadInvoiceDocument"
            hint="Beleg downloaden"
            icon="assets/iconexperience/iconex_o2/o_collection_svg/dark_grey/download.svg"
            text="Download">
          </dxi-button>
          <dxi-button
            [onClick]="showInvoiceDocument"
            name="showInvoiceDocument"
            hint="Beleg direkt anzeigen"
            icon="assets/iconexperience/iconex_o2/o_collection_svg/dark_grey/eye.svg"
            cssClass="downloadInvoice"
            text="Anzeigen">
            <dxo-hover-style>
              <dxo-hatching direction="left"></dxo-hatching>
            </dxo-hover-style>
          </dxi-button>
        </dxi-column>
        <dxi-column dataField="op_storno_kz"
                    [visible]="true"
                    caption="Storniert"
                    width="auto"
                    alignment="center"
                    cellTemplate="storniertTemplate">
          <div *dxTemplate="let data of 'storniertTemplate'">
          <span *ngIf="data.value === 1" [ngClass]="{'badge-warning': data.value, 'badge-secondary': !data.value}"
                class="badge badge-pill"
          >{{data.value === 1 ? 'storniert' : 'Nein'}}</span>
          </div>
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>
</div>
