<div class="dx-card sits-card">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header">
      <dx-toolbar>
        <dxi-item widget="dxButton" location="before">
          <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
        </dxi-item>
        <dxi-item location="left" widget="dxButton" locateInMenu="never">
          <div *dxTemplate>
            <div class="toolbar-label">{{ [this.componentTitle] }} {{getGeraeteVermietungIdPrefixed()}}</div>
          </div>
        </dxi-item>
      </dx-toolbar>
    </div>
    <div class="dx-field">
      <div class="card-text">
        Informationen zu Ihrem Auftrag.
        <span *ngIf="storageBoxOrder?.gervm_bezahlt_kz?.toString() === '0'"><br>Bitte den Betrag vor Leistungsbeginn bezahlen.</span><br>
      </div>
      <div class="clr-col">
        <dx-form #frachtFormShow [(formData)]="storageBoxOrder"
                 labelLocation="top"
                 [scrollingEnabled]="true"
                 [showValidationSummary]="true"
                 colCount="2"
                 [minColWidth]="500"
                 width="auto"
                 [showColonAfterLabel]="false">
          <dxi-item itemType="group">
            <div id="barcodecontainer" >
              <ngx-barcode6
                [bc-format]="'CODE128'"
                [bc-value]=getGeraeteVermietungIdPrefixed()
                [bc-display-value]="true"
              ></ngx-barcode6>
            </div>
          </dxi-item>

          <dxi-item dataField="gervm_beginn_datzeit"
                    type="date"
                    editorType="dxDateBox"
                    [editorOptions]="{
                    width: '100%',
                    type: 'date',
                    readOnly: true,
                    useMaskBehavior: 'true',
                    displayFormat: 'EE. dd.MM.yyyy',
                    hint: 'Datum an dem ...',
                    dateSerializationFormat: 'yyyy-MM-dd'}">
            <dxo-label
              text="Beginn-Datum"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gervm_ende_datzeit"
                    type="date"
                    editorType="dxDateBox"
                    [editorOptions]="{
                    width: '100%',
                    type: 'date',
                    readOnly: true,
                    useMaskBehavior: 'true',
                    displayFormat: 'EE. dd.MM.yyyy',
                    hint: 'Datum an dem ...',
                    dateSerializationFormat: 'yyyy-MM-dd'}">
            <dxo-label
              text="Ende-Datum"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gervm_ladestelle_adr_gepaeck_name"
                    [stylingMode]=""
                    editorType="dxTextBox"
                    [editorOptions]="{readOnly: true }">
            <dxo-label
              text="Insel-Adresse (Straße HausNr. / Hotel)"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gervm_ger_name"
                    [stylingMode]=""
                    editorType="dxTextBox"
                    [editorOptions]="{readOnly: true }">
            <dxo-label
              text="Lagerbox"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item
            dataField="gervm_brutto_amount"
            editorType="dxNumberBox"
            [editorOptions]="{format: ' ##0.00 EUR',
            readOnly: true,
            hint: 'Kosten für den Lagerbox-Service.' }">
            <dxo-label
              text="Gesamtbetrag (inkl. MwSt.)">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gervm_extern_name"
                    editorType="dxTextBox"
                    [editorOptions]="{readOnly: true }">
            <dxo-label
              text="Mein Name"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gervm_extern_mobil_tel"
                    editorType="dxTextBox"
                    [editorOptions]="{readOnly: true }">
            <dxo-label
              text="Meine Mobiltelefon-Nr."
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item [editorOptions]="{
                  readOnly: true,
                  hint: 'Optional, dringend empfohlen. Erlaubt die Übermittlung ausführlicherer Informationen ergänzend zur SMS-Benachrichtigung.'}"
                    dataField="gervm_extern_email"
                    editorType="dxTextBox">
            <dxo-label
              text="Meine E-Mail-Adresse"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item dataField="gervm_bemerk_text"
                    editorType="dxTextArea"
                    [editorOptions]="{width: '100%', height: 'auto',
                  readOnly: true}"
          >
            <dxo-label
              text="Bemerkung"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>
          <dxi-item itemType="group">
            <span>Bedingungen für den Lagerbox-Service &nbsp;</span>
            <span
              [ngClass]="{'badge-success': getTransportBedingungenStatus(), 'badge-danger': !getTransportBedingungenStatus()}"
              class="badge badge-pill"
            >{{getTransportBedingungenStatus() ? 'zugestimmt' : 'NICHT zugestimmt'}}</span><br>
            <span class="sits-kachel-text-sm"><a href="https://www.inselspedition.de/lagerbox-service" target="_blank">Zu unseren Geschäftsbedingungen für den Lagerbox-Service
              ...</a></span>
          </dxi-item>

          <dxi-item itemType="empty" [visible]="!isAuthenticated()">
          </dxi-item>
          <dxi-item editorType="dxSelectBox"
                    [visible]="isAuthenticated()"
                    [editorOptions]="{items: adresseDddwAll,
                searchEnabled: 'true',
                  valueExpr: 'adr_id',
                  displayExpr: 'adr_such_name',
                  placeholder: 'min. 3 Zeichen ...',
                  readOnly: true}"
                    dataField="gervm_rgempf_adr_id">
            <dxo-label
              text="Beauftragende, Kd.Nr. {{storageBoxOrder.gervm_rgempf_adr_id}}"
              horizontalAlignment="left">
            </dxo-label>
          </dxi-item>

          <dxi-item itemType="group">
            <span>Lagerbox-Service bezahlt? &nbsp;</span>
            <span [ngClass]="{'badge-success': getBezahltStatus(), 'badge-danger': !getBezahltStatus()}"
                  class="badge badge-pill"
            >{{ storageBoxOrder.gervm_bezahlt_kz === undefined || storageBoxOrder.gervm_bezahlt_kz === null
              ? '?'
              : storageBoxOrder.gervm_bezahlt_kz .toString() === '0'
              ?  'Noch nicht bzw. Betrag ist noch nicht angekommen / gebucht. Zahlart: ' + getZahlArtName()
              : storageBoxOrder.gervm_bezahlt_kz.toString() === '1'
              ? 'Ja, ist bezahlt per: ' + getZahlArtName()
              : storageBoxOrder.gervm_bezahlt_kz.toString() === '2'
                ? 'Bezahlung per Rechnung'
                : '?'}}</span><br>
            <span *ngIf="this.storageBoxOrder?.gervm_bezahlt_kz && this.storageBoxOrder.gervm_bezahlt_kz === '0'" class="sits-kachel-text-sm">
              <br>Um den Auftrag zu aktivieren, überweisen Sie bitte <b>{{this.storageBoxOrder.gervm_brutto_amount | currency:'EUR'}}</b><br>
              (bei kurzfristiger Buchung bitte möglichst als Sofortüberweisung)<br>
              auf unser Konto <b>{{this.bankVerbindungStorageBox}}</b>,<br>
              oder zahlen Sie einfach per
              <b><a href="{{this.payPalMeUrl}}/{{this.storageBoxOrder.gervm_brutto_amount}}"
                    target="_blank">PayPal {{this.payPalMeUrl}}/{{this.storageBoxOrder.gervm_brutto_amount}}EUR</a></b><br>
              Verwendungszweck: <b>GEP-{{this.storageBoxOrder.gervm_id}}
              TAN-{{this.storageBoxOrder.gervm_extern_mobiletan}}</b></span>
            <span *ngIf="storageBoxOrder.gervm_bezahlt_kz === '1'" class="sits-kachel-text-sm">
              Konto {{this.bankVerbindungStorageBox}}
              <span *ngIf="this.payPalMeUrl?.length > 0">
              <br>oder {{this.payPalMeUrl}}</span>
            </span>
          </dxi-item>
          <dxi-item itemType="group">&nbsp;
            <app-imprint></app-imprint>
          </dxi-item>
          <dxi-item itemType="empty" [colSpan]="2">&nbsp;</dxi-item>
          <!--<dxi-item class="sits-print-hide"
                    [width]="120"
                    [colSpan]="2"
                    itemType="button"
                    horizontalAlignment="left"
                    [buttonOptions]="buttonCancel">
          </dxi-item>-->
          <dxi-item itemType="empty" [colSpan]="2">&nbsp;</dxi-item>
          <dxi-item itemType="empty" [colSpan]="2">&nbsp;<br></dxi-item>
        </dx-form>
      </div>
    </div>
  </div>
</div>



