import {Injectable} from '@angular/core';
import {paymentTypesEnum} from './paymenttypes';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PaymentService {
  paymentType: paymentTypesEnum = paymentTypesEnum.none;

  constructor() { }

  protected static getHttpHeader() {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders()
        .append('API-Key',environment.sitsApiKey)
        .append('Content-Type','application/json')
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

}

