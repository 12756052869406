import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {FrachtOrtService} from '../../../api/api-services/fracht-ort.service';

@Component({
  selector: 'app-gepaeck-info',
  templateUrl: !!environment.targetKey
    ? './gepaeck-info-' + environment.targetKey + '.component.html'
    : './gepaeck-info.component.html',
  styleUrls: ['./gepaeck-info.component.scss']
})
export class GepaeckInfoComponent implements OnInit, OnChanges {
  @Input() public inputPopoverVisible: boolean | false;
  @Input() public inputTypeOfTransportName: string | undefined;  // Flug / Fähre
  public titleText: string = 'So kriegen wir das hin ...';

  public frachtOrtName1: string = '';
  public frachtOrtName2: string = '';

  constructor(
    private frachtOrtService: FrachtOrtService
  ) {

    switch (environment.targetKey) {
      case '10477':
        this.titleText = 'So geht das ...';
    }

    if (this.inputTypeOfTransportName == undefined || !(!!this.inputTypeOfTransportName)) {
      this.inputTypeOfTransportName = 'FAEHRE';
    }
    this.frachtOrtName1 = '?';
  }

  ngOnInit(): void {
    return;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!environment.production) console.log('ngOnChanges', changes);
    this.frachtOrtService.getAll().toPromise().then(resOrts => {
      if (!environment.production) console.log('GEP-Info resOrts', resOrts);
      let index = resOrts.findIndex(e =>
        e.fort_tags.toUpperCase().includes((this.inputTypeOfTransportName === 'FAEHRE' ? 'SCHIFF' : 'FLGZG'))
        && e.fort_tags.toUpperCase().includes('INSEL'));
      if (index >= 0) {
        this.frachtOrtName1 = resOrts[index].fort_name;
      } else {
        this.frachtOrtName1 = '!';
      }
      return;
    });
  }

}
