import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Verpackung} from '../api-dtos/verpackung';
import {Ladungstraeger} from '../api-dtos/ladungstraeger';

@Injectable({
  providedIn: 'root'
})
export class VerpackungService {
  baseUrl = `${environment.restApiUrl}`;

  constructor(
    private http: HttpClient) { }

  public async getAll(): Promise<Verpackung[]> {
    return await this.http.get<Verpackung[]>(
      `${this.baseUrl}/verpackung`,
      await VerpackungService.getHttpHeader()
    ).toPromise();
  }

  public async getAllLadungstraeger(): Promise<Ladungstraeger[]> {
    return await this.http.get<Ladungstraeger[]>(
      `${this.baseUrl}/ladungstraeger`,
      await VerpackungService.getHttpHeader()
    ).toPromise();
  }

  private static async getHttpHeader() {
    const accessToken: string = await localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return httpOptions;
  }

}
