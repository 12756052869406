import { Component } from '@angular/core';
import {User} from '../api/api-dtos/user';
import {AuthenticationService} from '../shared-services/authentication.service';
import {environment} from '../../environments/environment';
import {GepaeckProcessStatus} from '../api/api-services/gepaeck-process-status';
import {TarifService} from '../api/api-services/tarif.service';

@Component({
  templateUrl:   !!environment.targetKey
    ? 'home-' + environment.targetKey + '.component.html'
    : 'home.component.html',
  styleUrls: ['home.component.css']
})

export class HomeComponent {
  public currentUser: User;
  today: Date = new Date;
  tomorrow:Date =  new Date(this.today.setDate(this.today.getDate() + 1));
  existsTarifScopeNext: boolean = true;
  public readonly environment = environment;

  constructor(private tarifService: TarifService,
              private authenticationService: AuthenticationService,
              ) {
    if ( !!this.authenticationService?.currentUser && !!this.authenticationService?.sessionKey) {
      this.currentUser = this.authenticationService.currentUser;
    } /*else {
      this.router.navigate(['login']);
    }*/
    this.isProductionEnvironment = this.isProductionEnvironment.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);

    this.tarifService.getAllByTimeScope( this.tarifService.timeScopeNext).subscribe(res =>{
      this.existsTarifScopeNext = (res !== undefined && res?.length > 0);
    });
  }

  public isAuthenticated(): boolean {
    if (this.authenticationService === undefined || this.authenticationService.authenticated === undefined) {
      return false;
    }
    return this.authenticationService.authenticated.value;
  }

  public isProductionEnvironment(): boolean {
    return environment.production ? environment.production : false;
  }
  public getQueryParams(context: string): any {
    switch (context) {
      case 'GEP-abholen':
        return {processingMode: GepaeckProcessStatus.abholen};
      case 'GEP-zustellen':
        return {processingMode: GepaeckProcessStatus.zustellen};
      default:
        return {processingMode: 0};
    }
  }

}
