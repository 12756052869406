import {Component, OnInit} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {environment} from '../../../environments/environment';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';

@Component({
  selector: 'app-navi',
  providers: [Location, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  templateUrl: './navi.component.html',
  styleUrls: [ !!environment.targetKey
    ? './navi-' + environment.targetKey + '.component.scss'
    : './navi.component.scss']
})

export class NaviComponent implements OnInit {
  location: Location;
  version: string;
  imprintUrl: string;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authenticationService: AuthenticationService) {
    this.getLogoFilename = this.getLogoFilename.bind(this);
    this.isHideSitsLink = this.isHideSitsLink.bind(this);
  }


  public reloadClient(): void {
    // parent.document.location.reload();
    window.location.reload()
  }

  async ngOnInit() {
    this.version = environment.version;
  }

  public isAuthenticated() {
    if (this.authenticationService.sessionKey && !this.authenticationService.currentUser) {
      // this.authenticationService.checkRefreshLogin(),then();
    }
    // return this.authenticationService.authenticated.toPromise();
    return this.authenticationService.isAuthenticated;
  }

  public getLogoFilename():string {
    return "../../../assets/images/logo_frei.png";
  }

  public isProductionEnvironment() {
    return environment.production;
  }

  public isHideSitsLink(): boolean {
    switch (environment.targetKey) {
      case '10477':
        return true;
      default:
        return false;
    }

  }
}
