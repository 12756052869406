export class ShopOrderItem {
  id?: number;
  sortNo: number;             // Sortierung
  positionNo?: string;        // Position
  subPositionNo?: string;     // Unterposition
  itemNo?: string;            // Artikelnummer
  itemName1?: string;         // Artikelbezeichnung 1
  itemName2?: string;         // Artikelbezeichnung 1
  itemText?: string;          // Artikel Langtext
  itemTypeCode?: string;          // Artikelart 'goods
  quantity?: number;          // Menge
  quantityUnitCode?: string;  // MengenEinheit
  priceNet?: number;          // Preis netto
  priceGross?: number;        // Preis brutto
  amountNet?: number;         // Nettobetrag
  amountGross?: number;       // Bruttobetrag
  taxCode?: string;           // Steuersatz % z.B. 1
  taxPercent?: number;        // Steuersatz % z.B. 19.00
  amountTax?: number;         // Steuerbetrag
}
