import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {AdresseDddw} from '../../../api/api-dtos/adresse-dddw';
import {Subject} from 'rxjs/internal/Subject';
import {DxFormComponent} from 'devextreme-angular';
import {GeraeteVermietungStorageBox} from '../../../api/api-dtos/geraete-vermietung-storage-box';
import {AuthenticationService} from '../../../shared-services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdresseService} from '../../../api/api-services/adresse.service';
import {SmsService} from '../../../api/api-services/sms.service';
import {StorageBoxService} from '../../../api/api-services/storage-box.service';
import {ITan} from '../../../api/api-dtos/itan';
import notify from 'devextreme/ui/notify';
import {Location} from '@angular/common';
import {Geraet} from '../../../api/api-dtos/geraet';
import {GeraetService} from '../../../api/api-services/geraet.service';
import {ZahlungStatus} from '../../../api/api-services/accounting.service';
import {IArtikel} from '../../../api/api-dtos/artikel';
import {LockService} from '../../../api/api-services/lock.service';
import {Lock} from '../../../api/api-dtos/lock';
import {ArtikelService} from '../../../api/api-services/artikel.service';
import {environment} from '../../../../environments/environment';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-storagebox-order-cust-edit',
  templateUrl: './storagebox-order-cust-edit.component.html',
  styleUrls: ['./storagebox-order-cust-edit.component.scss']
})
export class StorageboxOrderCustEditComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  public maxCardWidth: string = 'auto';
  public submitButtonText: BehaviorSubject<string> = new BehaviorSubject<string>('Lagerbox buchen');
  popoverVisible: boolean = false;
  submitOrderDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  validatePhoneNumber: BehaviorSubject<boolean> = new BehaviorSubject<boolean | undefined>(false);
  isValidOrderData: boolean = false;
  isValidPhoneNumber: boolean = false;
  isValidStorageBoxId: boolean = false;
  public notValidEndeDatzeitMessage: string = 'Ungültiges Enddatum';
  public isValidEndeDatzeit: boolean = true;
  startDate: Date = new Date();
  adresseDddw: AdresseDddw[] = [{}];
  storageBoxDddw: Geraet[] = [{}];
  defaultLagerboxServiceArtikel: IArtikel;
  ladestelleAdresseDddw: AdresseDddw[] = [{}];
  colCountByScreen: Object;
  storageBoxOrder: GeraeteVermietungStorageBox = new GeraeteVermietungStorageBox();
  public isProcessing: boolean = false;
  public isLoading: boolean = true;
  public isDisableEditingBelegEmpfaenger: boolean = false;
  public isDisableEditingBruttoAmount: boolean = true;
  public isGetApprovalCodeDisabled: boolean = true;
  public isMobileTanDisabled: boolean = true;
  public isPrintPreviewMode: boolean = false;
  storageBoxDddwRefreshMilliSec: number = 700;
  gervmGerNrEditorOptions: any = {
    dataSource: this.storageBoxDddw,
    searchEnabled: true,
    valueExpr: 'ger_nr',
    displayExpr: 'ger_name',
    placeholder: 'verfügbare Box auswählen ...',
    noDataText: 'keine verfügbare Box in diesem Zeitraum',
    deferRendering: true,
    focusIn: this.storageBoxDddwFocusIn,
    disabled: this.isDisableEditingLagerBox
  };
  buttonBack: any = {
    text: 'zurück',
    icon: 'back',
    type: 'normal',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    onClick: () => {
      this.goBack();
      return;
    },
  };
  buttonPrint: any = {
    text: 'Druck-Ansicht',
    icon: 'print',
    type: 'normal',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    onClick: () => {
      this.printPreview();
      return;
    },
  };
  buttonSubmitOrderData: any = {
    text: this.getSaveButtonLabelText(),
    name: 'saveButton',
    type: 'default',
    disabled: false,
    stylingMode: 'contained',
    useSubmitBehavior: false,
    hint: 'Prüft den Lagerbox-Auftrag und sendet ihn an das Buchungssystem.',
    onClick: (): void => {
      this.onSubmitOrderData().then();
    },
  };
  title: string = 'Lagerbox-Auftrag';
  @ViewChild(DxFormComponent, {static: false}) storageboxForm: DxFormComponent;
  @Input() inputGepaeckAdresseDddw: AdresseDddw[];
  @Input() inputAdresseDddw: AdresseDddw[];
  inputStorageBoxOrderID: number | undefined;
  inputStorageBoxOrderTAN: number | undefined;
  inputStorageBoxOrderGerNr: number | undefined;
  inputStorageBoxOrderBeginnDatzeit: Date | undefined;
  inputStorageBoxOrderEndeDatzeit: Date | undefined;
  private interval: any;
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private adresseService: AdresseService,
    private artikelService: ArtikelService,
    private geraetService: GeraetService,
    private smsService: SmsService,
    private storageBoxService: StorageBoxService,
    private lockService: LockService,
    private readonly location: Location) {

    this.reloadStorageBoxDddw = this.reloadStorageBoxDddw.bind(this);
    this.form_fieldDataChanged = this.form_fieldDataChanged.bind(this);
    this.getIsValidGervmGerNr = this.getIsValidGervmGerNr.bind(this);
    this.getDisableEditingLagerBox = this.getDisableEditingLagerBox.bind(this);
    this.getDisplayStorageBoxName = this.getDisplayStorageBoxName.bind(this);
    this.getIsValidEndeDatzeit = this.getIsValidEndeDatzeit.bind(this);
    this.getIsValidOrderData = this.getIsValidOrderData.bind(this);
    this.getIsValidPhoneNumber = this.getIsValidPhoneNumber.bind(this);
    this.getMaxCardWidth = this.getMaxCardWidth.bind(this);
    this.getSaveButtonLabelText = this.getSaveButtonLabelText.bind(this);
    this.getTan = this.getTan.bind(this);
    this.printPreview = this.printPreview.bind(this);
    this.storageBoxDddwFocusIn = this.storageBoxDddwFocusIn.bind(this);

    this.isLoading = true;

    this.geraetService.getStorageStorageBoxDddw(false).then(resDddw => {
      this.storageBoxDddw = resDddw;
    });
    this.defaultLagerboxServiceArtikel = new class implements IArtikel {
    };
  }

  private _isDisableEditing: boolean = false;

  get isDisableEditing(): boolean {
    return this._isDisableEditing;
  }

  set isDisableEditing(value: boolean) {
    this._isDisableEditing = value;
  }

  private _isDisableEditingLagerBox: boolean = true;

  get isDisableEditingLagerBox(): boolean {
    return this._isDisableEditingLagerBox;
  }

  set isDisableEditingLagerBox(value: boolean) {
    this._isDisableEditingLagerBox = value;
    this.gervmGerNrEditorOptions.disabled = value;
    setTimeout(() => {
      this.storageboxForm.instance.getEditor('gervm_ger_nr').option('disabled', value);
    }, 1000);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.storageboxForm.instance.getEditor('gervm_ger_nr').option('dataSource', this.storageBoxDddw);
    }, 1000);
  }

  async ngOnInit() {
    this.isDisableEditingBelegEmpfaenger = this.isDisableEditing || !this.authenticationService.isEmployee;
    this.isDisableEditingBruttoAmount = this.isDisableEditing || !this.authenticationService.isEmployee;

    this.route.params.subscribe(params => {
      if (!!params['gervm_ger_nr'] && !isNaN(params['gervm_ger_nr'])) {
        this.inputStorageBoxOrderGerNr = Number(params['gervm_ger_nr']);
      }
      this.inputStorageBoxOrderBeginnDatzeit = new Date(params['gervm_beginn_datzeit']);
      this.inputStorageBoxOrderEndeDatzeit = new Date(params['gervm_ende_datzeit']);

      if (!!params['id'] && !isNaN(params['id'])) {
        this.inputStorageBoxOrderID = Number(params['id']);
      }
      if (this.inputStorageBoxOrderID === undefined || this.inputStorageBoxOrderID < 1) {
        this.inputStorageBoxOrderID = null;
        if (this.inputStorageBoxOrderGerNr !== undefined && this.inputStorageBoxOrderGerNr > 1) {
          this.storageBoxOrder.gervm_ger_nr = this.inputStorageBoxOrderGerNr.valueOf();
        } else {
          this.storageBoxOrder.gervm_ger_nr = null;
        }

        let storageBoxLockData: Lock = this.storageBoxService.getStorageBoxLockData(0);
        storageBoxLockData.lock_tag = '*';
        this.lockService.deleteReservation(storageBoxLockData).then((): void => {
          if (this.storageBoxOrder.gervm_ger_nr !== null) {
            storageBoxLockData = this.storageBoxService.getStorageBoxLockData(this.storageBoxOrder.gervm_ger_nr);
            this.lockService.createReservation(storageBoxLockData);
          }
        });
      } else {
        let storageBoxLockData: Lock = this.storageBoxService.getStorageBoxLockData(0);
        storageBoxLockData.lock_tag = '*';
      }

      this.initOrder().then((): void => {
        this.isLoading = false;
        this.getDisableEditingLagerBox();
      });
    });

    if (this.maxCardWidth === 'auto') {
      this.doResize();
    }
    window.onresize = (): void => {
      this.doResize();
    };
    await this.initOrder();
  }

  doResize(): void {
    if (window.innerWidth < 300) {
      this.maxCardWidth = '300px';
    } else if (window.innerWidth <= 930) {
      this.maxCardWidth = (window.innerWidth - 40).toString() + 'px';
    } else {
      this.maxCardWidth = '930px';
    }
  }

  ngOnChanges(): void {
    this.isLoading = true;
    this.storageBoxService.getStorageBoxOrder(this.inputStorageBoxOrderID, null)
      .toPromise()
      .then((resStorageBoxOrder: GeraeteVermietungStorageBox) => {
        if (resStorageBoxOrder === undefined) {
          this.isLoading = false;
          return;
        }
        this.storageBoxOrder = resStorageBoxOrder;
        this.isLoading = false;
        return;
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    // Emit something to stop all Observables
    this.unsubscribe.next();
    // Complete the notifying Observable to remove it
    this.unsubscribe.complete();
  }

  async initOrder() {
    await this.geraetService.getStorageStorageBoxDddw(true).then((resStbx: Geraet[]): void => {
      this.storageBoxDddw = resStbx;
    });

    await this.storageBoxService.getDefaultLagerboxServiceArtikel().then((resArt: IArtikel): void => {
      if (resArt.art_nr) {
        this.defaultLagerboxServiceArtikel = resArt;
      }
    });

    if (!this.authenticationService.isAuthenticated || !this.authenticationService.isCustomer) {
      await this.adresseService.getDiversePortalKundenAdrId().then((resAdrId: number): void => {
        this.storageBoxOrder.gervm_rgempf_adr_id = resAdrId;
      });
    }

    /* Regel fuer Lagerbox-Kunden:
     * bis 16:00 eines Tages fuer den Folgetag, nach 16.00 fuer den uebernaechsten Tag buchen
     * */
    this.storageBoxOrder.gervm_extern_transpbed_bestaetigt_yn = this.authenticationService.isAuthenticated && this.authenticationService.isEmployee ? 1 : 0;

    this.defaultLagerboxServiceArtikel = new class implements IArtikel {
    };

    this.storageBoxService.getDefaultLagerboxServiceArtikel().then(resArt => {
      if (resArt.art_nr) {
        this.defaultLagerboxServiceArtikel = resArt;
        if (this.storageBoxOrder.gervm_art_nr === null) {
          this.storageBoxOrder.gervm_art_nr = resArt.art_nr;
        }
        if (!environment.production) console.log('Success:', this.defaultLagerboxServiceArtikel);
        return;
      }
    })
      .catch(error => {
        if (!environment.production) console.error('Failed to load DefaultLagerboxServiceArtikel:', error);
      });

    if (this.inputStorageBoxOrderID !== undefined && this.inputStorageBoxOrderID > 0) {
      this.storageBoxService.getStorageBoxOrder(this.inputStorageBoxOrderID, null)
        .toPromise()
        .then((resStorageBoxOrder: GeraeteVermietungStorageBox): void => {
          if (resStorageBoxOrder === undefined || resStorageBoxOrder === null) {
            notify({
              message: 'Der Auftrag konnte nicht erfolgreich geladen werden. Abbruch',
              position: {
                my: 'center',
                at: 'center'
              }
            }, 'warning', 2000);
            this.isLoading = false;
            this.goBack();
            return;
          }
          this.submitButtonText.next('Lagerbox-Auftrag ändern');
          // this.buttonSubmitOrderData.text = 'Lagerbox-Auftrag speichern';
          // console.log('ngOnInit SET buttonSubmitOrderData.text', this.buttonSubmitOrderData.text);
          // damit aus dem String wieder ein Array wird ...
          this.storageBoxOrder = resStorageBoxOrder;
          this.reloadStorageBoxDddw();
          this.isDisableEditing = new Date(this.storageBoxOrder.gervm_beginn_datzeit) < this.storageBoxService.getMinimalStartDate();
          this.isDisableEditingBelegEmpfaenger = this.isDisableEditing || !this.authenticationService.isEmployee;
          this.isDisableEditingBruttoAmount = this.isDisableEditing || !this.authenticationService.isEmployee;
          this.startDate = this.isDisableEditing ? null : this.storageBoxService.getMinimalStartDate();
        }, (): void => {
          notify({
            message: 'Der Auftrag konnte nicht geladen werden. Abbruch',
            position: {
              my: 'center',
              at: 'center'
            }
          }, 'warning', 2000);
          this.isLoading = false;
          this.goBack();
          return;
        });
    }

    if (!this.inputStorageBoxOrderID) {
      this.storageBoxOrder = this.storageBoxService.getNewPreAllocatedStorageBoxOrder();
      this.storageBoxOrder.gervm_art_nr = this.defaultLagerboxServiceArtikel?.art_nr ?? null;
      this.storageBoxOrder.gervm_brutto_amount = null;

      this.startDate = this.storageBoxService.getMinimalStartDate();
      if (this.inputStorageBoxOrderBeginnDatzeit !== undefined && !!this.inputStorageBoxOrderBeginnDatzeit &&
        this.inputStorageBoxOrderBeginnDatzeit >= this.storageBoxService.getMinimalStartDate()) {
        this.storageBoxOrder.gervm_beginn_datzeit = this.inputStorageBoxOrderBeginnDatzeit;
      } else {
        this.storageBoxOrder.gervm_beginn_datzeit
          = this.authenticationService.isAuthenticated && this.authenticationService.isEmployee
          ? new Date(new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000))
          : this.storageBoxService.getMinimalStartDate();
      }

      if (this.storageBoxOrder.gervm_beginn_datzeit !== undefined && this.storageBoxOrder.gervm_beginn_datzeit !== null && this.storageBoxOrder.gervm_art_nr !== undefined ) {
        // 150 oder 160 ...;
        /*this.artikelService.getArtikelByDate(this.storageBoxOrder.gervm_art_nr, this.storageBoxOrder.gervm_beginn_datzeit).then( res => {
          if (res === undefined) {
            this.storageBoxOrder.gervm_brutto_amount = null;
          } else {
            this.storageBoxOrder.gervm_brutto_amount = res?.art_brutto_preis1 || null;
          }
        });*/
      } else {
        this.storageBoxOrder.gervm_brutto_amount = null;
      }
      this.storageBoxOrder.gervm_ende_datzeit = this.storageBoxService.calcRentalEndDate(this.storageBoxOrder.gervm_beginn_datzeit);

      if (!!this.inputStorageBoxOrderGerNr && this.inputStorageBoxOrderGerNr > 0) {
        this.storageBoxOrder.gervm_ger_nr = this.inputStorageBoxOrderGerNr.valueOf();
      }
      this.reloadStorageBoxDddw();
    }

    if (this.storageBoxOrder.gervm_art_nr === undefined || this.storageBoxOrder.gervm_art_nr == null) {
      this.storageBoxOrder.gervm_art_nr = this.storageBoxOrder.gervm_art_nr ? this.defaultLagerboxServiceArtikel.art_nr : null;
    }
    if (this.storageBoxOrder.gervm_anz === undefined || this.storageBoxOrder.gervm_anz == null) {
      this.storageBoxOrder.gervm_anz = 1;
    }

    if (this.storageBoxOrder.gervm_brutto_amount === undefined || !this.storageBoxOrder.gervm_brutto_amount) {
      this.storageBoxOrder.gervm_brutto_amount = this.defaultLagerboxServiceArtikel.art_brutto_preis1;
    }

    if (this.authenticationService.isAuthenticated && this.authenticationService.isEmployee) {
      this.startDate = new Date(new Date().setHours(0, 0, 0, 0));
      this.startDate = this.storageBoxService.addYears(this.startDate, -1);
    }
    this.getDisableEditingLagerBox();

    this.adresseDddw = [{}];
    this.adresseService.getAllDddw().then((resAdr: AdresseDddw[]): void => {
      this.adresseDddw = resAdr;
      this.adresseService.getStorageboxAdrDddw().then(resGepaeckAdr => {
        this.ladestelleAdresseDddw = resGepaeckAdr;
      });

      if (this.authenticationService.isAuthenticated && this.authenticationService.isCustomer) {
        this.storageBoxOrder.gervm_rgempf_adr_id = this.authenticationService.customerId;
      } else {
        this.adresseService.getDiversePortalKundenAdrId().then((res: number): void => {
          this.storageBoxOrder.gervm_rgempf_adr_id = res;
        });
      }

    });
    this.isLoading = false;
    // console.log("initOrder END this.storageBoxOrder ", this.storageBoxOrder);

  }

  // Versorge Dropdown mit Daten ...
  getAdresses(options: any): any {
    return {
      store: this.adresseDddw,
      filter: options.data ? ['adr_id', '=', options.data.adr_id] : null
    };
  }

  form_fieldDataChanged(e: any): void {
    if (this.isLoading) {
      this.storageBoxOrder = e.component.option('formData');
      this.isLoading = false;
      return;
    }
    if (this.isDisableEditing) {
      return;
    }

    if (e.component.option('formData') === undefined || e.component.option('formData') === null) {
      return;
    }
    this.storageBoxOrder = e.component.option('formData');

    switch (e.dataField) {
      case 'gervm_ger_nr':
        // alte Reservierungen canceln und dann diese Lagerbox reservieren ...
        let storageBoxLockData: Lock = this.storageBoxService.getStorageBoxLockData(this.storageBoxOrder.gervm_ger_nr);
        storageBoxLockData.lock_tag = '*';
        this.lockService.deleteReservation(storageBoxLockData).then((): void => {
          if (e.value !== undefined && !!e.value) {
            storageBoxLockData = this.storageBoxService.getStorageBoxLockData(e.value);
            this.lockService.createReservation(storageBoxLockData).then();
          }
        });
        break;
      case 'gervm_beginn_datzeit':
        if (this.getDisableEditingLagerBox()) {
          break;
        }

        if (e?.value === undefined || e.value === null) {
          this.storageBoxOrder.gervm_ende_datzeit = null;
          if (!this.storageBoxOrder?.gervm_bezahlt_provider_name && (!this.storageBoxOrder?.gervm_bezahlt_kz || (this.storageBoxOrder?.gervm_bezahlt_kz ?? '0') !== '1'))
          {
            this.storageBoxOrder.gervm_brutto_amount = null;
          }
          this.getDisableEditingLagerBox();
          break;
        }
        let tempBeginnDatzeit: Date = new Date(e.value);
        this.storageBoxOrder.gervm_ende_datzeit = this.storageBoxService.calcRentalEndDate(tempBeginnDatzeit);
        // 150 oder 160 ...;
        if (!environment.production) console.log('form_fieldDataChanged 474', tempBeginnDatzeit, this.storageBoxOrder);
        this.artikelService.getArtikelByDate(this.storageBoxOrder?.gervm_art_nr, tempBeginnDatzeit).then( res => {
          if (res !== undefined) {
            this.storageBoxOrder.gervm_brutto_amount = res?.art_brutto_preis1 || null;
          }
        });
        this.reloadStorageBoxDddw();
        this.getDisableEditingLagerBox();
        break;

      case 'gervm_ende_datzeit':
        if (e.value === null) {
          this.getDisableEditingLagerBox();
          break;
        }
        // attacke
        this.reloadStorageBoxDddw();
        this.getDisableEditingLagerBox();
        break;

      case 'gervm_extern_mobil_tel':
        // TelefonNr. ist bei Kunden nicht zwingend erforderlich
        if (e.value === null || e.value.toString().trim() === '') {
          this.validatePhoneNumber.next(true);
          break;
        } else {
          if (e.value.substr(0, 4) === '0049') {
            this.storageBoxOrder.gervm_extern_mobil_tel = '+49' + e.value.substr(4, 99);
          }
        }
        this.smsService.callPhoneNumberValidator(e.value).then(resSmsPhoneFormat => {
          if (!!resSmsPhoneFormat && resSmsPhoneFormat?.success === true) {
            this.isValidPhoneNumber = true;
            this.storageBoxOrder.gervm_extern_mobil_tel = resSmsPhoneFormat.international_formatted;
            this.getIsValidOrderData();
          } else {
            this.isValidPhoneNumber = false;
            this.getIsValidOrderData();
          }
        });
        break;
    }
  }

  public onValueChangedGerNr(): void {
    return;
  }

  checkComparison(): boolean {
    return true;
  }

  getIsValidOrderData(): boolean {
    if (this.authenticationService.isAuthenticated && this.authenticationService.isEmployee
      && (this.storageBoxOrder.gervm_rgempf_adr_id === undefined || this.storageBoxOrder.gervm_rgempf_adr_id <= 0)) {
      this.adresseService.getDiversePortalKundenAdrId().then(res => {
        this.storageBoxOrder.gervm_rgempf_adr_id = res;
      });
    }
    this.isValidOrderData = this.storageboxForm?.instance?.validate().isValid;
    return this.storageboxForm?.instance?.validate()?.isValid;
  }

  async onSubmitOrderData(): Promise<void> {
    this.isDisableEditing = true;
    this.isProcessing = true;
    if (this.getIsValidOrderData()) {

      if (this.authenticationService.isAuthenticated && this.authenticationService.isEmployee) {
        // bei Barzahler / DiverseKunden ...
        await this.adresseService.getDiversePortalKundenAdrId().then(res => {
          if (this.storageBoxOrder.gervm_rgempf_adr_id === res) {
            if (this.storageBoxOrder.gervm_bezahlt_kz === undefined || this.storageBoxOrder.gervm_bezahlt_kz === '' || this.storageBoxOrder.gervm_bezahlt_kz !== ZahlungStatus.Offen) {
              this.storageBoxOrder.gervm_bezahlt_kz = ZahlungStatus.Offen;
            }
          } else if (this.storageBoxOrder.gervm_rgempf_adr_id !== undefined && this.storageBoxOrder.gervm_rgempf_adr_id > 0) {
            // auf Rechnung
            this.storageBoxOrder.gervm_bezahlt_kz = ZahlungStatus.Rechnung;
          }
        });
      }

      if (this.storageBoxOrder.gervm_rgempf_adr_id && this.storageBoxOrder.gervm_rgempf_adr_id > 0) {
        this.storageBoxOrder.gervm_extern_personalkey = 'LX-CUST-' + this.storageBoxOrder.gervm_rgempf_adr_id.toString();
      } else {
        this.storageBoxOrder.gervm_rgempf_adr_id = null;
        this.storageBoxOrder.gervm_extern_personalkey = 'LX-CUST-00000';
      }
      this.storageBoxOrder.gervm_extern_personalKey = this.storageBoxOrder.gervm_extern_personalkey;

      // console.log('onSubmitOrderData START isValid (2) ', this.storageBoxOrder);
      // auch für Kunden eine TAN generieren, damit für deren Kunden ein oeffentlicher Link zum GEP generiert werden kann.
      this.getTan(this.storageBoxOrder.gervm_extern_personalkey).then((resTan: ITan) => {
        this.storageBoxOrder.gervm_extern_mobiletan = resTan.tan;
        this.storageBoxOrder.gervm_tpol_id = resTan.tpol_id;
        // console.log('onSubmitOrderData: getTan returned: ', this.storageBoxOrder.gervm_extern_mobiletan);
        if (this.storageBoxOrder.gervm_extern_mobiletan && this.storageBoxOrder.gervm_extern_mobiletan.toString().trim().length === 6) {
          this.storageBoxService.create(this.storageBoxOrder).then(res => {
            this.storageBoxOrder = res;
            // console.log('onSubmitOrderData storageBoxOrder', this.storageBoxOrder);
            if (this.storageBoxOrder.gervm_id && this.storageBoxOrder.gervm_id > 0) {
              notify({
                message: 'Ihr Lagerbox-Auftrag wurde gebucht. ' +
                  'Siehe Benachrichtigung per SMS und ggf. E-Mail.',
                position: {
                  my: 'center',
                  at: 'center'
                }
              }, 'info', 4000);
              this.router.navigate(['/storagebox/order/show', this.storageBoxOrder.gervm_id, this.storageBoxOrder.gervm_extern_mobiletan]);
            }
          }, error => {
            let msg: string = 'Problem: ihr Lagerbox-Auftrag wurde noch NICHT erfolgreich angelegt. ';
            if (!!error.message) {
              msg += error.message;
            }
            msg += 'Bitte ggf. korrigieren und nochmals versuchen. ';

            notify({
              message: `${msg}`,
              position: {
                my: 'center',
                at: 'center'
              }
            }, 'error', 6000);
            this.isDisableEditing = false;
            this.isProcessing = false;
          }).catch(() => {
            notify({
              message: 'Problem: ihr Lagerbox-Auftrag konnte NICHT erfolgreich gespeichert werden. ' +
                'Bitte ggf. korrigieren und nochmals versuchen.',
              position: {
                my: 'center',
                at: 'center'
              }
            }, 'error', 6000);
            this.isDisableEditing = false;
            this.isProcessing = false;
          });
          return;
        }
      }, (): void => {
        notify({
          message: 'Erforderliche TAN konnte vom System nicht erfolgreich generiert werden. ' +
            'Evtl. nochmals speichern.',
          position: {
            my: 'center',
            at: 'center'
          }
        }, 'error', 6000);
      });
      this.isDisableEditing = false;
      this.isProcessing = false;
      return;
    }

    notify({
      message: 'Bitte die erforderlichen Daten vervollständigen und dann nochmals buchen.',
      position: {
        my: 'center',
        at: 'center'
      }
    }, 'error', 3000);

    this.isDisableEditing = false;
    this.isProcessing = false;
    return;
  }

  public getIsValidEndeDatzeit(e: any): boolean {
    switch (true) {
      case e.value === undefined || e.value === null:
        this.isValidEndeDatzeit = true;
        break;
      case !this.storageBoxOrder.gervm_beginn_datzeit:
        this.isValidEndeDatzeit = true;
        break;
      case !!this.storageBoxOrder.gervm_beginn_datzeit
      && this.storageBoxOrder.gervm_beginn_datzeit >= new Date(e.value):
        this.isValidEndeDatzeit = false;
        this.notValidEndeDatzeitMessage = 'Enddatum muss größer als das Beginndatum sein';
        break;
      case !this.authenticationService.isEmployee && !!this.storageBoxOrder.gervm_beginn_datzeit && new Date(e.value) > this.storageBoxService.calcRentalEndDate(this.storageBoxOrder.gervm_beginn_datzeit):
        this.isValidEndeDatzeit = false;
        this.notValidEndeDatzeitMessage = 'Enddatum darf die maximale Mietdauer nicht überschreiten: '
          + this.storageBoxService.calcRentalEndDate(this.storageBoxOrder.gervm_beginn_datzeit).toLocaleDateString('de-DE');
        /*+ this.storageBoxService.calcRentalEndDate(this.storageBoxOrder.gervm_beginn_datzeit).toLocaleDateString('de-DE', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric' });*/
        break;
      default:
        this.isValidEndeDatzeit = true;
        break;
    }
    return this.isValidEndeDatzeit;
  }

  public getIsValidGervmGerNr(e: any): boolean {
    if (e.value === null || e.value.toString().trim() === '') {
      this.reloadStorageBoxDddw();
      this.isValidStorageBoxId = true;
      return true;
    } else {
      let firstEntryMatchIndex: number = this.storageBoxDddw.findIndex((source: Geraet):boolean => Number(e.value) == source.ger_nr);
      this.isValidStorageBoxId = firstEntryMatchIndex >= 0;
    }
    return this.isValidStorageBoxId;
  }


  /**
   * leere oder korrekte Telefonnummer
   * @param e
   */
  public getIsValidPhoneNumber(e: any): boolean {
    if (e.value === null || e.value.toString().trim() === '') {
      this.isValidPhoneNumber = true;
      return true;
    }
    return this.isValidPhoneNumber;
  }

  public isSubmitOrderDisabled(): boolean {
    return this.submitOrderDisabled.getValue();
  }

  public goBack(): void {
    this.location.back();
  }

  public printPreview(): void {
    if (!!this.storageBoxOrder.gervm_id && this.storageBoxOrder.gervm_id > 0
      && !!this.storageBoxOrder.gervm_extern_mobiletan && this.storageBoxOrder.gervm_extern_mobiletan?.valueOf() !== null) {
      this.router.navigate(['storagebox/order/show',
        this.storageBoxOrder.gervm_id,
        this.storageBoxOrder.gervm_extern_mobiletan?.valueOf()]).then();
    } else {
      window.print();
    }
  }

  async getTan(personalKey: string): Promise<ITan> {
    /*console.log('_getTan START', this.storageBoxOrder.gervm_extern_mobiletan,
      (this.storageBoxOrder.gervm_extern_mobiletan && this.storageBoxOrder.gervm_extern_mobiletan?.toString().trim().length === 6));*/
    if (this.storageBoxOrder.gervm_extern_mobiletan && this.storageBoxOrder.gervm_extern_mobiletan?.toString().trim().length === 6) {
      // bereits vorhandene TAN wiederverwenden, keine neue generieren
      // console.log('_getTan schon da', this.storageBoxOrder.gervm_extern_mobiletan);
      return new Promise<ITan>((resolve) => {
        const tan: ITan = {
          tan: this.storageBoxOrder.gervm_extern_mobiletan,
          tpol_id: this.storageBoxOrder.gervm_tpol_id
        };
        resolve(tan);
      });
    }
    return this.storageBoxService.getNewTan(personalKey).then((resTan: ITan) => {
      // console.log('_getTan Neue TAN', resTan);
      return resTan;
    });
  }

  popoverToggleDefault(): void {
    this.popoverVisible = !this.popoverVisible;
  }

  public getSaveButtonLabelText(): string {
    // console.log('getSaveButtonLabelText', this.storageBoxOrder, this.storageBoxOrder.gervm_id);
    if (this.storageBoxOrder === undefined || this.storageBoxOrder.gervm_id === undefined || this.storageBoxOrder.gervm_id <= 0) {
      return 'Lagerbox-Auftrag buchen';
    }
    return 'Lagerbox-Auftrag ändern & benachrichtigen';
  }

  public reloadStorageBoxDddw(): void {
    if (!!this.storageBoxOrder.gervm_beginn_datzeit && !!this.storageBoxOrder.gervm_ende_datzeit) {
      let alwaysIncludeGerNrOfGervmId: number = null;
      if (!!this.storageBoxOrder.gervm_id) {
        alwaysIncludeGerNrOfGervmId = this.storageBoxOrder.gervm_id;
      }

      this.geraetService.getStorageStorageBoxDddwAvailableInRange(this.storageBoxOrder.gervm_beginn_datzeit, this.storageBoxOrder.gervm_ende_datzeit, alwaysIncludeGerNrOfGervmId)
        .pipe(tap((resStorageBox) => {
          this.storageBoxDddw = resStorageBox;
        })).subscribe();

      /*this.geraetService.getStorageStorageBoxDddwAvailableInRange(this.storageBoxOrder.gervm_beginn_datzeit, this.storageBoxOrder.gervm_ende_datzeit, alwaysIncludeGerNrOfGervmId)
        .subscribe(resStorageBox => {
            this.storageBoxDddw = resStorageBox;
          },
          error => {
            console.log('reloadStorageBoxData error', error);
            return;
          });*/
    } else {
      this.storageBoxDddw = null;
      // this.isDisableEditingLagerBox = this.isDisableEditing || false;
      this.isDisableEditingLagerBox = false;
    }
    setTimeout(() => {
      this.storageboxForm.instance.getEditor('gervm_ger_nr').option('dataSource', this.storageBoxDddw);
    }, 1000);
  }

  public getDisplayStorageBoxName(item: any | undefined): string {
    // console.log('getDisplayStorageBoxName START', item, this.storageBoxOrder);

    if (!!item?.gervm_ger_nr && !!item?.ger_name) {
      return item.ger_name;
    }

    let firstEntryMatchIndex = -1;
    let ger_nr: number = !!item?.gervm_ger_nr ? item?.gervm_ger_nr : -1;
    if (ger_nr < 1) {
      return 'nicht zugewiesen';
    }

    if (ger_nr > 0 && this.storageBoxDddw.length > 0) {
      console.log('getDisplayStorageBoxName suche ...', this.storageBoxDddw);
      firstEntryMatchIndex = this.storageBoxDddw.findIndex((e: Geraet) => ger_nr == e.ger_nr);
      if (firstEntryMatchIndex >= 0) {
        return this.storageBoxDddw[firstEntryMatchIndex].ger_name;
      }
    }
    if (this.storageBoxOrder !== undefined && !!this.storageBoxOrder?.gervm_ger_name) {
      return this.storageBoxOrder.gervm_ger_name;
    }
    return 'nicht zugewiesen ' + firstEntryMatchIndex.toString() + ', ' + ger_nr.toString() + ', ' + this.storageBoxDddw.length;
  }

  public storageBoxDddwFocusIn(e: any): any {
    console.log('storageBoxDddwFocusIn', e);
    return;
  }

  public getDisableEditingLagerBox(): boolean {
    this.isDisableEditingLagerBox = this.storageBoxService.getDisableEditingLagerBox(this.storageBoxOrder) || this.isDisableEditing;
    return this.isDisableEditingLagerBox;
  }

  getMaxCardWidth = (): string => this.maxCardWidth;
}
