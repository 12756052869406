<div id="container">
  <dx-popup
    [width]="300"
    [height]="280"
    [showTitle]="true"
    title="Zahlung"
    [dragEnabled]="false"
    [showCloseButton]="false"
    container=".dx-viewport"
    [(visible)]="popupVisible"
  >
    <dxo-loading-indicator></dxo-loading-indicator>
    Warten auf die Zahlung per PayOne (PayPal, ...).<br>
    Die Zahlung erfolgt über einen separaten Dialog, der bereits geöffnet sein müsste.<br>
    Dieser Dialog schließt sich automatisch, sobald die Zahlung erfolgt ist.<br>
    <p *ngIf="!!popupStatusText">Aktueller Status: {{popupStatusText}}</p><br>
    <dxo-position at="center" my="center" collision="fit">
    </dxo-position>

  </dx-popup>
</div>
