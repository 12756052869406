import {DataElement} from '../api-basics/data-element';

export class User implements DataElement {
  id?: number;
  userName?: string;
  displayName?: string;
  shortName?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  postbox?: string;
  password?: string;
  mail?: string;
  phone?: string;
  mobilePhone?: string;
  city?: string;
  street?: string;
  zipCode?: string;
  countryPostalCode?: string;
  department?: string;
  languageCode?: string;
  isEmployee?: number;
  employeeId?: number;
  isCustomer?: number;
  customerCompanyName?: string;
  customerId?: number;
  registration_mobiletan?: string;
  gepaeckTranspBedingungenBestaetigt_yn?: number;
  frachtTranspBedingungenBestaetigt_yn?: number;
  datenschutzBedingungenBestaetigt_yn?: number;
  isAdminUser?: number;
}
