<div class="sits-container">
  <div class="dx-card sits-card-large">
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">
        <dx-toolbar>
          <dxi-item widget="dxButton" location="before">
            <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
          </dxi-item>
          <dxi-item location="left" widget="dxButton" locateInMenu="never">
            <div *dxTemplate>
              <div class="toolbar-label">{{ [this.componentTitle] }} <span *ngIf="!isVisibleForSpecialUsers()">heute</span>
              </div>
            </div>
          </dxi-item>
          <dxi-item id="dateselection" locateInMenu="auto" location="before" widget="dxDateBox" [hidden]=!isVisibleForSpecialUsers() >
            <dx-date-box
              type="date"
              displayFormat="EE. dd.MM.yy"
              width="165px"
              useMaskBehavior=true
              showClearButton="true"
              [value]="filterByDate"
              applyValueMode="instantly"
              (onValueChanged)="onValueChangedFilterByDate($event)"
              hint="Liste auf ein bestimmtes Datum eingrenzen. Erforderlich, damit Aufträge als bearbeitet markiert werden können.">
            </dx-date-box>
          </dxi-item>
          <dxi-item widget="dxButton" location="before" locateInMenu="auto">
            <dx-button id="link1" icon="info" hint="Hinweise zum Fahrplan"
                       (click)="this.popoverToggleDefault()"></dx-button>
          </dxi-item>
        </dx-toolbar>
      </div>
      <dx-popover
        target="#link1"
        position="bottom"
        cdkScrollable
        [width]="300"
        [showTitle]="true"
        title="Hinweise zum Fahrplan ..."
        [(visible)]="popoverVisible">
        <div *dxTemplate="let data = model of 'content'">
          <dx-scroll-view width="100%" height="100%">
            Der Fahrplan basiert auf dem von der Reederei bereitgestellten Fahrplan.<br>
            Wir bemühen uns, gelegentliche Änderungen zeitnah einzupflegen.<br>
            Geänderte Abfahrtzeiten sind <span class="scheduleChanged">markiert</span>.<br>
            Ausfallende Fahrten werden meistens von der Reederei auf alternative Abfahrtzeiten geändert (sind dann auch
            markiert).<br>
            Für mit "Gepäck-Service" gekennzeichnete Fahrten bieten wir unseren <b>Gepäcktransport-Service auf der
            Insel</b> an
            <span *ngIf="environment.targetKey==='10477'"><b>, zwischen Bahnhof und Inseladresse</b></span>.<br>
            <span *ngIf="environment.targetKey==='10426'"><b>WattnExpress (WEX)</b>: Für diese Fähre bieten wir den Gepäckservice derzeit nicht an.<br>
            Siehe auch <a class="dx-icon-link" href="https://www.spiekeroog.de/fahrzeitenhinweis" target="_blank">www.spiekeroog.de/fahrzeitenhinweis</a></span><br>
            Mit <img ngSrc="../../../assets/iconexperience/iconex_o2/o_collection_svg/dark_grey/suitcase.svg"
                     height="14" width="15"
                     alt="Gepäckservice, Koffersymbol"
                     class="sits-toolbar-button-inline"> gelangen Sie direkt zum Gepäckauftrag.<br>
            Alle Angaben ohne Gewähr.
          </dx-scroll-view>
        </div>
      </dx-popover>
      <dx-toolbar>
        <dxi-item widget="dxButton" location="before" locateInMenu="auto">
          <dx-button icon="refresh" (click)="grid.instance.clearSelection(); refreshFahrplanList()"></dx-button>
        </dxi-item>
      </dx-toolbar>
      <dx-data-grid #grid
                    id="grid-container"
                    [dataSource]="fahrplans"
                    [allowColumnReordering]="true"
                    [allowColumnResizing]="true"
                    keyExpr="id"
                    (onOptionChanged)="optionChangedHandler($event)"
                    [hoverStateEnabled]="true"
                    [showBorders]="true"
                    [showColumnLines]="false"
                    [showRowLines]="true"
                    (onCellPrepared)="onCellPrepared($event)"
                    [rowAlternationEnabled]="true">
        <dxo-paging [enabled]="true" [pageSize]="gridPageSize"></dxo-paging>
        <dxo-pager
          [visible]="true"
          [showPageSizeSelector]="true"
          [showNavigationButtons]="true"
          displayMode="compact"
          [allowedPageSizes]="[7,8,9,10,12,15,20,100]"></dxo-pager>
        <dxo-remote-operations
          [filtering]="true"
          [groupPaging]="true"
          [grouping]="true"
          [paging]="true"
          [sorting]="true"
          [summary]="false">
        </dxo-remote-operations>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-selection selectAllMode="allPages" mode="multiple" showCheckBoxesMode="none"></dxo-selection>
        <dxo-scrolling mode="standard" [useNative]="true"></dxo-scrolling>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
        <dxo-editing
          mode="row"
          [allowUpdating]="false"
          [allowDeleting]="false"
          [useIcons]="true"
        ></dxo-editing>
        <dxi-column dataField="id"
                    [visible]="false"
                    caption="Nr."
                    width="auto"
        ></dxi-column>
        <dxi-column dataField="fp_faelltaus_yn"
                    [visible]="false"
                    caption="Fällt aus"
                    width="auto"
        ></dxi-column>

        <dxi-column
          caption="Gep.service"
          type="buttons" [visible]="true">
          <dxi-button
            hint="Link zur Gepäckservice-Buchung für diese Fahrt ..."
            icon="assets/iconexperience/iconex_o2/o_collection_svg/dark_grey/suitcase.svg"
            [visible]="isCreateGepaeckAuftragButtonEnabled"
            [onClick]="createGepaeckAuftrag"
          ></dxi-button>
          <dxi-button
            hint="Gepäckservice kann für diese Fahrt nicht (mehr) gebucht werden."
            icon="assets/iconexperience/iconex_o2/o_collection_svg/light_grey/suitcase.svg"
            [visible]="isCreateGepaeckAuftragButtonDisabled"
          ></dxi-button>

          <dxi-button
            hint="zur Gepäckliste navigieren"
            icon="assets/iconexperience/iconex_o2/o_collection_svg/dark_grey/list_style_numbered.svg"
            [visible]="isVisibleNavigateGepaeckAuftragCustListButton"
            [disabled]="false"
            [onClick]="cmdNavigateGepaeckAuftragCustList"
          ></dxi-button>

        </dxi-column>

        <dxi-column caption="Abfahrt"
                    width="120"
                    dataField="fp_abfahrt_datzeit"
                    cellTemplate="abfahrtzeitTemplate"
                    [sortIndex]="1"
                    sortOrder="asc"
        ></dxi-column>

        <dxi-column dataField="fp_abfahrt_fort_id" caption="von ..." width="auto">
          <dxo-lookup [dataSource]="getFrachtOrts"
                      valueExpr="fort_id"
                      displayExpr="fort_name"
          ></dxo-lookup>

        </dxi-column>
        <dxi-column
          [visible]="!isVisibleForSpecialUsers()"
          dataField="fp_ankunft_fort_id"
          caption="nach ..." width="auto">
          <dxo-lookup [dataSource]="getFrachtOrts"
                      valueExpr="fort_id"
                      displayExpr="fort_name"
          ></dxo-lookup>
        </dxi-column>
        <dxi-column dataField="fp_ger_nr" caption="Fähre" width="auto">
          <dxo-lookup [dataSource]="getFaehres"
                      valueExpr="ger_nr"
                      displayExpr="ger_name"
          ></dxo-lookup>
        </dxi-column>
        <dxi-column
          [visible]="isVisibleTransportArtForCustomer()"
          caption="Art" cellTemplate="transportArtTemplate"
          [calculateCellValue]="calcTransportArtCellValue"
          alignment="center"
          width="auto">
        </dxi-column>

        <dxi-column
          [visible]="isFrachtManager()"
          caption="Ist Menge" dataField="frako_ist_menge_sum"
          width="auto">
          <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
        </dxi-column>
        <dxi-column
          [visible]="isFrachtManager()"
          caption="Ist Anz." dataField="frako_ist_count"
          width="auto">
        </dxi-column>
        <dxi-column
          [visible]="false"
          caption="Ist Stellfläche m², beansprucht durch Fracht" dataField="frapo_ist_stellflaeche_brutto_sum"
          width="100">
          <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
        </dxi-column>
        <dxi-column
          [visible]="isFrachtManager()"
          caption="Plan Menge" dataField="frako_plan_menge_sum"
          width="auto">
          <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
        </dxi-column>
        <dxi-column
          [visible]="false"
          caption="Plan Stellfläche m², beansprucht durch Fracht" dataField="frapo_plan_stellflaeche_brutto_sum"
          width="80">
          <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
        </dxi-column>
        <dxi-column
          [visible]="isFrachtManager()"
          caption="Plan Anz." dataField="frako_plan_count"
          width="auto">
        </dxi-column>
        <dxi-column
          [visible]="isGepaeckManager()"
          caption="GEP Stücke"
          dataField="gpblg_anz_sum"
          width="auto">
          <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
        </dxi-column>

        <dxi-column
          [visible]="isGepaeckManager()"
          caption="GEP Aufträge" dataField="gptra_beleg_count"
          width="auto">
          <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
        </dxi-column>
        <dxi-column
          [visible]="false"
          caption="Stellfläche netto m²" dataField="ger_stellflaeche_netto_m2"
          width="80">
        </dxi-column>


        <div *dxTemplate="let data of 'transportArtTemplate'">
                  <span class="badge"
                        [ngClass]="{'badge-success': data.value === 'P', 'badge-secondary': data.value  === 'F', 'badge-info': data.value  === 'PF', 'badge-danger': data.value  === 'CANCEL'  }"
                  >{{ data.value === 'F' ? 'Fracht' : data.value === 'PF' ? 'Pers.&Fracht' : data.value === 'P' ? 'Personen' : data.value === 'CANCEL' ? 'fällt aus!' : '?' }}</span>
        </div>

        <div
          *dxTemplate="let data of 'abfahrtzeitTemplate'"
          [class]=calcAbfahrtzeitGeaendertStyleClass(data.data).toString()>
          {{ data.value | date: 'EE dd.MM. HH:mm' }}
        </div>

      </dx-data-grid>

      <dx-tooltip position="right">
        <div *dxTemplate="let data of 'content'">
          <div class='tooltipContent'>
            <div [hidden]="this.currentFahrPlan.fp_abfahrtzeitgeaendert_yn===0">Ursprüngliche Abfahrt ...<br>
              <b>{{ currentFahrPlan.fp_initial_abfahrt_datzeit | date: 'EE dd.MM. HH:mm' }}</b></div>
            <div [hidden]="this.currentFahrPlan.fp_faelltaus_yn===0"><span class="badge badge-danger">!</span> Fahrt
              findet
              nicht statt!<br>
              {{ currentFahrPlan.fp_bemerk_text }}
            </div>
            <div
              [hidden]="this.currentFahrPlan.svstrs_gepaeck_yn === 0 || (this.currentFahrPlan?.fp_gepaeckservice_yn ?? 1) === 1">
              <span class="badge badge-warning">!</span> Für diese Fahrt bieten wir den Gepäckservice NICHT an.
            </div>
          </div>
        </div>
      </dx-tooltip>

    </div>
  </div>
</div>
