import { DataService } from './data-service';
import { ApiService } from './api.service';
import { DataElement } from './data-element';
import { Patch } from '../api-dtos/patch';
import { Observable } from 'rxjs';

export abstract class GenericDataService<T extends DataElement> implements DataService<T> {

  protected onSuccess: (result: any) => void = console.log;
  protected onError: (message: any) => void = console.error;

  protected constructor(
    public readonly apiService: ApiService,
    protected readonly serviceUrl: string) {
  }

  public createObservable(item: T): Observable<T> {
    return this.apiService.post(this.serviceUrl, item);
  }

  public create(item: T, onSuccess: (result: T) => void = this.onSuccess, onError: (message: any) => void = this.onError): void {
    this.apiService.post(this.serviceUrl, item).subscribe(onSuccess, onError);
  }

  public update(item: T, onSuccess: (result: T) => void = this.onSuccess, onError: (message: any) => void = this.onError): void {
    this.apiService.put(this.serviceUrl, item, item.id).subscribe(onSuccess, onError);
  }

  public deleteObservable(id: number): Observable<T> {
    return this.apiService.delete(this.serviceUrl, id);
  }

  public delete(id: number, onSuccess: (result: any) => void = this.onSuccess, onError: (message: any) => void = this.onError): void {
    this.apiService.delete(this.serviceUrl, id).subscribe(onSuccess, onError);
  }

  public get(id: number, onSuccess: (result: T) => void = this.onSuccess, onError: (message: any) => void = this.onError): void {
    this.apiService.get(this.serviceUrl, id).subscribe(onSuccess, onError);
  }

  public getAll(onSuccess: (result: T[]) => void, onError: (message: any) => void = this.onError): void {
    this.apiService.get(this.serviceUrl).subscribe(onSuccess, onError);
  }

  public getFiltered(filter: string | Object | Object[], onSuccess: (result: T[]) => void, onError?: (message: any) => void): void {
    this.apiService.get(`${this.serviceUrl}?${filter}`).subscribe(onSuccess, onError);
  }

  public patch(item: Patch<T>, onSuccess: (result: any) => void = this.onSuccess, onError: (message: any) => void = this.onError): void {
    if (item.values.length && (item.create || item.modify || item.remove)) {
      this.apiService.post(this.serviceUrl, item).subscribe(onSuccess, onError);
    } else {
      onSuccess(undefined);
    }
  }
}
