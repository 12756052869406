// src/app/services/csp-nonce.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CspNonceService {
  private readonly _nonce: string | null = null;

  constructor() {
    this._nonce = this.getNonce();
  }

  private getNonce(): string | null {
    const nonceMetaTag = document.querySelector('meta[name="CSP-NONCE"]') as HTMLMetaElement;
    return nonceMetaTag ? nonceMetaTag.content : null;
  }

  public getNonceValue(): string | null {
    return this._nonce;
  }
}
