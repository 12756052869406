<div class="dx-card sits-card">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header">
      <dx-toolbar>
        <dxi-item widget="dxButton" location="before">
          <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
        </dxi-item>
        <dxi-item location="left" widget="dxButton" locateInMenu="never">
          <div *dxTemplate>
            <div class="toolbar-label">{{[this.componentTitle]}}</div>
          </div>
        </dxi-item>
      </dx-toolbar>
    </div>

    <dx-toolbar class="py-2 px-1">
      <dxi-item id="btnminus1" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button (onClick)="toolbarAction('minus01')" hint="Datum um einen Tag reduzieren"
                   icon="chevronleft"></dx-button>
      </dxi-item>
      <dxi-item id="dateselection" locateInMenu="auto" location="before" widget="dxDateBox">
        <dx-date-box #dateSelector
                     (onValueChanged)="toolbarDateChanged($event)"
                     [(value)]="selectedStartDate"
                     [max]="rangeEndDate"
                     [min]="rangeStartDate"
                     [width]="125"
                     pickerType="calendar"
                     displayFormat="E dd.MM.yy"
                     [showClearButton]="false"
                     type="date">
        </dx-date-box>
      </dxi-item>
      <dxi-item id="btnplus1" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button (onClick)="toolbarAction('plus01')" hint="Datum um einen Tag erhöhen"
                   icon="chevronright"></dx-button>
      </dxi-item>
      <dxi-item widget="dxButton" location="before" locateInMenu="auto">
        <dx-button (click)="this.createStorageBoxOrder({ger_nr: 0})" text="zur Buchung"
                   [disabled]="!(this.storageBoxData !== undefined && this.storageBoxData.length > 0)"></dx-button>
      </dxi-item>
    </dx-toolbar>

    <p><span class="sits-kachel-text" >Bitte einen Zeitraum wählen ...</span>&nbsp;<span class="sits-kachel-text-xs" >(Balken verschieben)</span></p>
    <dx-range-selector
      id="range-selector"
      [value]="[selectedStartDate, selectedEndDate]"
      (onValueChanged)="onRangeValueChanged($event)">
      <dxo-margin [top]="3"></dxo-margin>
      <dxo-scale
        [startValue]="rangeStartDate"
        [endValue]="rangeEndDate"
        minorTickInterval="day"
        tickInterval="week"
        minRange="year"
        maxRange="year"
      >
        <dxo-minor-tick [visible]="true"></dxo-minor-tick>
        <dxo-marker [visible]="true"></dxo-marker>
        <dxo-label [customizeText]="getCustomizedLabel"></dxo-label>
      </dxo-scale>
      <dxo-slider-marker [customizeText]="getCustomizedSliderMarker"></dxo-slider-marker>
      <dxo-behavior callValueChanged="onMoving"> </dxo-behavior>
    </dx-range-selector>
    <h4>Verfügbare Lagerboxen: {{storageBoxData.length ? storageBoxData.length : 0 }}</h4>

    <dx-data-grid #grid
                  [dataSource]="storageBoxData"
                  (onSelectionChanged)="selectionChangedHandler($event)"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [columnHidingEnabled]="false"
                  [hoverStateEnabled]="true"
                  [rowAlternationEnabled]="true"
                  [selectedRowKeys]="selectedStorageBoxIds"
                  [showBorders]="true"
                  [showColumnLines]="false"
                  [showRowLines]="true"

                  id="grid-container"
                  keyExpr="ger_nr"
                  noDataText="keine Box verfügbar">

      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[7,8,9,10, 15, 20, 30, 50, 100]"
        [showInfo]="true"
        [showNavigationButtons]="true"
        [showPageSizeSelector]="true"></dxo-pager>
      <dxo-column-chooser [enabled]="false" mode="select"></dxo-column-chooser>
      <dxo-remote-operations
        [filtering]="true"
        [groupPaging]="true"
        [grouping]="true"
        [paging]="true"
        [sorting]="true"
        [summary]="false">
      </dxo-remote-operations>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="none"></dxo-selection>
      <dxo-scrolling mode="standard" [useNative]="true"></dxo-scrolling>
      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-editing
        mode="row"
        [allowUpdating]="false"
        [allowDeleting]="false"
        [useIcons]="true"
      ></dxo-editing>
      <dxi-column
        caption="Lagerbox buchen"
        type="buttons" [visible]="true">
        <dxi-button
          hint="zur Lagerbox-Buchung für den gewählten Zeitraum ..."
          icon="assets/iconexperience/iconex_o2/o_collection_svg/blue_dark_grey/table_selection_all.svg"
          [visible]="isCreateStorageBoxOrderButtonEnabled"
          [onClick]="createStorageBoxOrder"
        ></dxi-button>
        <dxi-button
          hint="Lagerbox-Service kann für diese Lagerbox nicht (mehr) gebucht werden."
          icon="assets/iconexperience/iconex_o2/o_collection_svg/light_grey/tables.svg"
          [visible]="isCreateStorageBoxOrderButtonDisabled"
        ></dxi-button>
      </dxi-column>

      <dxi-column dataField="ger_nr"
                  [visible]="false"
                  dataType="number"
                  caption="Nr."
                  width="auto"
      ></dxi-column>

      <dxi-column [sortIndex]="1"
                  caption="Lagerbox"
                  dataField="ger_name"
                  dataType="string"
                  width="90"
      ></dxi-column>

      <dxi-column caption="Preis inkl. MwSt."
                  cellTemplate="cellTemplatePreisBrutto"
                  width="90"
      ></dxi-column>
      <dxi-column caption="Preis netto"
                  cellTemplate="cellTemplatePreisNetto"
                  width="90"
                  [visible] = "this.currentUser !== undefined && (this.currentUser?.isEmployee === 1 || this.currentUser?.isCustomer === 1)"
      ></dxi-column>

      <div *dxTemplate="let d of 'cellTemplatePreisBrutto'">
        <div *ngIf="defaultLagerboxServiceArtikel !== undefined">
          {{defaultLagerboxServiceArtikel?.art_brutto_preis1| currency : "EUR" : "symbol"}}
        </div>
      </div>

      <div *dxTemplate="let d of 'cellTemplatePreisNetto'">
        <div *ngIf="defaultLagerboxServiceArtikel !== undefined">
          {{defaultLagerboxServiceArtikel?.art_preis1| currency : "EUR" : "symbol"}}
        </div>
      </div>
    </dx-data-grid>
  </div>
</div>


