import {Injectable, Inject} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IApiConfiguration} from '../api-dtos/api-configuration';
import {Observable} from 'rxjs/internal/Observable';
import {strings} from '@angular-devkit/core';

@Injectable()
export class ApiService {
  window: Window;
  customConfigurations: IApiConfiguration = {};
  private readonly baseUrl: string;
  private headers: HttpHeaders = this.getHeaders();
  private options: any = {headers: this.headers};
  private apiConfigurationFilename: string = './assets/config/api-settings.json';

  constructor(
    @Inject('Window') injectedWindow: any,
    private http: HttpClient
  ) {
    this.baseUrl = this.getApiUrl();
    // Unfortunately there is a problem using Window as type in constructor that causes production build to fail
    if (injectedWindow) {
      this.window = (injectedWindow as Window);
    } else {
      this.window = window;
    }
  }

  public getApiConfigurationFilename(): string {
    return this.apiConfigurationFilename;
  }


  public getBaseUrlOld(): string {
    return this.baseUrl;
  }

  public getApiUrl(): string {
    if (environment.restApiUrl != undefined)
      return environment.restApiUrl.trim() === '' ? '???' : environment.restApiUrl;
  }

  public post(endpoint: string, object: any, id: number | null = null, relatedEndpoint: string | null = null): Observable<any> {
    endpoint = this.removeLeadingSlash(endpoint);
    const url: string = this.baseUrl + '/' + endpoint + (id != null ? '/' + id + (relatedEndpoint != null ? '/' + relatedEndpoint : '') : '');
    const jsonObject: string = JSON.stringify(object);
    return this.http.post(url, jsonObject, this.options);
  }

  public put(endpoint: string, object: any, id: number|null = object.id ?? null, relatedEndpoint: string|null = null, relatedId: number|null = null): Observable<any> {
    endpoint = this.removeLeadingSlash(endpoint);
    const url: string = this.baseUrl + '/' + endpoint + '/' + id
      + (relatedEndpoint != null ? '/'
        + relatedEndpoint + (relatedId != null ? '/'
          + relatedId : '') : '');
    const jsonObject: string = JSON.stringify(object);
    return this.http.put(url, jsonObject, this.options);
  }

  public get(endpoint: string, id: number | null = null, relatedEndpoint: string | null = null, relatedId: number | null = null): Observable<any> {
    let url: string;
    endpoint = this.removeLeadingSlash(endpoint);
    url = this.baseUrl + '/' + endpoint + (id != null ? '/' + id
      + (relatedEndpoint != null ? '/' + relatedEndpoint
        + (relatedId != null ? '/' + relatedId : '') : '') : '');
    return this.http.get(url, this.options);
  }

  public async getAsync(endpoint: string, id: number | null = null, relatedEndpoint: string|null, relatedId: number|null = null): Promise<any> {
    let url: string;
    endpoint = this.removeLeadingSlash(endpoint);
    url = this.baseUrl + '/' + endpoint + (id != null ? '/' + id
      + (relatedEndpoint != null ? '/' + relatedEndpoint
        + (relatedId != null ? '/' + relatedId : '') : '') : '');
    return await this.http.get<any>(
      url, await this.getHeadersAsync()
    ).toPromise();
  }

  public delete(endpoint: string, id: number | null, relatedEndpoint: string | null = null, relatedId: number | null = null): Observable<any> {
    let url: string;
    endpoint = this.removeLeadingSlash(endpoint);
    url = this.baseUrl + '/' + endpoint + '/' + id
      + (relatedEndpoint != null ? '/' + relatedEndpoint + (relatedId != null ? '/' + relatedId : '') : '');
    return this.http.delete(url, this.options);
  }


  public upload(endpoint: string, files: any[]): Observable<any> {
    let url: string;
    endpoint = this.removeLeadingSlash(endpoint);
    url = this.baseUrl + '/' + endpoint;
    const headers:HttpHeaders = this.headers.delete('Content-Type');
    const formData: FormData = new FormData();

    for (const file of files) {
      formData.append('upload', file, file.name);
    }

    return this.http.post(url, formData, {headers});
  }

  private async getHeadersAsync() {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + localStorage.getItem('sessionKey'));
    return httpOptions;
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .append('Accept', 'application/json')
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + localStorage.getItem('sessionKey'));
  }

  private removeLeadingSlash(text: string): string {
    if (!!text) {
      return (text.substring(0, 1) == '/') ? text.substring(1) : text;
    }
    return text;
  }

}
