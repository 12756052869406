<div class="sits-container">
    <div class="dx-card sits-card-large">
        <div class="dx-fieldset">
            <div class="dx-fieldset-header">
                <dx-toolbar>
                    <dxi-item widget="dxButton" location="before">
                        <dx-button (click)="goBack()" icon="back" hint="zurück navigieren"></dx-button>
                    </dxi-item>
                    <dxi-item location="left" widget="dxButton" locateInMenu="never">
                        <div *dxTemplate>
                            <div class="toolbar-label">{{[componentTitle]}}</div>
                        </div>
                    </dxi-item>
                </dx-toolbar>
            </div>
        </div>
        <div class="dx-field-item-content">
            <p>Verantwortlich im Sinne des Telemediengesetzes:</p>
            <h3>Lüders Logistik e.K.</h3>
            <p>Inhaber: Frederik Lüders<br>
                Am Hafen West 1<br>
                D-26474 Spiekeroog<br><br>
                Telefon: +49 176 85 63 79 71<br>
                E-Mail: <a href="mailto:info@inselspedition.de" class="mail">info&#64;inselspedition.de</a><br><br>
                Gerichtsstand: Wittmund<br>
                Handelsregister: HRA 202908, Amtsgericht Aurich<br>
                UStId: DE327092262<br>
                <br>

                Die hier gegebenen Informationen erfolgen zur Erfüllung nachfolgend genannter gesetzlicher
                Verpflichtungen:<br><br>§ 6 und § 7 Teledienstgesetz (TDG)<br>§ 4 Abs. 3
                Bundesdatenschutzgesetz (BDSG)<br>§ 312c Bürgerliches Gesetzbuch (BGB)<br>§ 1
                BGB-Informationspflichten-Verordnung (BGB-InfoV)
            </p>
            <p>
                siehe auch <a href="https://www.inselspedition.de/impressum" target="_blank">Impressum
                www.inselspedition.de</a></p>

            <p>Konzept, Design, Programmierung, Betrieb:<br>
                Scheltwort IT-Services KG<br>
                D-34117 Kassel<br>
                <a href="https://www.scheltwort-its.de" target="_blank">www.scheltwort-its.de</a>
            </p>
        </div>
        <div class="clr-row">
            <div class="clr-col-lg-8 clr-col-12">
                <div class="card">
                    <div class="card-header">
                        Haftungsausschluss
                    </div>
                    <div class="card-block">
                        <div class="card-subtitle sits-text-bold">1. Inhalt des Onlineangebotes</div>
                        <div class="card-text">
                            Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit,
                            Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen
                            den
                            Autor, welche sich
                            auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung
                            der
                            dargebotenen
                            Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen
                            verursacht
                            wurden, sind
                            grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder
                            grob fahrlässiges
                            Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es
                            sich ausdrücklich
                            vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu
                            ergänzen, zu löschen
                            oder die Veröffentlichung zeitweise oder endgültig einzustellen.
                        </div>
                        <div class="card-subtitle sits-text-bold">
                            2. Verweise und Links
                        </div>
                        <div class="card-text">
                            Bei direkten oder indirekten Verweisen auf fremde Internetseiten ("Links"), die außerhalb
                            des
                            Verantwortungsbereiches
                            des Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft
                            treten,
                            in dem der Autor
                            von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im
                            Falle
                            rechtswidriger
                            Inhalte zu verhindern. Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der
                            Linksetzung keine
                            illegalen
                            Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige
                            Gestaltung, die Inhalte
                            oder die Urheberschaft der gelinkten/verknüpften Seiten hat der Autor keinerlei Einfluss.
                            Deshalb distanziert er
                            sich hiermit ausdrücklich von allen Inhalten aller gelinkten /verknüpften Seiten, die nach
                            der
                            Linksetzung
                            verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes
                            gesetzten Links und
                            Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen
                            und
                            Mailinglisten.
                            Für
                            illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der
                            Nutzung oder
                            Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der
                            Seite, auf welche
                            verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung
                            lediglich
                            verweist.
                        </div>
                        <div class="card-subtitle sits-text-bold">3. Urheber- und Kennzeichenrecht</div>
                        <div class="card-text">Der Autor ist bestrebt, in allen Publikationen die
                            Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten,
                            von
                            ihm selbst
                            erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie
                            Grafiken,
                            Tondokumente,
                            Videosequenzen und Texte zurückzugreifen. Alle innerhalb des Internetangebotes genannten und
                            ggf. durch Dritte
                            geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des
                            jeweils
                            gültigen
                            Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein
                            aufgrund
                            der bloßen
                            Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter
                            geschützt
                            sind! Das
                            Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor
                            der
                            Seiten. Eine
                            Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in
                            anderen
                            elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors
                            nicht
                            gestattet.
                        </div>
                        <div class="card-subtitle sits-text-bold">Sonstiges</div>
                        <div class="card-text">Bildschirmauflösung (aktuell, netto): {{screenWidth}} x {{screenHeight}}
                            <br><br><br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>
</div>

