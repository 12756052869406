import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {IFahrplan} from '../api-dtos/fahrplan';
import {formatDate} from '@angular/common';
import {Observable} from 'rxjs';
import {IFrachtOrt} from '../api-dtos/fracht-ort';
import {StreckeSettingService} from './strecke-setting.service';
import {of} from 'rxjs/internal/observable/of';
import {map} from 'rxjs/operators';
import {FahrplanFilter} from '../api-dtos/fahrplan-filter';
import {TransportTypes} from '../api-basics/transportTypes';

@Injectable({
  providedIn: 'root'
})
export class FahrplanService {
  baseUrl = `${environment.restApiUrl}/fahrplan`;
  nonFrachtJanusDaysCache: IFahrplan[] = [];
  nonFrachtJanusDaysCacheDays = 0;
  frachtJanusDaysCache: IFahrplan[] = [];
  frachtJanusDaysCacheDays = 0;

  constructor(
    private http: HttpClient,
    private streckeSettingService: StreckeSettingService
  ) {
  }

  private async getHttpHeaderAsync() {
    const accessToken: string = await localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  private getHttpHeaders(): HttpHeaders {
    const accessToken: string = localStorage.getItem('sessionKey');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (accessToken) {
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  }

  public getAll( transportArt: TransportTypes = TransportTypes.All, date: Date | undefined, pastDays: number = 0, futureDays: number = 0 ): Observable<IFahrplan[]> {
    // refactored: 19.06.2023 (vorher Promise)
    const options: { headers: HttpHeaders } = {headers: this.getHttpHeaders()}
    let url:URL = new URL (this.baseUrl);

    switch (transportArt) {
      case TransportTypes.Faehre:
        url.searchParams.set('transportArt', 'faehre');
        break;
      case TransportTypes.Flug:
        url.searchParams.set('transportArt', 'flug');
        break;
      case TransportTypes.All:
      default:
        url.searchParams.set('transportArt', 'all');
        break;
    }

    if (date != undefined && date != null) {
      url.searchParams.set('date', formatDate(date, 'yyyy-MM-dd', 'de-DE'));
    }

    if (pastDays != 0) {
      url.searchParams.set('pastDays', String(pastDays));
    }
    if (futureDays != 0) {
      url.searchParams.set('futureDays', String(futureDays));
    }

    return this.http.get<IFahrplan[]>(url.href, options)
      .pipe(map(data => {
        return data;
      }));
  }


  public getAllFlug(): Observable<IFahrplan[]> {
    // refactored: 19.06.2023 (vorher Promise)
    const options: { headers: HttpHeaders } = {headers: this.getHttpHeaders()}
    return this.http.get<IFahrplan[]>(`${this.baseUrl}/flug`, options);
  }

  public getAllFaehre(): Observable<IFahrplan[]> {
    // refactored: 19.06.2023 (vorher Promise)
    const options: { headers: HttpHeaders } = {headers: this.getHttpHeaders()}
    return this.http.get<IFahrplan[]>(`${this.baseUrl}/faehre`, options)
      .pipe(map(data => {
        return data;
      }));
  }

  public async getAllJanusDays(days: number): Promise<IFahrplan[]> {
    const options: { headers: HttpHeaders } = {headers: this.getHttpHeaders()}
    if (!days || days <= 0) {
      days = 0;
    }
    return this.http.get<IFahrplan[]>(
      `${this.baseUrl}/all/janusdays/${days}`, options
    ).toPromise();
  }

  public getFrachtJanusDays(days: number): Observable<IFahrplan[]> {
    // refactored: 19.06.2023 (vorher Promise)
    if (!days || days <= 0) {
      days = 0;
    }
    if (days <= this.frachtJanusDaysCacheDays && this.frachtJanusDaysCache && this.frachtJanusDaysCache.length > 0) {
      return of(this.frachtJanusDaysCache);
    }
    const options: { headers: HttpHeaders } = {headers: this.getHttpHeaders()}
    return this.http.get<IFahrplan[]>(`${this.baseUrl}/fracht/janusdays/${days}`, options)
      .pipe(
        map(data => {
          if (data && data.length > 0) {
            this.frachtJanusDaysCache = data;
            this.frachtJanusDaysCacheDays = days;
          }
          return data;
        })
      );
  }

  public async getNonFrachtJanusDays(days: number): Promise<IFahrplan[]> {
    if (!days || days <= 0) {
      days = 0;
    }
    if (days <= this.nonFrachtJanusDaysCacheDays && this.nonFrachtJanusDaysCache && this.nonFrachtJanusDaysCache.length > 0) {
      return this.nonFrachtJanusDaysCache;
    }
    const options: { headers: HttpHeaders } = {headers: this.getHttpHeaders()}

    this.nonFrachtJanusDaysCache = await this.http.get<IFahrplan[]>(
      `${this.baseUrl}/nonfracht/janusdays/${days}`, options
    ).toPromise();
    if (this.nonFrachtJanusDaysCache && this.nonFrachtJanusDaysCache.length > 0) {
      this.nonFrachtJanusDaysCacheDays = days;
    }
    return this.nonFrachtJanusDaysCache;
  }

  public getById(fahrplanId: number): Promise<IFahrplan[]> {
    // refactored: 19.06.2023 Promise<void> hinzu. Nicht mehr: return new Promise(() => { });
    if (!fahrplanId || fahrplanId <= 0) {
      return new Promise(() => {
      });
      //return;
    }
    const options: { headers: HttpHeaders } = {headers: this.getHttpHeaders()}
    return this.http.get<IFahrplan[]>(
      `${this.baseUrl}/${fahrplanId}`, options
    ).toPromise();
  }

  public getFiltered(datum: Date, abfahrtOrtId: number): Observable<IFahrplan[]> {
    const options: { headers: HttpHeaders } = {headers: this.getHttpHeaders()}
    const filterDate = datum ? formatDate(datum, 'yyyy-MM-dd', 'de-DE') : '2099-01-01';
    abfahrtOrtId = abfahrtOrtId ? abfahrtOrtId : 0;
    return this.http.get<IFahrplan[]>(`${this.baseUrl}/${filterDate}/${abfahrtOrtId}`, options);
  }

  public getFahrplanDropDownData(fahrplanFilter: FahrplanFilter): Observable<IFahrplan[]> {
    // refactored: 19.06.2023 (vorher Promise)
    const params = new HttpParams()
      .set('date', fahrplanFilter?.date ? formatDate(fahrplanFilter?.date, 'yyyy-MM-dd', 'de-DE') : '2099-01-01')
      .set('transportArt', !!fahrplanFilter?.transportArt ? fahrplanFilter?.transportArt : 'all')
      .set('abfahrtOrtId', fahrplanFilter?.abfahrtOrtId ? fahrplanFilter?.abfahrtOrtId.toString() : null)
      .set('pastDays', fahrplanFilter?.pastDays ? fahrplanFilter?.pastDays.toString() : null)
      .set('futureDays', fahrplanFilter?.futureDays ? fahrplanFilter?.futureDays.toString() : null);
    const headerData: { headers: HttpHeaders, params: HttpParams } = {headers: this.getHttpHeaders(), params: params};
    headerData.params = params;

    return this.http.get<IFahrplan[]>(`${this.baseUrl}`, headerData)
      .pipe(map(data => {
        return data;
      }));
  }


  public getFrachtAbfahrten(frachtDatum: Date, abfahrtOrtId: number, pastDays: number, futureDays: number): Observable<IFahrplan[]> {
    const options: { headers: HttpHeaders } = {headers: this.getHttpHeaders()}
    let filterDate: string;
    filterDate = frachtDatum ? formatDate(frachtDatum, 'yyyy-MM-dd', 'de-DE') : '2099-01-01';
    abfahrtOrtId = abfahrtOrtId ? abfahrtOrtId : 0;

    // return this.http.get<ITextBlock>(`${this.baseUrl}?shortcut=${txblk_shortcut_code}&lng=${language}`,
    let url = `${this.baseUrl}/fracht/${filterDate}/${abfahrtOrtId}`;
    if (!pastDays || pastDays < 0 || pastDays > 3650) {
      pastDays = 0;
    }
    url = url + `?pastDays=${pastDays}`;

    if (!futureDays || futureDays < 0 || futureDays > 3650) {
      futureDays = 0;
    }
    url = url + `&futureDays=${futureDays}`;
    return this.http.get<IFahrplan[]>(url, options);
  }

  public getPersonenAbfahrten(frachtDatum: Date, abfahrtOrtId: number): Observable<IFahrplan[]> {
    const options: { headers: HttpHeaders } = {headers: this.getHttpHeaders()}
    const filterDate: string = frachtDatum ? formatDate(frachtDatum, 'yyyy-MM-dd', 'de-DE') : '2099-01-01';
    return this.http.get<IFahrplan[]>(
      `${this.baseUrl}/nonfracht/${filterDate}/${abfahrtOrtId}`, options
    );
  }

  /**
   * Abfahrten von Personenfähren, darunter die, für die es Gepäckservice gibt,
   * @param frachtDatum
   * @param abfahrtOrtId
   */
  public async getGepaeckServiceFahrtenByAbfahrtOrt(frachtDatum: Date, abfahrtOrtId: number): Promise<IFahrplan[]> {
    if (!frachtDatum || !abfahrtOrtId) {
      // console.log('getPersonenAbfahrtenGepaeckService. Missing parameter ');
      return new Promise(() => {
      });
    }
    // OHNE fp_faelltaus_yn == 1
    let filterDate: string;
    filterDate = await formatDate(frachtDatum, 'yyyy-MM-dd', 'de-DE');
    return await this.http.get<IFahrplan[]>(
      `${this.baseUrl}/nonfracht/${filterDate}/${abfahrtOrtId}`,
      await this.getHttpHeaderAsync()
    ).toPromise().then(resFahrplan => {
      return resFahrplan.filter(fp =>
        new Date(fp.fp_abfahrt_datzeit).getHours() * 60
        + new Date(fp.fp_abfahrt_datzeit).getMinutes() >= this.streckeSettingService.getGepaeckFirstOrderLimitMinutesOfDay()
        && new Date(fp.fp_abfahrt_datzeit).getHours() * 60
        + new Date(fp.fp_abfahrt_datzeit).getMinutes() <= this.streckeSettingService.getGepaeckLastOrderLimitMinutesOfDay()
        && new Date(fp.fp_abfahrt_datzeit) > new Date(frachtDatum)
        && (fp.fp_faelltaus_yn ?? 1) == 0)
        ;
    });
  }

  public getDisplayAbfahrtzeitAssembled(abfahrtDatzeit: Date | undefined, geraetName: string | undefined): string {
    if (!abfahrtDatzeit) {
      return 'keine Fähre zugewiesen';
    }

    let zeitinfo: string;
    zeitinfo = formatDate(abfahrtDatzeit, 'EE dd.MM.yy HH:mm', 'de-DE');
    if (parent.window.outerWidth < 1200) {
      return zeitinfo;
    }
    if (geraetName !== undefined && geraetName.trim() !== '') {
      zeitinfo += ' ' + geraetName;
    }
    return zeitinfo;
  }

  public isAnreise(ZielOrt: IFrachtOrt): boolean | undefined {
    if (!ZielOrt || !ZielOrt.fort_tags) {
      return undefined;
    }
    return ZielOrt.fort_tags.toUpperCase().includes('INSEL', 0);
  }

  public getDefaultTargetDestination(frachtOrtId: number): number | undefined {
    return frachtOrtId === undefined
      ? undefined
      : frachtOrtId === null || frachtOrtId < 1
        ? null
        : frachtOrtId === 1
          ? 2
          : 1;
  }

}

enum Caches {
  All,
  Adresse = 1,
  AdresseDddw = 2,
  GepaeckAdresseDddw = 3
}

