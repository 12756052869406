<div class="sits-container">
    <div class="dx-card sits-card-large">
        <div class="dx-fieldset">
            <div class="dx-fieldset-header">
                <dx-toolbar>
                    <dxi-item widget="dxButton" location="before">
                        <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
                    </dxi-item>
                    <dxi-item location="left" widget="dxButton" locateInMenu="never">
                        <div *dxTemplate>
                            <div class="toolbar-label">{{[this.componentTitle]}}</div>
                        </div>
                    </dxi-item>
                </dx-toolbar>
            </div>

            <div class="clr-row">
                <div class="clr-col-lg-8 clr-col-12">
                    <div class="card">
                        <div class="card-block">
                            <div class="card-text">
                                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
                                behandeln Ihre
                                personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
                                Datenschutzvorschriften sowie
                                dieser
                                Datenschutzerklärung.Die Nutzung unserer Webseite ist in der Regel ohne Angabe
                                personenbezogener Daten möglich.
                                Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder
                                E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger
                                Basis. Diese Daten werden
                                ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin,
                                dass die
                                Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
                                aufweisen kann. Ein lückenloser Schutz
                                der Daten vor dem Zugriff durch Dritte ist nicht möglich.<br>
                                <p><b>Erhebung, Verarbeitung, Speicherung, Verwendung von personenbezogenen
                                    Daten:</b><br>Personenbezogene
                                    Daten werden durch Lüders Logistik e.K. nur dann erhoben, wenn Sie aktiv mit uns
                                    Kontakt
                                    aufnehmen, z.B.
                                    bei
                                    der Anforderung von weiteren Informationen über unsere Leistungen. Alle in diesem
                                    Rahmen
                                    anfallenden
                                    personenbezogenen Daten werden nur für eigene Geschäftszwecke im Hinblick auf die
                                    Betreuung unserer
                                    Kunden und
                                    Interessenten erhoben, verarbeitet und genutzt. Es erfolgt keine Datenweitergabe an
                                    Dritte, weder zu
                                    kommerziellen noch zu nichtkommerziellen Zwecken. Sie können die Einwilligung zur
                                    Nutzung Ihrer Daten
                                    jederzeit
                                    mit Wirkung für die Zukunft widerrufen. Senden Sie uns dazu eine E-Mail mit dem
                                    Betreff
                                    „Widerruf“:<a
                                            href="mailto:info@inselspedition.de" class="mail">Kontakt</a>
                                    <br><br>

                                    <b>Cookies</b><br>
                                    Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf
                                    Ihrem
                                    Rechner keinen
                                    Schaden an und
                                    enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
                                    effektiver
                                    und sicherer zu
                                    machen.
                                    Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr
                                    Browser speichert.Die
                                    meisten
                                    der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden
                                    nachEnde
                                    Ihres Besuchs
                                    automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bisSie
                                    diese löschen. Diese
                                    Cookies
                                    ermöglichen es uns, Ihren Browser beim nächsten Besuchwiederzuerkennen.Sie können
                                    Ihren
                                    Browser so
                                    einstellen,
                                    dass Sie über das Setzen von Cookies informiert werden undCookies nur im Einzelfall
                                    erlauben, die
                                    Annahme von
                                    Cookies für bestimmte Fälle oder generellausschließen sowie das automatische Löschen
                                    der
                                    Cookies beim
                                    Schließen
                                    des Browser aktivieren. Beider Deaktivierung von Cookies kann die Funktionalität
                                    dieser
                                    Website
                                    eingeschränkt
                                    sein.<br><br>

                                    <b>Kontaktformular</b>
                                    <br>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
                                    Angaben aus demAnfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten
                                    zwecks Bearbeitung
                                    derAnfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten
                                    geben
                                    wir nicht ohne
                                    Ihre
                                    Einwilligung weiter.<br>

                                    <b><br>SSL-Verschlüsselung</b><br>
                                    Diese Seite nutzt aus Gründen der Sicherheit und zum
                                    Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die Sie
                                    an
                                    uns als
                                    Seitenbetreiber
                                    senden, eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
                                    dass die
                                    Adresszeile des
                                    Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer
                                    Browserzeile. Wenn die
                                    SSL
                                    Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
                                    von
                                    Dritten
                                    mitgelesen
                                    werden.<br><br>

                                    Wir sind bemüht, Ihre personenbezogenen Daten durch Ergreifung aller technischen und
                                    organisatorischen
                                    Möglichkeiten so zu speichern, dass sie für Dritte nicht zugänglich
                                    sind. Bei der Kommunikation per E Mail kann die vollständige Datensicherheit von uns
                                    nicht gewährleistet
                                    werden,
                                    so dass wir Ihnen bei vertraulichen Informationen den Postweg empfehlen.<br><br>

                                    <b>Recht auf Auskunft, Löschung, Sperrung</b><br>
                                    Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
                                    personenbezogenen
                                    Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein
                                    Recht
                                    auf
                                    Berichtigung,
                                    Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
                                    personenbezogene Daten
                                    können Sie
                                    sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.<br><br>

                                    <b>Rechtswirksamkeit dieses Haftungsausschlusses</b><br>
                                    Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von
                                    dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen
                                    dieses Textes der
                                    geltenden
                                    Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die
                                    übrigen Teile des
                                    Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.<br><br>

                                    <b>Fotorechte</b><br>
                                    Alle Fotorechte liegen Lüders Logistik e.K.<br><br>

                                    <b>Widerspruch Werbe-Mails</b><br>
                                    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur
                                    Übersendung von nicht
                                    ausdrücklich
                                    angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die
                                    Betreiber der Seiten
                                    behalten
                                    sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
                                    Werbeinformationen, etwa
                                    durch
                                    Spam-E-Mails, vor.<br>
                                    Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a><br><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
