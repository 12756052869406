import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class MapSetting {
  key: string;
  name: string;
}
@Injectable()
export class DevExpressSitsService {

mapTypes: MapSetting[] = [{
  key: 'roadmap',
  name: 'Straßenkarte'
}, {
  key: 'satellite',
  name: 'Satellit'
}, {
  key: 'hybrid',
  name: 'Kombiniert'
}];

  constructor() { }

  getMapTypes(): MapSetting[] {
    return this.mapTypes;
  }
}

