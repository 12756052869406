import {Component, HostListener, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

/*
templateUrl:   !!environment.targetKey
  ? 'about-' + environment.targetKey + '.component.html'
  : 'about.component.html',
*/

@Component({
  selector: 'app-about',
  templateUrl:   !!environment.targetKey
    ? 'about-' + environment.targetKey + '.component.html'
    : 'about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  private history: string[] = [];
  public screenHeight: number;
  public screenWidth: number;
  public componentTitle: string = 'Impressum / Info';

  constructor(
    private router: Router,
    private readonly location: Location
  ) {
    this.getScreenSize();
  }

  public ngOnInit() {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.getScreenSize();
  }

  public cancel(): void {
    this.location.back();
  }

  getScreenSize(): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  public goBack(): void {
    if (this.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl("/").then();
    }
  }

}
