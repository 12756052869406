import {DataElement} from '../api-basics/data-element';

export class Adresse implements DataElement {
  id?: number;
  mand_id?: number;
  adr_id?: number;
  anr_id?: number;
  adr_name?: string;
  adr_surname?: string;
  adr_name2?: string;
  adr_haus_name?: string;
  adr_strasse?: string;
  adr_hausnr_von_nra?: string;
  adr_hausnr_bis_nra?: string;
  adr_strasse_plz?: string;
  adr_ort?: string;
  adr_zusatz_ort?: string;
  adr_teil_ort?: string;
  land_id?: number;
  adr_such_name?: string;
  adr_tel?: string;
  adr_fax_tel?: string;
  adr_mobil_tel?: string;
  adr_email?: string;
  adr_internet_url?: string;
  adr_debitor_ktonr?: number;
  adr_text?: string;
  adr_create_stamp?: Date;
  adr_change_stamp?: Date;
  adr_type_code?: string;
  adr_briefanrede_text?: string;
  adr_ansprechpartner_name?: string;
  adr_gepaeck_name?: string;
  adr_liefersperre_yn?: number;
  adr_create_user?: string;
  adr_change_user?: string;
  adr_zahlart_code?: string;
  bk_blz?: string;
  adr_bk_konto?: string;
  zbd_id?: number;
  adr_beleg_syslng_code?: string;
  adr_eu_ausland_yn?: number;
  adr_eu_ustid?: string;
  adr_strasse_hausnr?: string;
  adr_belegausgabe1_anz?: number;
  adr_belegausgabe2_anz?: number;
  adr_belegpermail_yn?: number;
  adr_belegpermail_datum?: Date;
  adr_belegemail?: string;
  adr_ibancode?: string;
  adr_biccode?: string;
}

