<div class="dx-card sits-card-large">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header">
      <dx-toolbar>
        <dxi-item widget="dxButton" location="before">
          <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
        </dxi-item>
        <dxi-item location="left" widget="dxButton" locateInMenu="never">
          <div *dxTemplate>
            <div class="toolbar-label">{{ [this.componentTitle] }}</div>
          </div>
        </dxi-item>
      </dx-toolbar>
    </div>
    <dx-toolbar class="py-2 px-1">
      <dxi-item widget="dxButton" location="before" locateInMenu="auto">
        <dx-button icon="refresh" (click)="grid.instance.clearSelection(); refreshGepaeckAuftragList()"></dx-button>
      </dxi-item>

      <dxi-item id="btnadd" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button (onClick)="actionAddItem()"
                   hint="Neuen Gepäckauftrag anlegen."
                   icon="plus"></dx-button>
      </dxi-item>

      <dxi-item id="btnedit" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button (onClick)="actionEditItem()"
                   hint="Gepäckauftrag bearbeiten"
                   [disabled]="(selectedGepaeckBelege.length || 0 ) !== 1"
                   icon="edit"></dx-button>
      </dxi-item>
      <dxi-item id="btnshow" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button (onClick)="actionShowItem()"
                   hint="Gepäckauftrag Druckansicht"
                   [disabled]="(selectedGepaeckBelege.length || 0 ) !== 1"
                   icon="assets/iconexperience/iconex_o2/o_collection_svg/dark_grey/eye.svg"></dx-button>
      </dxi-item>
      <dxi-item id="dateselection" locateInMenu="auto" location="before" widget="dxDateBox">
        <dx-date-box
          type="date"
          displayFormat="EE. dd.MM.yy"
          width="165px"
          useMaskBehavior=true
          showClearButton="true"
          placeholder="(Datum)"
          [(value)]="filterByDate"
          applyValueMode="useButtons"
          (onValueChanged)="onValueChangedFilterByDate($event)"
          hint="Liste auf ein bestimmtes Datum eingrenzen.">
        </dx-date-box>
      </dxi-item>

      <dxi-item id="abfahrtortselection" locateInMenu="auto" location="before" widget="dxLookup">
        <dx-lookup
          [dataSource]="frachtOrts"
          [displayExpr]=getDisplayFrachtOrt
          valueExpr="fort_id"
          [value]=filterByOrtId
          applyValueMode="instantly"
          [disabled]="false"
          width="150px"
          [searchEnabled]="false"
          [showCancelButton]="false"
          [usePopover]="true"
          showClearButton="true"
          placeholder="(Abfahrtort)"
          hint="Liste auf einen bestimmten Abfahrt- / Abflugort der Kunden eingrenzen."
          noDataText="keiner = Alle"
          val
          (onSelectionChanged)="onValueChangedFilterByOrtId($event)">
        </dx-lookup>
      </dxi-item>

      <dxi-item id="fahrplanselection" locateInMenu="auto" location="before" widget="dxLookup">
        <dx-lookup
          [dataSource]="fahrplans"
          [displayExpr]=getDisplayAbfahrtzeitVoll
          valueExpr="fp_id"
          searchPlaceholder="Fähre/Flug ..."
          [value]="filterByFahrplanId"
          [disabled]="!this.filterByDate"
          width="200px"
          [searchEnabled]="false"
          [showCancelButton]="false"
          [usePopover]="true"
          showClearButton="true"
          placeholder="(Fähre...)"
          hint="Liste auf bestimmte Fähre/Flug eingrenzen."
          noDataText="keiner = Alle"
          (onSelectionChanged)="onValueChangedByFahrplanId($event)">
        </dx-lookup>
      </dxi-item>

      <dxi-item id="externalOrderId" locateInMenu="auto" location="before" widget="dxTextBox"
                [hidden]="!(getIsEmployee() && environment.externalGepaeckInterface)">
        <dx-text-box
          type="string"
          width="140px"
          showClearButton="true"
          [maxLength]="26"
          [(value)]="filterByExternalOrderId"
          (onValueChanged)="onValueChangedExternalOrderId($event)"
          placeholder="Ext. Auftragsnummer ..."
          hint="Filter auf Auftragsnummer im externen System, z.B. MDSI. (Beginnend mit ...) '1-7687' findet alle Aufträge, die mit 1-7687 beginnen.">
        </dx-text-box>
      </dxi-item>


      <dxi-item id="btnsignAsPayed" locateInMenu="auto"
                location="after"
                widget="dxButton"
                [hidden]="!getIsEmployee() || environment.targetKey === '10477' || true ">
        <dx-button [disabled]="(selectedGepaeckBelege.length || 0 ) !== 1 ||
                   !!selectedGepaeckBelege[0].gpblg_bezahlt_provider_name"
                   hint="Selektierte Aufträge als bezahlt markieren"
                   (onClick)="signAsPayedSelectedItems(paymentTypes.payPal)">
          <span class="badge badge-pill badge-success">bezahlt</span>
        </dx-button>
      </dxi-item>

      <dxi-item id="btndelete" locateInMenu="auto" location="after" widget="dxButton"
                [hidden]="!(getIsEmployee() ?? getIsCustomer())">
        <dx-button (onClick)="deleteSelectedItems()"
                   [disabled]="!getGepaeckBelegDeleteSelectedAllowed()"
                   icon="trash"></dx-button>
      </dxi-item>
      <dxi-item id="btnstop" locateInMenu="auto" location="after" widget="dxButton"
                [hidden]="!getIsEmployee() || environment.targetKey === '10477' || true ">
        <dx-button [disabled]="(selectedGepaeckBelege.length || 0 ) !== 1"
                   hint="Selektierte Aufträge als NICHT bezahlt markieren"
                   (onClick)="signAsUnPayedSelectedItems()">
          <span class="badge badge-pill badge-danger">NICHT bez.</span>
        </dx-button>
      </dxi-item>
    </dx-toolbar>

    <dx-popup #popupDeleteGepaeckBeleg
              class="popup"
              height="auto"
              [width]="330"
              title="Gepäckauftrag verwerfen"
              [(visible)]="popupDeleteGepaeckBelegVisible">
      <div *dxTemplate="let data of 'content'">
        <p>Selektierter GEP-Auftrag: {{ getSelectedGepaeckBelegeAsJoinedString() }}</p>
        <p>Das Verwerfen ist nur möglich für Belege ...<br>
          die noch nicht bezahlt sind.<br>
          Gepäckauftrag endgültig verwerfen?</p>
        <p>
          <button (click)="popupDeleteGepaeckBelegVisible = false"
                  class="btn btn-outline-secondary  mr-3"
                  data-dismiss="modal"
                  type="button">Abbrechen
          </button>
          <button (click)="deleteSelectedItems(); popupDeleteGepaeckBelegVisible = false"
                  class="btn btn-outline-danger  mr-3"
                  data-dismiss="modal"
                  type="button">
            Löschen
          </button>
        </p>
      </div>
    </dx-popup>


    <dx-data-grid #grid
                  (onSelectionChanged)="selectionChangedHandler($event)"
                  (onOptionChanged)="optionChangedHandler($event)"
                  (onCellPrepared)="onCellPrepared($event)"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [dataSource]="gepaeckBelege"
                  [columnHidingEnabled]="false"
                  [showColumnHeaders]="true"
                  [hoverStateEnabled]="true"
                  [rowAlternationEnabled]="true"
                  [selectedRowKeys]="selectedGepaeckBelegeIds"
                  columnResizingMode="widget"
                  [showBorders]="true"
                  [showColumnLines]="false"
                  [showRowLines]="true"
                  id="grid-container"
                  keyExpr="gpblg_id">
      <dxo-column-chooser #columnChooser [enabled]="false" mode="select">
        <dxo-position my="right top" at="right bottom" of=".dx-datagrid-column-chooser-button"></dxo-position>
        <dxo-selection [allowSelectAll]="true" [recursive]="false"></dxo-selection>
      </dxo-column-chooser>

      <dxo-paging [enabled]="true" [pageSize]="gridPageSize"></dxo-paging>
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[7,8,9,10, 15, 20, 30, 50, 100]"
        [showInfo]="true"
        [showNavigationButtons]="true"
        [showPageSizeSelector]="true"></dxo-pager>
      <dxo-remote-operations
        [filtering]="true"
        [groupPaging]="true"
        [grouping]="true"
        [paging]="true"
        [sorting]="true"
        [summary]="false">
      </dxo-remote-operations>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="none"></dxo-selection>
      <dxo-scrolling mode="standard" [useNative]="true"></dxo-scrolling>
      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="false"></dxo-header-filter>
      <dxi-column [sortIndex]="1"
                  caption="GEP-Nr."
                  dataField="gpblg_id"
                  dataType="number"
                  sortOrder="desc"
                  width="70"
      ></dxi-column>
      <dxi-column caption="Abfahrt"
                  dataField="fp_abfahrt_datzeit"
                  id="fp_abfahrt_datzeit"
                  dataType="date"
                  format="EE dd.MM. HH:mm"
                  width="120"
      ></dxi-column>

      <dxi-column caption="von ..." dataField="fp_abfahrt_fort_id" width="100">
        <dxo-lookup [dataSource]="getFrachtOrt"
                    valueExpr="fort_id"
                    displayExpr="fort_name"
                    cellTemplate="cellTemplate_smaller"
        ></dxo-lookup>
      </dxi-column>
      <dxi-column #gpblg_anz caption="Anzahl"
                  id="gpblg_anz"
                  dataField="gpblg_anz"
                  dataType="number"
                  width="50"
      ></dxi-column>

      <dxi-column caption="Abgeholt"
                  [visible]="this.authenticationService.isEmployee && (inputProcessingMode ?? 0) === 80 "
                  id="gpblg_processstatuslist_abgeholt"
                  dataField="gpblg_processstatuslist"
                  dataType="string"
                  width="50"
      ></dxi-column>

      <dxi-column caption="Tour"
                  [visible]="this.authenticationService.isEmployee"
                  dataField="gptr_id"
                  width="50"
                  cellTemplate="cellTemplate_smaller"
      ></dxi-column>

      <dxi-column caption="Transportmittel (FAEHRE/FLUG)"
                  dataField="gpblg_dummy_fp_art_kz"
                  [visible]="(environment.targetKey === '10477')"
                  alignment="center"
                  cellTemplate="fpArtTemplate"
                  width="40"></dxi-column>

      <dxi-column caption="Ext.Nr."
                  dataField="gpblg_external_order_id"
                  width="85"
                  cellTemplate="cellTemplate_smaller"
                  [visible]="environment.externalGepaeckInterface"
      ></dxi-column>

      <dxi-column alignment="center" caption="Bez." cellTemplate="bezahltTemplate" dataField="gpblg_bezahlt_kz"
                  width="45"></dxi-column>

      <div *dxTemplate="let data of 'bezahltTemplate'">
          <span [ngClass]="{
           'badge-secondary' : !data.value || Number(data.value) < 0 || Number(data.value) > 1,
           'badge-danger': Number(data.value) === 0,
            'badge-success': Number(data.value) === 1}"
                class="badge badge-pill"
          >{{
              Number(data.value) === 0 ? 'Nein'
                : Number(data.value) === 1 ? ' Ja '
                  : Number(data.value) === 2 ? ' Rg ' : '?'
            }}</span>
      </div>

      <dxi-column alignment="center" caption="Storno" cellTemplate="storniertTemplate" dataField="gpblg_storniert_yn"
                  width="55"></dxi-column>
      <div *dxTemplate="let data of 'storniertTemplate'">
          <span *ngIf="data.value === 1" [ngClass]="{
           'badge-secondary' : !data.value || data.value < 1 || data.value > 1,
           'badge-danger': data.value === 1 }"
                class="badge badge-pill"
          >{{
              data.value === 0 ? '   '
                : data.value === 1 ? 'Storno' : '?'
            }}</span>
      </div>

      <dxi-column caption="Insel-Adresse"
                  dataField="adr_id"
                  width="120"
                  cellTemplate="cellTemplate_smaller"
      >
        <dxo-lookup [dataSource]="getGepaeckAdresses"
                    displayExpr="adr_gepaeck_name"
                    valueExpr="adr_id"
        ></dxo-lookup>
      </dxi-column>

      <dxi-column caption="Gast"
                  dataField="gpblg_extern_name"
                  width="100"
                  cellTemplate="cellTemplate_smaller"
      ></dxi-column>

      <dxi-column caption="Gast, Telefon"
                  dataField="gpblg_extern_mobil_tel"
                  dataType="string"
                  width="110"
                  cellTemplate="cellTemplate_gpblg_extern_mobil_tel"
      ></dxi-column>
      <div *dxTemplate="let cell of 'cellTemplate_gpblg_extern_mobil_tel'">
        <div style="color:blue; font-size:small"><a
          href="tel:{{cell.text?.split(' ').join('')}}">{{ cell.text }}</a>
        </div>
      </div>

      <dxi-column caption="Gast, E-Mail"
                  dataField="gpblg_extern_email"
                  width="120"
                  cellTemplate="cellTemplate_gpblg_extern_email"
      ></dxi-column>
      <div *dxTemplate="let cell of 'cellTemplate_gpblg_extern_email'">
        <div style="color:blue; font-size:small"
             *ngIf="!!cell.text && !!cell.data.gpblg_external_order_id && !!cell.data.gpblg_extern_mobiletan && cell.data.gpblg_extern_mobiletan !== '000000' ">
          <a
            href="mailto:{{cell.text}}?subject=Gepäckauftrag {{cell.data.gpblg_external_order_id}} / [GEP-{{cell.data.gpblg_id}}] / Abf. {{cell.data.fp_abfahrt_datzeit | date : 'EE, dd.MM.yyyy HH:mm'}} Uhr -> Info
        &body=Moin Frau/Herr/Familie {{cell.data.gpblg_extern_name}},%0D%0A %0D%0Aes geht um Ihren Gepäckauftrag {{cell.data.gpblg_external_order_id}}%0D%0A %0D%0A{{this.getFrontendBaseUrl()}}/gepaeckauftrag/show/{{cell.data.gpblg_id}}/{{cell.data.gpblg_extern_mobiletan || '000000'}}%0D%0A %0D%0A
Mit freundlichem Gruß %0D%0A %0D%0A
%0D%0A %0D%0A{{imprintText}}">{{ cell.text }}</a>
        </div>

        <div style="color:blue; font-size:small"
             *ngIf="(!!cell?.text && !cell?.data?.gpblg_external_order_id && !!cell?.data?.gpblg_extern_mobiletan && cell?.data?.gpblg_extern_mobiletan !== '000000') ">
          <a
            href="mailto:{{cell.text}}?subject=Gepäckauftrag [GEP-{{cell.data.gpblg_id}}] / Abf. {{cell.data.fp_abfahrt_datzeit | date : 'EE, dd.MM.yyyy HH:mm'}} Uhr -> Info
        &body=Moin Frau/Herr/Familie {{cell.data.gpblg_extern_name}},%0D%0A %0D%0Aes geht um Ihren Gepäckauftrag %0D%0A %0D%0A{{this.getFrontendBaseUrl()}}/gepaeckauftrag/show/{{cell.data.gpblg_id}}/{{cell.data.gpblg_extern_mobiletan || '000000'}}%0D%0A %0D%0A
Mit freundlichem Gruß %0D%0A %0D%0A
%0D%0A %0D%0A{{imprintText}}">{{ cell.text }}</a>
        </div>

        <div style="color:blue; font-size:small"
             *ngIf="(!!cell?.text && !cell?.data?.gpblg_external_order_id && (!cell?.data?.gpblg_extern_mobiletan || cell?.data?.gpblg_extern_mobiletan === '000000')) ">
          <a
            href="mailto:{{cell.text}}?subject=Gepäckauftrag [GEP-{{cell.data.gpblg_id}}] / Abf. {{cell.data.fp_abfahrt_datzeit | date : 'EE, dd.MM.yyyy HH:mm'}} Uhr -> Info
        &body=Moin Frau/Herr/Familie {{cell.data.gpblg_extern_name}},%0D%0A %0D%0Aes geht um Ihren Gepäckauftrag GEP-{{cell.data.gpblg_id}} %0D%0A %0D%0A
Mit freundlichem Gruß %0D%0A %0D%0A
%0D%0A %0D%0A{{imprintText}}">{{ cell.text }}</a></div>
      </div>

      <dxi-column caption="Bemerkung"
                  dataField="gpblg_bemerk_text"
                  width="170"
                  cellTemplate="cellTemplate_smaller"
      ></dxi-column>

      <dxi-column [allowFiltering]="true" caption="Belegempfänger" dataField="gpblg_rgempf_adr_id" width="200">
        <dxo-lookup [dataSource]="getAdresses"
                    displayExpr="adr_such_name"
                    valueExpr="adr_id"
        ></dxo-lookup>
      </dxi-column>

      <dxi-column caption="Online"
                  [visible]="this.authenticationService.isEmployee"
                  dataField="gpblg_extern_kz"
                  width="60"
                  cellTemplate="cellTemplate_smaller"
      ></dxi-column>
      <dxi-column caption="created"
                  [visible]="this.authenticationService.isEmployee"
                  dataField="gpblg_create_stamp"
                  dataType="date"
                  format="dd.MM.yy HH:mm"
                  width="100"
                  cellTemplate="cellTemplate_smaller"
      ></dxi-column>

      <dxi-column caption="changed"
                  [visible]="this.authenticationService.isEmployee"
                  dataField="gpblg_change_stamp"
                  dataType="date"
                  format="dd.MM.yy HH:mm"
                  width="100"
                  cellTemplate="cellTemplate_smaller"
      ></dxi-column>

      <div *dxTemplate="let data of 'fpArtTemplate'">
        <img
          class="sits-button-sm"
          alt="Fähre"
          src="assets\images\db-siw-faehre2.png"
          *ngIf="data.value === 'FAEHRE'"
        >
        <img
          class="sits-button-sm"
          alt="Flug"
          src="assets\images\airplane-2.svg"
          *ngIf="data.value === 'FLUG'">
      </div>

      <div *dxTemplate="let cell of 'cellTemplate_smaller'">
        <div class="sits-text-small-pad">{{ cell.text }}</div>
      </div>
    </dx-data-grid>

    <dx-tooltip
      target="fp_abfahrt_datzeit"
      showEvent="dxhoverstart"
      hideEvent="dxhoverend" position="right">
      <div *dxTemplate="let data of 'content'">
        <div class='tooltipContent'>
          <div>{{ currentGepaeckBeleg.fp_abfahrt_datzeit | date: 'EE dd.MM.yyyy HH:mm' }}</div>

        </div>
      </div>
    </dx-tooltip>
  </div>
</div>

