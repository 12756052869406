<div class="dx-card sits-card">
  <div class="dx-fieldset">
    <div class="dx-fieldset-header">
      <dx-toolbar >
        <dxi-item widget="dxButton" location="before">
          <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
        </dxi-item>
        <dxi-item location="left" widget="dxButton" locateInMenu="never">
          <div *dxTemplate>
            <div class="toolbar-label">{{[this.componentTitle]}}</div>
          </div>
        </dxi-item>
      </dx-toolbar>
    </div>
    <dx-toolbar class="py-2 px-1">
      <dxi-item widget="dxButton" location="before" locateInMenu="auto">
        <dx-button icon="refresh" (click)="grid.instance.clearSelection(); refreshFrachtbriefList()"></dx-button>
      </dxi-item>

      <dxi-item id="btnadd" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button (onClick)="actionShowPopup('add')"
                   hint="Neuen Frachtbrief anlegen."
                   icon="plus"></dx-button>
      </dxi-item>
      <dxi-item id="btnduplicate" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button [disabled]="!frachtbriefCopyAllowed"
                   hint="Selektierte Frachtbriefe als Vorlage für neue Frachtbriefe verwenden. Legt eine Kopie für morgen an, die auf Stop gesetzt ist."
                   (onClick)="duplicateSelectedItem()"
                   icon=".\assets/iconexperience/iconex_o2/o_collection_svg/dark_grey/copy.svg"></dx-button>
      </dxi-item>

      <dxi-item id="btnedit" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button (onClick)="actionShowPopup('edit')"
                   hint="Frachtbrief sichten bzw. bearbeiten."
                   [disabled]="(selectedFrachtbriefe.length || 0 ) !== 1"
                   icon="edit"></dx-button>
      </dxi-item>

      <dxi-item id="btnstop" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button [disabled]="(selectedFrachtbriefe.length || 0 ) < 1"
                   hint="Selektierte, bearbeitbare Beleg auf Stop setzen."
                   (onClick)="stopSelectedItems()">
          <span class="badge badge-pill badge-danger">Stop</span>
        </dx-button>
      </dxi-item>
      <dxi-item id="btnunstop" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button [disabled]="(selectedFrachtbriefe.length || 0 ) < 1"
                   hint="Selektierte, bearbeitbare Beleg auf ok setzen (entstoppen)."
                   (onClick)="unstopSelectedItems()">
          <span class="badge badge-pill badge-secondary">ok</span>
        </dx-button>
      </dxi-item>

      <dxi-item id="btnapprove" locateInMenu="auto" location="before" widget="dxButton">
        <dx-button [disabled]="(selectedFrachtbriefe.length || 0 ) < 1"
                   hint="Selektierte Frachtbriefe zum Transport beauftragen und freigeben. Erfordert gültige Daten. Stop muss aus sein."
                   (onClick)="approveSelectedItemsForTransportation()"
                   icon="check"></dx-button>
      </dxi-item>

      <dxi-item id="btndelete" locateInMenu="auto" location="after" widget="dxButton">
        <dx-button (onClick)="popupDeleteFrachtbriefVisible = true"
                   [disabled]="(selectedFrachtbriefe.length || 1 ) !== 1 || (frachtbriefDeleteAllowed === false)"
                   icon="trash"></dx-button>
      </dxi-item>
    </dx-toolbar>


    <dx-popup #popupEditFrachtbrief
              class="popup"
              [showTitle]="true"
              title="Frachtbrief {{getFirstSelectedFrachtbriefId()}}"
              [dragEnabled]="false"
              [hideOnOutsideClick]="false"
              [(visible)]="popupEditFrachtbriefVisible"
              [fullScreen]="true"
              [showCloseButton]="true"
    >
      <div *dxTemplate="let data of 'content'">
        <app-frachtbrief-edit
          [inputAdresseDddw]="adresseDddw"
          [inputFrachtbriefId]=getFirstSelectedFrachtbriefId()
          (outputFrachtbrief)="editedFrachtbrief($event)">
        </app-frachtbrief-edit>
      </div>
    </dx-popup>

    <dx-popup #popupAddFrachtbrief
              class="popup"
              [showTitle]="true"
              title="Neuer Frachtbrief"
              [dragEnabled]="false"
              [hideOnOutsideClick]="false"
              [(visible)]="popupAddFrachtbriefVisible"
              [fullScreen]="true"
              [showCloseButton]="true"
    >
      <div *dxTemplate="let data of 'content'">
        <app-frachtbrief-edit
          [inputAdresseDddw]="adresseDddw"
          [inputFrachtbriefId]=getEmptyDummyFrachtbriefId()
          (outputFrachtbrief)="addedFrachtbrief($event)">
        </app-frachtbrief-edit>
      </div>
    </dx-popup>

    <dx-popup #popupDeleteFrachtbrief
              class="popup"
              height="auto"
              [width]="330"
              title="FrachtBrief verwerfen"
              [(visible)]="popupDeleteFrachtbriefVisible">
      <div *dxTemplate="let data of 'content'">
        <p>Selektierter FrachtBrief: {{ getSelectedFrachtbriefeAsJoinedString() }}</p>
        <p>Das Verwerfen ist nur möglich für Belege ...<br>
          mit Erfassungsstatus kleiner 'Freigabe erteilt',<br>
          mit Transportstatus 'initial'<br>
          und wenn die Faktura noch nicht erfolgt ist.<br>
          FrachtBrief endgültig mit Positionen verwerfen?</p>
        <p>
          <button (click)="popupDeleteFrachtbriefVisible = false"
                  class="btn btn-outline-secondary  mr-3"
                  data-dismiss="modal"
                  type="button">Abbrechen
          </button>
          <button (click)="deleteSelectedItems(); popupDeleteFrachtbriefVisible = false"
                  class="btn btn-outline-danger  mr-3"
                  data-dismiss="modal"
                  type="button">
            Löschen
          </button>
        </p>
      </div>
    </dx-popup>

    <dx-data-grid #grid (onCellPrepared)="onCellPrepared($event)"
                  (onOptionChanged)="optionChangedHandler($event)"
                  (onSelectionChanged)="selectionChangedHandler($event)"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [dataSource]="frachtbriefe"
                  [columnHidingEnabled]="false"
                  [hoverStateEnabled]="true"
                  [rowAlternationEnabled]="true"
                  [selectedRowKeys]="selectedFrachtbriefIds"
                  columnResizingMode="widget"
                  [showBorders]="true"
                  [showColumnLines]="false"
                  [showRowLines]="true"
                  id="grid-container"
                  keyExpr="frako_id">
      <dxo-paging [enabled]="true" [pageSize]="gridPageSize"></dxo-paging>
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[7,8,9,10, 15, 20, 30, 50, 100]"
        [showInfo]="true"
        [showNavigationButtons]="true"
        [showPageSizeSelector]="true"></dxo-pager>
      <dxo-column-chooser [enabled]="false" mode="select"></dxo-column-chooser>
      <dxo-remote-operations
        [filtering]="true"
        [groupPaging]="true"
        [grouping]="true"
        [paging]="true"
        [sorting]="true"
        [summary]="false">
      </dxo-remote-operations>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="none"></dxo-selection>
      <dxo-scrolling mode="standard" [useNative]="true"></dxo-scrolling>
      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxi-column [sortIndex]="2"
                  caption="FB-Nr."
                  dataField="frako_id"
                  dataType="number"
                  sortOrder="desc"
                  width="70"
      ></dxi-column>
      <dxi-column [sortIndex]="1"
                  caption="FB-Datum"
                  dataField="frako_datum"
                  dataType="date"
                  format="dd.MM.yy"
                  sortOrder="desc"
                  width="75"
      ></dxi-column>
      <dxi-column [sortIndex]="3"
                  caption="Beleg"
                  dataField="frako_rg_nr"
                  dataType="number"
                  sortOrder="desc"
                  width="70"
      ></dxi-column>
      <dxi-column caption="Stop" cellTemplate="activeTemplate" dataField="frako_stop_kz" width="45"></dxi-column>

      <div *dxTemplate="let data of 'activeTemplate'">
          <span [ngClass]="{'badge-secondary': !data.value, 'badge-danger': data.value}"
                class="badge badge-pill"
          >{{data.value ? 'Stop' : 'ok'}}</span>
      </div>
      <dxi-column caption="Parität" dataField="frako_paritaet_code" width="70">
        <dxo-lookup [dataSource]="getParitaets"
                    displayExpr="name"
                    valueExpr="code"
        ></dxo-lookup>
      </dxi-column>
      <dxi-column [allowFiltering]="true" caption="Absender" dataField="frako_absender_adr_id" width="200">
        <dxo-lookup [dataSource]="getAdresses"
                    valueExpr="adr_id"
                    displayExpr="adr_such_name"
        ></dxo-lookup>
      </dxi-column>
      <dxi-column [allowFiltering]="true" caption="Empfänger" dataField="frako_empf_adr_id" width="200">
        <dxo-lookup [dataSource]="getAdresses"
                    displayExpr="adr_such_name"
                    valueExpr="adr_id"
        ></dxo-lookup>
      </dxi-column>
      <dxi-column [allowFiltering]="true" caption="Belegempfänger" dataField="frako_rgempf_adr_id" width="200">
        <dxo-lookup [dataSource]="getAdresses"
                    displayExpr="adr_such_name"
                    valueExpr="adr_id"
        ></dxo-lookup>
      </dxi-column>
      <dxi-column caption="von ..." dataField="frako_abgang_fort_id" width="100">
        <dxo-lookup [dataSource]="getFrachtOrts"
                    displayExpr="fort_name"
                    valueExpr="fort_id"
        ></dxo-lookup>
      </dxi-column>
      <dxi-column caption="... nach" dataField="frako_ziel_fort_id" width="100">
        <dxo-lookup [dataSource]="getFrachtOrts"
                    valueExpr="fort_id"
                    displayExpr="fort_name"
        ></dxo-lookup>
      </dxi-column>
      <dxi-column caption="Erfassungsstatus" dataField="frako_fest_status_nr" width="70">
        <dxo-lookup [dataSource]="getFrachtErfassungsStatuss"
                    displayExpr="fest_name"
                    valueExpr="fest_status_nr"
        ></dxo-lookup>
      </dxi-column>
      <dxi-column caption="Menge"
                  dataField="frapo_menge_sum"
                  dataType="number"
                  tooltip="Summe der Frachtmenge pro Frachtbrief. Sollte > 0 sein."
                  width="60"
      ></dxi-column>
      <dxi-column caption="ohne Tarif"
                  dataField="frapo_notarif_cnt"
                  dataType="number"
                  tooltip="Belege mit # Positionen ohne Tarifberechnung oder Artikelwert"
                  width="60"
      ></dxi-column>
      <dxi-column caption="Transport-status" dataField="frako_trst_status_nr" width="90">
        <dxo-lookup [dataSource]="getTransportStatuss"
                    displayExpr="trst_name"
                    valueExpr="trst_status_nr"
        ></dxo-lookup>
      </dxi-column>
      <dxi-column caption="Fähre IST" dataField="frako_ist_fp_id" width="110">
        <dxo-lookup [dataSource]="getFahrplans"
                    [displayExpr]="getDisplayAbfahrtzeitVoll"
                    valueExpr="fp_id"
        ></dxo-lookup>
      </dxi-column>
      <dxi-column caption="Fähre Plan" dataField="frako_plan_fp_id" width="110">
        <dxo-lookup [dataSource]="getFahrplans"
                    [displayExpr]="getDisplayAbfahrtzeitVoll"
                    valueExpr="fp_id"
        ></dxo-lookup>
      </dxi-column>
      <dxi-column caption="Bemerkung"
                  dataField="frako_bemerk_text"
                  width="170"></dxi-column>
    </dx-data-grid>
  </div>
</div>
