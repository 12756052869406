<div class="sits-container">
  <div class="sits-card-center dx-card sits-card-small">
    <div class="dx-fieldset">
      <div class="dx-fieldset">
        <div class="dx-fieldset-header">
          <dx-toolbar>
            <dxi-item widget="dxButton" location="before">
              <dx-button (click)="this.goBack()" icon="back" hint="zurück navigieren"></dx-button>
            </dxi-item>
            <dxi-item location="left" widget="dxButton" locateInMenu="never">
              <div *dxTemplate>
                <div class="toolbar-label">{{ [this.componentTitle] }}</div>
              </div>
            </dxi-item>
            <dxi-item widget="dxButton" location="before" locateInMenu="auto">
              <dx-button id="link1" icon="info" hint="Hinweise zu diesem Dialog"
                         (click)="this.popoverToggleDefault()"></dx-button>
            </dxi-item>
          </dx-toolbar>
        </div>
        <dx-popover
          target="#link1"
          position="bottom"
          cdkScrollable
          [width]="300"
          [showTitle]="true"
          title="Hinweise ..."
          [(visible)]="popoverVisible">
          <div *dxTemplate="let data = model of 'content'">
            Aus Sicherheitsgründen können nicht alle Daten bearbeitbar sein.<br>
            Senden Sie uns bitte bei Bedarf eine Mail mit der hier verwendeten E-Mail-Adresse mit klaren Angaben, welche
            Änderungen Sie wünschen. Dann nehmen wir die Änderungen kurzfristig vor.
          </div>
        </dx-popover>
        <dx-toolbar>
        </dx-toolbar>
        <div class="dx-field" #formcontainer>
          <form class="userdata-form" autocomplete="on">
            <dx-form #userForm [(formData)]="userData"
                     labelLocation="top"
                     [scrollingEnabled]="true"
                     [showValidationSummary]="true"
                     colCount="1"
                     [width]="getMaxCardWidth()"
                     [showColonAfterLabel]="false"
                     (onFieldDataChanged)="form_fieldDataChanged($event)">

              <dxi-item [editorOptions]="{disabled: true,
                      inputAttr: { autocomplete: 'on'},
                      name: 'username' }"
                        dataField="userName"
                        editorType="dxTextBox"
                        id="username">
                <dxo-label
                  text="Benutzername / Login"
                  horizontalAlignment="left">
                </dxo-label>
                <dxi-validation-rule type="required"
                                     message="Benutzername ist erforderlich."></dxi-validation-rule>
              </dxi-item>

              <dxi-item [editorOptions]="{disabled: this.isDisableEditing,
                      inputAttr: { autocomplete: 'on'},
                      name: 'firstname' }"
                        dataField="firstName"
                        editorType="dxTextBox"
                        id="firstname">
                <dxo-label
                  text="Vorname"
                  horizontalAlignment="left">
                </dxo-label>
              </dxi-item>

              <dxi-item [editorOptions]="{disabled: this.isDisableEditing,
                      inputAttr: { autocomplete: 'on'},
                      name: 'lastname' }"
                        dataField="lastName"
                        editorType="dxTextBox"
                        id="lastname">
                <dxo-label
                  text="Nachname"
                  horizontalAlignment="left">
                </dxo-label>
                <dxi-validation-rule type="required"
                                     message="Nachname ist erforderlich."></dxi-validation-rule>
              </dxi-item>

              <dxi-item [editorOptions]="{disabled: this.isDisableEditing,
                      inputAttr: { autocomplete: 'on'},
                      name: 'companyname' }"
                        dataField="companyName"
                        editorType="dxTextBox"
                        id="companyname">
                <dxo-label
                  text="Firma"
                  horizontalAlignment="left">
                </dxo-label>
              </dxi-item>

              <dxi-item dataField="street"
                        id="street"
                        editorType="dxTextBox"
                        [editorOptions]="{ disabled: this.isDisableEditing,
                      inputAttr: { autocomplete: 'on'},
                      hint: 'Persönliche Anschrift, die auch für Belege verwendet werden kann. (Nicht die Urlaubsanschrift, Gepäckadresse, ...)',
                      name: 'street'}">
                <dxo-label
                  text="Straße HausNr."
                  horizontalAlignment="left">
                </dxo-label>
              </dxi-item>

              <dxi-item dataField="zipCode"
                        id="zipcode"
                        editorType="dxTextBox"
                        [editorOptions]="{ disabled: this.isDisableEditing,
                      inputAttr: { autocomplete: 'on'},
                      hint: null,
                      name: 'zipcode'}">
                <dxo-label
                  text="PLZ"
                  horizontalAlignment="left">
                </dxo-label>
              </dxi-item>

              <dxi-item dataField="city"
                        id="city"
                        editorType="dxTextBox"
                        [editorOptions]="{ disabled: this.isDisableEditing,
                      inputAttr: { autocomplete: 'on'},
                      hint: 'Die Angabe einer SMS-fähigen Telefonnummer wird dringend empfohlen, damit wir Sie bei der Bedarf per SMS informieren können.',
                      name: 'city'}">
                <dxo-label
                  text="Ort"
                  horizontalAlignment="left">
                </dxo-label>
              </dxi-item>

              <dxi-item dataField="mobilePhone"
                        id="telephone"
                        editorType="dxTextBox"
                        [editorOptions]="{ disabled: this.isDisableEditing,
                      inputAttr: { autocomplete: 'on'},
                      hint: 'Die Angabe einer SMS-fähigen Telefonnummer wird dringend empfohlen, damit wir Sie bei der Bedarf per SMS informieren können.',
                      name: 'telephone'}">
                <dxo-label
                  text="Mobiltelefon-Nr."
                  horizontalAlignment="left">
                </dxo-label>
                <dxi-validation-rule type="required"
                                     message="Mobiltelefon ist erforderlich."></dxi-validation-rule>
                <dxi-validation-rule type="custom"
                                     [validationCallback]="getIsValidPhoneNumber"
                                     [reevaluate]="true"
                                     message="Ungültiges Format oder unerlaubte Zeichen in der Telefonnummer. Beispiele: 0177-1234567, 0177-99999999, +49.177.99999999 oder ähnlich.">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item [editorOptions]="{disabled: this.isDisableEditing,
                inputAttr: { autocomplete: 'on'},
                name: 'email',
                hint: 'Optional, dringend empfohlen. Erlaubt uns die Übermittlung ausführlicherer Informationen ergänzend zur SMS-Benachrichtigung.'}"
                        dataField="mail"
              >
                <dxo-label
                  text="E-Mail-Adresse"
                  horizontalAlignment="left">
                </dxo-label>
                <dxi-validation-rule type="required"
                                     message="E-Mail ist erforderlich."></dxi-validation-rule>
                <dxi-validation-rule type="email"
                                     [reevaluate]="true"
                                     message="Ungültige E-Mail-Adresse.">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item
                [disabled]="this.isDisableEditing"
                [visible]="!(this.isDisableEditing || this.isProcessing)"
                itemType="button"
                horizontalAlignment="left"
                [buttonOptions]="buttonSubmit">
              </dxi-item>

              <dxi-item itemType="empty" [colSpan]="2">&nbsp;</dxi-item>
              <dxi-item itemType="empty" [colSpan]="2">&nbsp;<br></dxi-item>
            </dx-form>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

