<mat-card>
  <mat-card-title>Optionen</mat-card-title>
  <mat-card-content>
    <p>Hier können Einstellungen gewählt werden, die die Arbeit mit dem System erleichtern.</p>
    <form [formGroup]="settingsForm" (submit)="onSubmit()">
      <div class="form-group">
        <mat-form-field appearance="standard">
          <mat-label>Zeilen-Limit</mat-label>
          <mat-hint>max. Anzahl von Zeilen, die gelesen werden soll.</mat-hint>
          <input type="number" matInput
                 placeholder="readMaxRowsPerPageCount"
                 formControlName="readMaxRowsPerPageCount"
                 value="{{settingsForm.value['readMaxRowsPerPageCount']}}">
          <div class="invalid-feedback" *ngIf="settingsForm.controls['readMaxRowsPerPageCount'].errors">
            <div *ngIf="settingsForm.controls['readMaxRowsPerPageCount'].hasError('min')">Wert ist zu klein</div>
            <div *ngIf="settingsForm.controls['readMaxRowsPerPageCount'].hasError('max')">Wert ist zu groß</div>
          </div>
        </mat-form-field>
      </div>
      <br>
      <button mat-button class="btn btn-default" (click)="cancel()">Abbruch</button>
      <button mat-button class="btn btn-primary" (click)="onSubmit()">Speichern</button>

      <!--
        <button  class="btn btn-default" btnRadio="hideNewMapDetails" (click)="cancel()"><span>Abbruch</span></button>&nbsp;
        <button class="btn btn-primary" btnRadio="hideNewMapDetails" (click)="onSubmit()"><span>Speichern</span></button>
      -->
    </form>
    </mat-card-content>
</mat-card>
