import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Adresse} from '../api-dtos/adresse-dto';
import {AdresseDddw} from '../api-dtos/adresse-dddw';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AdresseService {
  baseUrl: string = `${environment.restApiUrl}/adresse`;
  diversePortalKundenAdrIdDefault = 10080;
  diversePortalKundenAdrIdCurrent = 0;
  adresseDddwCache: AdresseDddw[] = [];
  gepaeckAdresseDddwCache: AdresseDddw[] = [];
  storageboxAdresseDddwCache: AdresseDddw[] = [];
  adresseCache: Adresse[] = [];
  private adressAllObs$ = new ReplaySubject(1);
  private lastRefresh: Date[] = [new Date(), new Date(), new Date()];
  protected customerIdUsedForCaching: number;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) {
    this.lastRefresh[Caches.Adresse] = new Date();
    this.lastRefresh[Caches.AdresseDddw] = new Date();
    this.lastRefresh[Caches.GepaeckAdresseDddw] = new Date();
    this.lastRefresh[Caches.StorageboxAdresseDddw] = new Date();
  }

  static handleError(error: any) {
    let errorMessage: string;

    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return;
  }

  public getAll(filter: AdresseFilter, forceRefresh?: boolean) {
    let url = `${this.baseUrl}`;
    if (filter) {
      url = url + filter.getFilter();
    }

    // If the Subject was NOT subscribed before OR if forceRefresh is requested
    if (!this.adressAllObs$.observers.length || forceRefresh) {
      this.http.get(url, this.getHttpHeader()).subscribe(
        data => this.adressAllObs$.next(data),
        error => {
          this.adressAllObs$.error(error);
          // Recreate the Observable as after Error we cannot emit data anymore
          this.adressAllObs$ = new ReplaySubject(1);
        }
      );
    }
    return this.adressAllObs$;
  }

  public async getFiltered(filter: AdresseFilter): Promise<Adresse[]> {
    let url = `${this.baseUrl}`;
    if (filter) {
      url = url + filter.getFilter();
    }
    return await this.http.get<Adresse[]>(
      url, this.getHttpHeader()).toPromise();
  }

  public async getAllAsync(): Promise<Adresse[]> {
    if (!this.isApprovedForProcessing()) {
      this.resetCaches(Caches.Adresse);
      // await console.log('Adrsrv no appr');
      return this.adresseCache;
    }
    // refresh cache once an hour
    if (new Date() > new Date(this.lastRefresh[Caches.Adresse].getTime() + (1000 * 60 * 60))) {
      this.resetCaches(Caches.Adresse);
    }
    if (this.adresseCache?.length > 0
      && ((this.authenticationService.isCustomer && this.authenticationService.customerId > 0
        && !!this.customerIdUsedForCaching && this.authenticationService.customerId === this.customerIdUsedForCaching)
        || (this.authenticationService.isEmployee && (!this.customerIdUsedForCaching || this.customerIdUsedForCaching === 0)))) {
      return this.adresseCache;
    } else {
      if (this.authenticationService.isCustomer) {
        this.customerIdUsedForCaching = this.authenticationService.customerId;
      } else {
        [this.customerIdUsedForCaching] = await Promise.all([null]);
      }
      this.adresseCache = await this.http.get<Adresse[]>(
        this.baseUrl, this.getHttpHeader())
        .toPromise();
      this.lastRefresh[Caches.Adresse] = new Date();
      return this.adresseCache;
    }
  }

  public async getAllDddw(): Promise<AdresseDddw[]> {
    if (!this.isApprovedForProcessing()) {
      this.resetCaches(Caches.AdresseDddw);
      return this.adresseDddwCache;
    }
    // refresh cache once an hour
    if (new Date() > new Date(this.lastRefresh[Caches.AdresseDddw].getTime() + (1000 * 60 * 60))) {
      this.resetCaches(Caches.AdresseDddw);
    }
    // wenn Cache vorhanden, aber Customer nicht in der Liste ist, auch resetten ...
    if (this.adresseDddwCache?.length > 0
      && this.authenticationService?.isCustomer
      && !this.adresseDddwCache.find(x => x.adr_id === this.authenticationService.customerId)) {
      this.resetCaches(Caches.AdresseDddw);
      return this.adresseDddwCache;
    }

    if (this.adresseDddwCache && this.adresseDddwCache.length > 0
      && ((this.authenticationService.isCustomer && this.authenticationService.customerId > 0
        && !!this.customerIdUsedForCaching && this.authenticationService.customerId === this.customerIdUsedForCaching)
        || (this.authenticationService.isEmployee && (!this.customerIdUsedForCaching || this.customerIdUsedForCaching === 0)))) {
      return this.adresseDddwCache;
    } else {
      if (this.authenticationService.isCustomer) {
        this.customerIdUsedForCaching = this.authenticationService.customerId;
      } else {
        this.customerIdUsedForCaching = await null;
      }
      this.adresseDddwCache = await this.http.get<Adresse[]>(
        this.baseUrl + '/dropdownlist', await this.getHttpHeader())
        .toPromise();
      this.lastRefresh[Caches.AdresseDddw] = new Date();
      return this.adresseDddwCache;
    }
  }

  /**
   * getGepaeckAdrDddw, keine Authentifizierung erforderlich
   */
  public async getGepaeckAdrDddw(): Promise<AdresseDddw[]> {
    // refresh cache data once an hour
    // console.log('getGepaeckAdrDddw');
    if (new Date() > new Date(this.lastRefresh[Caches.GepaeckAdresseDddw].getTime() + (1000 * 60 * 60))) {
      this.resetCaches(Caches.GepaeckAdresseDddw);
    }

    if (!!this.gepaeckAdresseDddwCache && this.gepaeckAdresseDddwCache.length > 0) {
      // console.log('getGepaeckAdrDddw taking the cache');
      return this.gepaeckAdresseDddwCache;
    }

    await this.http.get<Adresse[]>(
      this.baseUrl + '/strassehausnr/dropdownlist')
      .toPromise().then(res => {
        this.gepaeckAdresseDddwCache = res;
        this.lastRefresh[Caches.GepaeckAdresseDddw] = new Date();
        // console.log('getGepaeckAdrDddw return after read', this.gepaeckAdresseDddwCache);
        return this.gepaeckAdresseDddwCache;
      });

    this.gepaeckAdresseDddwCache = await this.http.get<Adresse[]>(
      this.baseUrl + '/strassehausnr/dropdownlist')
      .toPromise();
    this.lastRefresh[Caches.GepaeckAdresseDddw] = new Date();
    // console.log('getGepaeckAdrDddw return after read', this.gepaeckAdresseDddwCache);
    return this.gepaeckAdresseDddwCache;
  }

  /**
   * getGepaeckAdrDddw, keine Authentifizierung erforderlich
   */
  public getGepaeckAdrDddwNow(): Observable<AdresseDddw[]> {
    if (new Date() > new Date(this.lastRefresh[Caches.GepaeckAdresseDddw].getTime() + (1000 * 60 * 60))) {
      this.resetCaches(Caches.GepaeckAdresseDddw);
    }

    if (!!this.gepaeckAdresseDddwCache && this.gepaeckAdresseDddwCache.length > 0) {
      return of(this.gepaeckAdresseDddwCache);
    }

    return this.http.get<AdresseDddw[]>(
      this.baseUrl + '/strassehausnr/dropdownlist')
      .pipe(
        map(res => {
          this.gepaeckAdresseDddwCache = res;
          this.lastRefresh[Caches.GepaeckAdresseDddw] = new Date();
          // console.log('getGepaeckAdrDddw return after read', this.gepaeckAdresseDddwCache);
          return res;
        })
      );
  }

  /**
   * getGepaeckAdrDddw, keine Authentifizierung erforderlich
   */
  public async getStorageboxAdrDddw(): Promise<AdresseDddw[]> {
    if (new Date() > new Date(this.lastRefresh[Caches.StorageboxAdresseDddw].getTime() + (1000 * 60 * 60))) {
      this.resetCaches(Caches.StorageboxAdresseDddw);
    }

    if (!!this.storageboxAdresseDddwCache && this.storageboxAdresseDddwCache.length > 0) {
      return this.storageboxAdresseDddwCache;
    }

    await this.http.get<Adresse[]>(
      this.baseUrl + '/strassehausnr/dropdownlist/storagebox')
      .toPromise().then(res => {
        this.storageboxAdresseDddwCache = res;
        this.lastRefresh[Caches.StorageboxAdresseDddw] = new Date();
        return this.storageboxAdresseDddwCache;
      });

    this.storageboxAdresseDddwCache = await this.http.get<Adresse[]>(
      this.baseUrl + '/strassehausnr/dropdownlist/storagebox')
      .toPromise();
    this.lastRefresh[Caches.StorageboxAdresseDddw] = new Date();
    return this.storageboxAdresseDddwCache;
  }


  public async create(adresse: Adresse) {
    return await this.http.post<Adresse>(
      this.baseUrl,
      adresse,
      await this.getHttpHeader()
    ).toPromise();
  }

  public async update(id: number, adresse: Adresse) {
    try {
      const url = `${this.baseUrl}/${id}`;
      return await this.http.put(
        url, adresse, this.getHttpHeader()).toPromise();
    } catch (error) {
      AdresseService.handleError(error);
    }
  }

  public async delete(id: number) {
    // await this.resetCaches();
    return await this.http.delete(
      `${this.baseUrl}/${id}`,
      await this.getHttpHeader()
    ).toPromise();
  }

  isApprovedForProcessing() {
    return this.authenticationService.isAuthenticated && (this.authenticationService.isEmployee || this.authenticationService.isCustomer);
  }

  public resetCaches(cache: number) {
    if (!this.adresseCache) {
      this.adresseCache = [new Adresse()];
    }
    if (!this.adresseDddwCache) {
      this.adresseDddwCache = [new AdresseDddw()];
    }

    if (cache === Caches.All || cache === Caches.Adresse) {
      this.adresseCache.splice(0, this.adresseCache.length);
      this.lastRefresh[Caches.Adresse] = new Date();
    }
    if (cache === Caches.All || cache === Caches.AdresseDddw) {
      this.adresseDddwCache.splice(0, this.adresseDddwCache.length);
      this.lastRefresh[Caches.AdresseDddw] = new Date();
    }
    if (cache === Caches.All) {
      this.customerIdUsedForCaching = null;
    }
  }

  private getHttpHeader() {
    const accessToken: string = this.authenticationService.sessionKey;
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  public async getDiversePortalKundenAdrId() {
    if (this.diversePortalKundenAdrIdCurrent !== undefined && this.diversePortalKundenAdrIdCurrent > 0) {
      return this.diversePortalKundenAdrIdCurrent;
    }
    return await this.getDiversePortalKundenAdrIdFromBackend().then(res =>{
      this.diversePortalKundenAdrIdCurrent = res.diversePortalKundenAdrId !== undefined ? res.diversePortalKundenAdrId : this.diversePortalKundenAdrIdDefault;
      return this.diversePortalKundenAdrIdCurrent;
    });
  }

  public async getDiversePortalKundenAdrIdFromBackend(): Promise<any> {
    let url = `${this.baseUrl}/diverseportalkundenadrid`;
    return this.http.get<any>(
      url, this.getHttpHeader()).toPromise();
  }
}

@Injectable()
export class AdresseFilter {
  id?: number;
  suchName?: string;
  plz?: string;
  ort?: number;

  public getFilter(): string {
    let filter: string[];
    filter = [];
    if (this.id) {
      filter.push('id=' + this.id);
    }

    if (this.suchName) {
      filter.push('suchName=' + this.suchName);
    }
    if (this.plz) {
      filter.push('plz=' + this.plz);
    }
    if (this.ort) {
      filter.push('ort=' + this.ort);
    }

    let concatedFilter: string = '';
    for (let i = 0; i < filter.length; i++) {
      concatedFilter += filter[i];
      if (i < filter.length - 1) {
        concatedFilter += '&';
      }
    }

    if (!!concatedFilter) {
      concatedFilter = '?' + concatedFilter;
    }
    return concatedFilter;
  }
}

enum Caches {
  All,
  Adresse = 1,
  AdresseDddw = 2,
  GepaeckAdresseDddw = 3,
  StorageboxAdresseDddw = 4
}

