// import {DataElement} from '../api-basics/data-element';

export class FrachtPosition {
  mand_id?: number;
  frako_id?: number;
  frapo_id?: number;
  frapo_sort_nr?: number;
  art_nr?: number;
  frapo_anz?: number;
  frapo_verpack_code?: string;
  frapo_ware_name?: string;
  frapo_menge?: number;
  frapo_aufschlag_proz?: number;
  frapo_trab_proz?: number;
  frapo_fracht1_amount?: number;
  frapo_fracht2_amount?: number;
  frapo_fracht3_amount?: number;
  frapo_artikel_amount?: number;
  frapo_tarif_amount?: number;
  frapo_rg_yn?: number;
  frapo_lang_text?: string;
  frapo_me_code?: string;
  frapo_preis?: number;
  ustk_nr?: number;
  frapo_typ_nr?: number;
  frapo_lgtxt_fontsize_nr?: number;
  frapo_lgtxt_bold_yn?: number;
  art_frachtberechnung_yn?: number;
  frapo_stellflaeche_brutto_m2?: number;
  frapo_frachtberechnung_manuell_yn?: number;
}

