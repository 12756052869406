import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../../shared-services/authentication.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GeraeteVermietungStorageBox} from '../api-dtos/geraete-vermietung-storage-box';
import {of} from 'rxjs/internal/observable/of';
import {ITan} from '../api-dtos/itan';
import {Observable} from 'rxjs/internal/Observable';
import {delay, map} from 'rxjs/operators';
import {IArtikel} from '../api-dtos/artikel';
import {Lock} from '../api-dtos/lock';
import {LockCategory} from './lock.service';
import {lastValueFrom} from 'rxjs/internal/lastValueFrom';

@Injectable({
  providedIn: 'root'
})

export class StorageBoxService {
  baseUrl: string = `${environment.restApiUrl}/storagebox`;
  payedByTypes = [{code: 'PayPal', name: 'PayPal'},
    {code: 'Vorkasse', name: 'Vorkasse'},
    {code: 'Bar', name: 'Bar'},
    {code: 'Rechnung', name: 'Rechnung'}];
  private _defaultLagerboxServiceArt: IArtikel;
  private _maxLengthOfLeaseItemsPerOrder = 1;
  private _maxLengthOfLeaseYears = 1;
  private _maxLengthOfLeaseMonths = 0;
  private _maxLengthOfLeaseDays = 0;
  private _maxLengthOfLeaseHours = 0;
  private _defaultLengthOfLeaseYears = 1;
  private _defaultLengthOfLeaseMonths = 0;
  private _defaultLengthOfLeaseDays = 0;
  private _defaultLengthOfLeaseHours = 0;

  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient) {
    this.getDefaultLagerboxServiceArtikel().then(resArt => {
      this._defaultLagerboxServiceArt = resArt;
    });
  };

  static handleError(error: any) {
    let errorMessage;

    if (error.error && error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = 'ii ' + error.error.message;
    } else {
      // Get server-side error
      if (!!error.error && error.error.count) {
        errorMessage = 'ee 0 ' + error.error[0];
      } else if (error.message && error.message !== '') {
        errorMessage = `${error.message}`;
      } else {
        if (error.status !== undefined && error.status < 300) {
          return;
        }
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
    }
    window.alert(errorMessage);
    /* const errMsg = error.message
       ? error.message
       : error.status
         ? `${error.status} - ${error.statusText} - ${error._body}`
         : 'Server error';*/
    return;
  }

  private static async getHttpHeaderAsync() {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  private static getHttpHeader() {
    const accessToken: string = localStorage.getItem('sessionKey');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    if (accessToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return httpOptions;
  }

  public getStorageBoxOrder(gervmId: number, tan: number | undefined): Observable<GeraeteVermietungStorageBox> {
    if (!gervmId || gervmId < 1) {
      return of();
    }
    let orderQuery = `?id=${gervmId.toString()}`;
    if (tan !== undefined) {
      orderQuery = `${orderQuery}&tan=${tan}`;
    }

    return this.http.get<GeraeteVermietungStorageBox>(
      `${this.baseUrl}/order${orderQuery}`,
      StorageBoxService.getHttpHeader()
    ).pipe(
      map(data => {
        return data;
      })
    );
  }

  public async create(storageBoxOrder: GeraeteVermietungStorageBox): Promise<GeraeteVermietungStorageBox> {
    // API erkennt, ob es sich um ein INSERT oder UPDATE handelt.
    return await this.http.post<GeraeteVermietungStorageBox>(
      `${this.baseUrl}/order`,
      storageBoxOrder,
      StorageBoxService.getHttpHeader()
    ).toPromise()
      .then(res => {
        if (!environment.production) console.log('stb.service create return ', res);
        if (!!res.status) {
          return res;
        } else {
          storageBoxOrder.status = 'error';
          return storageBoxOrder;
        }
      }).catch(() => {
        if (!environment.production) console.log('stb.service create catch ', storageBoxOrder);
        storageBoxOrder.status = 'error';
        return storageBoxOrder;
      });
  }

  public async update(storageBoxOrder: GeraeteVermietungStorageBox): Promise<GeraeteVermietungStorageBox> {
    return await this.http.put<GeraeteVermietungStorageBox>(
      `${this.baseUrl}/order`,
      storageBoxOrder,
      StorageBoxService.getHttpHeader()
    ).toPromise().then(res => {
      if (res !== undefined && res.gervm_id && res.gervm_id !== 0 && res.status === 'success') {
        return res;
      } else {
        return storageBoxOrder;
      }
    });
  }

  public async notifyOrderToCustomer(gervm_id: number, gervm_extern_mobiletan: string): Promise<void> {
    return await this.http.put<GeraeteVermietungStorageBox>(
      `${this.baseUrl}/notifyordertocustomer`,
      {gervm_id: gervm_id, gervm_extern_mobiletan: gervm_extern_mobiletan},
      StorageBoxService.getHttpHeader()
    ).toPromise().then(res => {
      return;
    });
  }


  public isStorageBoxOrderEditAllowed(storageBox: GeraeteVermietungStorageBox) {
    return this.authenticationService.isEmployee;
  }

  public getPreisProStorageBox(): number {
    if (this._defaultLagerboxServiceArt === undefined) {
      this.getDefaultLagerboxServiceArtikel().then(resArt => {
        return resArt.art_brutto_preis1;
      });
    }
    return this._defaultLagerboxServiceArt.art_brutto_preis1;
    // return 44.00;
  }

  public async getDefaultLagerboxServiceArtikel(): Promise<IArtikel> {
    if (this._defaultLagerboxServiceArt && !!this._defaultLagerboxServiceArt.art_nr) {
      return this._defaultLagerboxServiceArt;
    }
    return lastValueFrom(this.http.get<IArtikel>(
      `${this.baseUrl}/defaultlagerboxserviceart`,
      StorageBoxService.getHttpHeader()
    ));
  }

  public async getNewTan(personalKey: string): Promise<ITan> {
    return lastValueFrom(this.http.get<ITan>(
      `${environment.restApiUrl}/newTan/storageboxOrder?personalKey=${personalKey}`,
      StorageBoxService.getHttpHeader()
    ));
  }

  public getMinimalStartDate(): Date {
    let startDate: Date;

    if (this.authenticationService.isEmployee) {
      // heute - 62 Tage
      // Mitarbeiter koennen GEP auch nachtraeglich erfassen, bis 31 Tage rueckwirkend
      startDate = new Date(new Date().setHours(6, 0, 0, 0) - (62 * 24 * 60 * 60 * 1000));
    } else {
      // Regel für Kunden ...
      if (new Date().getHours() < 16) {
        // heute + 1 Tag
        startDate = new Date(new Date().setHours(6, 0, 0, 0) + (24 * 60 * 60 * 1000));
      } else {
        // heute + 2 Tage
        startDate = new Date(new Date().setHours(6, 0, 0, 0) + (48 * 60 * 60 * 1000));
      }
    }
    return startDate;
  }

  public getPayedByTypes() {
    return this.payedByTypes;
  }

  public async signAsPayed(gervm_id: number, payedByType: string): Promise<void> {
    await this.http.put(
      `${this.baseUrl}/order/sign/signaspayed/${gervm_id}`,
      {id: gervm_id, payedByType: payedByType},
      await StorageBoxService.getHttpHeaderAsync()
    ).toPromise();
  }

  public async signAsUnPayed(gervm_id: number): Promise<void> {
    await this.http.put(
      `${this.baseUrl}/order/sign/signasunpayed/${gervm_id}`,
      {id: gervm_id},
      StorageBoxService.getHttpHeader()
    ).toPromise();
  }

  public async signAsPayedByPayPal(gervm_id: number,
                                   mobileTan: string,
                                   transactionId: string,
                                   transactionDate: Date | undefined,
                                   transactionData: object | undefined): Promise<void> {
    if (!gervm_id || gervm_id < 1
      || !mobileTan || !transactionId) {
      return;
    }
    if (transactionDate === undefined) {
      transactionDate = new Date();
    }

    const url = `${this.baseUrl}/order/sign/signaspayed`;
    const body = {
      id: gervm_id,
      tan: mobileTan,
      paymentProvider: 'PayPal',
      paymentTransactionId: transactionId,
      paymentTransactionTimestamp: transactionDate,
      transactionData: transactionData
    };

    await this.http.put(
      url, body,
      await StorageBoxService.getHttpHeaderAsync()
    ).toPromise();
  }

  public async delete(gervm_id: number) {
    try {
      return await this.http.delete(
        `${this.baseUrl}/order/${gervm_id}`,
        await StorageBoxService.getHttpHeaderAsync()
      ).toPromise();
    } catch (error) {
      await StorageBoxService.handleError;
    }
  }

  public async getAll(): Promise<GeraeteVermietungStorageBox[]> {
    delay(20);
    const url = `${this.baseUrl}/order/list`;
    // ggf. weitere Parameter?
    return lastValueFrom(this.http.get<GeraeteVermietungStorageBox[]>(
      url,
      await StorageBoxService.getHttpHeaderAsync()
    ));
  }

  public calcRentalEndDate(beginDate: Date): Date {
    return new Date(this.addYearPeriods(beginDate, this._maxLengthOfLeaseYears).setHours(18, 0));
  }

  public addDays(date: Date, days: number): Date {
    let newDate: Date = new Date(date);
    if (days === 0) {
      return newDate;
    }
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  public addYears(date: Date, years: number): Date {
    let newDate: Date = new Date(date);
    if (years === 0) {
      return newDate;
    }
    let currentYear: number = newDate.getFullYear();
    newDate.setFullYear(currentYear + years);
    return newDate;
  }

  public addYearPeriods(date: Date, years: number): Date {
    let newDate: Date = new Date(date);
    if (years === 0) {
      return newDate;
    }
    newDate = this.addYears(date, years);
    if (years > 0) {
      return this.addDays(newDate, -1);
    }
    return this.addDays(newDate, +1);
  }


  public addMonths(date: Date, months: number): Date {
    let newDate: Date = new Date(date);
    if (months === 0) {
      return newDate;
    }
    newDate.setFullYear(newDate.getFullYear(), newDate.getMonth() + months);
    return newDate;
  }

  public getNewPreAllocatedStorageBoxOrder(): GeraeteVermietungStorageBox {
    // 13712 = Zeltplatz
    const storageBoxOrder: GeraeteVermietungStorageBox = {
      gervm_id: null,
      gervm_anz: 1,
      gervm_ger_nr: null,
      gervm_ger_name: null,
      gervm_art_nr: null,
      gervm_ladestelle_adr_id: 13712,
      gervm_extern_transpbed_bestaetigt_yn: null
    };
    if (!environment.production) console.log('storageBoxService getNewPreAllocatedStorageBoxOrder ', storageBoxOrder);
    return storageBoxOrder;
  }

  public getDisableEditingLagerBox(storageBoxOrder: GeraeteVermietungStorageBox): boolean {
    // Mitarbeiter duerfen die Lagerbox auch nach Mietbeginn aendern (tauschen)
    // Kunden duerfen das nicht
    if (!storageBoxOrder) {
      return true;
    }
    let isDisabled = true;
    // console.log( 'storageBoxService.getDisableEditingLagerBox', );
    isDisabled = !storageBoxOrder.gervm_beginn_datzeit || !storageBoxOrder.gervm_ende_datzeit
      || storageBoxOrder.gervm_beginn_datzeit >= storageBoxOrder.gervm_ende_datzeit;

    if (this.authenticationService.isAuthenticated && this.authenticationService.isCustomer && !isDisabled) {
      isDisabled = storageBoxOrder.gervm_beginn_datzeit < new Date();
    }
    return isDisabled;
  }

  getStorageBoxLockData(gervm_ger_nr: number): Lock {
    let lockData: Lock = new Lock();
    lockData.lock_tbl1_krz = 'GER';
    lockData.lock_tbl1_id1 = gervm_ger_nr;
    lockData.lock_internal_tag = this.authenticationService.getPublicSessionId();
    lockData.lock_tag = lockData.lock_tbl1_krz + '#' + gervm_ger_nr.toString();
    lockData.lock_lockcat_id = LockCategory.Reservation;
    lockData.lock_for_seconds = 1800;
    lockData.lock_create_user = this.authenticationService.isAuthenticated && !!this.authenticationService.currentUser.userName
      ? this.authenticationService.currentUser.userName
      : 'Gast';
    return lockData;
  }

}
